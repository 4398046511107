import {
  BrowserRouter,
  Route,
  Navigate,
  Routes as Switch
} from "react-router-dom"

import PrivateComponent from "./PrivateComponent"

import DashboardLayout from '../layouts/Dahsboard'

import SignIn         from "../views/SingIn"
import SignUp         from "../views/SignUp"
import Home           from "../views/Home"
import BuyOrders      from "../views/Requests"
import ListRequests  from "../views/Requests/ListRequests"
import FormRequests   from "../views/Requests/FormRequests"
import EditRequest   from "../views/Requests/EditRequest"
import Suppliers      from "../views/Suppliers"
import ListSuppliers  from "../views/Suppliers/ListSuppliers"
import AddNewSupplier from "../views/Suppliers/AddNewSupplier"
import SupplierEdit   from "../views/Suppliers/SupplierEdit"
import Preferences    from "../views/Preferences"
import ChangeImage    from "../views/Preferences/ChangeImages"

/* import { ProductsRecord } from "../views/Records/Products" */
import { InvoincingClients } from "../views/SellDetails/InvoincingClients"
import { InvoincingProducts } from "../views/SellDetails/InvoicingProducts"
/* import { EditProduct } from "../views/Records/Products/EditProduct"
import { ClientsRecord } from "../views/Records/Clients" */
import { DefaultRecord } from "../views/Records/Default"
/* import { EditClient } from "../views/Records/Clients/EditClient" */
import { SellDetails } from "../views/SellDetails"
import { ResetPass } from "../views/ResetPass"
import { RequestDetails } from "../views/RequestDetails"
import { MainDashboard } from "../views/Dashboards/Main"
import { QuoteDashboard } from "../views/Dashboards/Quote"
import { SupplyDashboard } from "../views/Dashboards/Supply"

import "@trendmicro/react-sidenav/dist/react-sidenav.css"
import { Tutorials } from "../views/Tutorials"

const Routes: React.FC = () => (
  <BrowserRouter>
    <Switch>
      <Route path="/" element={ <DashboardLayout/>} >
        <Route path="/" element={ <PrivateComponent component={<Home />} isPrivate/>}/>
        <Route path="/buy-orders"  element={ <PrivateComponent component={<BuyOrders/>} isPrivate/> }>
          <Route path="/buy-orders/list"          element={ <PrivateComponent component={<ListRequests/>} isPrivate/> }/>
          <Route path="/buy-orders/addNew"        element={ <PrivateComponent component={<FormRequests/>} isPrivate/> } />
          <Route path="/buy-orders/details/:type" element={ <PrivateComponent component={<EditRequest/>} isPrivate/> }/>
          <Route path="/buy-orders"               element={ <Navigate to="/buy-orders/list"/> } />
          <Route path="/buy-orders/*"             element={ <Navigate to="/buy-orders/list"/> } />
        </Route>
        <Route path="/dashboard"        element={ <PrivateComponent component={ <MainDashboard /> } isPrivate /> }/>
        <Route path="/dashboard/quote" element={ <PrivateComponent component={ <QuoteDashboard /> } isPrivate /> } />
        <Route path="/dashboard/supply" element={ <PrivateComponent component={ <SupplyDashboard /> } isPrivate /> } />
        <Route path="/suppliers"   element={ <PrivateComponent component={<Suppliers/>} isPrivate /> }>
          <Route path="/suppliers/list"          element={ <PrivateComponent component={<ListSuppliers />} isPrivate /> }/>
          <Route path="/suppliers/addNew"        element={ <PrivateComponent component={<AddNewSupplier />} isPrivate /> } />
          <Route path="/suppliers/details/:type" element={ <PrivateComponent component={<SupplierEdit />} isPrivate /> } />
          <Route path="/suppliers"               element={ <Navigate to="/suppliers/list"/> } />
          <Route path="/suppliers/*"             element={ <Navigate to="/suppliers/list"/> } />
        </Route>
        <Route path="/invoicing/details" element={ <PrivateComponent component={ <SellDetails /> } isPrivate/> }>
          <Route path="/invoicing/details/client" element={ <PrivateComponent component={ <InvoincingClients /> } isPrivate/> }/>
          <Route path="/invoicing/details/products" element={ <PrivateComponent component={ <InvoincingProducts /> } isPrivate/> }/>
          <Route path="/invoicing/details" element={ <Navigate to="/invoicing/details/client"/> } />
          <Route path="/invoicing/details/*" element={ <Navigate to="/invoicing/details/client"/> } />
        </Route>
        <Route path="/requests" element={ <PrivateComponent component={ <RequestDetails /> } isPrivate/> }/>
        <Route path="/preferences" element={<PrivateComponent component={<Preferences/>} isPrivate/> }>
          <Route path="/preferences/change-image/:option" element={<PrivateComponent component={<ChangeImage/>} isPrivate/> }/>
          <Route path="/preferences/*"                  element={<Navigate to="/preferences"/> }/>
        </Route>
        <Route path="/records">
          {/* <Route path="/records/products" element={<PrivateComponent component={<ProductsRecord/>} isPrivate/> }/>
          <Route path="/records/products/edit/:product" element={<PrivateComponent component={<EditProduct/>} isPrivate/> }/>
          <Route path="/records/products/*"             element={<Navigate to="/"/> }/>
          <Route path="/records/clients"                element={<PrivateComponent component={<ClientsRecord/>} isPrivate/> }/>
          <Route path="/records/clients/edit/:client"   element={<PrivateComponent component={<EditClient/>} isPrivate/> }/>
          <Route path="/records/clients/*"              element={<Navigate to="/"/> }/> */}
          <Route path="/records/:category"              element={<PrivateComponent component={<DefaultRecord/>} isPrivate/> }/>
          <Route path="/records/:category/*"            element={<Navigate to="/"/> }/>
        </Route>
        <Route path="/tutorials" element={ <PrivateComponent component={ <Tutorials /> } isPrivate /> }/>
      </Route>
      <Route path="/login" element={ <SignIn/> } />
      <Route path="/signup" element={ <SignUp/> } />
      <Route path="/reset-pass" element={ <ResetPass /> } />
      <Route path="*" element={ <Navigate to="/"/> } />
    </Switch>
  </BrowserRouter>
)

export default Routes
import { PaginationButton } from "./style"

interface PaginationItemProps{
    isCurrent?: boolean
    number: number
    onChangePage: (page: number) => void
}

export const PaginationItem: React.FC<PaginationItemProps> = ({ 
    number, 
    isCurrent = false, 
    onChangePage 
}) =>{
    if(isCurrent){
        return (
            <PaginationButton disabled>
                { number }
            </PaginationButton>
        )
    }

    return (
        <PaginationButton onClick={() => onChangePage(number) }>
            { number }
        </PaginationButton> 
    )
}
export const convertDateToEnglishPattern = (date: string): string => {
    const pattern = "00"

    let newDate = new Date()
    
    const aux = date as string
    const datePassed = aux.split("/")
    const dateToPass = [datePassed[1], datePassed[0], datePassed[2]].toString()
    newDate = new Date(dateToPass)

    const year = newDate.getFullYear()
    const month = pattern.substring(0, pattern.length - (newDate.getMonth() + 1).toString().length) + (newDate.getMonth() + 1).toString()
    const day = pattern.substring(0, pattern.length - newDate.getDate().toString().length) + newDate.getDate().toString()

    return `${month}-${day}-${year}`
}
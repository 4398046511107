import React from 'react'
import { useState, useEffect, useCallback } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'

import apiService from '../../../services/api.services'

import { RecordsType } from '../../../data/recordsType'

import { useAuth } from '../../../hooks/auth'

import { getErrorMessage } from '../../../utils/validations/getErrorMessage'

import { MobileLoading } from '../../../components/Mobile/Loading'
import ListMobileCard from '../../../components/Mobile/ListMobileCard'
import { AddItemForm } from './AddItemForm'

import { Add, Search } from '@mui/icons-material'
import { DataGrid, ptBR, GridColumns } from '@mui/x-data-grid'
import { Dialog, Stack, TextField, useTheme as useMuiTheme, useMediaQuery } from '@mui/material'

import { 
    Container,
    DefaultTable,
    SearchField,
    Header,
    Actions
} from './style'

interface Item{
    chave: string
    descricao: string
}

const categoryList = [
    "motives",
    "gamas",
    "brands"
]

export const DefaultRecord: React.FC = () => {

    const navigate = useNavigate()
    const routeParams = useParams()

    const { userData } = useAuth()

    const muiTheme = useMuiTheme()
    const isMobileView = useMediaQuery(muiTheme.breakpoints.down("sm"))

    const [pageIsReady, setPageIsReady] = useState<boolean>(false)

    const [isLoading, setIsLoading] = useState<boolean>(true)

    const [openAddDialog, setOpenAddDialog] = useState<boolean>(false)

    const [tableList, setTableList]         = useState<Item[]>([])
    const [filterContent, setFilterContent] = useState<string>("")

    const [tableColumns] = useState<GridColumns>([
        { field: 'chave', headerName: 'Código', width: 140 },
        { field: 'descricao', headerName: 'Descrição', flex: 1 }
    ])

    const getRecordInfo = async(): Promise<void> => {
        setTableList([])
        setIsLoading(true)

        try{
            const response = await apiService.get("/tabela_generica", {
                params: {
                    tabela: RecordsType[routeParams.category as string]
                }
            })

            if(response.data.data){
                setTableList(response.data.data.tabela_generica)
            }

        }catch(error: any){
            console.log(error)
            toast.error(getErrorMessage(error))
        } finally{
            setIsLoading(false)
        }
    }

    const handleCloseAddItemDialog = () => {
        setOpenAddDialog(false)
    }

    const saveNewItem = useCallback(async(data: Item) => {
        try{
            await apiService.post("/tabela_generica", data, {
                params: {
                    tabela: RecordsType[routeParams.category as string]
                }
            })

            setTableList(prev => [...prev, data])

            toast.success("Registro inserido com sucesso")
            handleCloseAddItemDialog()
        }catch(error: any){
            toast.error(getErrorMessage(error))
        }
    },[routeParams.category])

    useEffect(() => {
        const { category } = routeParams

        let hasPermission = false

        switch(category){
            case "motives": 
                hasPermission = !!userData.permissions?.includes('FINANC')
                break
            case "gamas": 
                hasPermission = !!userData.permissions?.includes('CADGAM')
                break
            case "brands": 
                hasPermission = !!userData.permissions?.includes('CADMAR')
                break
            default: break
        }

        if(!hasPermission){
            toast.info("Você não tem permissão para acessar essa pagina")

            navigate("/")
        } else{
            setPageIsReady(true)
        }
    },[userData, routeParams, navigate])

    useEffect(() => {
        if(pageIsReady){
            if(categoryList.includes(routeParams.category as string)){
                getRecordInfo()
            } else{
                navigate("/")
            }
        }

        return () => {
            setFilterContent("")
            setTableList([])
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[routeParams, pageIsReady])

    return(
        <Container>
            <Header>
                <SearchField>
                    <TextField
                        label="Pesquisar"
                        size="small"
                        type="text"
                        value={ filterContent }
                        fullWidth
                        onChange={ event => setFilterContent(event.target.value) }
                    />
                    <button type="button" onClick={ getRecordInfo }>
                        <Search/>
                    </button>
                </SearchField>
                <Actions>
                    <button onClick={ () => setOpenAddDialog(true)}>
                        { isMobileView ? <Add /> : "Adicionar Registro" }
                    </button>
                </Actions>
            </Header>
            { !isMobileView ? (
                <DefaultTable>
                    <DataGrid
                        localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
                        rows={ tableList }
                        columns={ tableColumns }
                        pageSize={ 30 }
                        rowsPerPageOptions={[30]}
                        getRowId={ item => item.chave }
                        disableColumnFilter
                        loading={ isLoading }
                        components={{
                            NoRowsOverlay: () => (
                                <Stack height="100%" alignItems="center" justifyContent="center">
                                    Nenhum resultado encontrado
                                </Stack>
                            )
                        }}
                    />
                </DefaultTable>
            ):(
                isLoading ? (
                    <MobileLoading />
                ): (
                    tableList.length > 0 ? (
                        tableList.map(item => (
                            <ListMobileCard 
                                key={item.chave}
                                onClick={() => navigate(`/records/items/edit/${item.chave}`)}
                            >
                                <p style={{ marginTop: "-5px" }}><b>{item.chave }</b></p>
                                <p><b>Descrição: </b>{ item.descricao }</p>
                            </ListMobileCard>
                        ))
                    ):(
                        <p style={{ textAlign: "center" }}>Nenhum resultado encontrado</p>
                    )
                )
            )}
            <Dialog
                open={ openAddDialog }
                onClose={ handleCloseAddItemDialog }
                fullWidth
                maxWidth="sm"
            >
                <AddItemForm 
                    onClose={ handleCloseAddItemDialog } 
                    onSubmit={ saveNewItem }
                />
            </Dialog>
        </Container>
    )
}
import React from 'react'
import { useState, useEffect, useCallback } from 'react'

import api from '../../../services/api.services'

import { useAuth } from '../../../hooks/auth'

import { removeSymbolsOnString } from '../../../utils/format/removeSymbolsOnString'
import { getErrorMessage } from '../../../utils/validations/getErrorMessage'

import { Button } from '../../Buttons/Button'

import { GridColumns } from '@mui/x-data-grid'
import { Close } from '@mui/icons-material'
import { TextField } from '@mui/material'

import { RemoveButton } from './style'

import { toast } from 'react-toastify'
import { SelectionItemModal } from '../SelectionItem'

interface RequestProductsParams{
    tabela: string
    campos: string
    ordem: string
    pesquisa?: string
    procura?: string
}

export interface Client{
    code: string
    name: string
}

interface SelectClientsModalProps {
    open: boolean
    onSelect: (products: Client[]) => void
    onClose: () => void
}

export const SelectClientsModal: React.FC<SelectClientsModalProps> = ({ 
    onSelect, 
    onClose,
    open
}) => {
    const { userData } = useAuth()

    const [filterContent, setFilterContent] = useState<string>("")
    const [isLoading, setIsLoading]         = useState<boolean>(true)

    const [clients, setClients]             = useState<Client[]>([])
    const [selectedItens, setSelectedItens] = useState<Client[]>([])

    const [viewSelectedClients, setViewSelectedClients] = useState<boolean>(false)

    const [tableColumns, setTabelColumns] = useState<GridColumns>([
        { field: 'code', headerName: 'Código', headerAlign: "center", align: "center", width: 140 },
        { field: 'name', headerName: 'Nome', flex: 1 }
    ])

    const filteredClients = clients.filter(client => (
        client.code.includes(filterContent.toUpperCase()) || client.name.includes(filterContent.toUpperCase())
    ))

    const selectClient = useCallback(( row: Client): void => {
        const isSelected = selectedItens.findIndex(clientSelected => clientSelected.code === row.code)

        if(isSelected < 0){ setSelectedItens(prev => [...prev, row])}

    },[selectedItens])

    const unselectClient = useCallback(( id: string ): void => {
        setSelectedItens(prev => prev.filter(client => client.code !== id))
    },[])

    const getClients = useCallback(async(): Promise<void> => {
        setClients([])
        setIsLoading(true)

        const requestParams: RequestProductsParams = {
            tabela: "SX5",
            campos: "X5_CHAVE,X5_DESCRI",
            ordem: "X5_DESCRI",
            procura: "X5_TABELA",
            pesquisa: "_N"
        }

        if(!!filterContent){
            requestParams.pesquisa = removeSymbolsOnString(filterContent.toUpperCase())
        }

        try{
            const response = await api.get("/consultapadrao", {
                params: {
                    ...requestParams,
                    branch: userData.selectedBranch?.id as string
                }
            })

            if(response.data.data){
                setClients(response.data.data.consultapadrao.map((info: any) => ({
                    code: info.X5_CHAVE,
                    name: info.X5_DESCRI,
                })))
            }
        }catch(error: any){
            console.log(error)
            toast.error(getErrorMessage(error))
        } finally{
            setIsLoading(false)
        }
    }, [filterContent, userData])

    const handleSubmit = () => {
        onSelect(selectedItens)
        onClose()

        setTimeout(() => {
            setFilterContent("")
            setClients([])
            setSelectedItens([])
        }, 200)
    }

    useEffect(() => {
        if(open){
            getClients()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[open])

    useEffect(() => {
        if(selectedItens.length <= 0){
            setViewSelectedClients(false)
        }
    },[selectedItens])

    useEffect(() => {
        if(viewSelectedClients){
            setTabelColumns([
                { field: 'code', headerName: 'Código', headerAlign: "center", align: "center", width: 140 },
                { field: 'name', headerName: 'Nome', flex: 1 },
                { field: 'remove', headerName: "Opções", align: "center", renderCell: (props) => (
                    <RemoveButton type="button" onClick={() => unselectClient(props.row.code) }>
                        <Close/>
                    </RemoveButton>
                )}
            ])
        } else{
            setTabelColumns([
                { field: 'code', headerName: 'Código', headerAlign: "center", align: "center", width: 140 },
                { field: 'name', headerName: 'Nome', flex: 1 }
            ])
        }
    }, [viewSelectedClients, unselectClient])

    return(
        <SelectionItemModal.Root
            open={ open }
            onClose={ onClose }
            fullWidth
            maxWidth="md"
        >
            <SelectionItemModal.Header>
                <TextField
                    label="Pesquisar Cliente"
                    size="small"
                    type="text"
                    value={ filterContent }
                    fullWidth
                    disabled={ viewSelectedClients }
                    onChange={event => setFilterContent(event.target.value)}
                />
            </SelectionItemModal.Header>
            <SelectionItemModal.Content>
                { selectedItens.length > 0 && 
                    <p onClick={ () => { setViewSelectedClients(prev => !prev) }}>
                        { viewSelectedClients ? "Voltar" : `${ selectedItens.length } Clients selecionados` }
                    </p>
                }
                <SelectionItemModal.GridTable
                    rows={ viewSelectedClients ? selectedItens : filteredClients }
                    columns={ tableColumns }
                    notFoundMessage="Nenhum produto encontrado"
                    onRowSelect={ row => selectClient(row as Client) }
                    setRowID={ client => client.code }
                    loading={ isLoading }
                    setClassname={ row => selectedItens.findIndex(item => item.code === row.code) >= 0 ? `selected-row` : ""}
                />
            </SelectionItemModal.Content>
            <SelectionItemModal.Actions>
                <Button 
                    type="button"
                    size='large'
                    variant="text"
                    color="primary"
                    onClick={ onClose }
                >
                    Fechar
                </Button>
                <Button 
                    type="submit" 
                    variant="contained"
                    color="primary"
                    size='large'
                    onClick={ handleSubmit }
                >
                    Selecionar
                </Button>
            </SelectionItemModal.Actions>            
        </SelectionItemModal.Root>
    )
}
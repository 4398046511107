import { Skeleton } from '@mui/material'
import styled from 'styled-components'
import { fade } from '../../../../styles/animations'

interface ContainerProps{
    width?: number 
}

export const Container = styled.div<ContainerProps>`
    width: ${props => props.width ? `${props.width}px` : "100%" };
    height: 100%;
    min-height: 250px;
    padding-top: 16px;
    background-color: ${ props => props.theme['gray-700'] };
    border-radius: 10px;
    overflow: hidden;
`

export const SkeletonChart = styled(Skeleton)`
    border-radius: 10px !important;
    min-height: 350px !important;
`

export const NotFoundContainer = styled.div`
    width: 100%;
    height: 100%;
    min-height: 364px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 18px;
    color: ${ props => props.theme["white-100"] };

    text-align: center;

    animation: ${ fade } 300ms;
`
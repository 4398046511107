import React from "react";
import { forwardRef, useState, useImperativeHandle, useRef, useEffect, useCallback } from "react";

import { CompareNFParams, FinishApproveProps } from "../../../../@types/buyOrder";

import { useDocuments } from "../../../../hooks/documents";
import { useBuyOrder } from "../../../../hooks/buyOrders";

import { getErrorMessage } from "../../../../utils/validations/getErrorMessage";

import Assignment from "./Assignment";
import Validations from "./Validations";
import DefaultDialog, { DefaultDialogRef } from "../../../../components/Modal/DefaultDialog";

import { CircularProgress, Dialog, Grid } from "@mui/material";
import { Close } from "@mui/icons-material";

import { DialogTitleCustom } from "../../../../styles/globalStyles";
import { toast } from "react-toastify";

type AssignmentStage = "add" | "aprove" | "validation";

interface DocumentAssignmentDialogProps {
  onlyView?: boolean;
  initialStage?: AssignmentStage;
}

export interface DocumentAssignmentDialogRef {
  isOpen: boolean;
  openDialog: () => void;
  closeDialog: () => void;
}

const DocumentAssignmentDialog: React.ForwardRefRenderFunction<DocumentAssignmentDialogRef, DocumentAssignmentDialogProps> = (
  { initialStage = "add", onlyView },
  ref
) => {
  const { selectedOrder, finalizeCheckNFxOC, aproveDocuments } = useBuyOrder();
  const { currentDocuments, clearDocuments, loadDocuments, saveDocuments } = useDocuments();

  const closeAlertDialog = useRef<DefaultDialogRef>(null);

  const [open, setOpen] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isSavingData, setIsSavingData] = useState<boolean>(false);
  const [stage, setStage] = useState<AssignmentStage>(initialStage as AssignmentStage);

  const totalValue = parseFloat(String(selectedOrder.total).replace(/\./g, "").replace(",", "."));

  const handleClose = useCallback(() => {
    /* if (isSavingData) {
      toast.info("Por favor aguarde até que todos os dados sejam processados");
      return;
    } */

    setOpen(false);
    setTimeout(() => clearDocuments(), 200);
  }, [/* isSavingData,  */clearDocuments]);

  const getHeaderTitle = () => {
    switch (stage) {
      case "aprove":
        return "Aprovar Notas";
      case "validation":
        return "Validação Notas";
      default:
        return "Vincular Notas";
    }
  };

  const containsAnyAlteration = useCallback((): boolean => {
    const existItensForUpdate = currentDocuments.filter((item) => item.status === "1");

    return existItensForUpdate.length > 0;
  }, [currentDocuments]);

  const getDocuments = useCallback(async () => {
    setIsLoading(true);
    try {
      await loadDocuments(selectedOrder.id);
      setStage(initialStage as AssignmentStage);
    } catch (error: any) {
      console.log(error);
      toast.error(getErrorMessage(error));
      handleClose();
    } finally {
      setIsLoading(false);
    }
  }, [selectedOrder, loadDocuments, handleClose, initialStage]);

  const startValidation = useCallback(async () => {
    if (containsAnyAlteration()) {
      const documentsFiles = JSON.parse(sessionStorage.getItem(`DocumentsForRequest${selectedOrder.id}`) || "null") as any[];

      if (!documentsFiles) {
        toast.warning(`Nenhum arquivo foi anexado`);
        return;
      }

      let haveEmptyFile = false;
      let documentsList = "";

      currentDocuments.forEach((item) => {
        if (!item.hasFile && item.status === "1") {
          haveEmptyFile = true;
          documentsList += item.number;
        }
      });

      if (haveEmptyFile) {
        toast.warning(`O(s) documento(s) ${documentsList} não foram anexados o arquivo da NF`);
        return;
      }
    }

    setStage("validation");
  }, [currentDocuments, selectedOrder.id, containsAnyAlteration]);

  const submitValidation = useCallback(
    async (data: CompareNFParams) => {
      try {
        const documentsFiles = JSON.parse(sessionStorage.getItem(`DocumentsForRequest${selectedOrder.id}`) || "null") as any[];

        console.log(documentsFiles)

        if (!documentsFiles) {
          toast.warning(`Falha ao obter arquivos, tente anexa-los novamente.`);
          return;
        }

        toast.info("Por favor aguarde até todos os dados dos documentos serem salvos.");

        for (let document of documentsFiles) {
          await saveDocuments({
            currentDocument: document.idNF,
            files: document.assignedDocs,
            selectedOrder: selectedOrder,
          });
        }

        console.log("teste", data)

        await finalizeCheckNFxOC(data);

        toast.success("Todos os dados foram salvos com sucesso");

        handleClose();
      } catch (error: any) {
        console.log(error);
        toast.error(getErrorMessage(error));
      }
    },
    [selectedOrder, handleClose, saveDocuments, finalizeCheckNFxOC]
  );

  const handleApprovement = async ({ documents, type }: FinishApproveProps) => {
    setIsSavingData(true);
    try {
      await aproveDocuments({ documents, type });

      if (type === "A") {
        toast.success("O(s) documento(s) foram aprovados com sucesso");
      }

      if (type === "R") {
        toast.success("O(s) documento(s) foram reprovadas com sucesso");
      }
      handleClose();
    } catch (error: any) {
      console.log(error);
      toast.error(getErrorMessage(error));
    } finally {
      setIsSavingData(false);
    }
  };

  useImperativeHandle(ref, () => ({
    isOpen: open,
    openDialog: () => setOpen(true),
    closeDialog: handleClose,
  }));

  useEffect(() => {
    if (open) {
      getDocuments();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  return (
    <>
      <Dialog
        open={open}
        fullWidth
        maxWidth="lg"
        disableEscapeKeyDown
        onClose={(_, reason) => {
          if (reason !== "backdropClick") {
            handleClose();
          }
        }}
      >
        <DialogTitleCustom>
          {getHeaderTitle()}
          <button
            onClick={() => {
              if (containsAnyAlteration()) {
                closeAlertDialog.current?.openDialog();
              } else {
                handleClose();
              }
            }}
          >
            <Close />
          </button>
        </DialogTitleCustom>
        {isLoading ? (
          <Grid container spacing={2}>
            <Grid item xs={12} md={12} height={"40vh"} display="flex" alignItems="center" justifyContent="center">
              <CircularProgress />
            </Grid>
          </Grid>
        ) : (
          <>
            {stage === "add" && (
              <Assignment 
                hasAlteration={containsAnyAlteration()} 
                onFinishProcess={startValidation} 
                onlyView={onlyView} 
              />
            )}
            {(stage === "validation" || stage === "aprove") && (
              <Validations
                type={stage}
                isSaving={isSavingData}
                total={totalValue}
                updateSaveDataState={setIsSavingData}
                onBackPage={() => setStage("add")}
                onFinishValidation={submitValidation}
                onFinishApprove={handleApprovement}
                onlyView={onlyView}
              />
            )}
          </>
        )}
      </Dialog>
      <DefaultDialog
        ref={closeAlertDialog}
        contentText={`Ao fechar você perderá todas as alterações realizadas.\nTem certeza que deseja prosseguir?`}
        open={false}
        titleText="Sair sem salvar"
        action={handleClose}
      />
    </>
  );
};

export default forwardRef(DocumentAssignmentDialog);

import styled from 'styled-components'
import { css } from 'styled-components'
import { shade } from 'polished'

import { StatusIconType } from './index'

export interface ContainerProps{
    status: StatusIconType
}

export const Container = styled.div<ContainerProps>`
    width: 20px;
    height: 20px;
    background-color: #c2c2c2;
    border-radius: 50%;
    box-shadow: 5px 5px 5px ${ shade(0.2, "#F9F9F9")};

    ${props => props.status === "active" && css`
        background-color: #149914;
    `}

    ${props => props.status === "blocked" && css`
        background-color: #c53030;
    `}

    ${props => props.status === "maintener" && css`
        background-color: #FF9000;
    `}

    ${props => props.status === "cancel" && css`
        background-color: #1a1a19;
    `}

    ${props => props.status === "await" && css`
        background-color: #e3e309;
    `}

    ${props => props.status === "default" && css`
        background-color: #1b7ccc;
    `}

    ${props => props.status === "secondary" && css`
        background-color: #743ac9;
    `}

    &:hover{
        cursor: pointer;
    }
`

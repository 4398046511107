import React from 'react'
import { useState, useEffect, useCallback } from 'react'

import api from '../../../services/api.services'

import { useAuth } from '../../../hooks/auth'

import { removeSymbolsOnString } from '../../../utils/format/removeSymbolsOnString'
import { getErrorMessage } from '../../../utils/validations/getErrorMessage'

import { Button } from '../../Buttons/Button'

import { GridColumns } from '@mui/x-data-grid'
import { Close, Search } from '@mui/icons-material'
import { TextField } from '@mui/material'

import { RemoveButton, SearchProduct } from './style'

import { toast } from 'react-toastify'
import { SelectionItemModal } from '../SelectionItem'

interface RequestProductsParams{
    tabela: string
    campos: string
    ordem: string
    bloqueio: string
    pesquisa?: string
    procura?: string
}

export interface Product{
    id?: string | number
    codigo: string
    descricao: string
    um: string
    cc: string
    cc_pel?: string
    cc_approver?: string
    cartao_corporativo?: boolean
    ncm: string
    valor_unitario?: number | string
    valor_total?: number | string
    quantidade?: number
    data_venc_titulo?: string
}

interface SelectProductProps {
    open: boolean
    disableMultiSelection?: boolean
    /* onlyPA?: boolean */
    onSelect: (products: Array<Product>) => void
    onClose: () => void
}

export const SelectProductModal: React.FC<SelectProductProps> = ({ 
    onSelect, 
    onClose, 
    /* onlyPA = false, */
    disableMultiSelection,
    open
}) => {
    const { userData } = useAuth()

    const [filterContent, setFilterContent] = useState<string>("")
    const [isLoading, setIsLoading]         = useState<boolean>(true)
    const [selectedItens, setSelectedItens] = useState<Array<Product>>([])
    const [productsList, setProductsList]   = useState<Array<Product>>([])

    const [viewSelectedProducts, setViewSelectedProducts] = useState<boolean>(false)

    const [tableColumns, setTabelColumns] = useState<GridColumns>([
        { field: 'codigo', headerName: 'Código Produto', headerAlign: "center", align: "center", width: 140 },
        { field: 'descricao', headerName: 'Descrição', width: 500 },
        { field: 'cc', headerName: 'Centro de Custo', headerAlign: "center", align: "center", flex: 1, minWidth: 150 },
        { field: 'ncm', headerName: 'NCM', headerAlign: "center", align: "center", flex: 1, minWidth: 150 },
        { field: 'um', headerName: "Unidade de Medida", flex: 1, align: "center", headerAlign: "center", minWidth: 170}
    ])

    const selectProduct = useCallback(( row: Product): void => {
        if(disableMultiSelection){
            setSelectedItens([row])
            return
        }

        const isSelected = selectedItens.findIndex(productSelected => productSelected.codigo === row.codigo)

        if(isSelected < 0){ setSelectedItens(prev => [...prev, row])}

    },[selectedItens, disableMultiSelection])

    const unselectProduct = useCallback(( id: string ): void => {
        setSelectedItens(prev => prev.filter(product => product.codigo !== id))
    },[])

    const getProducts = useCallback(async(): Promise<void> => {
        setProductsList([])
        setIsLoading(true)

        const requestParams: RequestProductsParams = {
            tabela: "SB1",
            campos: "B1_COD,B1_DESC,B1_UM,B1_CC,B1_POSIPI,B1_TIPO",
            ordem: "B1_DESC",
            bloqueio: "B1_MSBLQL"
        }

        /* if(onlyPA){
            requestParams.procura = "B1_TIPO"
            requestParams.pesquisa = "PA"
        } */

        if(!!filterContent){
            requestParams.pesquisa = removeSymbolsOnString(filterContent.toUpperCase())
        }

        try{
            const response = await api.get("/consultapadrao", {
                params: {
                    ...requestParams,
                    branch: userData.selectedBranch?.id as string
                }
            })

            if(response.data.data){
                setProductsList(response.data.data.consultapadrao.map((info: any) => ({
                    codigo: info.B1_COD,
                    descricao: info.B1_DESC,
                    um: info.B1_UM,
                    cc: info.B1_CC,
                    ncm: info.B1_POSIPI
                })))
            }
        }catch(error: any){
            console.log(error)
            toast.error(getErrorMessage(error))
        } finally{
            setIsLoading(false)
        }
    }, [filterContent, userData/* , onlyPA */])

    const handleSubmit = () => {
        onSelect(selectedItens)
        onClose()

        setTimeout(() => {
            setFilterContent("")
            setProductsList([])
            setSelectedItens([])
        }, 200)
    }

    useEffect(() => {
        if(open){
            getProducts()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[open])

    useEffect(() => {
        if(selectedItens.length <= 0){
            setViewSelectedProducts(false)
        }
    },[selectedItens])

    useEffect(() => {
        if(viewSelectedProducts){
            setTabelColumns([
                { field: 'codigo', headerName: 'Código Produto', width: 140 },
                { field: 'descricao', headerName: 'Descrição', width: 500 },
                { field: 'cc', headerName: 'Centro de Custo', flex: 1, minWidth: 150 },
                { field: 'ncm', headerName: 'NCM', flex: 1, minWidth: 150 },
                { field: 'um', headerName: "Unidade de Medida", flex: 1, minWidth: 170},
                { field: 'remove', headerName: "Opções", align: "center", renderCell: (props) => (
                    <RemoveButton type="button" onClick={() => unselectProduct(props.row.codigo) }>
                        <Close/>
                    </RemoveButton>
                )}
            ])
        } else{
            setTabelColumns([
                { field: 'codigo', headerName: 'Código Produto', width: 140 },
                { field: 'descricao', headerName: 'Descrição', width: 500 },
                { field: 'cc', headerName: 'Centro de Custo', flex: 1, minWidth: 150 },
                { field: 'ncm', headerName: 'NCM', flex: 1, minWidth: 150 },
                { field: 'um', headerName: "Unidade de Medida", flex: 1, minWidth: 170},
            ])
        }
    }, [viewSelectedProducts, unselectProduct])

    return(
        <SelectionItemModal.Root
            open={ open }
            onClose={ onClose }
            fullWidth
            maxWidth="md"
        >
            <SelectionItemModal.Header>
                <TextField
                    label="Pesquisar Produto"
                    size="small"
                    type="text"
                    value={ filterContent }
                    fullWidth
                    disabled={ viewSelectedProducts }
                    onChange={event => setFilterContent(event.target.value)}
                    onKeyPress={(event => {
                        if(event.key === "Enter"){
                            getProducts()
                        }
                    })} 
                />
                <SearchProduct>
                    <button disabled={ viewSelectedProducts } onClick={ getProducts }>
                        <Search/>
                    </button>
                </SearchProduct>
            </SelectionItemModal.Header>
            <SelectionItemModal.Content>
                { (selectedItens.length  > 0 && !disableMultiSelection) && 
                    <p onClick={ () => { setViewSelectedProducts(prev => !prev) }}>
                        { viewSelectedProducts ? "Voltar" : `${ selectedItens.length } produtos selecionados` }
                    </p>
                }
                <SelectionItemModal.GridTable
                    rows={ viewSelectedProducts && !disableMultiSelection ? selectedItens : productsList }
                    columns={ tableColumns }
                    notFoundMessage="Nenhum produto encontrado"
                    onRowSelect={ row => selectProduct(row as Product) }
                    setRowID={ product => product.codigo }
                    loading={ isLoading }
                    setClassname={ row => selectedItens.findIndex(item => item.codigo === row.codigo) >= 0 ? `selected-row` : ""}
                />
            </SelectionItemModal.Content>
            <SelectionItemModal.Actions>
                <Button 
                    type="button"
                    size='large'
                    variant="text"
                    color="primary"
                    onClick={ onClose }
                >
                    Fechar
                </Button>
                <Button 
                    type="submit" 
                    variant="contained"
                    color="primary"
                    size='large'
                    onClick={ handleSubmit }
                >
                    Selecionar
                </Button>
            </SelectionItemModal.Actions>            
        </SelectionItemModal.Root>
    )
}
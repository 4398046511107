import React from 'react'
import { useState, useImperativeHandle, useEffect, forwardRef } from 'react'
import { toast } from 'react-toastify'

import apiService from '../../../../../services/api.services'
import { useAuth } from '../../../../../hooks/auth'
import { useBuyOrder } from '../../../../../hooks/buyOrders'

import { getErrorMessage } from '../../../../../utils/validations/getErrorMessage'

import { Button } from '../../../../../components/Buttons/Button'

import { 
    Dialog,
    DialogActions, 
    DialogProps,
    CircularProgress,
    Stack
} from '@mui/material'

import { DataGrid, GridColumns, ptBR } from '@mui/x-data-grid'
import { Close } from '@mui/icons-material'

import { DialogTitleCustom } from '../../../../../styles/globalStyles'
import { Content, InputContainer } from './style'

interface Aprover{
    aprovador: string
    nome_aprovador: string
    substitutos: {
        id: string
        nome: string
    }[]
}

export interface ImplementsDialogRef {
    isOpen: boolean
    openDialog: () => void
    closeDialog: () => void
}

interface ImplementsDialogProps extends DialogProps {
    action: (aproverId: string) => void | Promise<void>
}

const ImplementsDialog: React.ForwardRefRenderFunction<ImplementsDialogRef,ImplementsDialogProps> = ({ 
    action, 
    open: initialOpen, 
    ...rest 
}, ref) => {
    const { userData } = useAuth()
    const { selectedOrder } = useBuyOrder()

    const [open, setOpen]           = useState<boolean>(initialOpen)
    const [isLoading, setIsLoading] = useState<boolean>(false)

    const [isLoadingAprovers, setIsLoadingAprovers] = useState<boolean>(false)

    const [selectedAprover, setSelectedAprover] = useState<string>("")
    const [aproversList, setAproversList] = useState<Aprover[]>([])

    const [tableColumns] = useState<GridColumns>([
        { field: 'aprovador', headerName: 'Aprovador', headerAlign: "left", align: "left", width: 100},
        { field: 'nome_aprovador', headerName: 'Nome', headerAlign: "left", align: "left", flex: 1},
        { field: 'substituto', headerName: 'Substituto', headerAlign: "left", align: "left", flex: 1, renderCell(params) {
            return params.row.substitutos[0]?.nome
        }}
    ])

    const handleClose = () => {
        setIsLoading(false)
        setOpen(false)
        setIsLoadingAprovers(false)
        setAproversList([])
        setSelectedAprover("")
    }

    const getAprovers = async() => {
        setIsLoadingAprovers(true)
        try{
            const response = await apiService.get("/efetivar", {
                params: {
                    branch: userData.selectedBranch?.id,
                    ordem: selectedOrder.id
                }
            })

            // ajustar mensagem de erro 

            if(response.data.data?.efetivar.length > 0){
                if(response.data.data?.efetivar.length === 1){
                    setSelectedAprover(response.data.data?.efetivar[0]?.aprovador)
                }

                setAproversList(response.data.data.efetivar)
            }
    
            setIsLoadingAprovers(false)
        } catch(error: any){
            console.log(error)
            toast.error(getErrorMessage(error))

            handleClose()
        }
    }

    const handleSubmit = async() => {
        if(isLoadingAprovers){ return }

        if(!selectedAprover){
            toast.warning("Por favor selecione um aprovador para efetivar essa solicitação")
            return
        }

        setIsLoading(true)
        await action(selectedAprover)
        setIsLoading(false)
    }

    useImperativeHandle(ref, () => ({
        isOpen: open,
        openDialog: () => setOpen(true),
        closeDialog: handleClose
    }))

    useEffect(() => {
        if(open){ getAprovers() }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[open])

    return(
        <Dialog
            { ...rest }
            open={ open }
            onClose={ handleClose }
        >
            <DialogTitleCustom>
                Efetivar
                <button onClick={ handleClose }><Close/></button>
            </DialogTitleCustom>
            <Content>
                <p><b>Tem certeza que deseja efetivar essa solicitação?</b></p>
                <InputContainer>
                    <DataGrid
                        localeText={ ptBR.components.MuiDataGrid.defaultProps.localeText }
                        rows={ aproversList }
                        columns={ tableColumns }
                        pageSize={30}
                        rowsPerPageOptions={[30]}
                        getRowId={ aprover => aprover.aprovador }
                        getRowClassName={({ row }) => selectedAprover === row.aprovador ? `selected-row` : ""}
                        disableColumnFilter
                        hideFooter
                        disableColumnMenu
                        loading={ isLoadingAprovers }
                        onRowClick={({ row }) => {
                            setSelectedAprover(prev => prev === row.aprovador ? "" : row.aprovador)
                        }}
                        components={{
                            NoRowsOverlay: () => (
                                <Stack height="100%" alignItems="center" justifyContent="center">
                                    Nenhum fornecedor encontrado
                                </Stack>
                            )
                        }}
                    />
                </InputContainer>
                <p><b>Lembrete: </b>Após a efetivação dessa solicitação não será mais possível efetuar alterações ou exclusão.</p>
            </Content>
            <DialogActions>
                <Button
                    variant="text"
                    fullWidth
                    disabled={ isLoading }
                    onClick={ handleClose }
                >
                    Cancelar
                </Button>
                <Button
                    color="success"
                    variant="contained"
                    fullWidth
                    disabled={ isLoading }
                    onClick={ handleSubmit }
                >
                    { isLoading ? <CircularProgress size={ 24 } color="inherit" /> : "Confirmar" }
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default forwardRef(ImplementsDialog)
import { useCallback, useRef } from 'react'
import { useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import { useAuth } from '../../../hooks/auth'
import { useTheme } from '../../../hooks/theme'
import { MenuContext } from '../../../hooks/useMenu'

import MenuBody from './MenuBody'
import MenuItem from './MenuItem'

import { MenuCategory } from './MenuCategory'
import MenuButton from './MenuButton'

import AlterParams, { AlterParamsRef } from '../../Modal/AlterParams'

import { 
    /* AccountBox,  */
    AssignmentRounded, 
    /* Business, Category,  */
    Comment, 
    Group, 
    Inventory,
    RequestQuote, 
    RoomPreferences, 
    Sell, 
    Settings, 
    ShoppingCart,
    Dashboard,
    RequestPage,
    ProductionQuantityLimits,
    Info
} from '@mui/icons-material'

interface MenuProps{
    expanded?: boolean
}

export default function Menu({ expanded }: MenuProps){
    const navigate = useNavigate()
    const location = useLocation()

    const { userData } = useAuth()
    const { appImages } = useTheme()

    const paramsDialogRef = useRef<AlterParamsRef>(null)

    const [expandedMenu, setExpandedMenu] = useState<boolean>(!!expanded)

    const toggleMenuExpanded = useCallback((currentValue: boolean) => {
        setExpandedMenu(currentValue)
    },[])

    const getLoggedUserName = (username: string) => {
        const names = username.split(" ")

        return `${names[0]} ${names[names.length - 1]}`
    }

    return(
        <MenuContext.Provider value={{ isExpanded: expandedMenu, toggleMenuExpanded }}>
            <MenuBody 
                title="Portal Naos"
                showUserFooter
                image={ appImages.icon }
                onSelectItem={(eventKey) => {
                    let to = "/" + eventKey
                    if (location.pathname !== to) {
                        navigate(to)
                    }
                    setExpandedMenu(false)
                }}
                userData={{
                    username: userData.username ? getLoggedUserName(userData.username as string) : "",
                    selectedBranch: userData.selectedBranch?.id ? `Filial: ${userData.selectedBranch?.id} - ${userData.selectedBranch?.name}` : ""
                }}
            >
                <MenuCategory 
                    icon={ ShoppingCart } 
                    title='Compras'
                    permissionsToAccess={["CADFOR", "FORMOC"]}
                >
                    <MenuItem 
                        icon={ !expandedMenu ? Group : undefined } 
                        eventKey='suppliers' 
                        access='CADFOR'
                    >
                        Fornecedores
                    </MenuItem>
                    <MenuItem 
                        icon={ !expandedMenu ? RequestQuote : undefined } 
                        eventKey='buy-orders/list'
                        access='FORMOC'
                    >
                        Solicitações
                    </MenuItem>
                </MenuCategory>
                <MenuCategory
                    icon={ Sell }
                    title="Faturamento"
                    permissionsToAccess={["FATDSH", "FATSLA", "FATCOT", "FATVEN", "FATPED"]}
                >
                    <MenuItem 
                        icon={ Dashboard } 
                        eventKey="dashboard"
                        access='FATDSH'
                    >
                        Dashboard
                    </MenuItem>
                    <MenuItem 
                        icon={ Inventory } 
                        eventKey="dashboard/supply"
                        access='FATSLA'
                    >
                        Supply / SLA
                    </MenuItem>
                    <MenuItem 
                        icon={ RequestQuote } 
                        eventKey="dashboard/quote"
                        access='FATCOT'
                    >
                        Cota x Real
                    </MenuItem>
                    <MenuItem 
                        icon={ RequestPage } 
                        eventKey="invoicing/details/client"
                        access='FATVEN'
                    >
                        Detalhes Vendas
                    </MenuItem>
                    <MenuItem 
                        icon={ ProductionQuantityLimits } 
                        eventKey="requests"
                        access='FATPED'
                    >
                        Detalhes Pedidos
                    </MenuItem>
                </MenuCategory>
                <MenuCategory 
                    title='Cadastros'
                    icon={ AssignmentRounded } 
                    permissionsToAccess={["CADPRD", "CADCLI", "SX5MOT", "CADGAM", "CADMAR"]}
                >
                    {/* <MenuItem 
                        eventKey='records/products'
                        icon={ !expandedMenu ? Inventory : undefined } 
                        access="CADPRD"
                    >
                        Produtos
                    </MenuItem>
                    <MenuItem 
                        eventKey='records/clients'
                        icon={ !expandedMenu ? AccountBox : undefined } 
                        access="CADCLI"
                    >
                        Clientes
                    </MenuItem> */}
                    <MenuItem 
                        eventKey='records/motives'
                        icon={ !expandedMenu ? Comment : undefined } 
                        access="SX5MOT"
                    >
                        Motivos Recusas
                    </MenuItem>
                    {/* <MenuItem 
                        eventKey='records/gamas'
                        icon={ !expandedMenu ? Category : undefined } 
                        access="CADGAM"
                    >
                        Gamas
                    </MenuItem>
                    <MenuItem 
                        eventKey='records/brands'
                        icon={ !expandedMenu ? Business : undefined } 
                        access="CADMAR"
                    >
                        Marcas
                    </MenuItem> */}
                </MenuCategory>
                <MenuItem
                    eventKey='tutorials'
                    icon={ Info } 
                    style={{ marginTop: "12px", marginBottom: "12px"}}
                >
                    Central de ajuda
                </MenuItem>
                <MenuCategory 
                    icon={ Settings } 
                    title='Configurações'
                    permissionsToAccess={["PREFER", "PARAME"]}
                >
                    <MenuItem 
                        access="PREFER"
                        eventKey='preferences'
                        icon={ !expandedMenu ? RoomPreferences : undefined } 
                    >
                        Preferências
                    </MenuItem>
                    <MenuButton 
                        access="PARAME"
                        icon={ !expandedMenu ? AssignmentRounded : undefined } 
                        action={() => paramsDialogRef.current?.openDialog() } 
                    >
                        Parâmetros
                    </MenuButton>
                </MenuCategory>
            </MenuBody>
            <AlterParams
                ref={ paramsDialogRef }
                open={ false } //initial open
                fullWidth
                maxWidth="sm"
            />
        </MenuContext.Provider>
    )
}
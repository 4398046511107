import styled from "styled-components"
import { createGlobalStyle } from "styled-components"
import { shade, lighten } from "polished"
import { normalize } from 'styled-normalize'

import { DialogTitle } from "@mui/material"

export const GlobalStyle = createGlobalStyle`
    ${ normalize }
    * {
        margin: 0;
        padding: 0;
        box-sizing: border-box;
        font-family: "Arial";
    }

    p, h1, h2, h3, h4, h5, h6{
        margin: 0px;
    }

    body {
        background-color: ${ props => props.theme["blue-800"] };
        overflow: hidden;
    }

    bordy, input, textarea, button{
        font-family: 'Inter', sans-serif;
    }

    input{
        accent-color: ${ props => props.theme["blue-800"] };
    }

    .MuiMenuItem-root{
        display: flex !important;
        flex-direction: column !important;
        padding: 0px 10px !important;
        justify-content: flex-start !important;
        align-items: flex-start !important;
    }

    .MuiDrawer-paper{
        background-color: #ffffff00 !important;
    }

    .MuiButton-containedInherit{
        background-color: ${ shade(0.2, "#F9F9F9")} !important;
        color: #F9F9F9 !important;

        &:hover{
            background-color: ${ shade(0.3, "#F9F9F9")} !important;
        }
    }

    .MuiDataGrid-cellContent{
        pointer-events: none;
    }

    .MuiDataGrid-columnHeaders{
        background-color: ${ props => props.theme['blue-800'] };
        color: ${ props => props.theme['white-100'] };
        border-color: ${ props => props.theme['blue-800'] } !important;
    }

    .MuiTableRow-head, .MuiTableCell-head{
        background-color: ${ props => props.theme["blue-800"] } !important;
        border-color: ${ props => props.theme['blue-800'] } !important;
        
        span{
            color: ${ props => props.theme['white-100'] };
        }
    }

    .MuiDataGrid-root{
        border-top-left-radius: 0px !important;
        border-top-right-radius: 0px !important;
        border-color: ${ props => props.theme['blue-800'] } !important;
    }

    input:-webkit-autofill,
    input:-webkit-autofill:hover, 
    input:-webkit-autofill:focus, 
    input:-webkit-autofill:active  {
        -webkit-box-shadow: 0 0 0 30px ${ props => lighten(0.1, props.theme["blue-500"]) } inset !important;
        -webkit-text-fill-color: ${ props => props.theme["blue-800"] } !important;
    }

    ::-webkit-scrollbar {
        width: 6px;
        height: 6px;
    }

    ::-webkit-scrollbar-track {
        background: #ffffff00;
    }

    ::-webkit-scrollbar-thumb {
        background-color: #808080;
        border-radius: 10px;
    }

    @media print {
        html, body {
            height: initial !important;
            overflow: initial !important;
            -webkit-print-color-adjust: exact;
        }

        .page-break {
            display: block;
            page-break-before: auto;
        }
    }

    @page {
        margin: 0.2cm 0cm;
    }
`

export const DialogTitleCustom = styled(DialogTitle)`
    background-color: ${props => props.theme["blue-800"] };
    color: #F9F9F9;

    button{
        width: 30px;
        height: 30px;
        position: absolute;
        top: 2px;
        right: 2px;
        background-color: #ffffff00;
        border: none;
        border-radius: 50%;
        padding: 3px;
        transition: 200ms;
        display: flex;
        justify-content: center;
        align-items: center;

        svg{
            font-size: 15px;
            margin-right: -1.5px;
        }
    }

    &:hover{
        cursor: default;
    }

    svg{ color: #F9F9F9; }

    button{
        &:hover{
            background-color: ${props => lighten(0.2, props.theme["blue-800"] ) } !important;
        }

        &:active{
            background-color: ${props => lighten(0.3, props.theme["blue-800"] ) }!important;
        }
    }

`
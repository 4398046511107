import { useState, useEffect, useMemo, useRef } from 'react'
import { formatValue } from '../../../../utils/format/formatValue'
import { Container, OptionsButton, OptionsContainer, OptionsMenu, SkeletonChart, SkeletonHeader } from './style'

import { DataTable  } from '../../../../components/Tables/Datagrid'
import { GridColumn, PinnedContent, PinnedRow } from '../../../../@types/datagrid'
import { Menu as MenuIcon } from '@mui/icons-material'
import { ClickAwayListener, MenuItem } from '@mui/material'
import { exportExcel } from '../../../../utils/misc/exportExcel'

interface Client{
    client: string
    net_sales: number
    invoiced: number
    per_tt: number
    per_target: number
    valor_cota?: number
    per_cota?: number
}

interface ClientsTableProps{
    isLoading?: boolean
    height?: number | string
    clients: Client[]
    hasSelectedSeller?: boolean
}

export const ClientsTable: React.FC<ClientsTableProps> = ({ 
    clients, 
    isLoading, 
    height,
    hasSelectedSeller
}) => {

    const optionsRef = useRef<HTMLDivElement>(null)

    const [clientTableColumns, setClientTableColumns] = useState<GridColumn[]>([])
    const [pinnedRows, setPinnedRows] = useState<PinnedRow[]>([])

    const [openOptions, setOpenOptions] = useState<boolean>(false)

    const summary = useMemo(() => (
        clients.reduce((accumulator, currentValue) => {
            const { total_invoiced, total, total_cota, total_per_tt } = accumulator

            accumulator.total_invoiced = currentValue.invoiced + total_invoiced
            accumulator.total = currentValue.net_sales + total
            accumulator.total_cota = (currentValue.valor_cota || 0) + total_cota
            accumulator.total_per_tt = currentValue.per_tt + total_per_tt

            accumulator.total_per_target = (total_invoiced * 100)/total
            accumulator.total_per_cota = (total_invoiced / total_cota)*100

            return accumulator
        },{
            total_invoiced: 0,
            total: 0,
            total_per_tt: 0,
            total_per_target: 0,
            total_cota: 0,
            total_per_cota: 0
        })
    ),[clients])

    const handleExportData = () => {
        setOpenOptions(false)

        const dataToExport = clients.map(client => {
            const data: any = {
                "Cliente": client.client,
                "Faturado": client.invoiced,
                "Total": client.net_sales,
                "%TT": client.per_tt,
                "%Atendimento": client.per_target
            }

            if(hasSelectedSeller){
                data["Cota"] = client.valor_cota || 0
                data["%Cota"] = client.per_cota || 0
            }

            return data
        })

        exportExcel(dataToExport)
    }

    useEffect(() => {
        let tableColumns: GridColumn[] = [
            { field: "client", headerName: "Cliente" },
            { field: "invoiced", headerName: "Faturado", renderCell: (row: Client) => formatValue(row.invoiced) },
            { field: "net_sales", headerName: "Total", renderCell: (row: Client) => formatValue(row.net_sales) },
            { field: "per_tt", headerName: "% TT", align: "center", headerAlign: "center", renderCell: (row: Client) => formatValue(row.per_tt)  },
            { field: "per_target", headerName: "% Atend.", align: "center", headerAlign: "center", renderCell: (row: Client) => formatValue(row.per_target)  }
        ]

        let pinnedContent: PinnedContent[] = [
            { value: "Total", field: "client", align: "left" },
            { value: formatValue(summary.total_invoiced), field: "invoiced", align: "left" },
            { value: formatValue(summary.total), field: "net_sales", align: "left" },
            { value: formatValue(summary.total_per_target), field: "per_target", align: "center"}
        ]

        if(hasSelectedSeller){
            tableColumns.push({ field: "valor_cota", headerName: "Cota", align: "center", headerAlign: "center", renderCell: (row: Client) => formatValue(row.valor_cota || 0) })
            tableColumns.push({ field: "per_cota", headerName: "%Cota", align: "center", headerAlign: "center", renderCell: (row: Client) => formatValue(row.per_cota || 0) })

            pinnedContent.push({ value: formatValue(summary.total_cota), field: "valor_cota", align: "center" })
            //SUM(Total faturado) / SUM(target cliente)
            pinnedContent.push({ value: formatValue(summary.total_per_cota), field: "per_cota", align: "center" })
            pinnedContent.push({ value: formatValue(summary.total_per_tt), field: "per_tt", align: "center" },)
        } else{
            pinnedContent.push({ value: formatValue(100), field: "per_tt", align: "center" })
        }

        setClientTableColumns(tableColumns)
        setPinnedRows([{
            position: "top",
            content: pinnedContent
        }])

    },[hasSelectedSeller, summary])

    if(isLoading){
        return(
            <Container height={ height }>
                <SkeletonHeader>
                    <SkeletonChart height={"50px"} width={"20%"}/>
                    <SkeletonChart height={"50px"} width={"20%"}/>
                    <SkeletonChart height={"50px"} width={"20%"}/>
                    <SkeletonChart height={"50px"} width={"20%"}/>
                    <SkeletonChart height={"50px"} width={"20%"}/>
                </SkeletonHeader>
                { [...new Array(6)].map((_, index) => (
                    <SkeletonChart key={ index } height={"100px"}/>
                ))}
            </Container>
        )
    }

    return (
        <Container height={ height }>
            <OptionsContainer ref={ optionsRef }>
                <OptionsButton onClick={() => setOpenOptions(prev => !prev)}>
                    <MenuIcon style={{ fontSize: "18px" }} />
                </OptionsButton>
                <OptionsMenu
                    data-testid="user-menu"
                    open={ openOptions }
                    anchorEl={ optionsRef.current }
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                    }}
                >
                    <ClickAwayListener onClickAway={() => setOpenOptions(false)}>
                        <div>
                            <MenuItem onClick={ handleExportData }>Exportar Excel</MenuItem>
                        </div>
                    </ClickAwayListener>
                </OptionsMenu>
            </OptionsContainer>
            <DataTable 
                rows={clients}
                columns={ clientTableColumns }
                paginationType="disabled"
                disableCheckboxSelection
                variant='dark'
                pinnedRows={ pinnedRows }
            />
        </Container>
    )
}
import React from 'react'

import { Container } from './style'

export type StatusIconType = "active" | "blocked" | "maintener" | "cancel" | "await" | "default" | "inherit" | "secondary"

interface StatusIconProps{
    status: StatusIconType
    onPress?: React.MouseEventHandler<HTMLDivElement> | undefined
}

const StatusIcon: React.FC<StatusIconProps> = ({ status, onPress }) =>{
    return (
        <Container onClick={ onPress } status={ status } />
    )
}

export default StatusIcon
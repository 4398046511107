import { Skeleton } from '@mui/material'
import styled from 'styled-components'

interface ContainerProps{
    width?: number 
}

export const Container = styled.div<ContainerProps>`
    width: ${props => props.width ? `${props.width}px` : "100%" };
    padding-top: 16px;
    background-color: ${ props => props.theme['gray-700'] };
    border-radius: 10px;
    overflow: hidden;
    position: relative;

    text:first-child{
        background-color: red !important; 
    }
`

export const SkeletonChart = styled(Skeleton)`
    border-radius: 10px !important;
`

export const LabelChart = styled.label`
    position: absolute;
    top: 128px;
    color: #63cfc8;
    left: calc(50% - 48px);
`
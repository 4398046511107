import { HomeImage, ImageContainer } from './style'

import Logo from "../../assets/logithink.png"

export default function Home(){
    return(
        <ImageContainer>
            <HomeImage src={ localStorage.getItem("DASH_LOGO") || Logo } alt="naos"/>
        </ImageContainer>
    )
}
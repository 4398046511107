import { Skeleton } from "@mui/material";
import styled from "styled-components";
import { fade } from "../../../../styles/animations";

export const Container = styled.div`
    width: 100%;
    height: calc(100vh - 484px);
    min-height: 240px !important;
    border-radius: 10px;
    background-color: ${ props => props.theme['gray-700'] };
    padding: 0px 18px;
`

export const SkeletonChart = styled(Skeleton)`
    border-radius: 10px !important;
`

export const NotFoundContainer = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 18px;
    color: ${ props => props.theme["white-100"] };
    
    text-align: center;

    animation: ${ fade } 300ms;
`
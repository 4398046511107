import { Add } from "@mui/icons-material"

import { AddProduct } from "./style"

interface AddProductButtonProps{
    onSelect: () => void
}

export const AddProductButton: React.FC<AddProductButtonProps> = ({ onSelect }) => (
    <AddProduct
        type="button"
        onClick={ onSelect }
    >
        <Add />
    </AddProduct>
)
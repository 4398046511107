import { useState, useCallback, useRef, useEffect } from "react";
import { FormHandles } from "@unform/core";
import { Form } from "@unform/web";
import { toast } from "react-toastify";
import { useQuery } from "react-query";

import { OCFormDefaultValue } from "../../../../../data/formValues";

import apiService from "../../../../../services/api.services";

import { useBilling } from "../../../../../hooks/billing";
import { useAuth } from "../../../../../hooks/auth";

import { getDate } from "../../../../../utils/format/getData";
import { removeSymbolsOnString } from "../../../../../utils/format/removeSymbolsOnString";
import { getErrorMessage } from "../../../../../utils/validations/getErrorMessage";

import FiltersForm from "../../../../../components/FiltersForm";
import FormAutoComplete from "../../../../../components/Form/FormAutoComplete";
import FormDataPicker from "../../../../../components/Form/FormDataPicker";
import FormInput from "../../../../../components/Form/FormInput";
import FormSelect from "../../../../../components/Form/FormSelect";
import { FilterIcon } from "../../../../../components/Misc/Icons/FilterIcon";
import { Supplier, SelectSupplierModal } from "../../../components/SelectSupplier";

import { Search as SearchIcon } from "@mui/icons-material";
import { Fade, Grid, useTheme as useMuiTheme, useMediaQuery, TextField, Tooltip } from "@mui/material";
import { ActionButton, FilterContainer, FilterPosition, SearchSupplier } from "./style";
import { convertDateToEnglishPattern } from "../../../../../utils/format/convertDateToEnglishPattern";

interface FilterParams {
  id?: string | number;
  datade?: any;
  dataate?: any;
  dentrde?: any;
  dentrate?: any;
  status?: string | number;
  tipo?: string | number;
  solicitante?: string;
  fornecedor?: string | number;
  stNF?: string | number;
  aprovador?: string;
  ccusto?: string;
  corporativeCard?: string | boolean;
  advance?: string | boolean;
}

interface Provider {
  name: string;
  cod: string;
  loj: string;
}

interface RequestersParams {
  onlyAprovers?: boolean;
}

interface FilterOptions {
  requesters?: string[];
  aprovers: any[];
  costCenters: string[];
}

interface RequestFilterProps {
  currentType: string;
  onStartFilter: (filters: FilterParams) => void;
  onCleanFilter?: () => void;
}

export const RequestFilter: React.FC<RequestFilterProps> = ({ currentType, onCleanFilter, onStartFilter }) => {
  const { userData } = useAuth();
  const { getCostCenter } = useBilling();

  const muiTheme = useMuiTheme();
  const isMobileView = useMediaQuery(muiTheme.breakpoints.down("lg"));

  const formRef = useRef<FormHandles>(null);

  const [openSuppliersDialog, setOpenSuppliersDialog] = useState<boolean>(false);
  const [openFilter, setOpenFilter] = useState<boolean>(false);

  const [selectedProvider, setSelectedProvider] = useState<Provider>({} as Provider);

  const { data, error, isLoading } = useQuery<FilterOptions>("requests-filters", () => getFilterOptions(), {
    staleTime: 1000 * 60 * 10, //10min
    refetchInterval: 1000 * 60 * 10, //10min
    refetchOnWindowFocus: false,
  });

  const defaultFilters = JSON.parse(sessionStorage.getItem("@portalnaos.requests.filters") || "{}")

  const getFilterOptions = async (): Promise<FilterOptions> => {
    const [costCenters, requesters, aprovers] = await Promise.all([
      getCostCenter(),
      getRequestUsers(),
      getRequestUsers({ onlyAprovers: true }),
    ]);

    return {
      aprovers,
      costCenters: costCenters.map((item) => `${item.id} - ${item.descricao}`),
      requesters,
    };
  };

  const onSelectSupplier = useCallback((supplier: Supplier) => {
    setSelectedProvider({
      name: supplier.name,
      cod: supplier.cod,
      loj: supplier.loja,
    });
  }, []);

  const getRequestUsers = async (params?: RequestersParams) => {
    const requestParams: any = {
      tabela: "ZP1",
      campos: "ZP1_CODIGO,ZP1_NOME,ZP1_SOBREN",
      ordem: "ZP1_NOME",
      bloqueio: "ZP1_MSBLQL",
    };

    if (params?.onlyAprovers) {
      requestParams["procura"] = "ZP1_APROVA";
      requestParams["pesquisa"] = "S";
    }

    try {
      const response = await apiService.get("/consultapadrao", {
        params: {
          ...requestParams,
          branch: userData.selectedBranch?.id || "",
        },
      });

      if (response.data.data) {
        const requesters = response.data.data.consultapadrao.map((info: any) => `${info.ZP1_CODIGO} - ${info.ZP1_NOME} ${info.ZP1_SOBREN}`);
        return requesters;
      }

      return [];
    } catch (error: any) {
      console.log(error);
      toast.error(getErrorMessage(error));
      return [];
    }
  };

  const filterSuppliers = useCallback(
    async (data: FilterParams) => {
      let params = {} as FilterParams;
      let attribute: keyof FilterParams;

      for (attribute in data) {
        if (!!data[attribute]) {
          if (attribute === "dataate" || attribute === "datade" || attribute === "dentrate" || attribute === "dentrde") {
            const parsedDate = getDate(convertDateToEnglishPattern(data[attribute]));
            console.log(parsedDate);

            params[attribute] = parsedDate;
          } else {
            if (attribute === "advance" || attribute === "corporativeCard") {
              params[attribute] = !!data[attribute];
            }
            if (attribute === "solicitante" || attribute === "aprovador" || attribute === "ccusto") {
              console.log("error");
              params[attribute] = data[attribute]?.split("-")[0].trim();
            } else {
              console.log("error");
              params[attribute] = removeSymbolsOnString(data[attribute] as string);
            }
          }
        }
      }

      if (selectedProvider.cod) {
        params.fornecedor = `${selectedProvider.cod}${selectedProvider.loj}`;
      }
      console.log(params);

      sessionStorage.setItem("@portalnaos.requests.filters", JSON.stringify(params))

      // colocar 
      onStartFilter(params);
      setOpenFilter(false);
    },
    [selectedProvider, onStartFilter]
  );

  const cleanerFilters = useCallback(async () => {
    sessionStorage.removeItem("OC_FILTERS");
    setSelectedProvider({ name: "" } as Provider);
    formRef.current?.reset();

    if (onCleanFilter) {
      onCleanFilter();
    }
  }, [onCleanFilter]);

  useEffect(() => {
    sessionStorage.removeItem("OC_FILTERS");
    setSelectedProvider({ name: "" } as Provider);
    formRef.current?.reset();
  }, [currentType]);

  useEffect(() => {
    if (error) {
      toast.error(getErrorMessage(error));
    }
  }, [error]);

  return (
    <>
      <FilterContainer>
        <Tooltip title="Filtrar">
          <ActionButton onClick={() => setOpenFilter((prev) => !prev)}>
            <FilterIcon />
          </ActionButton>
        </Tooltip>
        <SelectSupplierModal open={openSuppliersDialog} onSelect={onSelectSupplier} onClose={() => setOpenSuppliersDialog(false)} />
      </FilterContainer>
      <Fade in={openFilter}>
        <FilterPosition>
          <FiltersForm onClean={cleanerFilters} onSubmit={() => formRef.current?.submitForm()} onClose={() => setOpenFilter(false)}>
            <Form ref={formRef} onSubmit={filterSuppliers} defaultValue={defaultFilters}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={3}>
                  <FormInput name="id" label="Código" size="small" type="text" fullWidth />
                </Grid>
                {isMobileView && (
                  <Grid item xs={12} md={3}>
                    <FormSelect name="tipo" label="Tipo" fullWidth size="small" options={OCFormDefaultValue.typeRequest} />
                  </Grid>
                )}
                <Grid item xs={12} md={3}>
                  <FormSelect name="status" label="Status" fullWidth size="small" options={OCFormDefaultValue.status} />
                </Grid>
                <Grid item xs={12} md={3}>
                  <FormSelect name="stNF" label="Status NF" fullWidth size="small" options={OCFormDefaultValue.statusNF} />
                </Grid>
                {userData.approver && (
                  <Grid item xs={12} md={6}>
                    <FormAutoComplete name="solicitante" label="Solicitante" options={data?.requesters || []} loading={isLoading} />
                  </Grid>
                )}
                <Grid item xs={12} md={6}>
                  <FormAutoComplete name="aprovador" label="Aprovador" options={data?.aprovers || []} loading={isLoading} />
                </Grid>
                <Grid item xs={12} md={6}>
                  <FormAutoComplete name="ccusto" label="Centro de Custo" options={data?.costCenters || []} loading={isLoading} />
                </Grid>
                <Grid item xs={12} md={userData.approver ? 6 : 12} position="relative">
                  <TextField
                    label="Fornecedor"
                    size="small"
                    type="text"
                    value={selectedProvider.name}
                    fullWidth
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                  <SearchSupplier>
                    <button type="button" onClick={() => setOpenSuppliersDialog(true)}>
                      <SearchIcon />
                    </button>
                  </SearchSupplier>
                </Grid>
                <Grid item xs={6} md={3}>
                  <FormDataPicker name="datade" fullWidth views={["day"]} inputFormat="dd/MM/yyyy" label="Data Inicio" size="small" />
                </Grid>
                <Grid item xs={6} md={3}>
                  <FormDataPicker name="dataate" views={["day"]} fullWidth inputFormat="dd/MM/yyyy" label="Data Fim" size="small" />
                </Grid>
                <Grid item xs={6} md={3}>
                  <FormDataPicker
                    name="dentrde"
                    views={["day"]}
                    fullWidth
                    inputFormat="dd/MM/yyyy"
                    label="Data Entrega Início"
                    size="small"
                  />
                </Grid>
                <Grid item xs={6} md={3}>
                  <FormDataPicker
                    name="dentrate"
                    fullWidth
                    views={["day"]}
                    inputFormat="dd/MM/yyyy"
                    label="Data Entrega Fim"
                    size="small"
                  />
                </Grid>

                <Grid item xs={12} md={6}>
                  <FormSelect
                    name="cartao_corp"
                    label="Cartão Corporativo"
                    fullWidth
                    size="small"
                    options={OCFormDefaultValue.corporativeCard}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <FormSelect name="adiantamento" label="Adiantamento" fullWidth size="small" options={OCFormDefaultValue.advance} />
                </Grid>
              </Grid>
            </Form>
          </FiltersForm>
        </FilterPosition>
      </Fade>
    </>
  );
};

import { useState, useEffect, useRef, forwardRef } from 'react'

import { Visibility } from "@mui/icons-material"
import { Request } from "../.."
import ListMobileCard from "../../../../components/Mobile/ListMobileCard"
import { formatValue } from "../../../../utils/format/formatValue"

import { Container, LoadingContainer } from './style'
import { DetailsDialog } from '../DetailsDialog'
import { CircularProgress } from '@mui/material'

interface RequestsMobileListProps{
    totalRegisters: number
    currentPage: number
    requests: Request[]
    isLoading?: boolean
    onPageGoToTheEnd: (event: "next" | "prev") => void
}

const cardColors = {
    faturado_parcial: "#F8CBAD",
    faturado_total: "#A9D08E",
    cancelado: "#FFFFFF",
    em_processameto: "#FFE699"
}

const RequestsMobileList: React.ForwardRefRenderFunction<
    HTMLDivElement,
    RequestsMobileListProps
> = ({ 
    requests, 
    totalRegisters,
    onPageGoToTheEnd,
    isLoading,
    currentPage
}, ref) => {

    const endListRef = useRef<HTMLDivElement>(null)

    const [allRequests, setAllRequests] = useState<Request[]>([])
    const [openDetailsDialog, setOpenDetailsDialog] = useState<boolean>(false)
    const [selectedRequest, setSelectedRequest] = useState<Request>({} as Request)

    useEffect(() => {
        if(currentPage !== 1){
            setAllRequests(prev => [...prev, ...requests])
        } else{
            setAllRequests(requests)
        }
    },[requests, currentPage])

    useEffect(() => {
        const intersectionObserver = new IntersectionObserver((entries) => {
            if(entries.some(entry => entry.isIntersecting)){
                onPageGoToTheEnd("next")
            }
        })

        if(endListRef.current){
            intersectionObserver.observe(endListRef.current)
        }

        return () => intersectionObserver.disconnect()
    },[endListRef, onPageGoToTheEnd])

    const getStatusMask = (status: string): string => {
        switch(status){
            case "faturado_total": return "Faturado Completo"
            case "faturado_parcial": return "Faturado Parcial"
            case "em_processameto": return "Em Processamento"
            case "cancelado": return "Cancelado"
            default: return ""
        }
    }

    const handleShowDetails = (currentRequest: Request) => {
        setSelectedRequest(currentRequest)
        setOpenDetailsDialog(true)
    }

    const handleCloseDialog = () => {
        setOpenDetailsDialog(false)
        setTimeout(() => setSelectedRequest({} as Request), 200)
    }

    return (
        <>
        <p>{ totalRegisters } Registros encontrados</p>
        <Container ref={ ref }>
            {allRequests.map(request => (
                <ListMobileCard 
                    key={request.id}
                    color={cardColors[request.status]}
                    options={[{
                        title: "Vizualizar",
                        type: "button", 
                        action: "view", 
                        icon: Visibility, 
                        onSelect: () => handleShowDetails(request) 
                    }]}
                >
                    <p style={{ 
                        display: "flex", 
                        justifyContent: "flex-end",
                        zIndex: 1 
                    }}>
                        <b>{ getStatusMask(request.status) }</b>
                    </p>
                    <p><b>Pedido:</b> { request.id }</p>
                    <p><b>Cliente:</b> { request.client.nome_display }</p>
                    <p><b>Data Recebimento:</b> { request.id }</p>
                    <p><b>Estado:</b> { request.client.uf }</p>
                    <p><b>QTD Pendente:</b> { request.pendent.qtd }</p>
                    <p><b>QTD Confirmada:</b> { request.oInvoiced.qtd }</p>
                    <p><b>Vendas Pendentes:</b> { formatValue(request.pendent.value) }</p>
                    <p><b>Vendas Confirmadas:</b> { formatValue(request.pendent.value) }</p>
                    <p><b>QTD Total:</b> { request.total.qtd }</p>
                    <p><b>Vendas Total:</b> { formatValue(request.total.value) }</p>
                    <p><b>% Realizado:</b> { request.realized }</p>
                </ListMobileCard>
            ))}
            <LoadingContainer ref={endListRef} style={{ width: "100%", minHeight: "5px" }}>
                { isLoading && <CircularProgress style={{ marginTop: "16px" }}/> }
            </LoadingContainer>
        </Container>
        <DetailsDialog
            open={ openDetailsDialog }
            onClose={ handleCloseDialog }
            fullWidth
            maxWidth="lg"
            selectedRequest={ selectedRequest }
        />
        </>
    )
}

export default forwardRef(RequestsMobileList)
import { useState, useRef, useEffect, useCallback } from "react"
import { useLocation } from "react-router-dom"
import { FormHandles } from "@unform/core"
import { Form } from "@unform/web"

import { convertDateToEnglishPattern } from "../../../../utils/format/convertDateToEnglishPattern"
import { getDate } from "../../../../utils/format/getData"

import FiltersForm from "../../../../components/FiltersForm"
import FormInput from "../../../../components/Form/FormInput"
import { FilterIcon } from "../../../../components/Misc/Icons/FilterIcon"
import FormDataPicker, { FormDatePickerRef } from "../../../../components/Form/FormDataPicker"
import FormSelect from "../../../../components/Form/FormSelect"
import FormInputMultiSelection, { FormInputMultiSelectionRef } from "../../../../components/Form/FormInputMultiSelection"
import { Client, SelectClientsModal } from "../../../../components/Modal/SelectClient"
import FormAutoComplete from "../../../../components/Form/FormAutoComplete"

import { Fade, Grid, Tooltip } from "@mui/material"
import { Search } from "@mui/icons-material"

import { ActionButton, FilterContainer, FilterPosition, SearchButton } from "./style"
import { Product, SelectProductModal } from "../../../../components/Modal/SelectProduct"
import { toast } from "react-toastify"
import apiService from "../../../../services/api.services"

interface DisplayOptions {
    id: string
    label: string
}

export interface SellDetailsFilters{
    anomes?: string
    datade?: string
    dataate?: string
    marca?: string
    gama?: string
    pedcli?: string
    display?: DisplayOptions[] | string[]
    produto?: DisplayOptions[] | string[]
    vendedor?: string | null
}

enum Options {
    CLIENTS = "/invoicing/details/client",
    PRODUCTS = "/invoicing/details/products"
}

interface SellDetailsFilterProps{
    onStartFilter: (params: SellDetailsFilters) => void
    onCleanFilter: () => void
    gamas: string[]
}

export const SellDetailsFilter: React.FC<SellDetailsFilterProps> = ({ 
    gamas,
    onStartFilter, 
    onCleanFilter 
}) => {

    const location = useLocation()

    const formRef = useRef<FormHandles>(null)

    const clientInputRef = useRef<FormInputMultiSelectionRef>(null)
    const productsInputRef = useRef<FormInputMultiSelectionRef>(null)
    const dateAt = useRef<FormDatePickerRef>(null)
    const dateIn = useRef<FormDatePickerRef>(null)

    const [openFilter, setOpenFilter] = useState<boolean>(false)
    const [openClientModal, setOpenClientModal] = useState<boolean>(false)
    const [openProductModal, setOpenProductModal] = useState<boolean>(false)

    const [sellers, setSellers] = useState<string[]>([])

    const [controlledPeriod, setControledPeriod] = useState<string>(() => {
        const filtered = sessionStorage.getItem("@portalnaos:selldetails:periodfilter")
        
        if(!filtered){
            return `${ new Date().getFullYear() }-${ (new Date().getMonth() + 1).toString().padStart(2, "0")}`
        }
        
        return filtered  
    })

    const currentPage = location.pathname === Options.CLIENTS ? (
        "clients"
    ) : ( location.pathname === Options.PRODUCTS ? (
        "products"
    ) : null )

    const getSellers = async() => {
        try {
            const response = await apiService.get("/consultapadrao", {
                params: {
                    tabela: "SA3",
                    campos: "A3_COD,A3_NOME",
                    ordem: "A3_COD",
                    bolquio: "A3_MSBLQL"
                }
            })

            if(response.data.data){
                setSellers(response.data.data.consultapadrao.map(
                    (info: any) => (`${info.A3_COD} - ${info.A3_NOME}`)
                ))
            }

        } catch (error) {
            console.log(error)
            toast.error("Falha ao carregar dados dos vendedores")
        }
    }

    const handleFilterSubmit = (data: SellDetailsFilters) => {
        const formFilters: SellDetailsFilters = {
            ...data,
            vendedor: data.vendedor ? data.vendedor.split("-")[0]?.trim() : null,
            marca: data.marca === "03" ? undefined : data.marca,
            anomes: data.anomes?.replaceAll("-", ""),
            dataate: !!data.dataate ? getDate(convertDateToEnglishPattern(data.dataate)) : "",
            datade: !!data.datade ? getDate(convertDateToEnglishPattern(data.datade)) : ""
        }

        if(currentPage === "products"){
            formFilters.gama = data.gama?.split("-")[0].trim()
            formFilters.display = data.display?.map((display: any) => display.id) 
        }

        if(currentPage === "clients"){
            formFilters.produto = data.produto?.map((product: any) => product.id)
        }

        const params: any = {}

        Object.keys(formFilters).forEach(key => {
            if(!!formFilters[key] || formFilters[key]?.length > 0 ){
                params[key] = formFilters[key]
            }
        })

        if(data.anomes){
            sessionStorage.setItem("@portalnaos:selldetails:periodfilter", data.anomes)
        }

        onStartFilter(params)
    }

    const cleanFilters = () => {
        formRef.current?.reset()
        dateIn.current?.changeValue(null)
        dateAt.current?.changeValue(null)

        setControledPeriod(`${ new Date().getFullYear() }-${ (new Date().getMonth() + 1).toString().padStart(2, "0")}`)
        sessionStorage.removeItem("@portalnaos:selldetails:periodfilter")
        onCleanFilter()
    }

    const handleClientsSelected = useCallback((clients: Client[]) => {
        clientInputRef.current?.changeValue(
            clients.map(client => ({
                id: client.code,
                label: client.name
            }))
        )
    },[])

    const handleProductsSelected = useCallback((products: Product[]) => {
        productsInputRef.current?.changeValue(
            products.map(product => ({
                id: product.codigo,
                label: product.descricao
            }))
        )
    },[])

    useEffect(() => {
        formRef.current?.reset()
        clientInputRef.current?.changeValue([])
        productsInputRef.current?.changeValue([])
        dateIn.current?.changeValue(null)
        dateAt.current?.changeValue(null)
    },[currentPage])

    useEffect(() => {
        getSellers()
    },[])

    return(
        <>
        <FilterContainer>
            <Tooltip title="Filtrar">
                <ActionButton onClick={() => setOpenFilter(prev => !prev)}>
                    <FilterIcon />
                </ActionButton>
            </Tooltip>
        </FilterContainer>
        <Fade in={ openFilter }>
            <FilterPosition>
                <FiltersForm
                    onClean={ cleanFilters }
                    onSubmit={() => formRef.current?.submitForm() }
                    onClose={() => setOpenFilter(false)}
                >
                    <Form 
                        ref={ formRef } 
                        onSubmit={ handleFilterSubmit }
                        initialData={{
                            anomes: "teste 123"
                        }}
                    >
                        <Grid container spacing={ 2 } mb={2}>
                            <Grid item xs={ 12 } md={ 6 }>
                                <FormSelect
                                    name="marca"
                                    label="Marca"
                                    size="small"
                                    fullWidth
                                    options={[
                                        { label: "BIODERMA", value: "01" },
                                        { label: "ESTHEDERM", value: "02" },
                                        { label: "ETAT PUR", value: "04" },
                                        { label: "NAOS", value: "03" }
                                    ]}
                                />
                            </Grid>
                            { currentPage === "clients" && (
                                <>
                                <Grid item xs={ 12 } md={ 6 }>
                                    <FormInput 
                                        name="display"
                                        label="Cliente"
                                        size="small"
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={ 12 } md={ 12 } position="relative">
                                    <FormInputMultiSelection 
                                        ref={ productsInputRef }
                                        name="produto"
                                        label="Produto"
                                        size="small"
                                        variant="light"
                                        hasButtonOption
                                        onClick={ () => setOpenProductModal(true) }
                                    />
                                    <SearchButton 
                                        variant="contained"
                                        color="success"
                                        size="small"
                                        type="button"
                                        onClick={ () => setOpenProductModal(true) }
                                    >
                                        <Search />
                                    </SearchButton>
                                    <SelectProductModal 
                                        open={ openProductModal }
                                        /* onlyPA */
                                        onClose={ () => setOpenProductModal(false) }
                                        onSelect={ handleProductsSelected }
                                    />
                                </Grid>
                                </>
                            ) }
                            { currentPage === "products" && (
                                <>
                                <Grid item xs={ 12 } md={ 6 }>
                                    <FormAutoComplete
                                        name="gama"
                                        label="Gama"
                                        loading={ gamas.length <= 0 }
                                        options={ gamas }
                                    />
                                </Grid>
                                <Grid item xs={ 12 } md={ 6 } position="relative">
                                    <FormInputMultiSelection 
                                        ref={ clientInputRef }
                                        name="display"
                                        label="Cliente"
                                        size="small"
                                        variant="light"
                                        hasButtonOption
                                        onClick={ () => setOpenClientModal(true) }
                                    />
                                    <SearchButton 
                                        variant="contained"
                                        color="success"
                                        size="small"
                                        type="button"
                                        onClick={ () => setOpenClientModal(true) }
                                    >
                                        <Search />
                                    </SearchButton>
                                    <SelectClientsModal
                                        open={ openClientModal }
                                        onClose={ () => setOpenClientModal(false) }
                                        onSelect={ handleClientsSelected }
                                    />
                                </Grid>
                                <Grid item xs={ 12 } md={ 6 }>
                                    <FormInput
                                        name="pedcli"
                                        label="Pedido"
                                        size="small"
                                        fullWidth
                                        InputLabelProps={{ shrink: true }}
                                    />
                                </Grid>
                                </>
                            ) }
                        </Grid>
                        <Grid container spacing={ 2 }>
                            <Grid item xs={ 12 } md={ 4 }>
                                <FormInput
                                    name="anomes"
                                    label="Período"
                                    size="small"
                                    type="month"
                                    value={ controlledPeriod }
                                    fullWidth
                                    InputLabelProps={{ shrink: true }}
                                    onChange={ (event: any) => setControledPeriod(event.target.value) }
                                />
                            </Grid>
                            <Grid item xs={ 12 } md={ 8 }>
                                <FormAutoComplete
                                    name="vendedor"
                                    label="Vendedor"
                                    loading={ sellers.length <= 0 }
                                    options={ sellers }
                                />
                            </Grid>
                            <Grid item xs={ 6 } md={ 6 }>
                                <FormDataPicker
                                    ref={ dateIn }
                                    name="datade"
                                    fullWidth
                                    views={['day']}
                                    inputFormat="dd/MM/yyyy"
                                    label="Data Inicio"
                                    size="small"
                                />
                            </Grid>
                            <Grid item xs={ 6 } md={ 6 }>
                                <FormDataPicker
                                    ref={ dateAt }
                                    name="dataate"
                                    views={['day']}
                                    fullWidth
                                    inputFormat="dd/MM/yyyy"
                                    label="Data Fim"
                                    size="small"
                                />
                            </Grid>
                        </Grid>
                    </Form>
                </FiltersForm>
            </FilterPosition>
        </Fade>
        </>
    )
}
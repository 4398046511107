import { IconProps } from "../../../@types/icons";

export const TotalBillingIcon: React.FC<IconProps> = ({ color, height, size }) => (
    <svg 
        width={ size || "32"} 
        height={ height || "32"}  
        color={ color }
        viewBox="0 0 32 32" 
        fill="none" 
        xmlns="http://www.w3.org/2000/svg"
        style={{ marginTop: "5px", marginLeft: "12px" }}
    >
        <path d="M1.52381 3.45098C1.09206 3.45098 0.730413 3.28591 0.438857 2.95576C0.146286 2.62447 0 2.21438 0 1.72549C0 1.2366 0.146286 0.82651 0.438857 0.495216C0.730413 0.165072 1.09206 0 1.52381 0H16.7619C17.1936 0 17.5558 0.165072 17.8484 0.495216C18.1399 0.82651 18.2857 1.2366 18.2857 1.72549C18.2857 2.21438 18.1399 2.62447 17.8484 2.95576C17.5558 3.28591 17.1936 3.45098 16.7619 3.45098H1.52381ZM1.52381 10.3529C1.09206 10.3529 0.730413 10.1873 0.438857 9.856C0.146286 9.52586 0 9.11634 0 8.62745C0 8.13856 0.146286 7.72847 0.438857 7.39718C0.730413 7.06703 1.09206 6.90196 1.52381 6.90196H16.7619C17.1936 6.90196 17.5558 7.06703 17.8484 7.39718C18.1399 7.72847 18.2857 8.13856 18.2857 8.62745C18.2857 9.11634 18.1399 9.52586 17.8484 9.856C17.5558 10.1873 17.1936 10.3529 16.7619 10.3529H1.52381ZM1.52381 17.2549C1.09206 17.2549 0.730413 17.0893 0.438857 16.758C0.146286 16.4278 0 16.0183 0 15.5294C0 15.0405 0.146286 14.6304 0.438857 14.2991C0.730413 13.969 1.09206 13.8039 1.52381 13.8039H10.6667C11.0984 13.8039 11.4606 13.969 11.7531 14.2991C12.0447 14.6304 12.1905 15.0405 12.1905 15.5294C12.1905 16.0183 12.0447 16.4278 11.7531 16.758C11.4606 17.0893 11.0984 17.2549 10.6667 17.2549H1.52381Z" fill="#1B7CCC"/>
        <path d="M24.2245 10.3187L24.2232 10.3202C24.2087 10.337 24.1952 10.3547 24.1828 10.3731L24.1817 10.3747L19.0794 18.1193L16.0664 14.5327C15.9516 14.3865 15.8119 14.2674 15.6547 14.184C15.4966 14.1001 15.3246 14.0543 15.1491 14.0506C14.9736 14.047 14.8001 14.0855 14.6393 14.1628C14.4786 14.2401 14.3346 14.3543 14.2149 14.4968C14.0953 14.6392 14.0022 14.8075 13.9399 14.9911C13.8776 15.1746 13.8473 15.3706 13.8502 15.5674C13.8531 15.7642 13.8893 15.9589 13.957 16.1399C14.0244 16.3201 14.122 16.4839 14.2452 16.6211L18.2163 21.3506C18.2163 21.3506 18.2163 21.3506 18.2163 21.3506C18.3361 21.4933 18.4803 21.6075 18.6412 21.6849C18.8023 21.7622 18.976 21.8007 19.1517 21.7968C19.3273 21.7929 19.4995 21.7469 19.6577 21.6626C19.8158 21.5784 19.956 21.4582 20.0711 21.3106L20.0714 21.3109L20.0773 21.302L26.0654 12.383C26.2833 12.0979 26.3975 11.7279 26.3891 11.3505C26.3807 10.9713 26.249 10.6067 26.0161 10.3345C25.7826 10.0616 25.4632 9.90123 25.1233 9.89817C24.7833 9.8951 24.4617 10.0497 24.2245 10.3187ZM26.0815 21.3021L32.0707 12.386C32.1885 12.2457 32.2807 12.0804 32.3429 11.9C32.4062 11.7168 32.4376 11.521 32.4356 11.324C32.4337 11.1271 32.3985 10.9321 32.3316 10.7505L32.1909 10.8024L32.3316 10.7505C32.2647 10.569 32.1673 10.4037 32.0439 10.2652C31.9204 10.1267 31.7733 10.0175 31.6104 9.94584C31.4473 9.87408 31.2727 9.84179 31.0973 9.85177C30.922 9.86176 30.7513 9.9137 30.5956 10.0032L30.5956 10.0032C30.44 10.0926 30.3031 10.2173 30.1919 10.3684L30.1917 10.3682L30.1875 10.3747L25.0838 18.1191L24.485 17.4045L24.3568 17.2515L24.2455 17.4172L22.8296 19.5255L22.6727 19.7591H22.8857L24.2221 21.3506L24.2222 21.3507C24.342 21.4931 24.4861 21.6071 24.6468 21.6842C24.8077 21.7614 24.9812 21.7997 25.1567 21.7959C25.3321 21.792 25.5041 21.746 25.6622 21.662C25.8201 21.578 25.9602 21.458 26.0752 21.3108L26.0755 21.311L26.0815 21.3021Z" fill="#1B7CCC" stroke="#1B7CCC" stroke-width="0.3"/>
    </svg>
)
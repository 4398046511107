import { useCallback, useEffect, useState } from "react"
import { Outlet, useLocation, useNavigate } from "react-router-dom"
import { toast } from "react-toastify"

import { DataToExportClient, DataToExportProduct } from "../../@types/sellDetailts"

import { useAuth } from "../../hooks/auth"
import { useSellDetails } from "../../hooks/useSellDetails"

import { formatValue } from "../../utils/format/formatValue"
import { exportExcel } from "../../utils/misc/exportExcel"

import { SellDetailsFilter } from "./components/Filter"
import TabNav from "../../components/Navigation/TabNav"

import { Download, Refresh } from "@mui/icons-material"
import { Tooltip } from "@mui/material"

import { ActionButton, Actions, Container, DetailsContent, DetailsHeader } from "./style"
import { getErrorMessage } from "../../utils/validations/getErrorMessage"
import apiService from "../../services/api.services"

enum Options {
    CLIENTS = "/invoicing/details/client",
    PRODUCTS = "/invoicing/details/products"
}

export const SellDetails: React.FC = () => {

    const navigate = useNavigate()
    const location = useLocation()

    const { userData } = useAuth()
    const { 
        getClients, 
        getProducts, 
        clients, 
        products
    } = useSellDetails()

    const [gamas, setGamas] = useState<string[]>([])

    const navMenuOptions = [
        {
            title: "Clientes",
            eventKey: Options.CLIENTS
        },
        {
            title: "Produtos",
            eventKey: Options.PRODUCTS
        }
    ]

    const defaultNavSelection = location.pathname === Options.PRODUCTS
        ? Options.PRODUCTS
        : Options.CLIENTS

    const handleDetailsType = useCallback(async(eventKey: string) => {        
        navigate(eventKey)
    },[navigate])

    const filter = (params?: any) => {
        switch(location.pathname){
            case Options.CLIENTS:
                getClients(params)
                break
            case Options.PRODUCTS:
                getProducts(params)
                break
            default: return
        }
    }

    const exportDataToExcel = () => {
        switch(location.pathname){
            case Options.CLIENTS:
                let dataToExportClient: DataToExportClient[] = []

                clients.forEach(client => {
                    client.products.forEach(product => {
                        dataToExportClient.push({
                            /* "Código Cliente": client.client.trim(),
                            "Loja Cliente": client.shop.trim(), */
                            "Cliente": client.client_name.trim(),
                            "Marca": product.nome_marca.trim(),
                            "Código Produto": product.id.trim(),
                            "Produto": product.desc.trim(),
                            "QTD Faturado": product.invoicing.qtd.toString(),
                            "NET SALES Faturado": formatValue(product.invoicing.net_sales),
                            "PR MED Faturado": formatValue(product.invoicing.pr_med),
                            "QTD em Processamento": product.in_progress.qtd.toString(),
                            "NET SALES em Processamento": formatValue(product.in_progress.net_sales),
                            "PR MED em Processamento": formatValue(product.in_progress.pr_med),
                            "QTD Pendente": product.pendent.qtd.toString(),
                            "NET SALES Pendente": formatValue(product.pendent.net_sales),
                            "PR MED Pendente": formatValue(product.pendent.pr_med),
                            "QTD Cancelado": product.canceled.qtd.toString(),
                            "NET SALES Cancelado": formatValue(product.canceled.net_sales),
                            "PR MED Cancelado": formatValue(product.canceled.pr_med),
                        })
                    })
                })

                exportExcel(dataToExportClient)
                break
            case Options.PRODUCTS:

                const dataToExportProducts: DataToExportProduct[] = products.map(product => ({
                    "Código Produto": product.id.trim(),
                    "Produto": product.desc.trim(),
                    "QTD Faturado": product.invoicing.qtd.toString(),
                    "NET SALES Faturado": formatValue(product.invoicing.net_sales),
                    "PR MED Faturado": formatValue(product.invoicing.pr_med),
                    "QTD em Processamento": product.in_progress.qtd.toString(),
                    "NET SALES em Processamento": formatValue(product.in_progress.net_sales),
                    "PR MED em Processamento": formatValue(product.in_progress.pr_med),
                    "QTD Pendente": product.pendent.qtd.toString(),
                    "NET SALES Pendente": formatValue(product.pendent.net_sales),
                    "PR MED Pendente": formatValue(product.pendent.pr_med),
                    "QTD Cancelado": product.canceled.qtd.toString(),
                    "NET SALES Cancelado": formatValue(product.canceled.net_sales),
                    "PR MED Cancelado": formatValue(product.canceled.pr_med),
                    "QTD TOTAL": product.total.qtd.toString(),
                    "NET SALES TOTAL": formatValue(product.total.net_sales),
                    "PR MED TOTAL": formatValue(product.total.pr_med),
                    "Código Barras": `*${product.EAN}*`
                }))

                exportExcel(dataToExportProducts)
                break
            default: return
        }
    }

    const fetchGamas = async() => {
        const requestParams = {
            tabela: "SBM",
            campos: "BM_GRUPO,BM_DESC",
            ordem: "BM_DESC"
        }

        try{
            const response = await apiService.get("/consultapadrao", {
                params: {
                    ...requestParams,
                    branch: userData.selectedBranch?.id as string
                }
            })

            if(response.data.data){
                setGamas(response.data.data.consultapadrao.map((info: any) => (
                    `${ info.BM_GRUPO } - ${ info.BM_DESC }`
                )))
            }
        }catch(error: any){
            console.log(error)
            toast.error(getErrorMessage(error))
        }
    }

    const refetchData = () => {
        switch(location.pathname){
            case Options.CLIENTS:
                getClients()
                break
            case Options.PRODUCTS:
                getProducts()
                break
            default: return
        }
    }

    useEffect(() => {
        if(!userData.permissions?.includes('FATVEN')){
            toast.info("Você não tem permissão para acessar essa pagina")

            navigate("/")
            return
        }

        fetchGamas()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[userData, navigate])

    useEffect(() => {
        return () => {
            sessionStorage.removeItem("@portalnaos:selldetails:periodfilter")
        }
    },[])

    return (
        <Container>
            <DetailsHeader>
                <TabNav 
                    defaultSelection={defaultNavSelection}
                    options={navMenuOptions}
                    onSelectOption={ handleDetailsType }
                />
                <Actions>
                    <Tooltip title="Recarregar">
                        <ActionButton onClick={ refetchData }>
                            <Refresh />
                        </ActionButton>
                    </Tooltip>
                    <Tooltip title="Exportar Excel">
                        <ActionButton onClick={ exportDataToExcel }>
                            <Download />
                        </ActionButton>
                    </Tooltip>
                    <SellDetailsFilter 
                        gamas={ gamas }
                        onStartFilter={ filter }
                        onCleanFilter={ refetchData }
                    />
                </Actions>
            </DetailsHeader>
            <DetailsContent>
                <Outlet/>
            </DetailsContent>
        </Container>
    )
}
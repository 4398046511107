import { ReactNode } from "react";
import { IconProps } from "phosphor-react";

import { Button } from "./style";
import { checkColorClarity } from "../../../../utils/misc/verifyColorClarity";

interface GridOptionButtonProps extends React.HTMLAttributes<HTMLButtonElement>{
    children?: ReactNode
    icon?: React.ForwardRefExoticComponent<IconProps & React.RefAttributes<SVGSVGElement>>
    color: string
}

export const GridOptionButton: React.FC<GridOptionButtonProps> = ({
    children,
    color,
    icon,
    ...rest
}) => {
    const ButtonIcon = icon

    const theme = checkColorClarity(color.toUpperCase()) ? "light" : "dark"

    return (
        <Button 
            { ...rest }
            color={ color }
            themeType={ theme }
        >
            { ButtonIcon && <ButtonIcon />}
            { children }
        </Button>
    )
}
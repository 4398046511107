import styled from 'styled-components'

export const Container = styled.fieldset`
    position: relative;
    border: 2px solid ${ props => props.theme['blue-500'] };
    padding-top: 14px;
    margin: 16px 0px 28px 0px;
    border-radius: 10px;
    background-color: ${ props => props.theme['white-100'] };
`

export const Title = styled.div`
    position: absolute;
    top: -14px;
    height: 19px;
    color: black;
    font-weight: bold;
    border-bottom: 8px solid #FFF;
    padding: 0px 6px;
    width: auto;
    
    legend{
        font-size: 1.1rem;
        color: ${ props => props.theme['blue-500'] };
    }
`
import React from 'react'
import { useEffect } from 'react'
import { useNavigate, useLocation, Outlet } from 'react-router-dom'
import { toast } from 'react-toastify'

import { useAuth } from '../../hooks/auth'

import {
    Container,
    MenuList,
    MenuButton,
    Content,
} from "./style"

type OptionValue = "login" | "home" | "icon" | undefined

const PreferencesConfig: React.FC = () => {
    const { userData } = useAuth()

    const navigate = useNavigate()
    const location = useLocation()

    const controlSelection = (): OptionValue => {
        const currentUrl = location.pathname

        if(currentUrl.includes("change-image/login")){
            return "login"
        }

        if(currentUrl.includes("change-image/home")){
            return "home"
        }

        if(currentUrl.includes("change-image/icon")){
            return "icon"
        }
    }

    const currentSelection = controlSelection()

    useEffect(() => {
        if(!userData.permissions?.includes('PREFER')){
            toast.info("Você não tem permissão para acessar essa pagina")

            navigate("/")
        }
    },[userData, navigate])

    return (
        <Container>
            <MenuList>
                <MenuButton
                    fullWidth
                    variant='contained'
                    size='large'
                    isSelected={ currentSelection === "login" }
                    onClick={() => {
                        navigate("/preferences/change-image/login")
                    }}
                >
                    Alterar Logo Login
                </MenuButton>
                <MenuButton
                    fullWidth
                    variant='contained'
                    size='large'
                    isSelected={ currentSelection === "home" }
                    onClick={() => {
                        navigate("/preferences/change-image/home")
                    }}
                >
                    Alterar Logo Home
                </MenuButton>
                <MenuButton
                    fullWidth
                    variant='contained'
                    size='large'
                    isSelected={ currentSelection === "icon" }
                    onClick={() => {
                        navigate("/preferences/change-image/icon")
                    }}
                >
                    Alterar Ícone
                </MenuButton>
            </MenuList>
            <Content>
                <Outlet/>
            </Content>
        </Container>
    )
}

export default PreferencesConfig
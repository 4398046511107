import styled from "styled-components"
import { css } from "styled-components"
import { lighten } from "polished"

import { fade } from '../../styles/animations'

import { Button } from '../../components/Buttons/Button'

interface MenuButtonProps{
    isSelected?: boolean
}

export const Container = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 16px;

    @media(max-width: 750px){
        flex-direction: column;
    }
`

export const MenuList = styled.div`
    width: 300px;
    display: flex;
    flex-direction: column;
    padding: 15px;
    animation: ${ fade } 120ms;

    button + button{
        margin-top: 20px;
    }

    @media(max-width: 750px){
        width: 100%;

        button{
            width: 100%;
            min-width: 180px;
            margin: 0px !important;
            margin-bottom: 10px !important;
            font-size: 1.2rem;
        }
    }

    @media(max-width: 355px){
        button{
            font-size: 1rem;   
        }
    }
`

export const MenuButton = styled(Button)<MenuButtonProps>`
    ${ props => props.isSelected && css`
        background-color: ${ lighten(0.2, props.theme["blue-800"]) } !important;
    `}

    @media(max-width: 750px){
        padding: 10px 0px !important;
        font-size: 15px !important;
    }
`

export const Content = styled.div`
    flex: 1;
    
    @media(max-width: 750px){
        width: 100%;
    }
`
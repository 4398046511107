import React, { useMemo } from "react";
import { useState, useEffect, useRef } from "react";
import { validate } from "uuid";
import { toast } from "react-toastify";

import { CompareNFParams, DocumentToAprove, FinishApproveProps } from "../../../../../@types/buyOrder";
import { AssosiatedItem, Document } from "../../../../../@types/documents";

import api from "../../../../../services/api.services";

import { useAuth } from "../../../../../hooks/auth";
import { useDocuments } from "../../../../../hooks/documents";
import { useBuyOrder } from "../../../../../hooks/buyOrders";

import { getDatePtBR } from "../../../../../utils/format/getData";
import { getErrorMessage } from "../../../../../utils/validations/getErrorMessage";
import { formatValue } from "../../../../../utils/format/formatValue";

import { Button } from "../../../../../components/Buttons/Button";
import ChangeBankProviderRequestDialog, { ChangeBankProviderRequestDialogRef } from "./components/ChangeBankProviderRequestDialog";
import DefaultDialog, { DefaultDialogRef } from "../../../../../components/Modal/DefaultDialog";

import { Info } from "@mui/icons-material";
import { CircularProgress, ClickAwayListener, Grid, Checkbox, Autocomplete, TextField } from "@mui/material";

import {
  Actions,
  Content,
  DocumentCard,
  ErrorPaper,
  InfoButton,
  InfoContainer,
  InfoField,
  AlertProps,
  InfosHeader,
  GridNav,
  ProviderInfo,
  ProviderActions,
} from "./style";

interface Validation {
  title: string;
  nf: string;
  oc: string;
  description: string;
  status: AlertProps;
  showError?: boolean;
  detailing?: {
    item_oc: string;
    quantidade: number;
    valor_unitario: number;
    valor_total: number;
    centro_custo: string;
    descripition: string;
    status: string;
    showError?: boolean;
    itens_nf: {
      item_nf: string;
      quantidade: number;
      valor_unitario: number;
      valor_total: number;
    }[];
  }[];
}

interface DocumentsValidations {
  idNF: string;
  tiponf: string;
  numnot: string;
  serie: string;
  valornf: number;
  status: string;
  itens: Validation[];
}

interface Supplier {
  id: string;
  branch: string;
  name: string;
  cpf_cnpj: string;
  bank: string;
  bank_name: string;
  bank_branch: string;
  bank_account: string;
}

interface DocConsult {
  number: string;
  serie?: string;
  wasSynced?: boolean
  type: string;
  value: number;
  associatedItens?: AssosiatedItem[];
  cnpj_emit?: string;
  issueDate: string;
  validDate: string;
}

export type CurrentValidationAction = "proceed" | "submitToAprove" | "cancel" | null;

interface ValidationsProps {
  isSaving: boolean;
  type?: "aprove" | "validation";
  onlyView?: boolean;
  total: number;
  updateSaveDataState: React.Dispatch<React.SetStateAction<boolean>>;
  onBackPage: () => void;
  onFinishValidation: (data: CompareNFParams) => Promise<void> | void;
  onFinishApprove: (data: FinishApproveProps) => Promise<void> | void;
}

const Validations: React.FC<ValidationsProps> = ({
  isSaving,
  type = "validation",
  onlyView,
  total,
  updateSaveDataState,
  onBackPage,
  onFinishValidation,
  onFinishApprove
}) => {
  const { userData } = useAuth();
  const { selectedOrder, compareNFxOC } = useBuyOrder();
  const { currentDocuments } = useDocuments();

  const confirmCancelDialogRef = useRef<DefaultDialogRef>(null);
  const changeBankProviderRequestDialogRef = useRef<ChangeBankProviderRequestDialogRef>(null);

  const [currentOCStatus, setCurrentOCStatus] = useState<string>("");

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isLoadingSubmit, setIsLoadingSubmit] = useState<boolean>(false);
  const [isLoadingJustifyOptions, setIsLoadingJustifyOptions] = useState<boolean>(false);

  const [justifyFieldErrored, setJustifyFieldErrored] = useState<boolean>(false);

  const [actionType, setActionType] = useState<CurrentValidationAction>(null);
  const [documentsValidations, setDocumentsValidations] = useState<DocumentsValidations[]>([]);
  const [supplier, setSupplier] = useState<Supplier>({} as Supplier);
  const [justifyOptions, setJustifyOptions] = useState<Array<any>>([]);

  const [documentsSelected, setDocumentsSelected] = useState<DocumentToAprove[]>([]);

  const [confirmedBanksData, setConfirmedBanksData] = useState<boolean>(false);
  const [isTicket, setIsTicket] = useState<boolean>(false);
  const [assignedNfValue, setAssignedNFValue] = useState<number>(0)

  const [wasRequestChangeBillingInfo, setWasRequestChangeBillingInfo] = useState<boolean>(false)

  const summary = useMemo(() => {
    const valoresNfs = documentsValidations.map((object) => object.valornf);

    const valorTotal = valoresNfs.reduce((acc, valornf) => {
      return acc + valornf;
    }, 0);

    const totalAssigned = onlyView 
      ? assignedNfValue
      : assignedNfValue + valorTotal

    return {
      nfsTotalValue: totalAssigned || 0,
      divergentValue: total - totalAssigned
    }
  },[documentsValidations, assignedNfValue, total, onlyView])

  const loadValidations = async () => {
    setIsLoading(true);

    console.log(currentDocuments)

    const parsedDocs = currentDocuments.reduce((accumulator, currentValue) => {
      const existDocIndex = accumulator.findIndex((doc) => doc.number === currentValue.number && doc.serie === currentValue.serie);

      const existDoc = existDocIndex >= 0;

      console.log(existDoc)

      if (!existDoc) {
        accumulator.push({
          number: currentValue.number,
          serie: currentValue.serie,
          type: currentValue.type,
          value: currentValue.value,
          wasSynced: currentValue.wasSynced,
          associatedItens: currentValue.associatedItens,
          cnpj_emit: currentValue.cnpj_emit,
          issueDate: getDatePtBR(currentValue.issueDate),
          validDate: getDatePtBR(currentValue.validDate),
        });

        return accumulator;
      }

      const currentDoc = accumulator[existDocIndex];

      const currentItens = currentValue.associatedItens || [];
      const usedItens = currentDoc.associatedItens || [];

      accumulator[existDocIndex] = {
        ...currentDoc,
        associatedItens: [...usedItens, ...currentItens],
      };

      return accumulator;
    }, [] as DocConsult[]);

    let docs = parsedDocs

    console.log(docs)

    console.log(onlyView)
    console.log(selectedOrder.status)
    console.log(selectedOrder.status_diver_nfxoc)

    if(
      selectedOrder.status === "7" || 
      (selectedOrder.status_diver_nfxoc === "2" || selectedOrder.status_diver_nfxoc === "3")
    ){
      if(!onlyView){
        docs = parsedDocs.filter(item => !item.wasSynced)
      }
    }

    console.log(docs)

    try {
      const { status, validacoes, supplier, valor_vinculado } = await compareNFxOC({
        oc: selectedOrder.id,
        documents: docs as any,
      });

      setCurrentOCStatus(status);
      setDocumentsValidations(validacoes);
      setAssignedNFValue(valor_vinculado)
      setSupplier(supplier);
    } catch (error: any) {
      console.log(error);
      toast.error(getErrorMessage(error));
    } finally {
      setIsLoading(false);
    }
  };

  const handleOpenErrorInfo = (selectedAttribute: string, currentDocument: string) => {
    setDocumentsValidations((prev) =>
      prev.map((document) =>
        document.numnot === currentDocument
          ? {
              ...document,
              itens: document.itens.map((item) =>
                item.title === selectedAttribute
                  ? {
                      ...item,
                      showError: !item.showError,
                    }
                  : {
                      ...item,
                      showError: false,
                    }
              ),
            }
          : {
              ...document,
              itens: document.itens.map((item) => ({
                ...item,
                showError: false,
              })),
            }
      )
    );
  };

  const handleOpenErrorInfoCC = (selectedAttribute: string, currentDocument: string, costCenter: string) => {
    setDocumentsValidations((prev) =>
      prev.map((document) =>
        document.numnot === currentDocument
          ? {
              ...document,
              itens: document.itens.map((item) =>
                item.title === selectedAttribute
                  ? {
                      ...item,
                      detailing: item.detailing?.map((detail) =>
                        detail.centro_custo === costCenter
                          ? {
                              ...detail,
                              showError: !detail.showError,
                            }
                          : {
                              ...detail,
                              showError: false,
                            }
                      ),
                    }
                  : {
                      ...item,
                      detailing: item.detailing?.map((detail) => ({
                        ...detail,
                        showError: false,
                      })),
                    }
              ),
            }
          : {
              ...document,
              itens: document.itens.map((item) => ({
                ...item,
                detailing: item.detailing?.map((detail) => ({
                  ...detail,
                  showError: false,
                })),
              })),
            }
      )
    );
  };

  const getDocumentStatus = (currentDocument: Document) => {
    const currentValidation = documentsValidations.find((document) => document.numnot === currentDocument.number);

    let containsCancel = false;
    let containsDiverger = false;

    currentValidation?.itens.forEach((validation) => {
      if (validation.status === "cancelar") {
        containsCancel = true;
      }

      if (validation.status === "divergente") {
        containsDiverger = true;
      }

      validation.detailing?.forEach((item) => {
        if (item.status === "divergente") {
          containsDiverger = true;
        }
      });
    });

    if (containsCancel) {
      return "5";
    }

    if (containsDiverger) {
      return "3";
    }

    return "2";
  };

  const getActionColor = () => {
    switch (actionType) {
      case "cancel":
        return "error";
      case "proceed":
        return "success";
      default:
        return "primary";
    }
  };

  const verifyNecessaryAction = () => {
    let cancelSolicitation = false;
    let submitToAproveSolicitation = false;

    documentsValidations.forEach((document) => {
      if (document.itens.some((item) => item.status === "cancelar")) {
        cancelSolicitation = true;
      }

      const isDivergent = document.itens.some(
        (item) => item.status === "divergente" || item.detailing?.some((detail) => detail.status === "divergente")
      );

      if (isDivergent) {
        submitToAproveSolicitation = true;
      }
    });

    if (cancelSolicitation) {
      return setActionType("cancel");
    }

    if (submitToAproveSolicitation) {
      return setActionType("submitToAprove");
    }

    return setActionType("proceed");
  };

  const finishValidation = async () => {
    if (!!actionType) {
      const isntConfirmProviderInfo = !confirmedBanksData && !isTicket;

      if (isntConfirmProviderInfo && !wasRequestChangeBillingInfo) {
        toast.warn("Por favor confirme os dados de pagamento do fornecedor marcando o os itens correspondentes.");
        return;
      }
    }

    if (actionType === "cancel") {
      return confirmCancelDialogRef.current?.openDialog();
    }

    if (isSaving) {
      return;
    }

    updateSaveDataState(true);

    try {
      const data = {
        oc: selectedOrder.id,
        status: currentOCStatus,
        documents: currentDocuments
          .filter(item => !item.wasSynced)
          .map((document) =>
            ({
              idNF: validate(document.idNF) ? "" : document.idNF,
              number: document.number,
              serie: document.serie,
              type: document.type,
              value: document.value,
              status: getDocumentStatus(document),
              associatedItens: document.associatedItens,
              cnpj_emit: document.cnpj_emit,
              issueDate: getDatePtBR(document.issueDate),
              validDate: getDatePtBR(document.validDate),
            } as any)
          ),
      };

      await onFinishValidation(data);

      toast.success("Os dados dos documentos foram salvos com sucesso.");
    } catch (error) {
      console.log(error);
      toast.error(getErrorMessage(error));
    } finally {
      updateSaveDataState(false);
    }
  };

  const cancelDocuments = async () => {
    try {
      const data = {
        oc: selectedOrder.id,
        status: currentOCStatus,
        documents: currentDocuments.map(
          (document) =>
            ({
              idNF: validate(document.idNF) ? "" : document.idNF,
              number: document.number,
              serie: document.serie,
              type: document.type,
              value: document.value,
              status: getDocumentStatus(document),
              associatedItens: document.associatedItens,
              cnpj_emit: document.cnpj_emit,
              issueDate: getDatePtBR(document.issueDate),
              validDate: getDatePtBR(document.validDate),
            } as any)
        ),
      };

      await onFinishValidation(data);

      toast.success("Os dados dos documentos foram salvos com sucesso.");
      toast.success("Solicitação cancelada com sucesso, por favor inicie uma Solicitação de Pagamento Urgente");
    } catch (error) {
      console.log(error);
      toast.error(getErrorMessage(error));
    }
  };

  const getJustifyOptions = async () => {
    const requestParams = {
      tabela: "SX5",
      campos: "X5_CHAVE,X5_DESCRI",
      procura: "X5_TABELA",
      pesquisa: "Z2",
    };

    try {
      setIsLoadingJustifyOptions(true);
      const response = await api.get("/consultapadrao", {
        params: {
          ...requestParams,
          branch: userData.selectedBranch?.id as string,
        },
      });

      if (response.data.data) {
        setJustifyOptions(
          response.data.data.consultapadrao.map((info: any) => ({
            codigo: info.X5_CHAVE,
            descricao: info.X5_DESCRI,
          }))
        );
      }
    } catch (error: any) {
      console.log(error);
      toast.error(getErrorMessage(error));
    } finally {
      setIsLoadingJustifyOptions(false);
    }
  };

  const handleRequest = async (type: "aprove" | "reprove") => {
    if (isLoadingSubmit) {
      return;
    }

    if (documentsSelected.length <= 0) {
      toast.warning("Por favor selecione pelo menos um item para realizar uma operação");
      return;
    }

    let hasEmptyJustify = false;
    documentsSelected.forEach((item) => {
      if (!item.justificativa) {
        hasEmptyJustify = true;
      }
    });

    if (hasEmptyJustify) {
      setJustifyFieldErrored(true);
      toast.warning("Insira uma justificativa para realizar a operação");
      return;
    }

    setJustifyFieldErrored(false);
    setIsLoadingSubmit(true);

    try {
      const data: FinishApproveProps = {
        type: type === "aprove" ? "A" : "R",
        documents: documentsSelected,
      };

      await onFinishApprove(data);

      setIsLoadingSubmit(false);
    } catch (error: any) {
      console.log(error);
      setIsLoadingSubmit(false);
      toast.error(getErrorMessage(error));
    }
  };

  const handleDocumentSelection = (currentDocument: string) => {
    setJustifyFieldErrored(false);
    setDocumentsSelected((prev) => {
      const existItem = prev.find((item) => item.id === currentDocument);

      if (!existItem) {
        return [
          ...prev,
          {
            id: currentDocument,
            justificativa: "",
          },
        ];
      }

      return prev.filter((item) => item.id !== currentDocument);
    });
  };

  const selectAllDocuments = () => {
    const documentsSelectable = documentsValidations.filter((document) => document.status === "3");
    setDocumentsSelected(
      documentsSelectable.map((document) => ({
        id: document.idNF,
        justificativa: "",
      }))
    );
  };

  const handleJustifySelected = (currentDocument: string, value: string) => {
    if (value.length > 255) {
      return;
    }

    setDocumentsSelected((prev) =>
      prev.map((document) =>
        document.id === currentDocument
          ? {
              ...document,
              justificativa: value,
            }
          : document
      )
    );
  };

  const handleConfirmProvider = (type: "ticket" | "bank") => {
    switch (type) {
      case "ticket":
        setConfirmedBanksData(false);
        setIsTicket((prev) => !prev);
        break;
      case "bank":
        setIsTicket(false);
        setConfirmedBanksData((prev) => !prev);
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    loadValidations();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (documentsValidations.length > 0 && !actionType) {
      verifyNecessaryAction();
      getJustifyOptions();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [documentsValidations, assignedNfValue]);

  if (isLoading) {
    return (
      <Grid container spacing={2}>
        <Grid item xs={12} md={12} height={"40vh"} display="flex" alignItems="center" justifyContent="center">
          <CircularProgress />
        </Grid>
      </Grid>
    );
  }

  if (documentsValidations.length <= 0) {
    return (
      <Content>
        <Grid container spacing={2}>
          <Grid item xs={12} md={12} height={"40vh"} display="flex" alignItems="center" justifyContent="center">
            <p>Não foi possível carregar as validações</p>
          </Grid>
        </Grid>
        <Actions>
          <Button color="primary" variant="text" onClick={onBackPage}>
            Voltar
          </Button>
        </Actions>
      </Content>
    );
  }

  return (
    <>
      <Content style={type === "aprove" ? { marginTop: "16px" } : undefined}>
        {type === "validation" && (
          <ProviderInfo>
            <section>
              <p>
                <strong>Fornecedor: </strong>
                {supplier.name}
              </p>
              <p>
                <strong>CNPJ/CPF: </strong>
                {supplier.cpf_cnpj}
              </p>
              <div>
                <p>
                  <strong>Banco: </strong>
                  {`${supplier.bank} - ${supplier.bank_name}`}
                </p>
                <p>
                  <strong>Agência: </strong>
                  {supplier.bank_branch}
                </p>
                <p>
                  <strong>Conta: </strong>
                  {supplier.bank_account}
                </p>
              </div>
              <p>
                <strong>Orçamento: </strong>
                R$ {formatValue(total)}
              </p>
              <p>
                <strong>Valor total das notas: </strong>
                R$ {formatValue(summary.nfsTotalValue)}
              </p>
              <p>
                <strong>Valor disponível: </strong>
                R$ {formatValue(summary.divergentValue)}
              </p>
            </section>
            {!onlyView && (
              <ProviderActions>
                <label>
                  <Checkbox style={{ height: "18px" }} checked={confirmedBanksData} onChange={() => handleConfirmProvider("bank")} />
                  Confirmar os dados bancários
                </label>
                <label>
                  <Checkbox style={{ height: "18px" }} checked={isTicket} onChange={() => handleConfirmProvider("ticket")} />O pagamento
                  será via boleto
                </label>
                <p onClick={() => changeBankProviderRequestDialogRef.current?.openDialog()}>Solicitar alteração de dados bancários</p>
              </ProviderActions>
            )}
          </ProviderInfo>
        )}
        <GridNav>
          {documentsValidations.map((document) => (
            <DocumentCard key={document.idNF}>
              <Grid container spacing={2} overflow="visible">
                {type === "aprove" && (
                  <Grid item xs={1}>
                    <Checkbox
                      disabled={document.status !== "3"}
                      checked={!!documentsSelected.find((item) => item.id === document.idNF)}
                      onChange={() => {
                        handleDocumentSelection(document.idNF as string);
                      }}
                    />
                  </Grid>
                )}
                <Grid item xs={type === "aprove" ? 11 : 12}>
                  <header>
                    <p>
                      <strong>Número:</strong> {document.numnot}{" "}
                      {document.serie && (
                        <>
                          <strong>Série:</strong> {document.serie}
                        </>
                      )}{" "}
                    </p>
                    <p>
                      <strong>Tipo:</strong> {document.tiponf}
                    </p>
                    <p>
                      <strong>Valor Total:</strong> R$ {formatValue(Number(document.valornf))}
                    </p>
                  </header>
                  <Grid container spacing={2} mt="10px">
                    <Grid item xs={3} md={3} display="flex" alignItems="center">
                      <InfosHeader>Categoria</InfosHeader>
                    </Grid>
                    <Grid item xs={1} md={1}></Grid>
                    <Grid item xs={4} md={4} display="flex" justifyContent="center" alignItems="center">
                      <InfosHeader>Nota Fiscal</InfosHeader>
                    </Grid>
                    <Grid item xs={4} md={4} display="flex" justifyContent="center" alignItems="center">
                      <InfosHeader>Solicitação</InfosHeader>
                    </Grid>
                  </Grid>
                  {document.itens.map((item) => (
                    <>
                      <InfoContainer key={item.title} status={item.status} container spacing={2} mt={"10px"}>
                        <Grid item xs={3} md={3} display="flex" alignItems="center">
                          <p>{item.title}</p>
                        </Grid>
                        <Grid item xs={1} md={1} display="flex" justifyContent="flex-end" alignItems="center" position="relative">
                          {(item.status === "divergente" || item.status === "alerta" || item.status === "cancelar") && (
                            <>
                              <ClickAwayListener
                                onClickAway={() => {
                                  if (item.showError) {
                                    handleOpenErrorInfo(item.title, document.numnot);
                                  }
                                }}
                              >
                                <InfoButton
                                  status={item.status}
                                  onClick={() => {
                                    handleOpenErrorInfo(item.title, document.numnot);
                                  }}
                                >
                                  <Info />
                                </InfoButton>
                              </ClickAwayListener>
                              <ErrorPaper open={!!item.showError} type={item.status}>
                                <p>{item.description}</p>
                              </ErrorPaper>
                            </>
                          )}
                        </Grid>
                        <Grid item xs={4} md={4} display="flex" justifyContent="center" alignItems="center">
                          <InfoField status={item.status}>
                            <p>
                              {item.title === "Vencimento"
                                ? new Date(item.nf).toLocaleDateString("pt-BR", {
                                    day: "2-digit",
                                    month: "2-digit",
                                    year: "numeric",
                                  })
                                : item.nf}
                            </p>
                          </InfoField>
                        </Grid>
                        <Grid item xs={4} md={4} display="flex" justifyContent="center" alignItems="center">
                          <InfoField status={item.status}>
                            <p>
                              {item.title === "Vencimento"
                                ? new Date(item.oc).toLocaleDateString("pt-BR", {
                                    day: "2-digit",
                                    month: "2-digit",
                                    year: "numeric",
                                  })
                                : item.oc}
                            </p>
                          </InfoField>
                        </Grid>
                      </InfoContainer>
                      {item.detailing?.map((detail, index) => {
                        const sumItem = detail.itens_nf.reduce((accumulator, currentValue) => {
                          accumulator += currentValue.valor_total;
                          return accumulator;
                        }, 0);
                        return (
                          <InfoContainer key={index} status={detail.status as any} container spacing={2} mt={"10px"}>
                            <Grid item xs={3} md={3} display="flex" alignItems="center">
                              <p>Valor centro custo: {detail.centro_custo}</p>
                            </Grid>
                            <Grid
                              item
                              xs={1}
                              md={1}
                              display="flex"
                              justifyContent="flex-end"
                              alignItems="center"
                              position="relative"
                              overflow="visible"
                            >
                              {(detail.status === "divergente" || detail.status === "alerta" || detail.status === "cancelar") && (
                                <>
                                  <ClickAwayListener
                                    onClickAway={() => {
                                      //@ts-ignore
                                      if (detail.showError) {
                                        handleOpenErrorInfoCC(item.title, document.numnot, detail.centro_custo);
                                      }
                                    }}
                                  >
                                    <InfoButton
                                      status={detail.status as any}
                                      onClick={() => handleOpenErrorInfoCC(item.title, document.numnot, detail.centro_custo)}
                                    >
                                      <Info />
                                    </InfoButton>
                                  </ClickAwayListener>
                                  <ErrorPaper open={!!detail.showError} type={detail.status as any}>
                                    <p>{detail.descripition}</p>
                                  </ErrorPaper>
                                </>
                              )}
                            </Grid>
                            <Grid item xs={4} md={4} display="flex" justifyContent="center" alignItems="center">
                              <InfoField status={detail.status as any}>
                                <p>{formatValue(sumItem)}</p>
                              </InfoField>
                            </Grid>
                            <Grid item xs={4} md={4} display="flex" justifyContent="center" alignItems="center">
                              <InfoField status={detail.status as any}>
                                <p>{formatValue(detail.valor_total)}</p>
                              </InfoField>
                            </Grid>
                          </InfoContainer>
                        );
                      })}
                    </>
                  ))}
                  {!!documentsSelected.find((item) => item.id === document.idNF) && (
                    <Grid item xs={12} mt="25px">
                      <Autocomplete
                        style={{ backgroundColor: "#FFF" }}
                        value={documentsSelected.find((item) => item.id === document.idNF)?.justificativa || ""}
                        options={justifyOptions.map((item) => `${item.codigo} - ${item.descricao}`)}
                        freeSolo
                        fullWidth
                        loading={isLoadingJustifyOptions}
                        onBlur={(event: any) => {
                          const option = event.target.value || "";
                          handleJustifySelected(document.idNF as string, option || "");
                        }}
                        onChange={(_, option) => handleJustifySelected(document.idNF as string, option || "")}
                        renderInput={(props) => (
                          <TextField
                            {...props}
                            error={!documentsSelected.find((item) => item.id === document.idNF)?.justificativa && justifyFieldErrored}
                            variant="outlined"
                            label="Justificativa *"
                            type="text"
                            size="small"
                            fullWidth
                            multiline
                            maxRows={4}
                          />
                        )}
                      />
                    </Grid>
                  )}
                </Grid>
              </Grid>
            </DocumentCard>
          ))}
        </GridNav>
      </Content>
      {!onlyView && (
        <Actions>
          {type === "validation" && (
            <>
              <Button color="primary" variant="text" onClick={onBackPage} disabled={isSaving}>
                Voltar
              </Button>
              {!!actionType && (
                <Button
                  variant="contained"
                  color={getActionColor()}
                  onClick={finishValidation}
                  /* disabled={ isSaving || (!confirmedBanksData && !isTicket)} */
                >
                  {actionType === "cancel" && "Cancelar solicitação"}
                  {actionType === "proceed" && "Confirmar"}
                  {actionType === "submitToAprove" && "Submeter para aprovação"}
                </Button>
              )}
            </>
          )}
          {type === "aprove" && (
            <>
              <Button color="secondary" variant="contained" disabled={isSaving} onClick={selectAllDocuments}>
                Selecionar Tudo
              </Button>
              <Button
                color="error"
                variant="contained"
                disabled={isSaving}
                onClick={() => {
                  handleRequest("reprove");
                }}
              >
                Reprovar
              </Button>
              <Button
                variant="contained"
                color="primary"
                disabled={isSaving}
                onClick={() => {
                  handleRequest("aprove");
                }}
              >
                Aprovar
              </Button>
            </>
          )}
        </Actions>
      )}
      <DefaultDialog
        ref={confirmCancelDialogRef}
        open={false} //valor inicial
        disableEscapeKeyDown
        disableClickAwayClose
        confirmButtonColor="primary"
        titleText="Cancelar Solicitação"
        contentText={`O vencimento da NF está divergente.\n\nLembrete: Caso escolha cancelar solicitação, a solicitação atual será\ncancelada e será necessário criar uma Solicitação de Pagamento Urgente`}
        action={cancelDocuments}
        confirmButtonTitle="Cancelar solicitação"
      />
      <ChangeBankProviderRequestDialog
        ref={changeBankProviderRequestDialogRef}
        onFinishProcess={() => setWasRequestChangeBillingInfo(true) }
        provider={{
          id: supplier.id,
          branch: supplier.branch,
          cnpj: supplier.cpf_cnpj,
          name: supplier.name,
        }}
      />
    </>
  );
};

export default Validations;

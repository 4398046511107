import { useMemo, useCallback, useState, useEffect } from "react"
import { useQuery } from "react-query"
import { toast } from "react-toastify"

import { BrandsResponseData, ClientResponseData, Quotes, SellersResponseData } from "../../../@types/dashboards"

import apiService from "../../../services/api.services"

import { formatValue } from "../../../utils/format/formatValue"

import { ResumeBrandChart } from "../components/ResumeBrandChart"

import { Grid } from "@mui/material"
import { Container } from "./style"
import { DashboardFilter } from "../components/Filters"
import { BarTargetChart, ChartSelection } from "../components/BarTargetChart"
import { ClientsTable } from "../components/ClientsTable"
import { DefaultTheme } from "../../../themes/defaultTheme"
import { getErrorMessage } from "../../../utils/validations/getErrorMessage"

export enum BrandsTypes {
    BIODERMA = "01",
    ESTHEDERM = "02",
    NAOS = "03",
} 

interface Filters{
    period: string
    quote: Quotes
    seller?: string
}

export const QuoteDashboard: React.FC = () => {

    const [filters, setFilters] = useState<Filters>(() => {
        const currentDate = new Date()
        const savedPeriod = sessionStorage.getItem("@portalnaos:dashboard:period")

        const currentPeriod = savedPeriod || `${currentDate.getFullYear()}-${String(currentDate.getMonth() + 1).padStart(2, "0")}`

        return {
            period: currentPeriod,
            quote: "BU",
            seller: ""
        }
    })

    const [selectedBrand, setSelectedBrand] = useState<BrandsTypes | null>(null)

    const { data, isLoading, error, isFetching } = useQuery([
        "quotedash", 
        filters.quote, 
        filters.period,
        filters.seller,
        selectedBrand
    ], 
    () =>  getDashboardValues(filters), 
    {
        staleTime: 1000 * 60 * 10, // 10min
        refetchInterval: 1000 * 60 * 10, // 10min
        refetchOnWindowFocus: true
    })

    const { data: sellerData, isLoading: sellerLoading, error: sellerError } = useQuery([
        "quotedash-seller", 
        filters.quote, 
        filters.period,
        selectedBrand
    ], 
    () =>  getSellerValues({
        period: filters.period,
        quote: filters.quote
    }), 
    {
        staleTime: 1000 * 60 * 10, // 10min
        refetchInterval: 1000 * 60 * 10, // 10min
        refetchOnWindowFocus: true
    })

    const getDashboardValues = async(params: Filters) => {

        const requestParams: any = {
            anomes: params.period.replaceAll("-", ""),
            tipocota: params.quote,
            codven: params.seller
        }

        if(selectedBrand){
            requestParams.marca = selectedBrand
        }

        const [
            brandsResponse,
            clientsResponse
        ] = await Promise.all([
            apiService.get<BrandsResponseData>("/dashbo_venmet", {
                params: requestParams
            }),
            apiService.get<ClientResponseData>("/dashbo_targcli", {
                params: requestParams
            })
        ])

        if(!brandsResponse.data.summary){
            throw new Error("Dados das marcas não encontrados")
        }

        if(!clientsResponse.data.data){
            throw new Error("Dados dos clientes não encontrados")
        }

        const brands = {
            bioderma: brandsResponse.data.summary.find(brand => brand.brand_id === BrandsTypes.BIODERMA),
            esthederm: brandsResponse.data.summary.find(brand => brand.brand_id === BrandsTypes.ESTHEDERM),
            naos: brandsResponse.data.summary.find(brand => brand.brand_id === BrandsTypes.NAOS)
        }

        return {
            brands,
            clients: clientsResponse.data.data || []
        }
    }

    const getSellerValues = async(params: Filters) => {

        const sellersResponse = await apiService.get<SellersResponseData>("/dashbo_targven", {
            params: {
                anomes: params.period.replaceAll("-", ""),
                tipocota: params.quote,
                marca: selectedBrand
            }
        })

        if(!sellersResponse.data.data){
            throw new Error("Dados dos vendedores não encontrados")
        }

        return {
            sellers: sellersResponse.data.data || []
        }
    }

    const hanldeQuoteSelection = useCallback(async(quote: Quotes) => {
        setFilters(prev => ({
            ...prev,
            quote: quote,
            seller: undefined
        }))
    },[])

    const handleFilter = useCallback(async(filters: Omit<Filters, "quote">) => {
        setFilters(prev => ({
            ...prev,
            ...filters
        }))
    },[])

    const handleSellerSelection = useCallback((selectedPoint: ChartSelection) => {
        const selectedSeller = selectedPoint.label_info_id

        setFilters(prev => ({
            ...prev,
            seller: prev.seller === selectedSeller ? "" : selectedSeller as string
        }))
    },[])

    const biodermaChartValues = useMemo(() => {
        if(!data?.brands){
            return null
        }

        const { bioderma } = data.brands

        if(!bioderma){
            return null
        }

        return {
            title: `${bioderma.brand} - R$${formatValue(bioderma.value)} %${Math.floor(bioderma.current_percent)}`,
            subtitle: `Target: R$${formatValue(bioderma.target)}`,
            labels: bioderma.data.map(item => item.date),
            values: bioderma.data.map(item => item.current_percent)
        }
    },[data?.brands])

    const esthedermChartValues = useMemo(() => {
        if(!data?.brands){
            return null
        }

        const { esthederm } = data.brands

        if(!esthederm){
            return null
        }

        return {
            title: `${esthederm.brand} - R$${formatValue(esthederm.value)} %${Math.floor(esthederm.current_percent)}`,
            subtitle: `Target: R$${formatValue(esthederm.target)}`,
            labels: esthederm.data.map(item => item.date),
            values: esthederm.data.map(item => item.current_percent)
        }
    },[data?.brands])

    const naosChartValues = useMemo(() => {
        if(!data?.brands){
            return null
        }

        const { naos } = data.brands

        if(!naos){
            return null
        }

        return {
            title: `${naos.brand} - R$${formatValue(naos.value)} %${Math.floor(naos.current_percent)}`,
            subtitle: `Target: R$${formatValue(naos.target)}`,
            labels: naos.data.map(item => item.date),
            values: naos.data.map(item => item.current_percent)
        }
    },[data?.brands])

    const sellersChartValues = useMemo(() => {
        if(!sellerData?.sellers){
            return []
        }

        return sellerData.sellers.map(seller => ({
            label: seller.seller_name,
            value: seller.invoice_value,
            label_info_id: seller.seller_code,
            goals: [{
                title: "Target",
                value: seller.target_value
            }]
        }))
    },[sellerData?.sellers])

    useEffect(() => {
        sessionStorage.setItem("@portalnaos:dashboard:period", filters.period)
    },[filters.period])

    if(error){
        toast.error(getErrorMessage(error))
    }

    if(sellerError){
        toast.error(getErrorMessage(sellerError))
    }

    return (
        <Container>
            <Grid container mb={1}>
                <Grid xs={12} md={12}>
                    <DashboardFilter 
                        isLoading={ isLoading || isFetching }
                        onChangeQuote={ hanldeQuoteSelection }
                        onStartFilter={ handleFilter }
                    />
                </Grid>
            </Grid>
            <Grid 
                container
                maxHeight={"calc(100vh - 140px)"}
                overflow={["auto","auto","auto", "hidden"]}
                spacing={2}
            >
                <Grid item xs={12} md={12} lg={3}>
                    <ResumeBrandChart 
                        isLoading={ isLoading }
                        title={ biodermaChartValues?.title || "" }
                        subtitle={ biodermaChartValues?.subtitle || "" }
                        height={200}
                        labels={biodermaChartValues?.labels || []}
                        values={biodermaChartValues?.values || []}
                        onSelectCard={() => setSelectedBrand(BrandsTypes.BIODERMA)}
                        isSelected={ selectedBrand === BrandsTypes.BIODERMA }
                    />
                </Grid>
                <Grid item xs={12} md={12} lg={3}>
                    <ResumeBrandChart 
                        isLoading={ isLoading }
                        title={ esthedermChartValues?.title || "" }
                        subtitle={ esthedermChartValues?.subtitle || "" }
                        height={200}
                        labels={esthedermChartValues?.labels || []}
                        values={esthedermChartValues?.values || []}
                        onSelectCard={() => setSelectedBrand(BrandsTypes.ESTHEDERM)}
                        isSelected={ selectedBrand === BrandsTypes.ESTHEDERM }
                    />
                </Grid>
                <Grid item xs={12} md={12} lg={3}>
                    <ResumeBrandChart 
                        isLoading={ isLoading }
                        title={ naosChartValues?.title || "" }
                        subtitle={ naosChartValues?.subtitle || "" }
                        height={200}
                        labels={naosChartValues?.labels || []}
                        values={naosChartValues?.values || []}
                        onSelectCard={() => setSelectedBrand(null)}
                        isSelected={ !selectedBrand }
                    />
                </Grid>
                <Grid item xs={12} md={12} lg={3}>
                </Grid>
                <Grid item xs={12} md={12} lg={6}>
                    <BarTargetChart
                        isLoading={ sellerLoading }
                        values={ sellersChartValues }
                        height={"98%"}
                        amount="currency"
                        onSelectDataPoint={ handleSellerSelection }
                        legends={[
                            {
                                name: "Realizado",
                                color: DefaultTheme["cyan-200"],
                            },
                            {
                                name: "Target",
                                color: DefaultTheme["yellow-500"],
                            }
                        ]}
                    />
                </Grid>
                <Grid item xs={12} md={12} lg={6}>
                    <ClientsTable 
                        height="calc(100vh - 390px)"
                        isLoading={ isLoading }
                        clients={ data?.clients || [] } 
                        hasSelectedSeller={ !!filters.seller }
                    />
                </Grid>
            </Grid>
        </Container>
    )
}
import styled from "styled-components"

import { DialogContent } from "@mui/material"

export const Content = styled(DialogContent)`
    p{
        width: 100%;
        margin-top: 35px;
        font-family: sans-serif;
    }
`

export const InputContainer = styled.div`
    width: 100%;
    height: 162px;
    margin-top: 20px;

    .selected-row{
        background-color: #bbfcd0 !important;
    }

    .MuiDataGrid-row:hover{
        cursor: pointer !important;
    }
`
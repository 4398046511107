import styled from 'styled-components'
import { css } from 'styled-components'
import { fade } from '../../../../styles/animations'

import { Skeleton } from '@mui/material'

interface ContainerProps{
    width?: number 
    height?: number
    isSelected?: boolean
}

export const Container = styled.div<ContainerProps>`
    width: ${props => props.width ? `${props.width}px` : "100%" };
    height: ${props => props.height ? `${props.height}px` : "auto" };
    padding-top: 16px;
    background-color: ${ props => props.theme['gray-700'] };
    border: 4px solid ${ props => props.theme['gray-700'] };
    border-radius: 10px;
    overflow: hidden;
    cursor: pointer;
    
    ${ props => props.isSelected && css`
        border: 4px solid ${  props.theme['yellow-500'] };
    ` }

    .apexcharts-yaxis-texts-g{
        visibility: hidden !important;
    }
`

export const SkeletonChart = styled(Skeleton)`
    border-radius: 10px !important;
`

export const NotFoundContainer = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 18px;
    color: ${ props => props.theme["white-100"] };
    text-align: center;

    animation: ${ fade } 300ms;
`
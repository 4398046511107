import styled from 'styled-components'

import { DialogContent } from '@mui/material'
import { Button } from '../../../../../components/Buttons/Button'

export const Content = styled(DialogContent)`
    .MuiDataGrid-columnHeaderTitleContainerContent{
        .MuiCheckbox-root{
            display: none !important;
        }
    }
`

export const Subtitle = styled.p`
    font-weight: bold;
    font-size: 1.1rem;
    color: ${ props => props.theme['blue-800'] };
`

export const GetNFDataButton = styled(Button)`
    position: absolute !important;
    right: 0px;
    bottom: 0px;
    height: 41px !important;
    border-radius: 0px 5px 5px 0px !important;
    border: none;
    background-color: #2e7d32;
    transition: 200ms;
`
import styled from "styled-components"

export const CloseDialogButton = styled.button`
    width: 30px;
    height: 30px;
    position: absolute;
    top: 2px;
    right: 2px;
    background-color: #ffffff00;
    border: none;
    border-radius: 50%;
    padding: 3px;
    transition: 200ms;

    svg{
        font-size: 15px;
    }
`

export const Hint = styled.div`
    width: 100%;
    height: auto;
    position: relative;
    padding: 10px;
    padding-top: 25px;
    display: flex;
    flex-direction: column;

    svg{
        width: 32px;
        height: 32px;
        margin: 0px !important;
        margin-right: 16px !important;
    }

    > div{
        width: 100%;
        height: 40px;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;

        div{
            margin-right: 16px;
        }
    
        p{
            margin-right: 10px;
            font-size: 1.2rem;

            &:hover{
                cursor: default;
            }
        }
    }

    @media(max-width: 750px){
        width: 260px;

        p{
            font-size: 14px !important;
        }
    }
`
import { IconProps } from "../../../@types/icons";

export const AprovedNFIcon: React.FC<IconProps> = ({ color, height, size }) => (
    <svg 
        width={ size || "32"} 
        height={ height || "32"}  
        color={ color }
        viewBox="0 0 32 32" 
        fill="none" 
        xmlns="http://www.w3.org/2000/svg"
    >
        <g clip-path="url(#clip0_301_1353)">
            <path d="M15.9996 0.00012207C7.16335 0.00012207 0 7.1638 0 16C0 24.8362 7.16335 31.9998 15.9996 31.9998C24.8358 31.9998 32 24.8359 32 16C31.9999 7.16348 24.8358 0.00012207 15.9996 0.00012207ZM23.1687 23.1686C23.1687 24.2423 22.2984 25.1126 21.2249 25.1126H10.775C9.70108 25.1126 8.83099 24.2423 8.83099 23.1686V8.83101C8.83099 7.75729 9.70129 6.887 10.775 6.887H18.9769V10.775H23.1687V23.1686ZM19.8069 9.94483V6.88678L23.1687 9.94483H19.8069Z" fill="#149914"/>
            <path d="M15.9996 0.00012207C7.16335 0.00012207 0 7.1638 0 16C0 24.8362 7.16335 31.9998 15.9996 31.9998C24.8358 31.9998 32 24.8359 32 16C31.9999 7.16348 24.8358 0.00012207 15.9996 0.00012207ZM23.1687 23.1686C23.1687 24.2423 22.2984 25.1126 21.2249 25.1126H10.775C9.70108 25.1126 8.83099 24.2423 8.83099 23.1686V8.83101C8.83099 7.75729 9.70129 6.887 10.775 6.887H18.9769V10.775H23.1687V23.1686ZM19.8069 9.94483V6.88678L23.1687 9.94483H19.8069Z" fill="#149914"/>
            <path d="M14.9055 17.5477L13.1078 15.7673L13.1079 15.7673L13.1015 15.7612C12.8219 15.4938 12.4483 15.3467 12.0616 15.3501C11.6749 15.3534 11.304 15.5069 11.029 15.7791C10.7539 16.0515 10.5969 16.421 10.5935 16.8083C10.5901 17.1957 10.7406 17.5679 11.0109 17.845L11.0109 17.845L11.017 17.8511L13.9602 20.7646L13.9603 20.7647C14.106 20.9089 14.2802 21.0211 14.4716 21.0946L14.6508 20.6278L14.4716 21.0946C14.663 21.168 14.8676 21.2012 15.0724 21.192C15.2772 21.1827 15.4779 21.1314 15.662 21.041L15.4415 20.5922L15.662 21.041C15.846 20.9506 16.0093 20.8231 16.1414 20.6662L16.1415 20.6662L21.0432 14.8434C21.1714 14.6971 21.2693 14.5267 21.3311 14.3422C21.3936 14.1555 21.4178 13.9581 21.4022 13.7619C21.3866 13.5656 21.3315 13.3746 21.2403 13.2001C21.1491 13.0257 21.0238 12.8714 20.872 12.7461C20.7202 12.6209 20.5449 12.5272 20.3565 12.4703C20.1681 12.4134 19.9703 12.3945 19.7745 12.4144C19.5787 12.4344 19.3888 12.4929 19.2158 12.5868C19.0448 12.6796 18.8937 12.8051 18.7713 12.9564L14.9055 17.5477Z" fill="#F2F2F2" stroke="#149914"/>
        </g>
        <defs>
            <clipPath id="clip0_301_1353">
                <rect width="32" height="32" fill="white"/>
            </clipPath>
        </defs>
    </svg>
)
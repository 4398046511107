import React from "react";
import { useCallback } from "react";
import { forwardRef } from "react";

import { BuyOrder } from "../../../../../@types/buyOrder";

import { getBrandsMask, getDeliveryLocationMask, getNFStatusMask, getOCStatusMask, getTypeMask } from "../../../../../data/formValues";

import { useInitialData } from "../../../../../hooks/initialData";

import { formatValue } from "../../../../../utils/format/formatValue";

import { Grid } from "@mui/material";
import { useTheme } from "../../../../../hooks/theme";

import { BoxContainer, Container, Header, ReportTable, BoxWithBreakContent } from "./styles";

interface PrintOCProps {
  selectedOrder: BuyOrder;
}

const PrintOC: React.ForwardRefRenderFunction<HTMLDivElement, PrintOCProps> = ({ selectedOrder }, ref) => {
  const { appImages } = useTheme();
  const { filiaisData } = useInitialData();

  const getNFStatus = (status: string): string => {
    switch (Number(status)) {
      case 1:
        return "Aguardando vínculo";
      case 2:
        return "Ok";
      case 3:
        return "Divergente";
      case 4:
        return "Aprovado com divergencia";
      case 5:
        return "Reprovado";
      default:
        return "";
    }
  };

  const getNFType = (type: string) => {
    switch (type.toUpperCase()) {
      case "NF-E":
        return "NF-E";
      case "NFS-E":
        return "Nota de Serviço";
      default:
        return "-------";
    }
  };

  const getDateMask = useCallback((date: Date) => {
    const currentDate = new Date(date);

    return currentDate?.toLocaleDateString("pt-BR", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    });
  }, []);

  return (
    <Container ref={ref}>
      <Header>
        <h1>Relatório de solicitação</h1>
        <img src={appImages.home} alt="naos" />
      </Header>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Grid container spacing={3}>
            <Grid item xs={7}>
              <BoxContainer style={{ minHeight: "215px" }}>
                <legend>Dados Gerais</legend>
                <p>
                  <strong>Numero da solicitação: </strong>
                  {selectedOrder.id}
                </p>
                <p>
                  <strong>Tipo da solicitação: </strong>
                  {getTypeMask(selectedOrder.tipo)}
                </p>
                <p>
                  <strong>Marca: </strong>
                  {getBrandsMask(selectedOrder.marca)}
                </p>
                <p>
                  <strong>Data de criação: </strong>
                  {getDateMask(selectedOrder.data_emissao)}
                </p>
                <p>
                  <strong>Data de entrega: </strong>
                  {getDateMask(selectedOrder.data_entrega)}
                </p>
                <p>
                  <strong>Faturamento em: </strong>
                  {`${selectedOrder.faturar_em} - ${filiaisData.find((filial) => filial.filial === selectedOrder.faturar_em)?.nome}`}
                </p>
                <p>
                  <strong>Local de entrega: </strong>
                  {getDeliveryLocationMask(selectedOrder.local_entrega)}
                </p>
                <p>
                  <strong>Condição de pagamento: </strong>
                  {selectedOrder.condicao_pagamento}
                </p>
                {!!selectedOrder.obs_tipo_pagto && (
                  <p style={{ maxWidth: "300px" }}>
                    <strong>Motivo solicitação: </strong>
                    {selectedOrder.obs_tipo_pagto}
                  </p>
                )}
              </BoxContainer>
            </Grid>
            <Grid item xs={5}>
              <BoxContainer style={{ minHeight: "215px", height: "calc(100% - 25px)" }}>
                <legend>Status Solicitação</legend>
                <p>
                  <strong>Status: </strong>
                  {getOCStatusMask(selectedOrder.status)}
                </p>
                <p>
                  <strong>Status NFs: </strong>
                  {getNFStatusMask(selectedOrder.status_diver_nfxoc || "")}
                </p>
                <p>
                  <strong>Aprovador: </strong>
                  {selectedOrder.aprovadores?.aprovador1?.name}
                </p>
                <p>
                  <strong>Data Aprovação: </strong>
                  {getDateMask(selectedOrder.hitorico_aprov?.aprovador1?.data_aprovacao as any)}
                </p>
                <p>
                  <strong>Hora Aprovação: </strong>
                  {selectedOrder.hitorico_aprov?.aprovador1?.hora_aprovou}
                </p>
                {selectedOrder.hitorico_aprov?.aprovador2?.hora_aprovou && (
                  <>
                    <p>
                      <strong>2º Aprovador: </strong>
                      {selectedOrder.aprovadores?.aprovador2?.name}
                    </p>
                    <p>
                      <strong>Data 2ª Aprovação: </strong>
                      {selectedOrder.hitorico_aprov?.aprovador2?.data_aprovacao &&
                        getDateMask(selectedOrder.hitorico_aprov.aprovador2?.data_aprovacao as any)}
                    </p>
                    <p>
                      <strong>Hora 2ª Aprovação: </strong>
                      {selectedOrder.hitorico_aprov?.aprovador2?.hora_aprovou}
                    </p>
                  </>
                )}
              </BoxContainer>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {selectedOrder.cnpj_fornecedor && (
        <Grid item xs={12}>
          <BoxContainer>
            <legend>Fornecedor</legend>
            <p>
              <strong>CNPJ/CPF: </strong>
              {selectedOrder.cnpj_fornecedor}
            </p>
            <p>
              <strong>Código: </strong>
              {selectedOrder.codigo_fornecedor} <strong>Loja: </strong>
              {selectedOrder.loja_fornecedor}
            </p>
            <p>
              <strong>Razão social: </strong>
              {selectedOrder.nome_fornecedor}
            </p>
            <p>
              <strong>Nome Fantasia: </strong>
              {selectedOrder.fantasia_fornecedor}
            </p>
            <p>
              <strong>Inscrição estadual: </strong>
              {selectedOrder.inscricao_fornecedor}
            </p>
            <p>
              <strong>E-mail: </strong>
              {selectedOrder.email_fornecedor}
            </p>
          </BoxContainer>
        </Grid>
      )}
      <BoxContainer>
        <legend style={{ marginBottom: "0px" }}>Produtos</legend>
        <ReportTable>
          <thead>
            <tr>
              <th>Descrição</th>
              <th>Centro Custo</th>
              <th>C.Corp</th>
              <th>UM*</th>
              <th>QTD</th>
              <th>Vl. Unit.</th>
              <th>Vl. Total</th>
            </tr>
          </thead>
          <tbody>
            {selectedOrder.itens?.map((item) => (
              <tr key={item.item}>
                <td>
                  {item.codigo_produto} - {item.descriao_produto}
                </td>
                <td>{item.codigo_cc}</td>
                <td>{
                  typeof item.cartao_corporativo === "string"
                    ? item.cartao_corporativo?.toUpperCase() === "S" ? "Sim" : "Não"
                    : item.cartao_corporativo ? "Sim" : "Não"
                }</td>
                <td>{item.unidade_produto}</td>
                <td>{item.quantidade}</td>
                <td>{formatValue(item.valor_unitario as number)}</td>
                <td>{formatValue(item.valor_total as number)}</td>
              </tr>
            ))}
          </tbody>
        </ReportTable>
        <p style={{ float: "right" }}>
          <strong>Valor Total: </strong>
          {formatValue(selectedOrder.itens?.reduce((accumulator, currentValue) => accumulator + Number(currentValue.valor_total), 0) || 0)}
        </p>
        <p style={{ marginTop: "15px" }}>
          <strong>* Unidade de Medida</strong>
        </p>
        <p>
          <strong>OBS: {selectedOrder.observacao}</strong>
        </p>
      </BoxContainer>
      {selectedOrder.documentos?.map((document, index) => (
        <BoxWithBreakContent key={index}>
          <legend>Validações da NF- {document.numero_nf}</legend>
          <p>
            <strong>Tipo: </strong>
            {getNFType(document.tipo_nf)}
          </p>
          <p>
            <strong>Status: </strong>
            {getNFStatus(document.status || "")}
          </p>
          <p>
            <strong>Número: {document.numero_nf}</strong> <strong> Série: {document.serie_nf}</strong>
          </p>
          <p>
            <strong>Data de emissão: </strong>
            {document.emissao.replaceAll("-", "/")}
          </p>
          <p>
            <strong>Data de vencimento: </strong>
            {document.vencimento.replaceAll("-", "/")}
          </p>
          <br />
          <p>
            <strong>Validações</strong>
          </p>
          <ReportTable>
            <thead>
              <tr>
                <th>Descrição</th>
                <th>Status</th>
                <th style={{ minWidth: "90px" }}>Info. NF</th>
                <th style={{ minWidth: "90px" }}>Info. Solic.</th>
                <th>Observação</th>
              </tr>
            </thead>
            <tbody>
              {document.validacoes?.map((validation) => (
                <tr>
                  <td>{validation.title}</td>
                  <td>{validation.status}</td>
                  <td>{validation.nf}</td>
                  <td>{validation.oc}</td>
                  <td>{validation.description}</td>
                </tr>
              ))}
            </tbody>
          </ReportTable>
          <br />
          <p>
            <strong>Itens Vinculados</strong>
          </p>
          <ReportTable>
            <thead>
              <tr>
                <th>Descrição</th>
                <th>Centro Custo</th>
                <th>UM*</th>
                <th>QTD</th>
                <th>Vl. Unit.</th>
                <th>Vl. Total</th>
              </tr>
            </thead>
            <tbody>
              {document.itens?.map((item) => (
                <tr key={item.item}>
                  <td>
                    {item.codigo_produto} - {item.descriao_produto}
                  </td>
                  <td>{item.codigo_cc}</td>
                  <td>{item.unidade_produto}</td>
                  <td>{item.quantidade}</td>
                  <td>{formatValue(item.valor_unitario as number)}</td>
                  <td>{formatValue(item.valor_total as number)}</td>
                </tr>
              ))}
            </tbody>
          </ReportTable>
        </BoxWithBreakContent>
      ))}
    </Container>
  );
};

export default forwardRef(PrintOC);

import { useMemo } from 'react'
import { GridColumn } from '../../../../../@types/datagrid'
import { DataTable } from '../../../../../components/Tables/Datagrid'
import { formatValue } from '../../../../../utils/format/formatValue'
import { Container, SkeletonChart, SkeletonHeader } from './style'

interface Sku{
    sku_code: string
    sku_name: string
    stock_days: number
    per_represent: number
    inativo: "N" | "S"
}

interface SkusTableProps{
    isLoading?: boolean
    skus: Sku[]
}

export const SkusTable: React.FC<SkusTableProps> = ({ skus, isLoading }) => {

    const skusTableColumns: GridColumn[] = [
        { 
            field: "sku_code", 
            headerName: "Código" 
        },
        { 
            field: "sku_name", 
            headerName: "Descrição" 
        },
        { 
            field: "stock_days", 
            headerName: "Dias Est.", 
            align: "center", 
            headerAlign: "center" 
        },
        { 
            field: "per_represent", 
            headerName: "% Rep.", 
            align: "center", 
            headerAlign: "center",
            renderCell: (row: Sku) => formatValue(row.per_represent)
        },
        { 
            field: "inativo", 
            headerName: "Status", 
            align: "center", 
            headerAlign: "center",
            renderCell: (row: Sku) => row.inativo === "S"
                ? "inativo"
                : "ativo"
        }
    ]

    const total = useMemo(() => (
        skus.reduce((accumulator, currentSku) => accumulator + currentSku.per_represent ,0)
    ),[skus])

    if(isLoading){
        return(
            <Container>
                <SkeletonHeader>
                    <SkeletonChart height={"50px"} width={"20%"}/>
                    <SkeletonChart height={"50px"} width={"20%"}/>
                    <SkeletonChart height={"50px"} width={"20%"}/>
                    <SkeletonChart height={"50px"} width={"20%"}/>
                    <SkeletonChart height={"50px"} width={"20%"}/>
                </SkeletonHeader>
                { [...new Array(6)].map((_, index) => (
                    <SkeletonChart key={ index } height={"100px"}/>
                ))}
            </Container>
        )
    }

    return (
        <Container>
            <DataTable 
                rows={ skus }
                columns={ skusTableColumns }
                paginationType="disabled"
                disableCheckboxSelection
                variant='dark'
                rootStyle={{ border: "2px solid #FEBD11" }}
                pinnedRows={[
                    {
                        position: "bottom",
                        content: [
                            { field: "sku_code", value: "Total Representatividade (%)", colspan: 4, align: "left"},
                            { field: "inativo", value: formatValue(total), align: "center"}
                        ]
                    }
                ]}
            />
        </Container>
    )
}
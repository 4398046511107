import styled from "styled-components"
import { shade } from "polished"

import { fade } from "../../../styles/animations"

import { ButtonBase } from "@mui/material"

interface ContainerProps {
    bgColor?: string
}

export const Container = styled(ButtonBase)<ContainerProps>`
    display: flex;
    flex-direction: column !important;
    align-items: flex-start !important;
    width: 100%;
    padding: 15px !important;
    margin-bottom: 7px;
    border-radius: 10px !important;
    background-color: ${props => props.bgColor || "#F9F9F9"} !important;
    transition: 200ms;
    box-shadow: 5px 5px 5px ${props => shade(0.2, props.bgColor || "#F9F9F9")};
    position: relative;
    animation: ${ fade } 250ms;

    & + &{
        margin-top: 10px;
    }

    p{
        width: 100%;
        text-align: left;

        &:first-child{
            text-align: right !important;;
        }
    }
`

export const Actions = styled.div`
    width: 100%;
    display: flex !important;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-top: 15px;

    button, a{
        height: 30px;
    }
`
import styled from "styled-components"
import { shade } from "polished"
import { ButtonBase } from "@mui/material"

export const Content = styled.div`
    padding: 15px;
    padding-top: 0px;
    margin-bottom: 10px;
    width: 100%;
    max-height: 50vh;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
`

export const Actions = styled.div`
    width: 100%;
    padding: 15px;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;

    button{
        width: auto;
        max-width: 220px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-evenly;

        svg{
            margin-right: 10px;
            margin-top: -2px;
        }
    }
`

export const FileCard = styled.div`
    width: 100%;
    min-height: 45px;
    padding: 5px 5px 5px 15px;
    background-color: ${ shade(0.05, "#f9f9f9")};
    border-radius: 5px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    strong{
        margin-right: 10px;
        word-break: break-all;
    }

    & + &{
        margin-top: 15px;
    }

    div{
       display: flex;
       flex-direction: row; 
    }

    .file-content{
        display: flex;
        gap: 4px;
        width: 100%;

        strong:first-child{
            flex: 1;
        }
    }

    @media(max-width: 750px){
        strong, span, p{
            font-size: 12px;
        }
    }
`

export const LoadingContainer = styled.div`
    width: 100%;
    height: 280px;
    display: flex;
    align-items: center;
    justify-content: center;
`

export const NotFoundFileContainer = styled(ButtonBase)`
    width: 100%;
    display: flex;
    padding: 16px !important;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 24px;
    border-radius: 10px !important;

    svg{
        width: 200px;
    }
`
import styled from "styled-components"
import { css } from "styled-components"

import { fade, invertedFade } from '../../../styles/animations'

interface ButtonActionsProps{
    containFiles?: boolean
}

interface ContentProps{
    startAnimation?: "open" | "close" 
}

export const Content = styled.div<ContentProps>`
    flex: 1;
    height: 95%;
    padding: 15px;
    position: relative;
    animation: ${ fade } 200ms;

    > img{
        position: absolute;
        background-color: #f2f2f2;
        top: 0;
        width: 98%;
        height: 450px;
        margin-top: 15px;
        border-radius: 10px;
        object-fit: contain;
        animation: ${ fade } 150ms;

        @media (max-width: 750px){
            width: 95%;
            height: 25vh;
        }

        @media (max-width: 440px){
            width: 92%;
        }

        @media (max-width: 315px){
            width: 90%;
        }
    }

    @media(max-width: 750px){
        width: 100%;
        margin-top: -25px;
    }

    ${ props => props.startAnimation === "open" && css`
        animation: ${ fade } 200ms;
    `}

    ${ props => props.startAnimation === "close" && css`
        animation: ${ invertedFade } 200ms;
    `}
`

export const FileList = styled.div`
    height: 35px;
    padding-left: 10px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    position: absolute;
    bottom: 82px;
    z-index: 2;

    strong, span{
        font-family: sans-serif;
        font-size: 1.5rem;
    }

    strong{
        margin-right: 25px;
    }

    @media (max-width: 750px){
        strong, span{
            font-family: sans-serif;
            font-size: 1rem;
        }

        strong{
            margin-right: 5px;
        }
    }
`

export const ButtonActions = styled.div<ButtonActionsProps>`
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 16px;

    ${ props => props.containFiles === false && css`
        margin-top: 16px;
    `}

    button{ min-width: 200px; }

    @media(max-width: 750px){
        justify-content: space-between;

        button{
            width: 47%;
            max-width: 100%;
            margin-left: 0px;
            margin-bottom: 10px;
        }
    }
`
import { ToastContainer } from 'react-toastify'
import { ThemeProvider } from 'styled-components'
import { QueryClientProvider } from "react-query"
import { ReactQueryDevtools } from 'react-query/devtools'

import { queryClient } from "./services/queryClient"

import AppProvider from "./hooks"

import { DefaultTheme } from './themes/defaultTheme'

import Routes from './routes'
import { GlobalStyle } from './styles/globalStyles'

import 'bootstrap/dist/css/bootstrap.css';
import 'react-toastify/dist/ReactToastify.css'
import "./appstyle.css"

export default function App() {
  return (
    <QueryClientProvider client={ queryClient }>
      <ThemeProvider theme={ DefaultTheme }>
        <AppProvider>
          <Routes />
          <GlobalStyle />
          <ToastContainer 
            position="top-right" 
            autoClose={5000} 
            closeOnClick 
            theme="colored"
          />
        </AppProvider>
      </ThemeProvider>
      {/* <ReactQueryDevtools /> */}
    </QueryClientProvider>
  );
}

export const getDate = (date: string | Date) => {
    
    const selectedDate = new Date(date)

    const year  = selectedDate.getFullYear()
    const month = (selectedDate.getMonth() + 1).toString().padStart(2, "0")
    const day   = selectedDate.getDate().toString().padStart(2, "0")
    
    return (year + month + day)
}

export const getDatePtBR = (date: string) => {
    const day = new Date(date).getDate().toString().padStart(2, "0")
    const month = (new Date(date).getMonth() + 1).toString().padStart(2, "0")
    const year = new Date(date).getFullYear()

    return `${day}-${month}-${year}`
}

export const convertToDate = (data: string) => {
    const dateToCompareItens = data.split("/")
    const customDate = `${dateToCompareItens[0] <= "12" ? dateToCompareItens[0] : dateToCompareItens[1]}/${dateToCompareItens[0] <= "12" ? dateToCompareItens[1] : dateToCompareItens[0]}/${dateToCompareItens[2]}`

    return new Date(customDate)
}

export const getCustomDate = (data: string) => {
    const dateToCompareItens = data.split("/")
    const customDate = `${dateToCompareItens[0] <= "12" ? dateToCompareItens[0] : dateToCompareItens[1]}/${dateToCompareItens[0] <= "12" ? dateToCompareItens[1] : dateToCompareItens[0]}/${dateToCompareItens[2]}`

    return new Date(customDate).toLocaleDateString("pt-BR", {
        day: "2-digit",
        month: "2-digit",
        year: "numeric"
    })
}

export const convertDate = (date: string) => {
    const data = date.split("-")
    return new Date(`${data[2]}-${data[1]}-${data[0]}T03:00:00.033Z`)
}
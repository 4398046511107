import { useState, useRef, useCallback, useEffect} from "react"
import { FormHandles } from "@unform/core"
import { Form } from "@unform/web"
import { toast } from "react-toastify"

import apiService from "../../../../services/api.services"

import { getDate } from "../../../../utils/format/getData"
import { convertDateToEnglishPattern } from "../../../../utils/format/convertDateToEnglishPattern"

import FiltersForm from "../../../../components/FiltersForm"
import FormDataPicker, { FormDatePickerRef } from "../../../../components/Form/FormDataPicker"
import FormAutoComplete from "../../../../components/Form/FormAutoComplete"
import { FilterIcon } from "../../../../components/Misc/Icons/FilterIcon"
import { Product, SelectProductModal } from "../../../../components/Modal/SelectProduct"
import FormInputMultiSelection, { FormInputMultiSelectionRef } from "../../../../components/Form/FormInputMultiSelection"
import { Client, SelectClientsModal } from "../../../../components/Modal/SelectClient"

import { Search } from "@mui/icons-material"
import { Fade, Grid, TextField, Tooltip } from "@mui/material"

import { ActionButton, FilterContainer, FilterPosition, SearchButton } from "./style"
import FormSelect from "../../../../components/Form/FormSelect"
import FormInput from "../../../../components/Form/FormInput"

export interface RequestsDetailsFilters{
    datade: string
    dataate: string
    client?: any[] | string[]
    seller: string | null
    brand: string
    period?: string
    request?: string

}

interface RequestsDetailsFilterProps{
    onStartFilter: (params: RequestsDetailsFilters) => void
    onFinishFilter?: () => void
    onCleanFilter: () => void
}

export const RequestsDetailsFilter: React.FC<RequestsDetailsFilterProps> = ({ 
    onStartFilter, 
    onCleanFilter 
}) => {

    const formRef = useRef<FormHandles>(null)

    const initDateRef = useRef<FormDatePickerRef>(null)
    const endDateRef = useRef<FormDatePickerRef>(null)
    const clientRef = useRef<FormInputMultiSelectionRef>(null)

    const [selectedProduct, setSelectedProduct] = useState<Product>({} as Product)  
    const [openClientModal, setOpenClientModal] = useState<boolean>(false)  
    const [openProductsDialog, setOpenProductsDialog] = useState<boolean>(false)
    
    const [sellers, setSellers] = useState<string[]>([])

    const [openFilter, setOpenFilter] = useState<boolean>(false)

    const handleFilterSubmit = (data: RequestsDetailsFilters) => {
        let filters: RequestsDetailsFilters = {
            dataate: data.dataate ? getDate(convertDateToEnglishPattern(data.dataate)) : "",
            datade: data.datade ? getDate(convertDateToEnglishPattern(data.datade)) : "",
            seller: data.seller ? data.seller.split("-")[0]?.trim() : null,
            brand: data.brand,
            period: data.period,
            request: data.request
        }

        if(data.client && data.client.length > 0){
            filters.client = data.client.map((display: any) => display.id) 
        }

        onStartFilter(filters)
    }

    const handleClientsSelected = useCallback((clients: Client[]) => {
        clientRef.current?.changeValue(
            clients.map(client => ({
                id: client.code,
                label: client.name
            }))
        )
    },[])

    const cleanFilters = () => {
        formRef.current?.reset()
        initDateRef.current?.changeValue(null)
        endDateRef.current?.changeValue(null)
        clientRef.current?.changeValue([])
        
        onCleanFilter()
    } 

    const onSelectedProduct = (products: Product[]) => {
        const [selectedProduct] = products
        setSelectedProduct(selectedProduct)
    }

    const getSellers = async() => {
        try {
            const response = await apiService.get("/consultapadrao", {
                params: {
                    tabela: "SA3",
                    campos: "A3_COD,A3_NOME",
                    ordem: "A3_COD",
                    bolquio: "A3_MSBLQL"
                }
            })

            if(response.data.data){
                setSellers(response.data.data.consultapadrao.map(
                    (info: any) => (`${info.A3_COD} - ${info.A3_NOME}`)
                ))
            }

        } catch (error) {
            console.log(error)
            toast.error("Falha ao carregar dados dos vendedores")
        }
    }

    useEffect(() => {
        getSellers()
    },[])

    return(
        <>
        <FilterContainer>
            <Tooltip title="Filtrar">
                <ActionButton onClick={() => setOpenFilter(prev => !prev)}>
                    <FilterIcon />
                </ActionButton>
            </Tooltip>
            <SelectProductModal
                open={ openProductsDialog }
                /* onlyPA */
                onClose={ () => setOpenProductsDialog(false) }
                disableMultiSelection
                onSelect={ onSelectedProduct }
            />
        </FilterContainer>
        <Fade in={ openFilter }>
            <FilterPosition>
                <FiltersForm
                    onClean={ cleanFilters }
                    onSubmit={() => formRef.current?.submitForm() }
                    onClose={() => setOpenFilter(false)}
                >
                    <Form ref={ formRef } onSubmit={ handleFilterSubmit }>
                        <Grid container spacing={ 2 } mb={ 2 }>
                            <Grid item xs={ 12 } md={ 6 }>
                                <FormSelect
                                    name="marca"
                                    label="Marca"
                                    size="small"
                                    fullWidth
                                    options={[
                                        { label: "BIODERMA", value: "01" },
                                        { label: "ESTHEDERM", value: "02" },
                                        { label: "ETAT PUR", value: "04" },
                                        { label: "NAOS", value: "03" }
                                    ]}
                                />
                            </Grid>
                        </Grid>
                        <Grid container spacing={ 2 }>
                            <Grid item xs={ 12 } md={ 6 }>
                                <FormInput
                                    name="period"
                                    label="Período"
                                    size="small"
                                    type="month"
                                    fullWidth
                                    InputLabelProps={{ shrink: true }}
                                />
                            </Grid>
                            <Grid item xs={ 12 } md={ 6 }>
                                <FormInput
                                    name="request"
                                    label="Nº Pedido"
                                    size="small"
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={ 6 } md={ 3 }>
                                <FormDataPicker
                                    ref={ initDateRef }
                                    name="datade"
                                    fullWidth
                                    views={['day']}
                                    inputFormat="dd/MM/yyyy"
                                    label="Data Inicio"
                                    size="small"
                                />
                            </Grid>
                            <Grid item xs={ 6 } md={ 3 }>
                                <FormDataPicker
                                    ref={ endDateRef }
                                    name="dataate"
                                    views={['day']}
                                    fullWidth
                                    inputFormat="dd/MM/yyyy"
                                    label="Data Fim"
                                    size="small"
                                />
                            </Grid> 
                            <Grid item xs={ 12 } md={ 6 } position="relative">
                                <FormInputMultiSelection 
                                    ref={ clientRef }
                                    name="client"
                                    label="Cliente"
                                    size="small"
                                    variant="light"
                                    hasButtonOption
                                    onClick={() => setOpenClientModal(true)}
                                />
                                <SearchButton>
                                    <button type="button" onClick={() => setOpenClientModal(true)}>
                                        <Search/>
                                    </button>
                                </SearchButton>
                                <SelectClientsModal
                                    open={ openClientModal }
                                    onClose={ () => setOpenClientModal(false) }
                                    onSelect={ handleClientsSelected }
                                />
                            </Grid>
                            <Grid item xs={ 12 } md={ 6 } position="relative">
                                <TextField
                                    label="Código Produto"
                                    size="small"
                                    type="text"
                                    value={ selectedProduct.codigo }
                                    fullWidth
                                    InputLabelProps={{ shrink: true }}
                                    InputProps={{ readOnly: true }}
                                />
                                <SearchButton>
                                    <button type="button" onClick={() => setOpenProductsDialog(true)}>
                                        <Search/>
                                    </button>
                                </SearchButton>
                            </Grid>
                            <Grid item xs={ 12 } md={ 6 }>
                                <FormAutoComplete
                                    name="seller"
                                    label="Vendedor"
                                    loading={ sellers.length <= 0 }
                                    options={ sellers }
                                />
                            </Grid>
                        </Grid>
                    </Form>
                </FiltersForm>
            </FilterPosition>
        </Fade>
        </>
    )
}
import styled, { css } from 'styled-components'

interface InputProps{
    fullwidth?: boolean
    maxHeigth?: number
}

export const Container = styled.div<InputProps>`
    color: rgba(255,255,255,0.65);
    font-size: 14px;

    ${ props => props.fullwidth && css`
        width: 100%;
    `}
`

export const Tag = styled.div`
    display: flex;
    align-items: center;
    height: 24px;
    margin: 2px;
    line-height: 22px;
    background-color: #001d2a;
    border: 1px solid #001d2a;
    border-radius: 2px;
    box-sizing: content-box;
    padding: 0 4px 0 10px;
    outline: 0;
    overflow: hidden;

    &:focus {
        border-color: #facc15;
        background-color: #facc15;
    }

    & span {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }

    & svg {
        font-size: 22px;
        cursor: pointer;
        padding: 4px;
    }
`

export const ListBox = styled.ul`
    width: 530px;
    margin: 2px 0 0;
    padding: 0;
    position: absolute;
    list-style: none;
    background-color: #FFFFFF;
    color: #003b57;
    overflow: auto;
    max-height: 250px;
    border-radius: 4px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
    z-index: 1;
    cursor: pointer;

    & li {
        padding: 5px 12px;
        display: flex;

        & span {
            flex-grow: 1;
        }

        & svg {
            color: transparent;
        }
    }

    & li[aria-selected='true'] {
        /* background-color: #fafafa; */
        font-weight: 600;

        & svg {
            color: #1890ff;
        }
    }

    & li:focus {
        background-color: #003b57;
        cursor: pointer;

        & svg {
            color: currentColor;
        }
    }
`

export const InputWrapper = styled.div<InputProps>`
    width: ${ props => props.fullwidth ? "100%": "300px"};
    border: none;
    max-height: 52px;
    overflow-y: scroll;
    border-bottom: 1px solid #001d2a;
    background-color: #FFFFFF00;
    padding: 1px;
    display: flex;
    flex-wrap: wrap;

    &:hover {
        border-color: #177ddc;
    }

    &.focused {
        border-color: #177ddc;
        box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
    }

    & input {
        background-color: #FFFFFF00;
        color: #003b57;
        height: 30px;
        box-sizing: border-box;
        padding: 4px 6px;
        width: 0;
        min-width: 30px;
        flex-grow: 1;
        border: 0;
        margin: 0;
        outline: 0;
    }
`

export const Label = styled.label`
    padding: 0 0 4px;
    line-height: 1.5;
    display: block;
`
import { IconProps } from "../../../@types/icons";

export const CanceledIcon: React.FC<IconProps> = ({ color, height, size }) => (
    <svg 
        width={ size || "32"} 
        height={ height || "32"}  
        color={ color }
        viewBox="0 0 32 32" 
        fill="none" 
        xmlns="http://www.w3.org/2000/svg"
    >
        <path d="M5.33333 21.3333C4.95556 21.3333 4.63911 21.2053 4.384 20.9493C4.128 20.6942 4 20.3778 4 20C4 19.6222 4.128 19.3053 4.384 19.0493C4.63911 18.7942 4.95556 18.6667 5.33333 18.6667H12C12.3778 18.6667 12.6947 18.7942 12.9507 19.0493C13.2058 19.3053 13.3333 19.6222 13.3333 20C13.3333 20.3778 13.2058 20.6942 12.9507 20.9493C12.6947 21.2053 12.3778 21.3333 12 21.3333H5.33333ZM5.33333 16C4.95556 16 4.63911 15.872 4.384 15.616C4.128 15.3609 4 15.0444 4 14.6667C4 14.2889 4.128 13.972 4.384 13.716C4.63911 13.4609 4.95556 13.3333 5.33333 13.3333H17.3333C17.7111 13.3333 18.028 13.4609 18.284 13.716C18.5391 13.972 18.6667 14.2889 18.6667 14.6667C18.6667 15.0444 18.5391 15.3609 18.284 15.616C18.028 15.872 17.7111 16 17.3333 16H5.33333ZM5.33333 10.6667C4.95556 10.6667 4.63911 10.5391 4.384 10.284C4.128 10.028 4 9.71111 4 9.33333C4 8.95556 4.128 8.63867 4.384 8.38267C4.63911 8.12756 4.95556 8 5.33333 8H17.3333C17.7111 8 18.028 8.12756 18.284 8.38267C18.5391 8.63867 18.6667 8.95556 18.6667 9.33333C18.6667 9.71111 18.5391 10.028 18.284 10.284C18.028 10.5391 17.7111 10.6667 17.3333 10.6667H5.33333ZM18.2667 28.4C18.0222 28.1556 17.9 27.8444 17.9 27.4667C17.9 27.0889 18.0222 26.7778 18.2667 26.5333L20.8 24L18.2667 21.4667C18.0222 21.2222 17.9 20.9111 17.9 20.5333C17.9 20.1556 18.0222 19.8444 18.2667 19.6C18.5111 19.3556 18.8222 19.2333 19.2 19.2333C19.5778 19.2333 19.8889 19.3556 20.1333 19.6L22.6667 22.1333L25.2 19.6C25.4444 19.3556 25.7556 19.2333 26.1333 19.2333C26.5111 19.2333 26.8222 19.3556 27.0667 19.6C27.3111 19.8444 27.4333 20.1556 27.4333 20.5333C27.4333 20.9111 27.3111 21.2222 27.0667 21.4667L24.5333 24L27.0667 26.5333C27.3111 26.7778 27.4333 27.0889 27.4333 27.4667C27.4333 27.8444 27.3111 28.1556 27.0667 28.4C26.8222 28.6444 26.5111 28.7667 26.1333 28.7667C25.7556 28.7667 25.4444 28.6444 25.2 28.4L22.6667 25.8667L20.1333 28.4C19.8889 28.6444 19.5778 28.7667 19.2 28.7667C18.8222 28.7667 18.5111 28.6444 18.2667 28.4Z" fill="black"/>
    </svg>
)
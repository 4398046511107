import React from "react";
import { createContext, useContext, useCallback } from "react";

import api from "../services/api.services";

import { AttachFilesContextProps, AttachRequestData, OnUploadParams } from "../@types/attachFiles";

import { useAuth } from "./auth";
import { toast } from "react-toastify";

const AttachFilesContext = createContext({} as AttachFilesContextProps);

export const AttachFilesProvider: React.FC = ({ children }) => {
  const { userData } = useAuth();

  const onUpload = useCallback(
    async ({ files, type, id }: OnUploadParams): Promise<void> => {
      try {
        const response = await api.post(
          "/uploadanexo",
          { file: files },
          {
            params: {
              id: id,
              tipo: type,
              branch: userData.selectedBranch?.id || "",
            },
          }
        );

        if (response.data.status.status !== 200 && response.data.status.status !== 201) {
          throw new Error(`Falha ao realizar upload do arquivo ${files[0].fileName}`);
        }

        toast.success(`Arquivo ${files[0].fileName} gravado com sucesso`);
      } catch (error: any) {
        throw error;
      }
    },
    [userData]
  );

  const listFiles = useCallback(
    async ({
      id,
      type,
      chave,
      date_atual,
      fileName,
      number_vis,
      user_atual,
      user_name,
    }: Omit<OnUploadParams, "files">): Promise<Array<AttachRequestData>> => {
      try {
        const response = await api.get("/listaanexos", {
          params: {
            id: id,
            tipo: type,
            branch: userData.selectedBranch?.id as string,
            chave: chave,
            date_atual: date_atual,
            fileName: fileName,
            number_vis: number_vis,
            user_atual: user_atual,
            user_name: user_name,
          },
        });

        if (!response.data.data) {
          return [];
        }

        return response.data.data.listaanexos.map((file) => ({
          id: file.id,
          fileName: file.fileName,
          key: file.chave.trim(),
          chave: file.chave,
          date_atual: file.date_atual,
          number_vis: file.number_vis,
          user_atual: file.user_atual,
          user_name: file.user_name,
        }));
      } catch (error: any) {
        console.log(error);
        throw error;
      }
    },
    [userData]
  );

  const deleteFile = useCallback(
    async (fileId: string): Promise<void> => {
      try {
        await api.delete("/deleteanexo", {
          params: {
            id: fileId,
            branch: userData.selectedBranch?.id as string,
          },
        });
      } catch (error: any) {
        console.log(error);
        throw error;
      }
    },
    [userData]
  );

  const downloadFile = useCallback(
    async (fileId: string): Promise<string> => {
      const permission = userData.permissions?.includes("FINANC");

      try {
        const response = await api.get("/downloadanexo", {
          params: {
            id: fileId,
            branch: userData.selectedBranch?.id as string,
            perfil_financ: permission ? true : false,
          },
        });

        if (!response.data.data) {
          throw new Error("Documento não encontrado");
        }

        if (response.data.status.status !== 200) {
          throw new Error(response.data.status.descricao);
        }

        return response.data.data.downloadanexo[0].fileContent.split("/base64/")[1];
      } catch (error: any) {
        console.log(error);
        throw error;
      }
    },
    [userData]
  );

  return <AttachFilesContext.Provider value={{ onUpload, listFiles, deleteFile, downloadFile }}>{children}</AttachFilesContext.Provider>;
};

export const useAttachFiles = () => {
  const context = useContext(AttachFilesContext);

  if (!context) {
    throw new Error("The hook useAttachFiles must be used inside a AttachFilesProvider");
  }

  return context;
};

import styled from 'styled-components'
import { Fab } from '@mui/material'

export const AddProduct = styled(Fab)`
    position: absolute !important;
    background-color: ${ props => props.theme["blue-800"] } !important;
    bottom: -12px;
    left: -8px;

    svg{
        color: #FFF;
    }
`
import { Skeleton } from "@mui/material";
import { Video } from "../..";
import { Container } from './style'

interface VideoCardProps {
  video: Video
}

export const VideoCard: React.FC<VideoCardProps> = ({ 
  video 
}) => {
  return (
    <Container className="w-[300px] relative">
      <iframe
        title={video.title}
        className="max-w-[300px] h-[200px] rounded-md"
        src={video.url}
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
      />
      <h2 className="font-bold mt-2">{video.title}</h2>
    </Container>
  );
}

import styled from "styled-components";
import { lighten } from "polished";

import { ButtonBase, Popover } from "@mui/material";

export const OptionButton = styled(ButtonBase)`
    padding: 6px !important;
    border-radius: 5px !important;
`

export const OptionsMenu = styled(Popover)`
    .MuiPaper-root{
        width: min-content !important;
        min-width: 150px;
        max-height: 300px;
        overflow-y: auto;
        padding: 5px;
        position: relative;
        margin-left: 8px;
        background-color: ${props => props.theme["blue-800"]};
        color: #FFF;
    }

    .MuiMenuItem-root{
        height: 30px;
        border-radius: 5px;
        margin: 3px;
        transition: 200ms;
        display: flex !important;
        flex-direction: row !important;
        align-items: center !important;

        svg{
            margin-right: 10px;
        }

        &:hover{
            background-color: ${props => lighten(0.2, props.theme["blue-800"])};
        }
    }
`

export const OptionsContainer = styled.div`
`
import styled from "styled-components"
import { css } from "styled-components";

interface DropContainerProps{
    isDragActive: boolean;
    isDragReject: boolean;
    height: string
}

interface UploadMessageProps{
    type?: 'error' | 'success' | 'default'
}

const dragActive = css`
    border-color: #12a454;
`

const dragReject = css`
    border-color: #e83f5b;
`

const messageColors = {
    default: '#5636D3',
    error: '#e83f5b',
    success: '#12a454',
}

export const DropContainer = styled.div.attrs({ className: 'dropzone'})<DropContainerProps>`
    border: 1.5px dashed #969cb3;
    border-radius: 10px;
    height: ${props => props.height ? props.height : "450px" };
    margin-bottom: 20px;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    transition: height 0.2s ease;

    ${props => props.isDragActive && dragActive}

    ${props => props.isDragReject && dragReject}

    svg{
        font-size: 10rem;
        margin: 0px;

        ${props => props.height && css`
            margin-top: -15px;
        `}
    }

    p{
        padding: 0px;
    }

    @media(max-width: 750px){
        height: 25vh;

        svg{
            font-size: 5rem;
        }
    }
`

export const UploadMessage = styled.p<UploadMessageProps>`
    display: flex;
    font-size: 16px;
    line-height: 24px;
    padding: 48px 0;
    color: ${props => messageColors[props.type || 'default']};
    justify-content: center;
    align-items: center;

    @media(max-width: 750px){
        padding: 0px;
        text-align: center;
    }
`
import { ButtonBase } from "@mui/material";
import styled from "styled-components";

export const Container = styled.div`
    width: 100%;
    position: relative;
    /* min-height: 744px; */
`

export const SkusCountContainer = styled.div`
    width: 100%;
    height: 100vh;
    min-height: 200px;
    max-height: 300px;
    background-color: ${ props => props.theme['gray-700'] };
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 16px;

    h1{
        font-style: normal;
        font-weight: 700;
        font-size: 96px;
        line-height: 116px;
        display: flex;
        align-items: center;

        color: ${ props => props.theme["cyan-200"] };
    }

    p{
        font-style: normal;
        font-weight: 400;
        font-size: 32px;
        line-height: 39px;

        color: ${ props => props.theme["white-100"] };
    }
`

export const ActionButton = styled(ButtonBase)`
    padding: 4px !important;
    border-radius: 5px !important;

    svg{
        color: ${ props => props.theme["blue-500"] };
    }
`
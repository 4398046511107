import { CSSProperties, ReactNode } from "react"
import { Container, Title } from "./styled"

interface FormCardProps{
    title: string
    children: ReactNode
    style?: CSSProperties
}

export const FormCard: React.FC<FormCardProps> = ({ title, style, children}) => {
    return(
        <Container style={style}>
            <Title>
                <legend>{ title }</legend>
            </Title>
            { children }
        </Container>
    )
}
import React from 'react'
import { useState, useImperativeHandle, forwardRef } from 'react'
import { useNavigate } from 'react-router-dom'

import { OcStatus, useBuyOrder } from '../../../../hooks/buyOrders'

import { getErrorMessage } from '../../../../utils/validations/getErrorMessage'

import { 
    Dialog,
    DialogActions, 
    DialogProps, 
    DialogContent,
    Button,
    TextField,
    CircularProgress
} from '@mui/material'

import { Close } from '@mui/icons-material'

import { DialogTitleCustom } from '../../../../styles/globalStyles'
import { toast } from 'react-toastify'
import { useAuth } from '../../../../hooks/auth'
import apiService from '../../../../services/api.services'
import { queryClient } from '../../../../services/queryClient'

export interface RefuseModalRef {
    isOpen: boolean
    openDialog: () => void
    closeDialog: () => void
}

interface RefuseModalProps extends DialogProps {
    codigoOrdem: string
    orderStatus: number
}

const RefuseModal: React.ForwardRefRenderFunction<RefuseModalRef, RefuseModalProps> = ({ 
    open: initialOpen, 
    codigoOrdem, 
    orderStatus,
    ...rest
}, ref) => {
    const { refuseOrder } = useBuyOrder()
    const { userData } = useAuth()

    const navigate = useNavigate()

    const [open, setOpen] = useState<boolean>(initialOpen)
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [justify, setJustify] = useState<string>("")
    const [$isErrored, set$isErrored] = useState<boolean>(false)

    const onRefuse = async() => {
        if(justify.length <= 0){
            return set$isErrored(true)
        }
        
        set$isErrored(false)
        setIsLoading(true)

        try{
            if(orderStatus === OcStatus.APROVE_COAST_CENTER){
                const aproveData = {
                    po_number: codigoOrdem,
                    response: "R",
                    justification: justify,
                    approver_id: userData.id
                }

                await apiService.post("/aprovaccusto", {
                    purchase_order: aproveData
                },{
                    params: {
                        branch: userData.selectedBranch?.id as string
                    }
                })

                queryClient.invalidateQueries({ queryKey: ['requests'] })
                
                toast.success("Centros de custo reprovados com sucesso")
            } else{
                const status = await refuseOrder(codigoOrdem, justify)
                toast.success(status)
            }
            
            setIsLoading(false)
            navigate("/buy-orders")
        }catch(error: any){
            console.log(error)
            toast.error(getErrorMessage(error))
        }
    }

    useImperativeHandle(ref, () => ({
        isOpen: open,
        openDialog: () => setOpen(true),
        closeDialog: () => setOpen(false)
    }))

    return(
        <Dialog
            { ...rest }
            open={ open }
            fullWidth
            maxWidth="sm"
            onClose={ () => setOpen(false) }
        >
            <DialogTitleCustom>
                Reprovar
                <button onClick={() => setOpen(false)}><Close/></button>
            </DialogTitleCustom>
            <DialogContent>
                <br/>
                <TextField
                    label="Justificativa *"
                    type="text"
                    size="small"
                    fullWidth
                    maxRows={4}
                    error={ $isErrored }
                    value={ justify }
                    onChange={ event => {
                        set$isErrored(false)
                        setJustify(event.target.value)
                    }}
                />
            </DialogContent>
            <DialogActions>
                <Button
                    color="inherit"
                    variant="contained"
                    fullWidth
                    onClick={ () => setOpen(false) }
                    disabled={ isLoading }
                >
                    Cancelar
                </Button>
                <Button
                    color="error"
                    variant="contained"
                    fullWidth
                    disabled={ isLoading }
                    onClick={ () => {
                        if(!isLoading){
                            onRefuse()
                        }
                    }}
                >
                    { isLoading ? <CircularProgress color="inherit" size={ 26 } /> : "Reprovar" }
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default forwardRef(RefuseModal)
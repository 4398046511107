import React from 'react'
import { useNavigate } from 'react-router-dom'

import { NavMenuTitle } from './style'

export interface MenuTitleProps{
    image?: string
    title: string
}

const MenuTitle: React.FC<MenuTitleProps> = ({ image, title }) => {
    const navigate = useNavigate()

    return (
        <NavMenuTitle>
            <div className='info'>
                { !!image ? (
                    <img data-testid="img-title" src={ image } alt="portal" onClick={() => navigate("/")} />
                ): (
                    <h2 onClick={() => navigate("/")}>{ title.split("")[0].toUpperCase() }</h2>
                )}
                <p>{ title }</p>
            </div>
        </NavMenuTitle>
    )
}

export default MenuTitle
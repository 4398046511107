import styled from 'styled-components'

import { ButtonBase } from '@mui/material'

export const FilterContainer = styled.div`
    position: relative;
`

export const FilterPosition = styled.div`
    position: absolute;
    width: calc(100% - 180px);
    max-width: 1200px;
    top: 50px;
    right: 180px;

    @media(max-width: 1200px){
        width: 100%;
        right: 0px;
    }
`

export const ActionButton = styled(ButtonBase)`
    padding: 4px !important;
    border-radius: 5px !important;
`

export const SearchSupplier = styled.div`
    position: absolute;
    right: 0px;
    top: 16px;
    
    button{
        width: 40px;
        height: 40px;
        border-radius: 0px 4px 4px 0px;
        border: none;
        padding: 2px;
        background-color: ${ props => props.theme['green-500'] };
        transition: 200ms;
        
        svg{
            color: #FFF !important;
            font-size: 1.5rem;
        }
    }
`

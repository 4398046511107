import { 
    Children, 
    isValidElement, 
    cloneElement, 
    ReactElement, 
    useState, 
    useRef, 
    useEffect,
} from "react"

import { useMenu } from "../../../../hooks/useMenu"

import { MenuItemProps } from '../MenuItem'

import { ClickAwayListener, Collapse, Slide, SvgIconTypeMap } from "@mui/material"
import { OverridableComponent } from "@mui/material/OverridableComponent"

import { SelectionIcon } from "../MenuItem/style"
import { CategoryTitle, Container, SubMenuContainer, SubMenuPoppover } from "./style"
import { useLocation } from "react-router-dom"
import { useAuth } from "../../../../hooks/auth"

interface MenuCategoryProps{
    title: string
    permissionsToAccess?: string[]
    style?: React.CSSProperties
    children: Array<ReactElement<MenuItemProps>> | ReactElement<MenuItemProps>
    icon: OverridableComponent<SvgIconTypeMap<{}, "svg">> & { muiName: string; }
    onSelect?: (eventKey: string) => void
}

export const MenuCategory: React.FC<MenuCategoryProps> = ({ 
    children, 
    title, 
    icon: Icon, 
    onSelect,
    permissionsToAccess, 
    style 
}) => {
    const { isExpanded } = useMenu()
    const { userData } = useAuth()

    const location = useLocation()

    const menuTitleRef = useRef<HTMLButtonElement>(null)

    const [expandSubMenu, setExpandSubMenu] = useState<boolean>(false)
    const [showMenuPopper, setShowMenuPopper] = useState<boolean>(false)

    useEffect(() => {
        setShowMenuPopper(false)
    },[isExpanded])

    const childrenWithProps = Children.map(children, child => {
        if (isValidElement(child)) {
            return cloneElement(child, { onSelect: (eventKey: string) => {
                setShowMenuPopper(false)
                if(onSelect){
                    onSelect(eventKey)
                }
            } } as any)
        }
        return child
    })

    const eventKeys = Children.map(children, (child: any) => {
        const { eventKey } = child.props
        return eventKey
    })

    const toggleMenu = () => {
        setTimeout(() => {
            if(isExpanded){
                setExpandSubMenu(prev => !prev)
            } else{
                setShowMenuPopper(prev => !prev)
            }
        }, 200)
    }

    if(permissionsToAccess){
        const userPermissions = userData.permissions?.filter(permision => (
            permissionsToAccess.some(access => (
                permision === access
            ))
        ))

        if(userPermissions && userPermissions.length <= 0){
            return <></>
        }

    }

    return (
        <Container style={ style }>
            <Slide 
                mountOnEnter
                unmountOnExit
                direction='right' 
                in={ eventKeys?.some(key => location.pathname === `/${key}`)  }
            >
                <SelectionIcon/>
            </Slide>
            <CategoryTitle 
                ref={ menuTitleRef }
                onClick={ toggleMenu }
            >
                <Icon /> 
                <p>{ title }</p>
            </CategoryTitle>
            <Collapse in={ expandSubMenu && isExpanded } style={{ position: "relative" }}>
                <SubMenuContainer hiddenIcon>
                    { childrenWithProps }
                </SubMenuContainer>
            </Collapse>
            <SubMenuPoppover
                data-testid="user-menu"
                open={ showMenuPopper }
                anchorEl={ menuTitleRef.current }
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
            >
                <ClickAwayListener onClickAway={ () => setShowMenuPopper(false) }>
                    <div>
                        { childrenWithProps }
                    </div>
                </ClickAwayListener>
            </SubMenuPoppover>
        </Container>
    )
}
import React from 'react'

import { Slide, SvgIconTypeMap } from '@mui/material'
import { OverridableComponent } from '@mui/material/OverridableComponent'

import { Container, NavMenuItem, SelectionIcon } from './style'
import { useLocation } from 'react-router-dom'
import { useAuth } from '../../../../hooks/auth'

export interface MenuItemProps{
    eventKey: string
    access?: string
    hiddenMarker?: boolean
    children?: React.ReactNode
    style?: React.CSSProperties
    icon?: OverridableComponent<SvgIconTypeMap<{}, "svg">> & { muiName: string; }
    onSelect?: (key: string) => void
}

const MenuItem: React.FC<MenuItemProps> = ({ 
    icon: Icon, 
    eventKey, 
    onSelect, 
    access,
    children, 
    style,
    hiddenMarker 
}) => {
    const { userData } = useAuth()

    const location = useLocation()

    if(access){
        if(!userData.permissions?.includes(access)){
            return <></>
        }
    }

    return (
        <Container style={style}>
            { !hiddenMarker && (
                <Slide 
                    mountOnEnter
                    unmountOnExit
                    direction='right' 
                    in={ location.pathname === `/${eventKey}` }
                >
                    <SelectionIcon className='custom-selectable'/>
                </Slide>
            )}
            <NavMenuItem onClick={() => !!onSelect ? onSelect(eventKey) : {}}>
                { Icon && <Icon />}
                <p>{ children }</p>
            </NavMenuItem>
        </Container>
    )
}

export default MenuItem
import React from "react"

import { Check } from "@mui/icons-material"

import { 
    Container,
    ProgressItem,
    ProgressItemContainer
} from "./style"

export interface Fields{
    title: string
    isSelected: boolean
}

interface AlterParamsProps{
    options: Fields[]
    onItemSelect?: (index: number) => void 
}

const ProgressItens: React.FC<AlterParamsProps> = ({ options, onItemSelect }) =>{
    return (
        <Container>
            { options.map((option, index) => {
                const wasCompleted = !!options[(index + 1 === options.length) ? index : index + 1].isSelected

                return(
                    <ProgressItemContainer
                        startTransition={ wasCompleted }
                        barSize={100/options.length}
                    >
                        <ProgressItem
                            key={ index }
                            displayName={ option.title } 
                            isSelected={ option.isSelected }
                            onClick={() => !onItemSelect ? undefined : onItemSelect(index)}
                        >
                            { index + 1 }
                        </ProgressItem>
                        { (index !== (options.length - 1)) &&
                            <div className="bar">
                                <span/>
                            </div>
                        }
                        {( wasCompleted && (index !== options.length - 1)) &&
                            <div className="check">
                                <Check style={{ color: "#f9f9f9", fontSize: "15px" }}/>
                            </div>
                        }
                    </ProgressItemContainer>
                )
            })}
        </Container>
    )
}

export default ProgressItens
import styled from 'styled-components'

export const Container = styled.div`
    width: auto;
    height: 40px;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 14px;
    position: relative;
`

export const InputContainer = styled.label`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 4px;
    line-height: 8px;

    input{
        accent-color: ${ props => props.theme['yellow-500'] } !important;
    }
`
import { createContext, useContext, useCallback, useState } from 'react'
import { v4 as uuid } from 'uuid'

import apiService from '../services/api.services'

import { Document, DocumentsContextProps, Item, NewDocument, SaveDocumentsParams } from '../@types/documents'

import { convertDateToEnglishPattern } from '../utils/format/convertDateToEnglishPattern'

import { useAttachFiles } from './attachFiles'
import { useBuyOrder } from './buyOrders'
import { useAuth } from './auth'
import { convertDate } from '../utils/format/getData'
import { formatValue } from '../utils/format/formatValue'

const DocumentsContext = createContext({} as DocumentsContextProps)

export const DocumentsProvider: React.FC = ({ children }) => {

    const { userData } = useAuth()
    const { onUpload } = useAttachFiles()
    const { selectedOrder } = useBuyOrder()

    const [currentDocuments, setCurrentDocuments] = useState<Document[]>([])
    const [currentOCItens, setCurrentOCItens] = useState<Item[]>([])

    const loadDocuments = useCallback(async(ocId: string): Promise<void> => {
        try{
            const response = await apiService.get("retnotasxoc", {
                params: {
                    ordemcompra: ocId,
                    branch: userData.selectedBranch?.id
                }
            })

            if(response.data.data){
                const { documents } = response.data.data

                /* setCurrentDocuments(documents.map((document) => ({
                    ...document,
                    idNF: uuid(),
                    number: document.number.trim(),
                    cnpj_emit: document.cnpj_dest || "", //analizar
                    issueDate: convertDate(document.issueDate),
                    validDate: convertDate(document.validDate),
                    associatedItens: document.associatedItens?.map(item => ({
                        ...item,
                        id: item.idNF,
                        item: item.item,
                        qtd: item.qtd,
                        total_value: item.total_value,
                        unit_value: item.unit_value,
                        partial: false
                    }))
                }))) */

                const docInfo = documents.map((document) => {
                    const parsedAssociatedItens = document.associatedItens?.reduce((accumulator, currentValue, _, originalArray) => {
                        const existsOnAccumulator = accumulator.find(item => item.item === currentValue.itemOC)
    
                        if(existsOnAccumulator){
                            return accumulator
                        }
    
                        const allNfItems = originalArray.filter(item => item.itemOC === currentValue.itemOC )
    
                        const parsedItem = allNfItems.reduce((nfacc, nfCurent) => {
                            const data = {
                                id: uuid(),
                                item: nfCurent.itemOC,
                                codigo_produto: nfCurent.id_produto,
                                descriao_produto: nfCurent.desc_produto,
                                ncm_produto: '',
                                unidade_produto: '',
                                codigo_cc: nfCurent.id_cc,
                                descricao_cc: nfCurent.desc_cc,
                                quantidade: nfCurent.qtd,
                                valor_unitario: nfCurent.unit_value, 
                                valor_total: nfCurent.total_value,
                                possui_documento: true,
                                id_aprovador: "",
                                nome_aprovador: "",
                                data_aprovacao: "",
                                hora_aprovacao: "",
                                status_aprova: "",
                                data_venc_titulo: '',
                                item_nf: nfacc.item_nf + "," + nfCurent.itemNF,
                                cartao_corporativo: "",
                                usedByDocument: true,
                                wasSynced: true,
                                "quantidade-nf": nfacc["quantidade-nf"] + nfCurent.qtd_nf,
                                total_nf_value: nfacc.total_nf_value + (nfCurent.qtd_nf * nfCurent.unit_value_nf)
                            }
    
                            return data
                        }, {
                            "quantidade-nf": 0, 
                            /* "unit_value_nf": formatValue(item.unit_value_nf), */
                            total_nf_value:  0,
                        })
    
                        accumulator.push(parsedItem)
    
                        return accumulator
                    },[]) || []

                    return {
                        ...document,
                        idNF: uuid(),
                        number: document.number.trim(),
                        cnpj_emit: document.cnpj_dest || "", //analizar
                        issueDate: convertDate(document.issueDate),
                        validDate: convertDate(document.validDate),
                        partial: false,
                        wasSynced: (document.status?.trim() === "2" || document.status?.trim() === "4" || document.status?.trim() === "5") ? true : false,
                        associatedItens: parsedAssociatedItens.map(parsedInfo => ({
                            ...parsedInfo,
                            item_nf: parsedInfo.item_nf
                                .replaceAll("undefined,", "")
                                .replaceAll(",", " - ")
                                .trim(),
                            "unit_value_nf": formatValue(parsedInfo.total_nf_value / parsedInfo['quantidade-nf']),
                            qtd: parsedInfo.quantidade,
                            total_value: parsedInfo.valor_total,
                            unit_value: parsedInfo.valor_unitario,
                            document_itens: [
                                {
                                    id: parsedInfo.id,
                                    qtd: parsedInfo["quantidade-nf"],
                                    unit_value: parsedInfo.total_nf_value / parsedInfo['quantidade-nf']
                                }
                            ]
                        }))
                    }
                })

                console.log(docInfo)

                setCurrentDocuments(docInfo)
                
                const updatedItens: Item[] = selectedOrder.itens?.map(item => {
                    return {
                        ...item,
                        id: uuid(),
                        wasSynced: docInfo.some(doc => (
                            doc.associatedItens.some(info => info.item === item.item)
                        ))
                    }
                }) || []

                setCurrentOCItens(updatedItens)
    
                return
            }

            setCurrentOCItens(selectedOrder.itens?.map(item => ({
                ...item,
                id: uuid(),
                wasSelected: false,
                hasFile: false,
                partial: false
            })) || [])
        } catch(error){
            throw error
        }

    },[selectedOrder, userData])

    const addDocument = useCallback(async(data: NewDocument): Promise<void> => {
        try{
            const newDocument = {
                ...data,
                id: uuid(),
                idNF: uuid(),
                status: "1",
                issueDate: convertDateToEnglishPattern(data.issueDate as string),
                validDate: convertDateToEnglishPattern(data.validDate as string)
            } as Document
            
            const updatedDocuments = [
                ...currentDocuments,
                newDocument
            ]

            let updatedItens: Item[] = currentOCItens.map(item => ({
                ...item,
                id: item.id || uuid(), 
            }))

            currentOCItens.forEach(item => {
                const currentUpdated = data.associatedItens?.find(associatedItem => associatedItem.id === item.id)

                if(currentUpdated){

                    let duplicateItens: Item[] = []

                    if(currentUpdated.partial){
                        const duplicateItem = {
                            ...item,
                            id: uuid(),
                            partial: true,
                            quantidade: item.quantidade - currentUpdated.qtd,
                            valor_total: (item.quantidade - currentUpdated.qtd) * Number(item.valor_unitario),
                            usedByDocument: false,
                            /* document_itens: [
                                {
                                    id: item.document_itens,
                                    qtd: parsedInfo["quantidade-nf"],
                                    unit_value: parsedInfo.total_nf_value / parsedInfo['quantidade-nf']
                                }
                            ] */
                        }

                        duplicateItens.push(duplicateItem)
                    }

                    updatedItens = [
                        ...updatedItens.map(item => item.item === currentUpdated.item ? ({
                            ...item,
                            quantidade: item.quantidade - currentUpdated.qtd,
                            valor_total: (item.quantidade - currentUpdated.qtd) * Number(item.valor_unitario),
                            usedByDocument: currentUpdated.partial
                        }) : item),
                        ...duplicateItens
                    ]
                }
            })

            setCurrentDocuments(updatedDocuments)
            setCurrentOCItens(updatedItens)
        } catch(error){
            throw error
        }
    },[currentDocuments, currentOCItens])

    const removeDocument = useCallback(async(id: string): Promise<void> => {
        const currentDocument = currentDocuments.find(item => item.id === id)

        if(!currentDocument){
            throw new Error("Documento não encontrado")
        }

        const files = JSON.parse(sessionStorage.getItem(`DocumentsForRequest${selectedOrder.id}`) || "null") as any[]

        if(files){
            const updatedFiles = files.filter(item => item.idNF !== id)
            sessionStorage.setItem(`DocumentsForRequest${selectedOrder.id}`, JSON.stringify(updatedFiles))
        }

        let updatedItens: Item[] = currentOCItens

        console.log(currentOCItens)

        currentOCItens.forEach(item => {
            console.log(currentDocument.associatedItens)
            const itemToDelete = currentDocument.associatedItens?.find(associatedItem => associatedItem.id === item.id)
            
            console.log(itemToDelete)

            if(itemToDelete){
                const hasUnusedItemInDocument = currentOCItens.filter(data => (
                    data.item === itemToDelete.item && !data.usedByDocument
                ))

                console.log(itemToDelete)
                console.log("teste")
                console.log(hasUnusedItemInDocument)

                if(hasUnusedItemInDocument.length > 0){
                    console.log("OOOBBBAAA")
                    const unusedSummary =  hasUnusedItemInDocument.reduce((accumulator, currentValue) => {
                        const parsedData = {
                            ...currentValue,
                            quantidade: accumulator.quantidade 
                                ? accumulator.quantidade + currentValue.quantidade
                                : currentValue.quantidade,
                            valor_total: accumulator.valor_total 
                                ? accumulator.valor_total + Number(currentValue.valor_total)
                                : currentValue.valor_total,
                        }

                        return parsedData
                    }, {} as any)

                    console.log(unusedSummary)

                    const recoveryData = {
                        ...unusedSummary,
                        quantidade: itemToDelete.qtd + unusedSummary.quantidade,
                        valor_total: itemToDelete.total_value + unusedSummary.valor_total,
                        usedByDocument: false
                    }

                    const filteredItens = updatedItens.filter(item => (
                        item.item !== recoveryData.item && item.usedByDocument
                    ))

                    updatedItens = [
                        ...filteredItens,
                        recoveryData
                    ]

                    console.log(filteredItens)
                    console.log(recoveryData)

                } else{
                    console.log("caiu aqui")
                    updatedItens = updatedItens
                        .map(item => {
                            let defaultValue = selectedOrder.itens?.find(associatedItem => associatedItem.id === item.id)
    
                            if(!defaultValue){
                                defaultValue = selectedOrder.itens?.find(associatedItem => associatedItem.item === item.item)
                            }
                            
                            if(item.id === itemToDelete.id){ //atualizar item atual
                                return {
                                    ...item,
                                    ...defaultValue as Item
                                }
                            }
    
                            return item
                        })
                }
            }
        })

        console.log(updatedItens)

        setCurrentOCItens(updatedItens)
        setCurrentDocuments(prev => prev.filter(item => item.id !== id))
        sessionStorage.removeItem(`DocumentsForRequest${selectedOrder.id}`)

    },[currentDocuments, currentOCItens, selectedOrder])

    const editDocument = useCallback(async(data: Document): Promise<void> => {
        let updatedItens: Item[] = currentOCItens

        currentOCItens.forEach(item => {
            const currentUpdated = data.associatedItens?.find(associatedItem => associatedItem?.id === item?.id)

            if(currentUpdated){
                updatedItens = updatedItens
                    .map(item => {
                        const isAssociated = data.associatedItens?.some(associatedItem => associatedItem?.id === item?.id)
                        
                        if(item?.id === currentUpdated.id){ //atualizar item atual
                            return {
                                ...item,
                                quantidade: item.quantidade - currentUpdated.qtd,
                                valor_total: (item.quantidade - currentUpdated.qtd) * Number(item.valor_unitario),
                                usedByDocument: true
                            }
                        }
                        
                        if(!isAssociated){
                            const defaultValue = selectedOrder.itens?.find(associatedItem => associatedItem.id === item.id)
                            return defaultValue as Item
                        }

                        return item
                    })
            }
        })

        const filterdItens = updatedItens.filter(item => item !== undefined)

        setCurrentOCItens(filterdItens)

        /* setCurrentDocuments(prev => prev.map(item => item.id === data.id ? (
            {
            ...data,
            number: item.number,
            issueDate: convertDateToEnglishPattern(data.issueDate),
            validDate: convertDateToEnglishPattern(data.validDate),
            associatedItens: data.associatedItens
        }
        ): (item))) */

        setCurrentDocuments(prev => prev.map(item => {
            if(item.id === data.id){
                return {
                    ...item,
                    ...data,
                    number: item.number,
                    issueDate: convertDateToEnglishPattern(data.issueDate),
                    validDate: convertDateToEnglishPattern(data.validDate),
                    associatedItens: data.associatedItens
                }
            }

            return item
        }))

    },[currentOCItens, selectedOrder])

    const changeDocumentFileStatus = useCallback((id: string) => {
        const currentDoc = currentDocuments.find(doc => doc.id === id)

        if(!currentDoc){
            throw new Error("Documento não encontrado")
        }
        
        setCurrentDocuments(prev => prev.map(item => item.id === currentDoc.id ? ({
            ...item,
            hasFile: true
        }): item))
    },[currentDocuments])

    const clearDocuments = useCallback(() => {
        for (let key in sessionStorage) {
            if (key.startsWith("DocumentsForRequest")) {
              sessionStorage.removeItem(key);
            }
        }
        setCurrentDocuments([])
        setCurrentOCItens([])
    },[])

    const saveDocuments = useCallback(async({ currentDocument, files, selectedOrder}: SaveDocumentsParams) => {
        try{
            const selectedDoc = currentDocuments.find(item => item.id === currentDocument) 

            console.log(currentDocuments)
            console.log(currentDocument)

            console.log(selectedDoc)

            if(!selectedDoc){
                throw new Error("Documento não encontrado")
            }

            if(selectedDoc.uploadStatus !== "success"){
                for(let file in files){
                    await onUpload({
                        files: [files[file]],
                        type: 1,
                        id: selectedOrder.id
                    })
                }

                setCurrentDocuments(prev => prev.map(item => item.idNF === currentDocument ? ({
                    ...item,
                    hasFile: true,
                    uploadStatus: "success"
                }) : (item)))
            }
        } catch(error: any){
            console.log(error)
            throw new Error("Ocorreu uma falha ao fazer upload de um arquivo e processo foi encerrado. Por favor tente novamente")
        }
    },[currentDocuments, onUpload])

    return (
        <DocumentsContext.Provider value={{ 
            currentDocuments,
            currentOCItens, 
            loadDocuments, 
            addDocument,
            removeDocument,
            editDocument,
            clearDocuments,
            changeDocumentFileStatus,
            saveDocuments
        }}>
            { children }
        </DocumentsContext.Provider>
    )
}

export const useDocuments = () => {
    const context = useContext(DocumentsContext)

    if(!context){
        throw new Error("Hook 'useDocuments' must be used inside a Documents Provider")
    }

    return context
}
import { useState, useRef } from 'react'
import { Form } from '@unform/web'
import { FormHandles } from '@unform/core'
import * as Yup from 'yup'
import { toast } from 'react-toastify'

import { useAuth } from '../../../hooks/auth'

import { getErrorMessage } from '../../../utils/validations/getErrorMessage'
import getValidationErros from '../../../utils/validations/getValidationErros'

import FormInput from '../../Form/FormInput'
import { Button } from '../../Buttons/Button'

import { Dialog, DialogActions, DialogContent, DialogProps, Grid } from "@mui/material"
import { DialogTitleCustom } from '../../../styles/globalStyles'

interface ChangePassFields{
    currentPass: string
    newPass: string
    confirmPass: string
}

interface ChangePasswordDialogProps extends DialogProps{
    onClose: (event: {}, reason: "backdropClick" | "escapeKeyDown") => void
}

const formValidations = Yup.object().shape({
    currentPass: Yup.string()
        .required("Senha atual obrigatória"),
    newPass: Yup.string()
        .min(6, "No mínimo 6 carracteres")
        .notOneOf([
        null, Yup.ref("currentPass")
        ], "A senha não pode ser a mesma da anterior")
        .required("Nova senha obrigatória"),
    confirmPass: Yup.string().oneOf([
        null, Yup.ref("newPass")
    ], "As senhas precisam ser iguais").required("Confirmar senha obrigatório")
})

const ChangePasswordDilog: React.FC<ChangePasswordDialogProps> = ({ onClose, ...props }) => {
    const { changePass } = useAuth()

    const formRef = useRef<FormHandles>(null)

    const [isLoading, setIsLoading] = useState<boolean>(false)

    const handleSubmitPass = async(data: ChangePassFields): Promise<void> => {
        if(isLoading){ return }

        try{
            await formValidations.validate(data, { 
                abortEarly: false 
            })

            setIsLoading(true)
            const message = await changePass({
                currentPass: data.currentPass,
                newPass: data.newPass
            })

            toast.success(message)
            onClose({}, "escapeKeyDown")
        } catch(error: any){

            if(error instanceof Yup.ValidationError){
                const errors = getValidationErros(error)
                formRef.current?.setErrors(errors)

                return
            }

            setIsLoading(false)
            toast.error(getErrorMessage(error))
        }
    }

    return(
        <Dialog {...props} onClose={ onClose }>
            <DialogTitleCustom>
                Alterar Senha
            </DialogTitleCustom>
            <Form ref={ formRef } onSubmit={ handleSubmitPass }>
                <DialogContent>
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={12}>
                            <FormInput
                                name='currentPass'
                                label="Senha Atual"
                                type="password"
                                size="small"
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12} md={12}>
                            <FormInput
                                name='newPass'
                                label="Nova Senha"
                                type="password"
                                size="small"
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12} md={12}>
                            <FormInput
                                name='confirmPass'
                                label="Confirmar Senha"
                                type="password"
                                size="small"
                                fullWidth
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button
                        type="button"
                        disabled={isLoading}
                        variant="text"
                        onClick={() => onClose({}, "escapeKeyDown") }
                    >
                        Cancelar
                    </Button>
                    <Button 
                        color="success" 
                        type="submit"
                        variant="contained"
                        disabled={ isLoading }
                    >
                        { isLoading ? "Aguarde" : "Confirmar"}
                    </Button>
                </DialogActions>
            </Form>
        </Dialog>
    )
}

export default ChangePasswordDilog
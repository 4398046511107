import styled from "styled-components"
import { shade } from "polished";

export const RemoveButton = styled.button`
    background-color: #E83F5B;
    border: none;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px;
    transition: 200ms;

    svg{
        color: #f9f9f9;
    }

    &:hover{
        background-color: ${ shade(0.2, "#E83F5B")};
    }

    &:active{
        background-color: ${ shade(0.3, "#E83F5B")};
    }
`

export const SearchProduct = styled.div`
    position: absolute;
    right: 0px;
    top: 0px;
    
    button{
        width: 40px;
        height: 40px;
        border-radius: 0px 4px 4px 0px;
        border: none;
        padding: 2px;
        background-color: ${ props => props.theme["green-500"] };
        transition: 200ms;
        
        svg{
            color: #f9f9f9;
            font-size: 1.5rem;
        }
    }
`
import { IconProps } from "../../../@types/icons";

export const NotFoundFile: React.FC<IconProps> = (props) => (
    <svg 
        version="1.1" 
        id="Capa_1"
        x="0px" 
        y="0px"
	    viewBox="0 0 264 264" 
        {...props}
    >
        <path d="M148.167,222c0,23.159,18.841,42,42,42s42-18.841,42-42s-18.841-42-42-42S148.167,198.841,148.167,222z M214.167,222.5
        c0,4.143-3.357,7.5-7.5,7.5h-33c-4.143,0-7.5-3.357-7.5-7.5s3.357-7.5,7.5-7.5h33C210.809,215,214.167,218.357,214.167,222.5z
        M133.167,222c0-31.43,25.404-57,56.833-57c7.795,0,15.834,1.576,21.834,4.421V48h-42.167c-4.143,0-7.833-3.357-7.833-7.5V0H39.667
        c-4.143,0-7.833,3.357-7.833,7.5v231c0,4.133,3.51,7.737,7.644,7.75l99.241,0.451C135.108,239.224,133.167,230.844,133.167,222z
        M71.167,69h99c4.143,0,7.5,3.357,7.5,7.5s-3.357,7.5-7.5,7.5h-99c-4.143,0-7.5-3.357-7.5-7.5S67.024,69,71.167,69z M71.167,100h99
        c4.143,0,7.5,3.357,7.5,7.5s-3.357,7.5-7.5,7.5h-99c-4.143,0-7.5-3.357-7.5-7.5S67.024,100,71.167,100z M63.667,140.5
        c0-4.143,3.357-7.5,7.5-7.5h99c4.143,0,7.5,3.357,7.5,7.5s-3.357,7.5-7.5,7.5h-99C67.024,148,63.667,144.643,63.667,140.5z
        M209.1,33h-32.267V2.383L209.1,33z"/>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
    </svg>
)
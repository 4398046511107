import React  from 'react'
import { forwardRef } from 'react'

import {
    Search,
    DeleteOutlined,
    Close
} from '@mui/icons-material'

import { Button } from '@mui/material'

import{ FilterForm, FilterActions, FilterContent } from './style'

interface FilterFormProps{
    children: React.ReactNode
    fieldsAlign?: "space-bettwen" | "flex-start" | "flex-end" | "space-evenly" | "space-around" | undefined
    onSubmit: () => void
    onClean?: () => void
    onClose?: () => void
}

const FiltersForm: React.ForwardRefRenderFunction<HTMLDivElement, FilterFormProps> = ({
    fieldsAlign,
    children,
    onSubmit,
    onClean,
    onClose
}, ref) => {
    return(
        <FilterForm
            ref={ ref }
            align={ "top" }
        >
            <FilterContent>
                { fieldsAlign ?
                    <section style={{ justifyContent: fieldsAlign }}>
                        { children }
                    </section>
                :
                    children
                }
            </FilterContent>
            <FilterActions>
                { onClose && (
                    <Button
                        size={'small'} 
                        startIcon={ <Close/> }
                        onClick={ onClose }
                    >
                        Fechar
                    </Button>
                )}
                { onClean &&
                    <Button 
                        variant="outlined" 
                        size={'small'} 
                        startIcon={ <DeleteOutlined/> }
                        onClick={ onClean }
                    >
                        Limpar
                    </Button>
                }
                <Button 
                    variant="contained"
                    size={'small'} 
                    type="button" 
                    onClick={ onSubmit }
                    startIcon={<Search />}
                >
                    Pesquisar
                </Button>
            </FilterActions>
        </FilterForm>
    )
}

export default forwardRef(FiltersForm)
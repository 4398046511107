import { 
    useState, 
    useImperativeHandle, 
    forwardRef, 
    useEffect,
    useRef
} from "react"
import { toast } from "react-toastify"

import apiService from "../../../services/api.services"

import { getErrorMessage } from "../../../utils/validations/getErrorMessage"

import ProgressItens, { Fields } from "./ProgressItens"
import { Button } from "../../Buttons/Button"

import { 
    Dialog, 
    DialogProps, 
    DialogActions,
    Grid,
    TextField,
    CircularProgress,
    Popover,
} from "@mui/material"

import { Close, InfoOutlined } from "@mui/icons-material"

import { 
    ClosePaperButton,
    Container,
    ShowDescriptionButton,
    StatusHeader
} from "./style"

export interface AlterParamsRef{
    open: boolean
    openDialog: () => void
    closeDialog: () => void
}

interface Params{
    filial: string
    parametro: string
    tipo: string
    descricao: string
    conteudo: string
    showDescription?: boolean
    isValid?: boolean
}

const AlterParams: React.ForwardRefRenderFunction<AlterParamsRef, DialogProps> = ({ open: initialOpen, ...rest }, ref) => {

    const paramsDescriptionButtonsRefs = useRef<Array<HTMLButtonElement | null>>([])

    const [open, setOpen] = useState<boolean>(initialOpen || false)

    const [isLoading, setIsLoading]             = useState<boolean>(false) 
    const [isLoadingParams, setIsLoadingParams] = useState<boolean>(false) 

    const [currentFieldIndex, setCurrentFieldIndex] = useState<number>(0)
    const [params, setParams] = useState<Params[]>([])

    const [processFields, setProcessFields] = useState<Fields[]>([
        { title: "Info", isSelected: true },
        { title: "Parâmetros", isSelected: false },
        { title: "Conferência", isSelected: false }
    ])

    const handleClose = () => {
        setOpen(false)
        setParams([])

        setTimeout(() => {
            setCurrentFieldIndex(0)
            setProcessFields([
                { title: "Info", isSelected: true },
                { title: "Parâmetros", isSelected: false },
                { title: "Conferência", isSelected: false },
            ])
        }, 200)
    }

    const getItemPage = (index: number): string => {
        switch(index){
            case 0: return "home"
            case 1: return "form"
            case 2: return "validation"
            default: return ""
        }
    } 

    const toggleInterface = (action: "prev" | "next") => {
        if(action === "next"){
            if(currentFieldIndex === 1){
                if(!validateContent()){
                    return
                }
            }

            const selectedIndex = currentFieldIndex + 1
            setCurrentFieldIndex(selectedIndex)
            setProcessFields(prev => prev.map((item, index) => (index === selectedIndex) ? (
                { ...item, isSelected: true }
            ): (item)))
        }

        if(action === "prev"){
            if(currentFieldIndex > 0){
                setProcessFields(prev => prev.map((item, index) => (index === currentFieldIndex) ? (
                    { ...item, isSelected: false }
                ): (item)))
    
                setCurrentFieldIndex(prev => prev - 1)
            }
        }
    }

    const toggleDescriptionPopover = (param: string) => {
        setParams(prev => prev.map(item => {
            if(item.parametro === param){
                return ({
                    ...item,
                    showDescription: !item.showDescription
                })
            } else{
                return ({
                    ...item,
                    showDescription: false
                })
            }  
        }))
    }

    const changeParamsValue = (param: string, value: string) => {
        setParams(prev => prev.map(item => {
            if(item.parametro === param){
                return ({
                    ...item,
                    conteudo: value
                })
            } else{
                return item
            }  
          }))
    }

    const validateContent = () => {
        let isValidParams = true

        const validatedParams = params.map(param => {
            let isValid = true

            if(!param.conteudo){
                isValidParams = false
                isValid = false
                toast.warning(`O parâmetro "${ param.parametro }" está com valor inválido`)
            }

            return ({ ...param, isValid: isValid })
        })

        setParams(validatedParams)

        return isValidParams
    }

    const getParams = async() => {
        setIsLoadingParams(true)
        try {
            const response = await apiService.get("/atual_parametros")

            setParams(response.data.data.atual_parametros.map(param => ({
                ...param,
                showDescription: false,
                isValid: true
            })))

        } catch (error: any) {
            toast.error(getErrorMessage(error))
            handleClose()
        } finally{
            setIsLoadingParams(false)
        }
    }

    const updateParams = async() => {
        setIsLoading(true)
        try{
            const data = {
                parametro: params.map(param => {
                    const atualParams = Object.assign({}, param)
    
                    delete atualParams.showDescription
                    delete atualParams.isValid
    
                    return atualParams
                })
            }

            await apiService.post("/atual_parametros", data)

            toast.success("Parametros salvos com sucesso")

            handleClose()
        } catch(error: any){
            toast.error(getErrorMessage(error))
        } finally{
            setIsLoading(false)
        }
    }

    useImperativeHandle(ref, () => ({
        open: open,
        openDialog: () => setOpen(true),
        closeDialog: handleClose
    }))

    useEffect(() => {
        if(open){ getParams() }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[open])

    return (
        <Dialog
            { ...rest }
            open={ open }
            disableEscapeKeyDown
            onClose={( _, reason) => {
              if(reason !== "backdropClick"){
                handleClose()
              }
            }}
        >
            <StatusHeader>
                <ProgressItens options={processFields}/>
            </StatusHeader>
            <Container>
                { getItemPage(currentFieldIndex) === "home" &&
                    <>
                        <h3>Olá,</h3>
                        <br />
                        <p>Este é um assistente para a alteração de parâmentros, para prosseguir clique em avançar</p>
                    </>
                }
                { getItemPage(currentFieldIndex) === "form" &&
                    <Grid container spacing={2}>
                        { isLoadingParams ?
                            <Grid 
                                item 
                                xs={ 12 } 
                                md={ 12 }
                                display="flex"
                                justifyContent="center"
                                alignItems="center"
                                height={ 200 }
                            >
                                <CircularProgress/>
                            </Grid>
                        :
                            params.map((param, index) => (
                                <Grid 
                                    item
                                    key={ param.parametro }
                                    xs={ 12 } 
                                    md={ 12 }
                                    position="relative"
                                >
                                    <TextField 
                                        variant="outlined"
                                        fullWidth
                                        label={ param.parametro }
                                        value={ param.conteudo }
                                        onChange={ event => changeParamsValue(param.parametro, event.target.value) }
                                        error={ !param.isValid }
                                        inputProps={{
                                            style: {
                                                paddingRight: "45px"
                                            }
                                        }}
                                    />
                                    <ShowDescriptionButton 
                                        ref={ ref => paramsDescriptionButtonsRefs.current[index] = ref }
                                        onClick={() => toggleDescriptionPopover(param.parametro)}
                                    >
                                        <InfoOutlined style={ !param.isValid ? { color: "#d74242" } : undefined } />
                                    </ShowDescriptionButton>
                                    <Popover
                                        open={ !!param.showDescription }
                                        anchorEl={paramsDescriptionButtonsRefs.current[index]}
                                        PaperProps={{
                                            style: {
                                                maxWidth: "230px",
                                                maxHeight: "300px",
                                                overflowY: "auto",
                                                padding: "20px",
                                                position: "relative"
                                            }
                                        }}
                                        anchorOrigin={{
                                            vertical: 'bottom',
                                            horizontal: 'right',
                                        }}
                                        transformOrigin={{
                                            vertical: 'top',
                                            horizontal: 'right',
                                        }}
                                    >
                                        <ClosePaperButton onClick={ () => toggleDescriptionPopover(param.parametro) }>
                                            <Close style={{ fontSize: "15px" }} />
                                        </ClosePaperButton>
                                        { param.descricao }
                                    </Popover>
                                </Grid>
                            ))
                        }
                    </Grid>
                }
                { getItemPage(currentFieldIndex) === "validation" &&
                    <Grid container spacing={2}>
                        { params.map(param => (
                            <Grid 
                                item
                                key={ param.parametro }
                                xs={ 12 } 
                                md={ 12 }
                                position="relative"
                            >
                                <TextField 
                                    variant="outlined"
                                    fullWidth
                                    label={ param.parametro }
                                    value={ param.conteudo }
                                    disabled
                                />
                            </Grid>
                        ))}
                    </Grid>
                }
            </Container>
            <DialogActions>
                { currentFieldIndex === 0 &&
                    <Button
                        color="primary"
                        onClick={ handleClose }
                    >
                        Fechar
                    </Button>
                }
                { currentFieldIndex > 0 &&
                    <Button
                        onClick={() => {
                            toggleInterface("prev")
                        }}
                    >
                        Voltar
                    </Button>
                }
                { currentFieldIndex === (processFields.length - 1) &&
                    <>
                    <Button
                        variant="contained"
                        color="error"
                        onClick={ handleClose }
                    >
                        Cancelar
                    </Button>
                    <Button
                        variant="contained"
                        color="success"
                        style={{ width: "120px" }}
                        onClick={() => {
                            if(!isLoading){
                                updateParams()
                            }
                        }}
                    >
                        { isLoading ? <CircularProgress color="inherit" size={25} /> : "Finalizar" }
                    </Button>
                    </>
                }
                { currentFieldIndex < (processFields.length - 1) && 
                    <Button
                        variant="contained"
                        disabled={ currentFieldIndex === 1 && isLoadingParams }
                        onClick={() => {
                            toggleInterface("next")
                        }}
                    >
                        Avançar
                    </Button>
                }
            </DialogActions>
        </Dialog>
    )
}

export default forwardRef(AlterParams)
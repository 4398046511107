import { useState, useEffect, memo } from 'react'
import { TabItem, TabNavigation } from "./style"

interface Option {
    title: string
    eventKey: string
    isSelected?: boolean
}

interface TabNavProps{
    options: Omit<Option, "isSelected">[]
    defaultSelection?: string
    onSelectOption: (eventKey: string) => Promise<void>
}

const TabNav: React.FC<TabNavProps> = ({ onSelectOption, options, defaultSelection }) => {

    const [tabItens, setTabItens] = useState<Option[]>([])
    const [hasSelected, setHasSelected] = useState<boolean>(false)
    
    useEffect(() => {
        if(!hasSelected){
            const initialSelection = defaultSelection ? defaultSelection : options[0].eventKey
            
            setTabItens(options.map(option => (
                option.eventKey === initialSelection ? ({
                    ...option,
                    isSelected: true
                }): ({
                    ...option,
                    isSelected: false
                })
            )))
        }
    },[defaultSelection, options, hasSelected])

    const handleSelection = (eventKey: string) => {
        setTabItens(prev => prev.map(option => (
            option.eventKey === eventKey ? ({
                ...option,
                isSelected: true
            }): ({
                ...option,
                isSelected: false
            })
        )))
        
        setHasSelected(true)
        onSelectOption(eventKey)
    }

    return(
        <TabNavigation>
            { tabItens.map((item, index) => (
                <TabItem 
                    key={ item.eventKey } 
                    selected={ item.isSelected }
                    onClick={() => handleSelection(item.eventKey) }
                >
                    { item.title }
                    { (tabItens.length - 1 === index) && (
                        <div className="last-detail"/>
                    )}
                </TabItem>
            )) }
        </TabNavigation>
    )
}

export default memo(TabNav, (prevProps, nextProps) => (
    prevProps.options === nextProps.options
))
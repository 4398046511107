import React from 'react'
import { createContext, useContext } from 'react'
import { useQuery } from 'react-query'
import apiService from '../services/api.services'

interface Param{
    filial: string
    parametro: string
    tipo: string
    descricao: string
    conteudo: string
}

interface AppParamsContextProps{
    params: Param[]
}

const AppParamsContext = createContext({} as AppParamsContextProps)

export const AppParamsProvider: React.FC = ({ children }) => {
    const {
        data: paramsList
    } = useQuery<Param[]>(
        "app-params",
        () => getParams(),
        {
            staleTime: 1000 * 60 * 5, //5min
            refetchInterval: 1000 * 60 * 5, //5min
            refetchOnWindowFocus: "always"
        }
    )

    const getParams = async(): Promise<Param[]> => {
        const response = await apiService.get("/atual_parametros")

        return response.data.data?.atual_parametros || []
    }

    return(
        <AppParamsContext.Provider value={{ params: paramsList || [] }}>
            { children }
        </AppParamsContext.Provider>
    )
}

export const useAppParams = () => {
    const context = useContext(AppParamsContext)

    if(!context){ throw new Error("Hook useAppParamsmust be used inside of AppParams Provider") }

    return context
}
import React, { useCallback } from 'react'
import { Navigate } from 'react-router-dom'
import { useAuth } from '../hooks/auth'

interface VerifyAuthProps{
    isPrivate?: boolean,
    component: JSX.Element
}

const PrivateComponent: React.FC<VerifyAuthProps> = ({ component: Component, isPrivate = false }) => {
    const { userData } = useAuth()

    const validateToken = useCallback((): boolean => 
        (userData.token && sessionStorage.getItem("3467333a-0dfc-45d5-800a-b08eaa0d5ec1Token")) ? true : false 
    ,[userData])

    const hasChangePass = () => {
        const changeKey = sessionStorage.getItem("NEED_CHANGE") === "undefined"
            ? false
            : JSON.parse(sessionStorage.getItem("NEED_CHANGE") || "false")

        if(!changeKey){
            return false
        }

        return !!changeKey
    }

    return isPrivate === validateToken() ? (
        hasChangePass() ? (
            <Navigate to="/reset-pass" /> 
        ): (
            Component
        )
    ) : (
        <Navigate to="/login" /> 
    )
}

export default PrivateComponent
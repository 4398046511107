import { IconProps } from "../../../@types/icons";

export const AprovedIcon: React.FC<IconProps> = ({ color, height, size }) => (
    <svg 
        width={ size || "32"} 
        height={ height || "32"}  
        color={ color }
        viewBox="0 0 32 32" 
        fill="none" 
        xmlns="http://www.w3.org/2000/svg"
    >
        <path d="M9.33331 29.3334C8.59998 29.3334 7.97242 29.0725 7.45065 28.5507C6.92798 28.028 6.66665 27.4 6.66665 26.6667C6.66665 25.9334 6.92798 25.3054 7.45065 24.7827C7.97242 24.2609 8.59998 24 9.33331 24C10.0666 24 10.6942 24.2609 11.216 24.7827C11.7386 25.3054 12 25.9334 12 26.6667C12 27.4 11.7386 28.028 11.216 28.5507C10.6942 29.0725 10.0666 29.3334 9.33331 29.3334ZM22.6666 29.3334C21.9333 29.3334 21.3058 29.0725 20.784 28.5507C20.2613 28.028 20 27.4 20 26.6667C20 25.9334 20.2613 25.3054 20.784 24.7827C21.3058 24.2609 21.9333 24 22.6666 24C23.4 24 24.028 24.2609 24.5506 24.7827C25.0724 25.3054 25.3333 25.9334 25.3333 26.6667C25.3333 27.4 25.0724 28.028 24.5506 28.5507C24.028 29.0725 23.4 29.3334 22.6666 29.3334ZM9.33331 22.6667C8.33331 22.6667 7.57776 22.2276 7.06665 21.3494C6.55554 20.472 6.53331 19.6 6.99998 18.7334L8.79998 15.4667L3.99998 5.33335H2.63331C2.25554 5.33335 1.94442 5.20535 1.69998 4.94935C1.45554 4.69424 1.33331 4.3778 1.33331 4.00002C1.33331 3.62224 1.46131 3.30535 1.71731 3.04935C1.97242 2.79424 2.28887 2.66669 2.66665 2.66669H4.83331C5.07776 2.66669 5.31109 2.73335 5.53331 2.86669C5.75554 3.00002 5.9222 3.18891 6.03331 3.43335L6.93331 5.33335H26.6C27.2 5.33335 27.6111 5.55558 27.8333 6.00002C28.0555 6.44446 28.0444 6.91113 27.8 7.40002L23.0666 15.9334C22.8222 16.3778 22.5 16.7222 22.1 16.9667C21.7 17.2111 21.2444 17.3334 20.7333 17.3334H10.8L9.33331 20H24.0333C24.4111 20 24.7222 20.1276 24.9666 20.3827C25.2111 20.6387 25.3333 20.9556 25.3333 21.3334C25.3333 21.7111 25.2053 22.0276 24.9493 22.2827C24.6942 22.5387 24.3778 22.6667 24 22.6667H9.33331Z" fill="#149914"/>
        <path d="M20.9543 8.31163C20.9166 8.43792 20.8564 8.55456 20.7774 8.65469L16.2788 14.6411C16.1988 14.7476 16.0996 14.8344 15.9877 14.896C15.8757 14.9576 15.7534 14.9927 15.6286 14.999C15.5037 15.0053 15.379 14.9826 15.2625 14.9325C15.146 14.8824 15.0401 14.806 14.9517 14.708L12.2526 11.7148C12.0887 11.5266 11.998 11.2746 12 11.0129C12.0021 10.7513 12.0967 10.5011 12.2635 10.3161C12.4303 10.1311 12.656 10.0262 12.8919 10.0239C13.1278 10.0216 13.3551 10.1222 13.5248 10.304L15.5267 12.5249L19.3955 7.37758C19.47 7.27336 19.5626 7.18673 19.6679 7.12279C19.7731 7.05885 19.8887 7.01889 20.0081 7.00525C20.1274 6.99162 20.248 7.00459 20.3628 7.0434C20.4775 7.08221 20.5841 7.14608 20.6763 7.23125C20.7684 7.31643 20.8443 7.42118 20.8994 7.53934C20.9545 7.65751 20.9878 7.78671 20.9972 7.91934C21.0066 8.05197 20.9921 8.18535 20.9543 8.31163Z" fill="#F2F2F2"/>
    </svg>
)
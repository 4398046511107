import { useRef, useState } from 'react'
import { Form } from "@unform/web"
import { FormHandles } from "@unform/core"
import * as Yup from 'yup'

import { Button, CircularProgress, DialogContent, Grid } from "@mui/material"

import { DialogTitleCustom } from "../../../../styles/globalStyles"
import FormInput from '../../../../components/Form/FormInput'
import getValidationErros from '../../../../utils/validations/getValidationErros'

interface FormData {
    chave: string
    descricao: string
}

interface AddItemFormProps{
    onSubmit: (data: FormData) => Promise<void>
    onClose: () => void
}

export const AddItemForm: React.FC<AddItemFormProps> = ({ onClose, onSubmit }) => {

    const [isLoading, setIsLoading] = useState<boolean>(false)

    const formRef = useRef<FormHandles>(null)

    const handleSubmit = async(data: FormData) => {
        try{
            const schema = Yup.object().shape({
                chave: Yup.string().max(6, "A Chave deve ter no máximo 6 caracteres").required("Chave Obrigatória"),
                descricao: Yup.string().max(55, "A Descrição deve ter no máximo 55 caracteres").required("Descrição Obrigatória")
            })

            await schema.validate(data, {
                abortEarly: false
            })

            setIsLoading(true)
            await onSubmit(data)
            setIsLoading(false)
        } catch(error: any){
            if(error instanceof Yup.ValidationError){
                const errors = getValidationErros(error)
                formRef.current?.setErrors(errors)
                return
            }

            console.log(error)
        }
    }

    return (
        <>
            <DialogTitleCustom>
                Adicionar Item
            </DialogTitleCustom>
            <DialogContent>
                <Form ref={ formRef } onSubmit={ handleSubmit }>
                    <Grid container spacing={ 2 } mt={ 2 }>
                        <Grid item xs={12} md={4}>
                            <FormInput 
                                name="chave"
                                fullWidth
                                size='small'
                                label="Chave *"
                                inputProps={{
                                    maxLength: 6
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} md={8}>
                            <FormInput 
                                name="descricao"
                                fullWidth
                                size='small'
                                label="Descrição *"
                                inputProps={{
                                    maxLength: 55
                                }}
                            />
                        </Grid>
                        <Grid 
                            item 
                            xs={12}
                            display="flex"
                            justifyContent="flex-end"
                        >
                            <Button
                                variant='contained'
                                color="inherit"
                                onClick={ onClose }
                                style={{ marginRight: "10px" }}
                            >
                                Fechar
                            </Button>
                            <Button
                                style={{ minWidth: "120px" }}
                                type="submit"
                                variant='contained'
                                color="success"
                            >
                                { isLoading ? <CircularProgress size={20} color="inherit"/> : "Confirmar" }
                            </Button>
                        </Grid>
                    </Grid>
                </Form>
            </DialogContent>
        </>
    )
}
import { useState, useEffect } from 'react'
import { ApexOptions } from 'apexcharts'
import Chart from 'react-apexcharts'
import { ChartLocales } from '../../../../data/chartLocales'

import { Container, NotFoundContainer, SkeletonChart } from "./style"
import { formatValue } from '../../../../utils/format/formatValue'
import { DefaultTheme } from '../../../../themes/defaultTheme'

export interface ChartSelection{
    x: string
    y: number
    label_info_id: string | number
    goals: {
        name: string
        value: number
    }[]
}

interface BarTargetChartProps {
    legends: {
        name: string
        color: string
    }[]
    values: {
        label: string
        value: number
        label_info_id: string | number
        goals: {
            title: string
            value: number
        }[]
    }[]
    width?: number
    title?: string
    amount?: "unit" | "currency"
    height?: string | number
    isLoading?: boolean
    onSelectDataPoint?: (selectedPoint: ChartSelection) => void
}

const defaultOptions: ApexOptions = {
    theme: {
        mode: "dark"
    },
    chart: {
        defaultLocale: 'pt-br',
        locales: ChartLocales
    },
    plotOptions: {
        bar: {
            horizontal: true,
        }
    },
    title: {
        text: undefined,
        align: 'left',
        margin: 10,
        offsetX: 0,
        offsetY: 0,
        floating: false,
        style: {
            fontSize:  '14px',
            fontWeight:  'bold',
            fontFamily:  undefined,
            color:  DefaultTheme["yellow-500"]
        },
    }
}

export const BarTargetChart: React.FC<BarTargetChartProps> = ({ 
    legends, 
    values, 
    height, 
    isLoading, 
    width, 
    amount = "unit",
    title,
    onSelectDataPoint 
}) => {
    const [options, setOptions] = useState<ApexOptions>(defaultOptions)
    const [series, setSeries] = useState<any[]>([])

    useEffect(() => {
        const chartConfig: ApexOptions = {
            colors: [legends[0].color],
            legend: {
                show: true,
                showForSingleSeries: true,
                customLegendItems: legends.map(legend => legend.name),
                markers: {
                    fillColors: legends.map(legend => legend.color)
                }
            }
        }

        if(amount === "currency"){
            chartConfig.yaxis = {
                labels: {
                    formatter: (value) => isNaN(value) 
                        ? value.toString()
                        : formatValue(value)
                }
            }
            chartConfig.dataLabels = {
                formatter: (value) => isNaN(Number(value)) 
                    ? value.toString()
                    : formatValue(Number(value))
            }
            chartConfig.xaxis = {
                labels: {
                    formatter: (value) => isNaN(Number(value)) 
                        ? value
                        : formatValue(Number(value))
                },
            }
        }

        setOptions(prev => ({
            ...prev,
            chart: {
                ...prev.chart,
                events: {
                    dataPointSelection: onSelectDataPoint ? (
                        function (_, __, options){
                            const selectedInfo = options.w.config.series[0].data[options.dataPointIndex]
                            onSelectDataPoint(selectedInfo)
                        }
                    ): undefined
                },
            },
            ...chartConfig,
            title: title ? {
                ...prev.title,
                text: title
            } : prev.title
        }))
    },[legends, onSelectDataPoint, amount, title])

    useEffect(() => {
        setSeries([{
            name: "Atual",
            data: values.map(value => ({
                x: value.label,
                y: value.value,
                label_info_id: value.label_info_id,
                goals: value.goals.map(goal => ({
                    name: goal.title,
                    value: goal.value,
                    strokeWidth: 5,
                    strokeHeight: 15,
                    strokeColor: legends[1].color
                }))
            }))
        }])
    },[values, legends])

    if(isLoading){
        return (
            <Container>
                <SkeletonChart 
                    variant="rectangular" 
                    style={{ marginTop: "-16px" }}
                    height={
                        height ? (
                            isNaN(Number(height)) ? (
                                height
                            ): ( Number(height) + 30 )
                        ) : ( "100%" ) 
                    } 
                    width={width}
                    animation="wave"
                />
            </Container>
        )
    }

    if(values.length <= 0){
        return (
            <Container width={ width }>
                <NotFoundContainer>
                    <h1>Nenhum Resultado encotrado</h1>
                </NotFoundContainer>
            </Container>
        )
    }

    return (
        <Container>
            <Chart 
                type="bar"
                height={ height }
                width={ width }
                options={options}
                series={series}
            />
        </Container>
    )
}
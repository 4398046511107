import { useEffect, useRef, useState, forwardRef, useImperativeHandle } from "react"
import { useField } from "@unform/core"

import { TextFieldProps, BaseTextFieldProps } from "@mui/material"

import { CheckIcon, CloseIcon, Container, ErrorMessage, Input } from "./style"

interface FormInputProps extends BaseTextFieldProps{
    name: string
    hiddenErrorMessage?: boolean
    loading?: boolean
    onChange?: (event: React.ChangeEvent) => void
    onChangeRule?: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, input: TextFieldProps | null, value?: any) => void
    onPasteRule?: (event: any, input: TextFieldProps | null) => void,
    cleanRules?: () => void
}

export interface FormInputRef{
    changeValue(value: string): void
    getValue(): string | unknown
}

const FormInput: React.ForwardRefRenderFunction<FormInputRef, FormInputProps> = ({ 
    name, 
    label,
    hiddenErrorMessage,
    children,
    loading,
    InputLabelProps,
    onChange,
    ...rest
}, ref) => {

    const inputRef = useRef<TextFieldProps>(null)
    const textFieldRef = useRef(null)
    const {fieldName, registerField, defaultValue, error} = useField(name)

    const [hasAnError, setHasAnError] = useState(false)
    const [shrinkLabel, setShrinkLabel] = useState(false)

    useImperativeHandle(ref, () => ({
        changeValue: (value) => {
            if(inputRef.current){
                setShrinkLabel(true)
                inputRef.current.value = value || ""
            }

            if(value === ""){
                setHasAnError(false)
            }
        },
        getValue: () => ( inputRef.current?.value as string)
    }))

    useEffect(() => {
        registerField({
            name: fieldName,
            ref: inputRef.current,
            path: "value"
        })
    }, [fieldName, registerField])

    useEffect(() => {
        if(!!error){ setHasAnError(true) }
    }, [error])
    
    return(
        <Container>
            <Input 
                {...rest}
                ref={ textFieldRef }
                label={label}
                $isError={ !!error }
                $hasAnError={ hasAnError }
                inputRef={ inputRef }
                name={ name }
                defaultValue={ defaultValue }
                onChange={(event) => {
                    setShrinkLabel(false)
                    if(onChange){
                        onChange(event)
                    }
                }}
                InputLabelProps={ shrinkLabel ? ({
                    ...InputLabelProps,
                    shrink: shrinkLabel 
                }): InputLabelProps}
            />
            { !hiddenErrorMessage && (
                !!error ? (
                    <>
                    <CloseIcon />
                    <ErrorMessage> { error } </ErrorMessage>
                    </>

                ):((!error && hasAnError) &&
                    <CheckIcon />
                )
            ) }
        </Container>
    )
}

export default forwardRef(FormInput)
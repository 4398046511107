import React, { useCallback, useMemo } from "react";
import { forwardRef, useState, useImperativeHandle, useRef, useEffect } from "react";
import { Form } from "@unform/web";
import { FormHandles } from "@unform/core";
import * as Yup from "yup";

import apiService from "../../../../../services/api.services";

import { Document, Item, NewDocument } from "../../../../../@types/documents";

import { useDocuments } from "../../../../../hooks/documents";

import { formatValue, transformValueToNumberType } from "../../../../../utils/format/formatValue";
import getValidationErros from "../../../../../utils/validations/getValidationErros";
import { getErrorMessage } from "../../../../../utils/validations/getErrorMessage";

import FormInput, { FormInputRef } from "../../../../../components/Form/FormInput";
import FormSelect from "../../../../../components/Form/FormSelect";
import FormDatePicker, { FormDatePickerRef } from "../../../../../components/Form/FormDataPicker";
import { Button } from "../../../../../components/Buttons/Button";

import { CircularProgress, Dialog, DialogActions, Grid, Stack, TextField } from "@mui/material";

import { Content, GetNFDataButton, Subtitle } from "./style";
import { useBuyOrder } from "../../../../../hooks/buyOrders";
import { useAuth } from "../../../../../hooks/auth";
import { toast } from "react-toastify";
import { differenceInDays } from "date-fns";
import { convertDateToEnglishPattern } from "../../../../../utils/format/convertDateToEnglishPattern";
import { CustomAutocomplete } from "../../../../../components/Form/CustomAutocomplete";
/* import { GridColumn } from '../../../../../@types/datagrid'
import { DataTable } from '../../../../../components/Tables/Datagrid' */
import { DataGrid, GridColumns, GridRowId, ptBR } from "@mui/x-data-grid";
import { v4 } from "uuid";

interface FormData {
  type: "NF-E" | "NFS-E";
  key?: string;
  number?: string;
  serie?: string;
  value?: string;
  issueDate?: string;
  validDate?: string;
}

interface DocumentItem {
  id: string;
  label: string;
  desc_produto?: string;
  quantidade: number;
  valor_unitario: number;
}

export type FormAction = "add" | "view" | "edit";

export interface DocumentFormDialogRef {
  isOpen: boolean;
  openDialog: () => void;
  closeDialog: () => void;
}

interface DocumentFormDialogProps {
  selectedDocument?: Document | null;
  action?: FormAction;
}

const DocumentFormDialog: React.ForwardRefRenderFunction<DocumentFormDialogRef, DocumentFormDialogProps> = (
  { selectedDocument, action = "view" },
  ref
) => {
  const { userData } = useAuth();
  const { selectedOrder } = useBuyOrder();
  const { addDocument, editDocument, currentOCItens } = useDocuments();

  const formRef = useRef<FormHandles>(null);
  const valueRef = useRef<FormInputRef>(null);
  const issueDateRef = useRef<FormDatePickerRef>(null);
  const validDateRef = useRef<FormDatePickerRef>(null);
  const numberRef = useRef<FormInputRef>(null);
  const serieRef = useRef<FormInputRef>(null);
  const keyRef = useRef<FormInputRef>(null);

  const [open, setOpen] = useState<boolean>(false);
  const [itens, setItens] = useState<Item[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [selectedType, setSelectedType] = useState<string>("NF-E");
  const [selectedItens, setSelectedItens] = useState<any[]>([]);
  const [nfItems, setNfItems] = useState<DocumentItem[]>([]);

  const [cnpj, setCnpj] = useState("");

  const [hasDocumentData, setHasDocumentData] = useState<boolean>(false);

  const parsedDocs = useMemo(() => currentOCItens.map(item => {
    const currentDocument = selectedOrder.documentos.find((document) => document.itens?.includes(item.item as any))

    return {
      ...item,
      maxQuantity: item.quantidade,
      document: !!currentDocument 
        ? `${currentDocument.numero_nf}${currentDocument.serie_nf ? `-${currentDocument.serie_nf}` : ""}`
        : null
    }
  }),[selectedOrder, currentOCItens])

  const unselectedDocs = useMemo(() => {
    return parsedDocs?.filter(item => (
      item.document === null && !item.partial
    )).map(item => ({
      ...item,
      document_itens: []
    })) || []
  },[parsedDocs])

  const associatedItens = useMemo(
    () =>
      itens
        .filter((item) => selectedItens.includes(item.id))
        .map((findedItem) => {
          const defaultQuantityValue = selectedOrder.itens?.find((item) => item.item === findedItem.item)?.quantidade || 0;
          
          let documentItems: any = findedItem.document_itens

          if (selectedType === "NF-E" || selectedDocument?.type === "NF-E") {
            documentItems = typeof findedItem.document_itens === "string" ? [] : findedItem.document_itens;
          } else if (selectedType === "NFS-E") {
            //NFS-E
            findedItem.document_itens = !!findedItem.document_itens ? findedItem.document_itens : "";
            const itensInString = findedItem.document_itens as string;

            console.log(findedItem.document_itens)

            if (
              typeof itensInString === "string" ||
              findedItem.document_itens?.length === 0
            ) {
              let documentIds: any[] = []

              if(typeof itensInString === "string"){
                documentIds = itensInString?.split(";");
              } else{
                documentIds = [""]
              }

              documentItems = documentIds?.map((item) => {
                const quantity = findedItem["quantidade-nf"]
                  ? findedItem["quantidade-nf"] / (documentIds.length === 0 ? 1 : documentIds.length)
                  : 0;

                return {
                  id: item,
                  qtd: quantity,
                  unit_value: transformValueToNumberType(findedItem["unit_value_nf"]),
                };
              }) || [];
            }
          }

          return {
            id: findedItem.id,
            item: findedItem.item,
            partial: Number(findedItem.quantidade) === defaultQuantityValue ? false : true,
            qtd: findedItem.quantidade,
            unit_value: Number(findedItem.valor_unitario),
            total_value: findedItem.quantidade * Number(findedItem.valor_unitario),
            document_itens: documentItems,
          };
        }),
    [itens, selectedDocument?.type, selectedItens, selectedOrder, selectedType]
  );

  const totalAssociatedItens = useMemo(() => {
    const { totalItens, totalNF } = associatedItens.reduce(
      (accumulator, currentValue) => {
        const value = currentValue.total_value + accumulator.totalItens;

        let docValue = 0

        if (currentValue.document_itens && currentValue.document_itens.length === 1) {
          const docItem = currentValue.document_itens[0]
          const docTotalValue = docItem.unit_value * (docItem["quantidade-nf"] || docItem.qtd)

          docValue = isNaN(docTotalValue) ? 0 : docTotalValue
        } else{
          docValue = currentValue.document_itens?.reduce((docAccumulator, currentDoc) => {
            docAccumulator += currentDoc.unit_value * (currentDoc["quantidade-nf"] || currentDoc.qtd);
  
            return docAccumulator;
          }, 0);
        }

        accumulator.totalItens = value || 0;
        accumulator.totalNF += docValue || 0;

        return accumulator;
      },
      {
        totalItens: 0,
        totalNF: 0,
      }
    );
    return {
      itens: totalItens,
      nf: totalNF,
    };
  }, [associatedItens]);

  const handleClose = () => {
    if (isLoading) {
      return;
    }
    setOpen(false);
    setHasDocumentData(false);
    setSelectedItens([]);
    setNfItems([]);
    setTimeout(() => setSelectedType("NF-E"), 200);
  };

  const addValueMask = (event) => {
    const value = Number(event.target.value.replaceAll(".", "").replaceAll(",", "."));

    if (isNaN(value)) {
      valueRef.current?.changeValue(`R$ 0,00`);
    } else {
      valueRef.current?.changeValue(`R$ ${formatValue(value)}`);
    }
  };

  const clearFields = () => {
    valueRef.current?.changeValue("");
    issueDateRef.current?.changeValue(null);
    validDateRef.current?.changeValue(null);
    numberRef.current?.changeValue("");
    serieRef.current?.changeValue("");
    setHasDocumentData(false);
  };

  const consultNF = async () => {
    setIsLoading(true);
    setHasDocumentData(false);
    try {
      const actualKey = keyRef.current?.getValue() as string;

      const response = await apiService.get("/retinfonotafiscal", {
        params: {
          chvnfe: actualKey,
          branch: userData.selectedBranch?.id,
        },
      });

      setIsLoading(false);

      const { documento } = response.data;

      if (documento) {
        setHasDocumentData(true);
        setCnpj(documento.cnpj_emit);

        setNfItems(
          documento.itens_nf.map((item) => ({
            id: item.id_produto,
            label: `${item.id_produto} - ${item.desc_produto}`,
            desc_produto: item.desc_produto,
            quantidade: item.quantidade,
            valor_unitario: item.valor_unitario,
          }))
        );

        let validDate: string = "";

        const differenceDays = differenceInDays(new Date(documento.vencimento), new Date());

        if (differenceDays >= 0) {
          validDate = documento.vencimento;
        }

        setTimeout(() => {
          valueRef.current?.changeValue(`R$ ${formatValue(Number(documento.valor_nf))}`);
          issueDateRef.current?.changeValue(documento.emissao);
          validDateRef.current?.changeValue(validDate);
          numberRef.current?.changeValue(documento.nota);
          serieRef.current?.changeValue(documento.serie);
        }, 200);
      } else {
        setHasDocumentData(false);
        toast.error(response.data.status.descricao);
      }
    } catch (error) {
      console.log(error);
      setIsLoading(false);
      setHasDocumentData(false);

      toast.error(getErrorMessage(error));
    }
  };

  const handleSubmit = async (data: FormData) => {
    if (isLoading) {
      return;
    }

    const validationShape: any = {
      type: Yup.string().oneOf(["NF-E", "NFS-E"], "Tipo selecionado inválido").required("Tipo Obrigatório"),
      validDate: Yup.string().required("Data Vencimento Obrigatório"),
    };

    try {
      if (data.type === "NF-E" && !selectedDocument) {
        validationShape.key = Yup.string().required("Chave Obrigatória");
      } else {
        validationShape.number = Yup.string().required("Número Obrigatório");
        validationShape.serie = Yup.string().notRequired();
        validationShape.value = Yup.string().required("Valor Obrigatório");
        validationShape.issueDate = Yup.string().required("Data Emissão Obrigatória");
      }

      const schema = Yup.object().shape(validationShape);

      await schema.validate(data, {
        abortEarly: false,
      });

      const differenceDays = differenceInDays(new Date(convertDateToEnglishPattern(data.validDate!)), new Date());

      if (differenceDays < 0) {
        formRef.current?.setErrors({
          ...formRef.current.getErrors(),
          validDate: "Data de vencimento inferior a data atual",
        });
        throw new Error("Data de vencimento inferior a data atual");
      }

      const value = transformValueToNumberType(data.value?.split("$")[1].trim());

      if (value <= 0) {
        const currentErrors = formRef.current?.getErrors();
        formRef.current?.setErrors({
          ...currentErrors,
          value: "Valor inválido",
        });

        return;
      }

      if (associatedItens.length <= 0) {
        toast.warning("Nenhum item selecionado");
        return;
      }

      const hasIncorrectValues = associatedItens.some((item) => {
        if (!item.document_itens || item.document_itens?.length <= 0) {
          return true;
        }

        return item.document_itens?.some(
          (docItem) =>
            docItem.qtd === 0 ||
            docItem.qtd === undefined ||
            docItem.qtd === null ||
            docItem.unit_value === 0 ||
            docItem.unit_value === undefined ||
            docItem.unit_value === null
        );
      });

      if (hasIncorrectValues) {
        toast.warning("Por favor verifique as quantidades, valores unitário e codigos dos itens");
        return;
      }

      const totalAssociatedItensValue = associatedItens.reduce((accumulator, currentValue) => {
        const docItensTotal = currentValue.document_itens.reduce((docAccumulator, docCurrentValue) => {
          docAccumulator += (docCurrentValue["quantidade-nf"] || docCurrentValue.qtd) * docCurrentValue.unit_value;
          return docAccumulator;
        }, 0);

        accumulator += docItensTotal;
        return accumulator;
      }, 0);

      const assignmentDocValue = transformValueToNumberType(data.value?.split("R$")[1] || "0,00");

      if (totalAssociatedItensValue.toFixed(2) !== assignmentDocValue.toFixed(2)) {
        toast.warning("A somatória dos valores da NF dos itens selecionados não batem com o valor da nota");
        return;
      }

      const hasCorrectDocumentValues = associatedItens.some((item) =>
        item.document_itens?.some((docItem) => !docItem.qtd || !docItem.unit_value)
      );

      if (hasCorrectDocumentValues) {
        toast.warning("Por favor preencha corretamente os dados dos itens selecionados");
        return;
      }

      setIsLoading(true);

      if (selectedDocument) {
        await editDocument({
          ...data,
          value: value,
          associatedItens: associatedItens.map((item: any) => ({
            ...item,
            document_itens: item.document_itens?.map(doc => ({
              id: doc.id,
              label: doc.label,
              qtd: doc['quantidade-nf'] || doc.qtd,
              unit_value: doc.unit_value
            }))
          })),
        } as Document);
      } else {
        await addDocument({
          ...data,
          cnpj_emit: cnpj,
          value: value,
          associatedItens: associatedItens.map((item: any) => ({
            ...item,
            document_itens: item.document_itens?.map(doc => ({
              id: doc.id,
              label: doc.label,
              qtd: doc['quantidade-nf'] || doc.qtd,
              unit_value: doc.unit_value
            }))
          })),
          documentOptionsItens: nfItems.length > 0 ? nfItems : undefined,
        } as NewDocument);
      }

      handleClose();
    } catch (error: any) {
      console.log(error);

      if (error instanceof Yup.ValidationError) {
        const errors = getValidationErros(error);
        formRef.current?.setErrors(errors);
        return;
      }

      console.log(error);
      toast.error(getErrorMessage(error));
    } finally {
      setIsLoading(false);
    }
  };

  const handleItemSelection = useCallback((selection: GridRowId[]) => {
    setSelectedItens(selection)
  }, []);

  const onQuantityChanges = useCallback((value: string, currentItem: Item) => {
    let updatedItem = currentItem;

    const isAddAfterAprove = selectedOrder.status_diver_nfxoc !== "4"

    const maxQuantity = isAddAfterAprove
      ? itens.find((item) => item.item === currentItem.item)?.maxQuantity || 1
      : currentOCItens.find((item) => item.id === currentItem.id)?.quantidade || 1

    if (Number(value) <= Number(maxQuantity) && Number(value) >= 0) {
      updatedItem = {
        ...currentItem,
        quantidade: Number(value) === 0 ? (value as any) : Number(value),
      };
    }

    setItens((prev) => prev.map((item) => (item.id === currentItem.id ? updatedItem : item)));
  },[currentOCItens, selectedOrder, itens]);

  const onNFQuantityChanges = useCallback(
    (value: string, currentItem: Item) => {
      let updatedItem = currentItem;

      const isAddAfterAprove = selectedOrder.status_diver_nfxoc !== "4"
      
      let maxQuantityItem = 1

      if(selectedType === "NF-E"){
        console.log(updatedItem.document_itens)
        //@ts-ignore
        maxQuantityItem = updatedItem.document_itens ? updatedItem.document_itens[0].qtd : 1
      } else{
        maxQuantityItem = isAddAfterAprove
          ? itens.find((item) => item.item === currentItem.item)?.maxQuantity || 1
          : currentOCItens.find((item) => item.id === currentItem.id)?.quantidade || 1
      }

      const maxQuantity = maxQuantityItem < 1 ? 1 : maxQuantityItem


      const isLessThenMaxQuantity = Number(value) <= Number(maxQuantity) && Number(value) >= 0

      const quantityNf = isLessThenMaxQuantity 
      ? Number(value) === 0
        ? value as any
        : Number(value)
      : maxQuantity

      const isMultiItemServiceInvoice = typeof currentItem.document_itens === "string"

      updatedItem = {
        ...currentItem,
        "quantidade-nf": quantityNf,
        //@ts-ignore
        document_itens: !isMultiItemServiceInvoice ? currentItem.document_itens?.map(item => ({
          ...item,
          "quantidade-nf": quantityNf,
        })) : currentItem.document_itens
      };

      setItens((prev) => prev.map((item) => (item.id === currentItem.id ? updatedItem : item)));
    },
    [currentOCItens, selectedOrder, itens, selectedType]
  );

  const onValueNFChanges = useCallback((value: string, currentItem: Item) => {
    const newValue = transformValueToNumberType(value as string);

    const updatedItem = {
      ...currentItem,
      unit_value_nf: isNaN(newValue) ? "" : formatValue(newValue),
    };

    setItens((prev) => prev.map((item) => (item.id === currentItem.id ? updatedItem : item)));
  }, []);

  const handleNFESelectItem = useCallback((values: DocumentItem[], row: Item) => {
    console.log(values)
    setItens((prev) =>
      prev.map((tableRow) => {
        if (tableRow.id === row.id) {
          console.log("Batata")
          return {
            ...tableRow,
            "quantidade-nf": values.length > 0 ? values[0].quantidade : undefined,
            document_itens: values.map((item) => ({
              id: item.id,
              label: item.label,
              qtd: item.quantidade,
              "quantidade-nf": item.quantidade,
              unit_value: item.valor_unitario,
            })),
          };
        } else {
          return tableRow
        }
      })
    );
  }, []);

  const columns: GridColumns =
    selectedType === "NF-E" || selectedDocument?.type === "NF-E"
      ? [
          {
            field: "codigo_produto",
            headerName: "Código",
            width: 120,
          },
          {
            field: "document_itens",
            headerName: "Código (NF)",
            minWidth: 550,
            renderCell: ({ row }) => {
              if(action !== "edit" && action !== "add"){
                return row.item_nf
              }

              const item = row as Item;
              const docItens = item.document_itens as Array<any>;

              if (typeof docItens === "string") {
                return docItens;
              }

              const docItensInfo =
                docItens?.map((item) => ({
                  id: item.id,
                  label: item.label,
                  quantidade: item.qtd,
                  valor_unitario: item.unit_value,
                })) || [];

              return (
                <CustomAutocomplete
                  fullwidth
                  defaultValue={docItensInfo}
                  options={nfItems}
                  onChange={(value) => handleNFESelectItem(value as DocumentItem[], row)}
                />
              );
            },
          },
          {
            field: "descriao_produto",
            headerName: "Descrição",
            minWidth: 550,
          },
          {
            field: "descricao_cc",
            headerName: "Centro de Custo",
            minWidth: 300,
          },
          {
            field: "quantidade",
            headerName: "QTD",
            minWidth: 100,
            headerAlign: "center",
            align: "center",
            renderCell: ({ row }) => (
              <TextField
                size="small"
                fullWidth
                variant="standard"
                type="number"
                disabled={action !== "edit" && action !== "add"}
                value={row.quantidade}
                onChange={(event) => {
                  onQuantityChanges(event.target.value, row as Item);
                }}
              />
            ),
          },
          /* {
            field: "quantidade-nf",
            headerName: "QTD (NF)",
            minWidth: 100,
            headerAlign: "center",
            align: "center",
            renderCell: ({ row }) => {
              const item = row as Item;

              const docItens = item.document_itens as Array<any>;

              if (typeof docItens === "string") {
                return docItens;
              }

              const quantityNF = docItens?.reduce((accumulator, currentValue) => {
                accumulator += currentValue.qtd;

                return accumulator;
              }, 0);

              return quantityNF;
            },
          }, */
          {
            field: "quantidade-nf",
            headerName: "QTD (NF)",
            minWidth: 100,
            headerAlign: "center",
            align: "center",
            renderCell: ({ row }) => {
              const item = row as Item;

              const docItens = item.document_itens as Array<any>;

              if (typeof docItens === "string") {
                return docItens;
              }

              const quantityNF = docItens?.reduce((accumulator, currentValue) => {
                accumulator += currentValue.qtd;

                return accumulator;
              }, 0);

              if(docItens?.length > 1){
                return quantityNF
              }
              
              return (
                <TextField
                  size="small"
                  fullWidth
                  type="number"
                  variant="standard"
                  disabled={action !== "edit" && action !== "add"}
                  value={row["quantidade-nf"] }
                  onChange={(event) => {
                    onNFQuantityChanges(event.target.value, row as Item);
                  }}
                />
              )
            },
          },
          {
            field: "valor_total",
            headerName: "Valor (R$)",
            minWidth: 150,
            headerAlign: "center",
            align: "right",
            renderCell: ({ row }) => {
              const value = row.quantidade * row.valor_unitario
              //return formatValue(row.valor_total)
              return formatValue(value || 0)
            },
          },
          {
            field: "valor_total-nf",
            headerName: "Valor (R$)(NF)",
            minWidth: 150,
            headerAlign: "center",
            align: "right",
            renderCell: ({ row }) => {
              const item = row as Item;

              const docItens = item.document_itens as Array<any>;

              if (typeof docItens === "string") {
                return row.total_nf_value || docItens;
              }

              console.log("teste")

              const totalValue = docItens?.reduce((accumulator, currentValue) => {
                console.log(currentValue)
                accumulator += (currentValue["quantidade-nf"] || currentValue.qtd) * currentValue.unit_value;

                return accumulator;
              }, 0);

              return !!totalValue 
                ? formatValue(totalValue) 
                : !!row.total_nf_value 
                  ? formatValue(row.total_nf_value)
                  : ""
            },
          },
        ]
      : [
          {
            field: "codigo_produto",
            headerName: "Código",
            minWidth: 120,
          },
          {
            field: "document_itens",
            headerName: "Código Serviço",
            minWidth: 200,
            renderCell: ({ row }) => {
              if(action !== "edit" && action !== "add"){
                return row.item_nf
              }

              let defaultValue = ""

              if(typeof row.document_itens === "string"){
                defaultValue = row.document_itens
              } else{
                defaultValue = row.document_itens?.map(item => item.id).toString() || ""
              }

              return (
                <TextField
                  size="small"
                  fullWidth
                  variant="standard"
                  disabled={action !== "edit" && action !== "add"}
                  defaultValue={defaultValue}
                  onBlur={(event) => {
                    setItens((prev) =>
                      prev.map((tableRow) => {
                        if (tableRow.id === row.id) {
                          return {
                            ...tableRow,
                            document_itens: event.target.value,
                          };
                        } else {
                          return tableRow;
                        }
                      })
                    );
                  }}
                />
              )
            }
          },
          {
            field: "descriao_produto",
            headerName: "Descrição",
            minWidth: 550,
          },
          {
            field: "descricao_cc",
            headerName: "Centro de Custo",
            minWidth: 300,
          },
          {
            field: "quantidade",
            headerName: "QTD",
            minWidth: 100,
            headerAlign: "center",
            align: "center",
            renderCell: ({ row }) => (
              <TextField
                size="small"
                fullWidth
                variant="standard"
                type="number"
                disabled={action !== "edit" && action !== "add"}
                value={row.quantidade}
                onChange={(event) => {
                  onQuantityChanges(event.target.value, row as Item);
                }}
              />
            ),
          },
          {
            field: "quantidade-nf",
            headerName: "QTD (NF)",
            minWidth: 100,
            headerAlign: "center",
            align: "center",
            renderCell: ({ row }) => (
              <TextField
                size="small"
                fullWidth
                type="number"
                variant="standard"
                disabled={action !== "edit" && action !== "add"}
                value={row["quantidade-nf"]}
                onChange={(event) => {
                  onNFQuantityChanges(event.target.value, row as Item);
                }}
              />
            ),
          },
          {
            field: "unit_value_nf",
            headerName: "Valor Unit. (R$)(NF)",
            minWidth: 200,
            headerAlign: "center",
            align: "right",
            renderCell: ({ row }) => (
              <TextField
                size="small"
                fullWidth
                variant="standard"
                value={row["unit_value_nf"]}
                disabled={action !== "edit" && action !== "add"}
                onChange={(event) => {
                  setItens((prev) =>
                    prev.map((tableRow) => {
                      if (tableRow.id === row.id) {
                        return {
                          ...tableRow,
                          unit_value_nf: event.target.value,
                        };
                      } else {
                        return tableRow;
                      }
                    })
                  );
                }}
                onBlur={(event) => {
                  onValueNFChanges(event.target.value, row);
                }}
              />
            ),
          },
          {
            field: "valor_total",
            headerName: "Valor (R$)",
            minWidth: 150,
            headerAlign: "center",
            align: "right",
            renderCell: ({ row }) => {
              const value = row.quantidade * row.valor_unitario
              //return formatValue(row.valor_total)
              return formatValue(value || 0)
            },
          },
          {
            field: "valor_total-nf",
            headerName: "Valor (R$)(NF)",
            minWidth: 150,
            headerAlign: "center",
            align: "right",
            renderCell: ({ row }) => {
              if(!!row.total_nf_value){
                return formatValue(row.total_nf_value)
              }

              const unitValue = !!row.unit_value_nf ? transformValueToNumberType(row.unit_value_nf) : 0;
              const quantity = !!row["quantidade-nf"] ? row["quantidade-nf"] : 1;

              return formatValue(unitValue * quantity);
            },
          },
        ];

  useImperativeHandle(ref, () => ({
    isOpen: open,
    openDialog: () => setOpen(true),
    closeDialog: handleClose,
  }));

  useEffect(() => {
    if (selectedDocument && open) {
      setSelectedType("NFS-E");
      setSelectedItens(selectedDocument.associatedItens?.map((item) => item.id) || []);
    }

    let documentItens: any[] = []

    if(action === "view"){
      documentItens = selectedDocument?.associatedItens || []
    } else{
      documentItens = selectedDocument
        ? currentOCItens
            .map((item) => {
              if (!item) {
                return null;
              }
              
              const currentItem = selectedDocument.associatedItens?.find((associated) => associated.id === item.id);
              
              if (currentItem) {
                if (selectedDocument.type === "NFS-E") {
                  const documentsIds = currentItem.document_itens
                    .map((item) => item.id)
                    .toString()
                    .replaceAll(",", ";");
  
                  const { unitValue, qtdNf } = currentItem.document_itens.reduce(
                    (accumulator, currentValue) => {
                      accumulator.unitValue = currentValue.unit_value;
                      accumulator.qtdNf += currentValue.qtd;
  
                      return accumulator;
                    },
                    {
                      unitValue: 0,
                      qtdNf: 0,
                    }
                  );
  
                  return {
                    ...item,
                    quantidade: currentItem.qtd,
                    valor_total: currentItem.total_value,
                    document_itens: documentsIds,
                    "quantidade-nf": qtdNf,
                    unit_value_nf: formatValue(unitValue),
                  };
                }
  
                return {
                  ...item,
                  id: item.id || v4(),
                  quantidade: currentItem.qtd,
                  valor_total: currentItem.total_value,
                  document_itens: currentItem.document_itens,
                };
              }

              return {
                ...item,
                id: item.id || v4()
              };
            })
            .filter((item) => item !== null)
            .filter((item) => (!!item 
              //@ts-ignore
              ? item.quantidade > 0 : false)
            )
        : currentOCItens
          .filter((item) => item.quantidade > 0 && !item.usedByDocument)
          .map((item) => ({ ...item, id: item.id || v4() }))
    }

    console.log(documentItens)

    if(
      selectedOrder.status_diver_nfxoc !== "4" && 
      (action === "edit" || action === "add")
    ){
      setItens(documentItens
        .map(item => ({ ...item, maxQuantity: item.quantidade }))
        .filter(item => !item.wasSynced)
      )
    } else{
      setItens(documentItens.map(item => ({
        ...item,
        "quantidade-nf": item["quantidade-nf"] || undefined
      })) as Item[]);
    }

    if (selectedDocument?.documentOptionsItens) {
      setNfItems(selectedDocument.documentOptionsItens);
    }

  }, [selectedDocument, open, currentOCItens, selectedOrder, action]);

  useEffect(() => {
    if(
      open && 
      selectedOrder.status_diver_nfxoc !== "4" &&
      (action === "add") 
    ){
      setItens(unselectedDocs)
    }
  },[selectedOrder, open, unselectedDocs, action])

  useEffect(() => {
    if (!selectedDocument) {
      clearFields();
      setHasDocumentData(false);
      setSelectedItens([]);
    }
  }, [selectedType, selectedDocument]);

  return (
    <Dialog open={open} fullWidth maxWidth="xl" disableEscapeKeyDown onClose={handleClose}>
      <Form
        ref={formRef}
        onSubmit={handleSubmit}
        initialData={{
          number: selectedDocument?.number,
          value: `R$ ${formatValue(selectedDocument?.value || 0)}`,
          serie: selectedDocument?.serie,
          type: selectedDocument?.type || "NF-E",
          issueDate: selectedDocument?.issueDate || "",
          validDate: selectedDocument?.validDate || "",
        }}
      >
        <Content>
          <Grid container spacing={3} style={{ marginLeft: "-25px" }}>
            <Grid item xs={12} md={12}>
              <Subtitle>Dados Gerais</Subtitle>
            </Grid>
            <Grid item xs={12} md={4}>
              <FormSelect
                size="small"
                name="type"
                label="Tipo Documento"
                fullWidth
                disabled={!!selectedDocument?.type}
                onChange={(event) => {
                  setSelectedType(event.target.value);
                }}
                options={[
                  { label: "DANFE", value: "NF-E" },
                  { label: "Nota Fiscal de Serviço", value: "NFS-E" },
                ]}
              />
            </Grid>
            {selectedType === "NF-E" && (
              <Grid item xs={12} md={8} position="relative">
                <FormInput
                  ref={keyRef}
                  size="small"
                  name="key"
                  label="Chave da NF"
                  fullWidth
                  onKeyPress={(event) => {
                    if (event.which === 13) {
                      consultNF();
                    }
                  }}
                  onChange={(event: any) => {
                    keyRef.current?.changeValue(event.target.value.replace(/\D+/g, ""));
                  }}
                />
                <GetNFDataButton type="button" variant="contained" onClick={consultNF}>
                  {isLoading ? <CircularProgress size={20} color="inherit" /> : "Consultar"}
                </GetNFDataButton>
              </Grid>
            )}
          </Grid>
          <Grid container spacing={3} mt="1px">
            {(selectedType === "NFS-E" || hasDocumentData) && (
              <>
                <Grid item xs={12} md={4}>
                  <FormInput
                    ref={numberRef}
                    label="Número NF"
                    size="small"
                    fullWidth
                    name="number"
                    InputLabelProps={{ shrink: true }}
                    disabled={!!selectedDocument?.number || selectedType === "NF-E"}
                    inputProps={{ maxLength: 9 }}
                    onChange={(event: any) => {
                      numberRef.current?.changeValue(event.target.value.replace(/\D+/g, ""));
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={2}>
                  <FormInput
                    ref={serieRef}
                    label="Série"
                    name="serie"
                    size="small"
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    disabled={!!selectedDocument?.serie || selectedType === "NF-E"}
                    inputProps={{ maxLength: 3 }}
                    onChange={(event: any) => {
                      serieRef.current?.changeValue(event.target.value.replace(/\D+/g, ""));
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <FormInput
                    ref={valueRef}
                    label="Valor da Nota"
                    size="small"
                    name="value"
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    inputProps={{ autoComplete: "off" }}
                    disabled={selectedDocument?.type === "NF-E" || (action !== "edit" && action !== "add") || selectedType === "NF-E"}
                    onBlur={addValueMask}
                    onChange={(event: any) => {
                      valueRef.current?.changeValue(event.target.value.replace(/[^0-9,.]+/g, ""));
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <FormDatePicker
                    ref={issueDateRef}
                    size="small"
                    views={["day"]}
                    fullWidth
                    inputFormat="dd/MM/yyyy"
                    label="Data Emissão"
                    name="issueDate"
                    disabled={selectedDocument?.type === "NF-E" || (action !== "edit" && action !== "add") || selectedType === "NF-E"}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <FormDatePicker
                    ref={validDateRef}
                    views={["day"]}
                    size="small"
                    fullWidth
                    inputFormat="dd/MM/yyyy"
                    label="Data Vencimento"
                    name="validDate"
                    minDate={new Date()}
                    disabled={selectedDocument?.type === "NF-E" || (action !== "edit" && action !== "add")}
                  />
                </Grid>
              </>
            )}
          </Grid>
          <Grid container spacing={3} mt="1px">
            <Grid item xs={12} md={6}>
              <FormInput
                size="small"
                name="total-nf"
                label="Total Itens (R$)"
                fullWidth
                disabled
                InputLabelProps={{ shrink: true }}
                value={formatValue(totalAssociatedItens.itens)}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <FormInput
                size="small"
                name="total-itens"
                label="Total NF (R$)"
                fullWidth
                disabled
                value={formatValue(totalAssociatedItens.nf) === "NaN" ? "0,00" : formatValue(totalAssociatedItens.nf)}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2} mt="10px">
            <Grid item xs={12} md={12}>
              <Subtitle>Vincular Produtos</Subtitle>
            </Grid>
            <Grid item xs={12} md={12} height={300}>
              <DataGrid
                style={{ height: "300px" }}
                columns={columns}
                localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
                rows={itens}
                pageSize={30}
                isRowSelectable={() => action === "edit" || action === "add"}
                checkboxSelection
                disableSelectionOnClick
                rowsPerPageOptions={[30]}
                disableColumnMenu
                onSelectionModelChange={handleItemSelection}
                selectionModel={selectedItens}
                components={{
                  NoRowsOverlay: () => (
                    <Stack height="100%" alignItems="center" justifyContent="center">
                      Nenhum resultado foi encontrado
                    </Stack>
                  ),
                }}
              />
            </Grid>
          </Grid>
        </Content>
        <DialogActions style={{ padding: "0px 24px 20px 0px" }}>
          <Button size="large" variant="text" onClick={handleClose}>
            Fechar
          </Button>
          {(action === "edit" || action === "add") && (
            <Button variant="contained" color="primary" type="submit" size="large" style={{ height: "37px", minWidth: "115px" }}>
              {selectedDocument ? "Alterar" : "Adicionar"}
            </Button>
          )}
        </DialogActions>
      </Form>
    </Dialog>
  );
};

export default forwardRef(DocumentFormDialog);

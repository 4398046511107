import styled from "styled-components"

import { DialogContent } from "@mui/material"

export const DefaultDialogContent = styled(DialogContent)`
    pre{
        width: 100%;
        margin-top: 35px;
        font-family: sans-serif;
    }
`
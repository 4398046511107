import { forwardRef } from 'react'

import { useAuth } from "../../../hooks/auth"
import { useInitialData } from "../../../hooks/initialData"

import { Check, ExitToApp, Person } from "@mui/icons-material"
import { MenuItem } from "@mui/material"

import { Container, MenuSection } from "./style"

interface UserMenuProps{
    onSelectOption: () => void
    onChangePass: () => void
}

const UserMenu: React.ForwardRefRenderFunction<HTMLDivElement, UserMenuProps> = ({ onChangePass, onSelectOption }, ref) => {
    const { filiaisData } = useInitialData()
    const { userData, signOut, changeBranch } = useAuth()

    return (
        <Container ref={ ref }>
            <MenuSection>
                <p>Filiais</p>
                { filiaisData.map(branch => (
                    <MenuItem
                        key={ branch.filial }
                        onClick={() => { 
                            onSelectOption()
                            changeBranch({
                                id: branch.filial,
                                name: branch.nome
                            })
                        }}
                    >
                        { `${branch.filial} - ${branch.nome}` }  
                        { userData.selectedBranch?.id === branch.filial && (
                            <Check style={{ fontSize: "14px", marginLeft: "5px" }} />
                        )}
                    </MenuItem>
                )) }
            </MenuSection>
            <MenuSection>
                <p>Ações</p>
                <MenuItem onClick={ onChangePass }>
                    <Person style={{ marginRight: "5px" }} />
                    Alterar Senha
                </MenuItem>
                <MenuItem onClick={ signOut }>
                    <ExitToApp style={{ marginRight: "5px" }} />
                    Sair
                </MenuItem>
            </MenuSection>
        </Container>
    )
}

export default forwardRef(UserMenu)
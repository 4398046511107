import { useState, useEffect, useMemo } from 'react'

import { formatValue, transformValueToNumberType } from '../../../utils/format/formatValue'

import OptionsButtons from '../../../components/Buttons/OptionsButtons'

import { Tooltip } from '@mui/material'
import { Visibility } from '@mui/icons-material'

import { Container, GridContainer } from "./style"
import { useAuth } from '../../../hooks/auth'
import { ProductDialiog } from '../components/ProductsDialog'
import { SellDetailsProduct } from '../../../@types/sellDetailts'
import { useSellDetails } from '../../../hooks/useSellDetails'
import { DataTable } from '../../../components/Tables/Datagrid'
import { GridColumn } from '../../../@types/datagrid'

export const InvoincingProducts: React.FC = () => {
    
    const { userData } = useAuth()
    const { products, getProducts, isFetching } = useSellDetails()

    const [selectedProduct, setSelectedProduct] = useState<SellDetailsProduct>({} as SellDetailsProduct)
    const [openDetailsModal, setOpenDetailsModal] = useState<boolean>(false)

    useEffect(() => {
        const currentMonth = !!sessionStorage.getItem("@portalnaos:selldetails:periodfilter")? (
            sessionStorage.getItem("@portalnaos:selldetails:periodfilter")?.replaceAll("-", "")
        ): (
            `${ new Date().getFullYear() }${ (new Date().getMonth() + 1).toString().padStart(2, "0")}`
        )

        getProducts({
            anomes: currentMonth
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[userData.selectedBranch])

    const summary = useMemo(() => {
        return products.reduce((accumulator, product) => {
            const newValues = {
                qtd_invoiced: accumulator.qtd_invoiced + Number(product.invoicing.qtd),
                net_sales_invoiced: formatValue(transformValueToNumberType(accumulator.net_sales_invoiced) + product.invoicing.net_sales),
                qtd_pendent: accumulator.qtd_pendent + Number(product.pendent.qtd) + Number(product.canceled.qtd) + Number(product.in_progress.qtd),
                net_sales_pendent: formatValue(transformValueToNumberType(accumulator.net_sales_pendent) + product.pendent.net_sales + product.canceled.net_sales + product.in_progress.net_sales),
                qtd_total: accumulator.qtd_total + Number(product.total.qtd),
                net_sales_total: formatValue(transformValueToNumberType(accumulator.net_sales_total) + product.total.net_sales),
            }

            return newValues
        },{
            qtd_invoiced: 0,
            net_sales_invoiced: "0,00",
            qtd_pendent: 0,
            net_sales_pendent: "0,00",
            qtd_total: 0,
            net_sales_total: "0,00",
        })
    },[products])

    const columns: GridColumn[] = useMemo(() => [
        { 
            field: "id", 
            headerName: "Código",
            minWidth: 100
        },
        { 
            field: "gama", 
            headerName: "Gama",
            minWidth: 150
        },
        
        { 
            field: "nome_marca", 
            headerName: "Marca",
            minWidth: 150
        },
        { 
            field: "desc", 
            headerName: "Descrição",
            minWidth: 200,
            renderCell: (row) => (
                <Tooltip title={ row.desc }>
                    <p>{ row.desc }</p>
                </Tooltip>
            )
        },
        { 
            field: "qtd_invoiced", 
            headerName: "QTD Faturado", 
            headerAlign: "center", 
            align: "center", 
            minWidth: 120,
            renderCell: (row) => (
                row.invoicing.qtd
            )
        },
        { 
            field: "net_sales_invoiced", 
            headerName: "NET SALE Faturado", 
            align: "right", 
            minWidth: 150,
            renderCell: (row) => (
                formatValue(row.invoicing.net_sales)
            )
        },
        { 
            field: "qtd_pendent", 
            headerName: "QTD Pendente",
            headerAlign: "center",
            align: "center",
            minWidth: 120,
            renderCell: (row) => (
                row.pendent.qtd + row.canceled.qtd + row.in_progress.qtd
            )
        },
        { 
            field: "net_sales_pendent", 
            headerName: "NET SALE Pendente", 
            align: "right",
            minWidth: 160,
            renderCell: (row) => (
                formatValue(row.pendent.net_sales + row.canceled.net_sales + row.in_progress.net_sales)
            )
        },
        { 
            field: "qtd_total", 
            headerName: "QTD Total", 
            headerAlign: "center",
            align: "center",
            minWidth: 120,
            renderCell: (row) => (
                row.total.qtd
            )
        }, 
        { 
            field: "net_sales_total", 
            headerName: "NET SALE Total", 
            headerAlign: "center",
            align: "right",
            minWidth: 150,
            renderCell: (row) => (
                formatValue(row.total.net_sales)
            )
        },
        { 
            field: "options", 
            headerName: "Opções", 
            headerAlign: "center", 
            align: "center", 
            minWidth: 120,
            disableSort: true,
            renderCell: (row) => (
                <OptionsButtons 
                    action='view'
                    icon={ Visibility } 
                    type='button' 
                    onClick={() => {
                        setSelectedProduct(row)
                        setOpenDetailsModal(true)
                    }} 
                />
            ) 
        }
    ],[])

    return(
        <Container>
            <GridContainer>
                <DataTable
                    columns={ columns }
                    rows={ products }
                    paginationType="disabled"
                    rowsPerPage={30}
                    disableCheckboxSelection
                    loading={ isFetching }
                    pinnedRows={products.length > 0 || !isFetching ? [
                        {
                            position: "bottom",
                            content: [
                                { value: "Total", field: "id", align: "left", colspan: 4 },
                                { value: summary.qtd_invoiced, field: "qtd_invoiced" },
                                { value: summary.net_sales_invoiced, field: "net_sales_invoiced", align: "right" },
                                { value: summary.qtd_pendent, field: "qtd_pendent", },
                                { value: summary.net_sales_pendent, field: "net_sales_pendent", align: "right"},
                                { value: summary.qtd_total, field: "qtd_total"},
                                { value: summary.net_sales_total, field: "net_sales_total", align: "right"},
                                { value: "", field: "options"}
                            ]
                        }
                    ]: []}
                />
                <ProductDialiog
                    open={ openDetailsModal }
                    fullWidth
                    maxWidth="sm"
                    selectedProduct={ selectedProduct }
                    onClose={() => {
                        setOpenDetailsModal(false)
                        setTimeout(() => setSelectedProduct({} as SellDetailsProduct), 200)
                    }}
                />
            </GridContainer>
        </Container>
    )
}
import React from 'react'
import Dropzone from 'react-dropzone'

import { 
    CloudUpload,
    CloseRounded
} from '@mui/icons-material'

import { 
    DropContainer, 
    UploadMessage 
} from './style'

/**
 * 
 * @param { onUpload: Function } props
 * @returns 
 */

interface UploadProps{
    accept: string
    multiple?: boolean
    height?: string
    onUpload(files: Array<any>): void
}

const Upload: React.FC<UploadProps> = ({ onUpload, accept, height, multiple }) => (
    <Dropzone 
        accept={ accept }
        onDropAccepted={(files) => onUpload(files)}
        maxSize={ 10485760 } //10mb
        multiple={ !!multiple }
    >
        {({ getRootProps, getInputProps, isDragActive, isDragReject }) => (
            <DropContainer
                {...getRootProps()}
                height={ height }
                isDragActive={isDragActive}
                isDragReject={isDragReject}
            >
                <input {...getInputProps()}/>
                {isDragReject ?
                    <>
                    <CloseRounded style={{ color: "#e83f5b" }}/>
                    <UploadMessage type="error">Arquivo não suportado</UploadMessage>
                    </>
                :
                    <>
                    <CloudUpload />
                    <UploadMessage>Selecione ou arraste o arquivo aqui.</UploadMessage>
                    </>
                }
            </DropContainer>
        )}
    </Dropzone>
)

export default Upload
import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { VideoCard } from "./components/VideoCard";
import { v4 } from "uuid";
import { Header, Container, NavList, NotFoundInfo } from "./style";
import { TextField } from "@mui/material";
import { MagnifyingGlass } from "phosphor-react";
import apiService from "../../services/api.services";
import { toast } from "react-toastify";

import NotFoundImage from '../../assets/not-found-video.svg'

export interface Video {
  id: string;
  url: string;
  title: string;
}

export interface FiltersProps {
  page?: number;
  itemsPerPage?: number;
  title?: string;
  category?: string;
  section?: string;
}

export const Tutorials: React.FC = () => {

    const [search, setSearch] = useState<string>("")

    const {
        data: videosList,
        error: fetchingError,
    } = useQuery<Video[]>(
        "videos", 
        () => getVideos(), 
        {
            staleTime: 1000 * 60 * 30, //5min
            refetchInterval: 1000 * 60 * 30, //5min
            refetchOnWindowFocus: false,
        }
    );

    const filteredVideos = videosList?.filter(item => JSON.stringify(item).toUpperCase().includes(search.toUpperCase()))

    const hasVideos = filteredVideos?.length && filteredVideos.length > 0

    const getVideos = async (): Promise<Video[]> => {
        const response = await apiService.get("/tutorial")

        const tutorials = response.data.data.tutorial?.map(item => ({
            id: v4(),
            url: item.link,
            title: item.titulo
        }))

        return tutorials || []
    };

    useEffect(() => {
        if(fetchingError){
            console.log(fetchingError)
            toast.error("Falha ao buscar os vídeos cadastrados")
        }
    },[fetchingError])

    return (
        <Container>
            <Header>
                <div>
                    <MagnifyingGlass size={22}/>
                    <TextField
                        fullWidth
                        variant="outlined"
                        size="small"
                        label="Pesquisar..."
                        value={ search }
                        onChange={(event) => setSearch(event.target.value)}
                    />
                </div>
            </Header>
                { hasVideos ? (
                    <NavList className="w-full flex flex-1 flex-wrap gap-12 h-full overflow-auto">
                        { filteredVideos.map((video) => (
                            <VideoCard
                                key={video.id}
                                video={video}
                            />
                        ))}
                    </NavList>
                ): (
                    <NotFoundInfo>
                        <img src={NotFoundImage} alt="not-found-tutorial-video" />
                    </NotFoundInfo>
                )}
        </Container>
    );
}

import { useState, useRef, useEffect } from 'react'
import { Form } from '@unform/web'
import { FormHandles } from '@unform/core'

import { FilterAlt, FilterAltOff } from '@mui/icons-material'
import { useTheme as useMuiTheme, useMediaQuery, Grid, Fade } from '@mui/material'

import { Container, FilterButton, FilterContainer, FilterPosition, InfoContainer, InputContainer } from './style'
import FormInput, { FormInputRef } from '../../../../components/Form/FormInput'
import { Quotes } from '../../../../@types/dashboards'
import FormSelect from '../../../../components/Form/FormSelect'
import FiltersForm from '../../../../components/FiltersForm'

interface FormData {
    period: string
    quote: Quotes
}

interface DashboardFilterProps{
    isLoading?: boolean
    onStartFilter: (filters: FormData) => void
    onChangeQuote: (quote: Quotes) => void
}

const currentPeriod = `${new Date().getFullYear()}-${String(new Date().getMonth() + 1).padStart(2, "0")}`

export const DashboardFilter: React.FC<DashboardFilterProps> = ({ isLoading, onChangeQuote, onStartFilter }) => {

    const formRef = useRef<FormHandles>(null)

    const quoteInputRef = useRef<FormInputRef>(null)
    const initailQuoteRef = useRef<HTMLInputElement>(null)

    const period = sessionStorage.getItem("@portalnaos:dashboard:period")

    const [openFilter, setOpenFilter] = useState<boolean>(false)
    const [selectedPeriod, setSelectedPeriod] = useState<string>(() => {
        const date = new Date(`${period || currentPeriod}-01Z03:00:00`)

        return `${date.toLocaleDateString("pt-br", {
            month: "long",
            year: "numeric"
        })}`
    })

    const muiTheme = useMuiTheme()
    const isMobileView = useMediaQuery(muiTheme.breakpoints.down("lg"))

    const handleSelection = (event: React.ChangeEvent<HTMLInputElement>) => {
        onChangeQuote(event.target.value as Quotes)
    }

    const handleSubmit = async(data: FormData) => {
        onStartFilter({
            ...data,
            period: data.period || currentPeriod
        })

        const period = new Date(`${data.period ? data.period : currentPeriod}-01Z03:00:00`)

        setSelectedPeriod(period.toLocaleDateString("pt-br", {
            month: "long",
            year: "numeric"
        }))

        setOpenFilter(false)
    }

    const cleanFilters = () => {
        formRef.current?.setFieldValue("period", currentPeriod)
        quoteInputRef.current?.changeValue("BU")

        if(initailQuoteRef.current){
            initailQuoteRef.current.checked = true
        }

        const period = new Date(`${ currentPeriod }-01Z03:00:00`)

        setSelectedPeriod(`${period.toLocaleDateString("pt-br", {
            month: "long",
            year: "numeric"
        })}`)

        onStartFilter({
            period: currentPeriod,
            quote: "BU"
        })

        setOpenFilter(false)
    }

    useEffect(() => {
        if(!isMobileView){
            quoteInputRef.current?.changeValue("")
        }
    },[isMobileView])

    return(
        <Container>
            <FilterContainer>
                <InputContainer>
                    <input
                        ref={ initailQuoteRef }
                        type="radio"
                        name="quote"
                        value="BU" 
                        onChange={ handleSelection }
                        defaultChecked
                        disabled={ isLoading }
                    />
                    Cota Budget
                </InputContainer>
                <InputContainer>
                    <input
                        type="radio"
                        name="quote"
                        value="AT" 
                        onChange={ handleSelection }
                        disabled={ isLoading }
                    />
                    Cota Atualizada
                </InputContainer>
                <InputContainer>
                    <input
                        type="radio"
                        name="quote"
                        value="BR" 
                        onChange={ handleSelection }
                        disabled={ isLoading }
                    />
                    Cota Brasil
                </InputContainer>
                <FilterButton onClick={() => setOpenFilter(prev => !prev)}>
                    { openFilter ? <FilterAltOff/> : <FilterAlt/> }
                </FilterButton>
                <Fade in={ openFilter }>
                    <FilterPosition>
                        <FiltersForm
                            onSubmit={() => formRef.current?.submitForm()}
                            onClean={ cleanFilters }
                        >
                            <Form 
                                ref={ formRef } 
                                onSubmit={ handleSubmit }
                                initialData={{ period: period || currentPeriod }}
                            >
                                <Grid container spacing={ 2 }>
                                    <Grid item xs={12} sm={ isMobileView ? 6 : 12}>
                                        <FormInput 
                                            label="Período"
                                            name="period"
                                            type="month"
                                            size="small"
                                            fullWidth
                                            InputLabelProps={{ shrink: true }}
                                        />
                                    </Grid>
                                    {isMobileView && (
                                        <Grid item xs={12} sm={6}>
                                            <FormSelect 
                                                ref={quoteInputRef}
                                                label="Cota"
                                                name="quote"
                                                size="small"
                                                fullWidth
                                                initialValue='BU'
                                                options={[
                                                    { label: "Cota Budget", value: "BU" },
                                                    { label: "Cota Atualizada", value: "AT" },
                                                    { label: "Cota Brasil", value: "BR" },
                                                ]}
                                            />
                                        </Grid>
                                    )}
                                </Grid>
                            </Form>
                        </FiltersForm>
                    </FilterPosition>
                </Fade>
            </FilterContainer>
            <InfoContainer>
                <p><strong>Período: </strong>{ selectedPeriod }</p>
            </InfoContainer>
        </Container>
    )
}
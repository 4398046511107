import { DialogContent } from '@mui/material'
import styled from 'styled-components'
import { DrilldownTable } from '../../../../components/Tables/DrilldownTable'

export const DetailsContainer = styled(DialogContent)`
    padding: 6px !important;
`

export const PendentsItem = styled(DrilldownTable)`
    .MuiTableCell-root{
        background-color: #F8CBAD !important;
    }
`

export const DocumentItem = styled(DrilldownTable)`
    .MuiTableCell-root{
        background-color: #A9D08E !important;
    }
`
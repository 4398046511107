import styled from "styled-components"
import { shade } from "polished"

export const Content = styled.div`
    padding: 15px;
`

export const FileList = styled.div`
    width: 100%;
    max-height: 30vh;
    padding: 2px;
    overflow-y: auto;

    > p{
        width: 100%;
        text-align: center;
    }
`

export const FileCard = styled.div`
    width: 100%;
    min-height: 45px;
    padding: 5px 5px 5px 15px;
    background-color: ${ shade(0.05, "#f9f9f9")};
    border-radius: 5px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    overflow: hidden;

    strong{
        margin-right: 10px;
        word-break: break-all;
        outline: none;
        padding: 2px 5px;
        transition: 200ms;

        &:focus{
            background-color: #FFFFFF;
            border-radius: 5px;
        }
    }

    & + &{
        margin-top: 15px;
    }

    input{
        width: auto;
        resize: horizontal;
    }

    div{
       display: flex;
       flex-direction: row; 
    }

    @media(max-width: 750px){
        strong, span, p{
            font-size: 12px;
        }
    }
`

export const Actions = styled.div`
    margin-top: 10px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    gap: 14px;

    button {
        min-width: 120px;
    }
`
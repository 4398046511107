import React from 'react'
import { useState, useImperativeHandle, forwardRef } from 'react'

import { Button } from '../../Buttons/Button'

import { 
    Dialog,
    DialogActions, 
    DialogProps,
    CircularProgress
} from '@mui/material'

import { Close } from '@mui/icons-material'

import { DialogTitleCustom } from '../../../styles/globalStyles'

import { DefaultDialogContent } from './style'

export interface DefaultDialogRef {
    isOpen: boolean
    openDialog: () => void
    closeDialog: () => void
}

interface DefaultDialogProps extends DialogProps {
    confirmButtonColor?: "inherit" | "primary" | "secondary" | "success" | "error" | "info" | "warning" 
    confirmButtonTitle?: string
    titleText: string
    contentText: string
    disableClickAwayClose?: boolean
    action?: () => void | Promise<void>
}

const DefaultDialog: React.ForwardRefRenderFunction<DefaultDialogRef,DefaultDialogProps> = ({ 
    titleText, 
    contentText, 
    action,
    disableClickAwayClose,
    open: initialOpen, 
    confirmButtonColor = "success",
    confirmButtonTitle = "Confirmar",
    ...rest 
}, ref) => {

    const [open, setOpen] = useState<boolean>(initialOpen)
    const [isLoading, setIsLoading] = useState<boolean>(false)

    const handleClose = () => {
        if(!isLoading){
            setIsLoading(false)
            setOpen(false)
        }
    }

    useImperativeHandle(ref, () => ({
        isOpen: open,
        openDialog: () => setOpen(true),
        closeDialog: handleClose
    }))

    return(
        <Dialog
            { ...rest }
            open={ open }
            onClose={( _, reason) => {
                if(!disableClickAwayClose){
                    handleClose()
                }

                if(reason !== "backdropClick" && disableClickAwayClose){
                    handleClose()
                }
            }}
        >
            <DialogTitleCustom>
                { titleText }
                <button onClick={ handleClose }><Close/></button>
            </DialogTitleCustom>
            <DefaultDialogContent>
                <pre>{ contentText }</pre>
            </DefaultDialogContent>
            { action && 
                <DialogActions>
                    <Button
                        color="primary"
                        variant="text"
                        fullWidth
                        disabled={ isLoading }
                        onClick={ handleClose }
                    >
                        Fechar
                    </Button>
                    <Button
                        color={ confirmButtonColor }
                        variant="contained"
                        fullWidth
                        disabled={ isLoading }
                        onClick={async() => {
                            setIsLoading(true)
                            await action()
                            handleClose()
                        }}
                    >
                        { isLoading ? <CircularProgress size={ 24 } color="inherit" /> : confirmButtonTitle }
                    </Button>
                </DialogActions>
            }
        </Dialog>
    )
}

export default forwardRef(DefaultDialog)
import styled from 'styled-components'

import { ButtonBase } from '@mui/material'

export const Container = styled.div`
    width: 100%;
    height: 40px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    position: relative;

    @media (max-width: 1200px){
        justify-content: flex-end;
    }
`

export const FilterContainer = styled.div`
    flex: 1;
    max-width: 600px;
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 14px;

    @media (max-width: 1200px){
        max-width: none;
        position: absolute;
        width: 100%;
    }
`

export const InfoContainer = styled.div`
    text-transform: uppercase;
`

export const InputContainer = styled.label`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 4px;
    line-height: 8px;

    input{
        accent-color: ${ props => props.theme['yellow-500'] } !important;
    }

    @media (max-width: 1200px){
        display: none;
    }
`

export const FilterButton = styled(ButtonBase)`
    border-radius: 5px !important;

    svg{
        color: #212121;
    }
`

export const FilterPosition = styled.div`
    width: 100%;
    max-width: 400px;
    position: absolute;
    top: 0px;
    right: -212px;
    z-index: 9999;

    @media (max-width: 1200px){
        top: 32px;
        left: 0px;
    }
`
import styled from "styled-components"
import { css } from "styled-components"
import { ReactNode } from "react"

import { ButtonBase, Drawer, Popover } from "@mui/material"
import { lighten } from "polished"

interface MenuProps{
    expanded?: boolean
    children?: ReactNode
}

interface MenuFooterProps{
    isExpanded?: boolean
}

export const DesktopLayout = styled.div`
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    scrollbar-width: auto;
    scrollbar-color: #808080 #ffffff;
    border-radius: 28px;
    margin: 10px 0px 10px 10px;
    backdrop-filter: blur(2px);
    
    div.border{
        margin: 0px;
        height: calc(100vh - 39px);
        width: 15px;
        min-height: 310px;
        background-color: ${props => props.theme["blue-500"]}B3;
        border-radius: 0px 25px 25px 0px;
        position: relative;
        border: none !important;

        button{
            position: absolute;
            right: -10px;
            top: 40px;
            width: 20px;
            height: 20px;
            border-radius: 50%;
            border: none;
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: #e4eaf7;
            z-index: 2;

            > svg{
                font-size: 15px;
                color: ${props => props.theme["blue-500"]};
            }

            &:hover{
                cursor: pointer;
            }
        }
    }

    @media (max-width: 750px){
        display: none;
    }
`

export const MobileLayout = styled.div`
    display: none;
    width: 50px;
    height: 50px;
    position: absolute;
    top: 10px;
    left: 10px;

    @media(max-width: 750px){
        display: block;
    }
`

export const NavMenu = styled.div<MenuProps>`
    min-width: 240px;
    padding: 15px 0px 15px 15px;
    height: calc(100vh - 39px);
    min-height: 310px;
    background-color: ${props => props.theme["blue-500"]}B3;
    border-radius: 15px 0px 0px 15px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    color: #fff !important;

    div.split{
        margin: 5px 0px;
        width: 23%;
        height: 1px;
        transition: 200ms;
        background: rgb(228,228,228);
        background: radial-gradient(circle, rgba(228,228,228,1) 0%, rgba(247,247,247,1) 56%, rgba(228,228,228,0) 100%); 
    }

    ${ props => props.expanded && css`
        div.split{
            width: 100%;
        }

        .MuiButtonBase-root{
            width: 100% !important;
        }
    `}
`

export const MenuList = styled.nav`
    margin: 20px 0px;
    width: 100%;
    height: calc(100vh - 250px);
    overflow-y: auto; 
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;

    ::-webkit-scrollbar {
        width: 6px;
    }

    ::-webkit-scrollbar-track {
        background: #ffffff00;
    }

    ::-webkit-scrollbar-thumb {
        background-color: #808080;
        border-radius: 10px;
    }
`

export const MenuFooter = styled.div<MenuFooterProps>`
    width: 100%;
    margin-top: 20px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    position: relative;

    h2, img{
        background-color: #e4eaf7;
        width: 50px;
        height: 50px;
        border-radius: 50%;
        transition: 500ms;

        &:hover{
            cursor: pointer;
        }

        ${ props => props.isExpanded && css`
            margin-right: 15px;

            &:hover{
                cursor: default;
            }
        `}
    }

    h2{
        display: flex;
        justify-content: center;
        align-items: center;
        color: #1f1f1f;
        background-color: #e4eaf7;
    }

    img{
        object-fit: cover;
        background-color: #e4eaf7;
    }

    p, span{
        color: #FFF !important;
    }
    
    > div{
        overflow: hidden;

        p, > span{
            font-size: 16px;
            color: #fff;
            font-family: sans-serif;
    
            &:hover{
                cursor: default;
            }

            &:not(:first-child){
                font-size: 12px;
                &:hover{
                    cursor: pointer;
                }
            }

        }

        div{
            margin-top: 8px;
            width: 100%;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            
            button{
                min-width: 100px !important;
                padding: 0px !important;
                height: 14px !important;
                font-size: 12px !important;
                justify-content: flex-start !important;
                color: #fff !important;

                &:last-child{
                    margin-left: 5px;
                    justify-content: center !important;
                    width: 30px !important;
                    padding: 5px !important;
                    min-width: 30px !important;
                }
            }
        }
    }

    @media(max-width: 750px){
        margin-top: 6vh;
    }
`

export const MobileDrawer = styled(Drawer)`
    @media(min-width: 751px){
        display: none !important;
    }
`

export const DrawerContent = styled.div`
    width: 240px;
    padding: 25px 15px 25px 15px;
    height: 100vh;
    display: flex;
    flex-direction: column;
    background-color: ${props => props.theme["blue-500"]}B3;
    backdrop-filter: blur(5px);
    position: relative;
    overflow-x: hidden;
    font-weight: bold !important;
    color: #fff !important;

    > button{
        position: absolute;
        top: 5px;
        right: 5px;
        z-index: 2;
        border: none;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #fff;
        border-radius: 8px;
    }
    
    div.split{
        background: rgb(228,228,228);
        background: radial-gradient(circle, rgba(228,228,228,1) 0%, rgba(247,247,247,1) 56%, rgba(228,228,228,0) 100%); 
    }
`

export const ProfileMenu = styled(Popover)`
    .MuiPaper-root{
        max-width: 230px;
        max-height: 300px;
        overflow-y: auto;
        padding: 5px;
        position: relative;
        margin-left: 8px;
        background-color: ${props => lighten(0.1, props.theme["blue-500"])};
        color: #FFF;
    }

    .MuiMenuItem-root{
        height: 30px;
        border-radius: 5px;
        margin: 3px;
        transition: 200ms;
        display: flex !important;
        flex-direction: row !important;
        align-items: center !important;
        color: #FFF;

        svg{
            margin-right: 10px;
        }

        &:hover{
            background-color: ${props => lighten(0.2, props.theme["blue-500"])};
        }
    }
`

export const MobileToggleMenu = styled(ButtonBase)`
    width: 100%;
    height: 50px;
    color: ${ props => props.theme["white-200"] } !important;
    border-radius: 10px !important;
    border: 2px solid ${ props => props.theme["white-200"] }!important;

    svg{
        font-size: 3rem;
        color: ${ props => props.theme["white-200"] } !important;
    }
`

export const MobileItem = styled(ButtonBase)`
    width: 100% !important;
    margin-top: 10px !important;
    display: flex;
    justify-content: flex-start !important;
    align-items: center;
    position: relative;
    border-radius: 5px !important;
    transition: 500ms !important;

    svg{
        font-size: 2.1rem;
        margin: 9px;
    }

    p{
        font-size: 18px;
        font-weight: bold;
        font-family: sans-serif;
    }

    &:hover{
        cursor: pointer !important;
    }
`
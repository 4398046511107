export const DefaultTheme = {
    await: "#E3E309",
    warning: "#FF9000",
    primary: "#1B7CCC",
    danger: "#C53030",
    secondary: "#743AC9",
    
    tranaprent: "#fffff00",
    black: "#000000",
    
    "blue-50": "#F3FEFF",
    "blue-100": "#E8FDFF",
    "blue-500": "#415D60",
    "blue-700": "#162E78",
    "blue-800": "#001D2A",

    "cyan-200": "#62CEC7",

    "white-100": "#FFFFFF",
    "white-200": "#F2F2F2",
    "white-300": "#E0E0E0",

    "gray-100": "#F5F5F5",
    "gray-300": "#C2C2C2",
    "gray-400": "#737373",
    "gray-700": "#424242",

    "green-500": "#149914",
    "green-700": "#1B5E20",

    "yellow-500": "#FEBD11"
} as const

export type ThemeType = typeof DefaultTheme
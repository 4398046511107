import { Skeleton } from "@mui/material";
import styled from "styled-components";
import { fade } from "../../../../../styles/animations";

interface ContainerProps{
    width?: number 
}

export const Container = styled.div<ContainerProps>`
    width: ${props => props.width ? `${props.width}px` : "100%" };
    height: calc(100vh - 168px);
    padding: 12px;
    background-color: ${ props => props.theme['gray-700'] };
    border-radius: 10px;

    @media (min-width: 750px){
        min-height: 556px;
    }
`

export const NotFoundContainer = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 18px;
    color: ${ props => props.theme["white-100"] };
    text-align: center;

    animation: ${ fade } 300ms;
`

export const Table = styled.table`
    margin-top: 16px;
    background-color: #FFF;
    border-radius: 10px;
    padding: 0px 15px;
    width: 100%;
    border-collapse: separate; 
    border-spacing: 0 1em;

    thead{
        th{
            background-color: #FFF;
            padding: 0px 6px;
            position: sticky;
            top: 0;
        }
    }

    tbody{
        tr{
            height: 50px;

            td{
                background-color: #FFF;
                padding: 0px 15px;
                border-bottom: 2px solid black;
                border-top: 2px solid black;
                
                &:first-child{
                    border-left: 2px solid black;
                    border-top-left-radius: 10px;
                    border-bottom-left-radius: 10px;
                }

                &:last-child{
                    border-right: 2px solid black;
                    border-top-right-radius: 10px;
                    border-bottom-right-radius: 10px;
                }
            }

            &:hover{
                cursor: default !important;
            }
        }
    }
`

export const SkeletonChart = styled(Skeleton)`
    border-radius: 10px !important;
`

export const SkeletonHeader = styled.div`
    width: 100%;
    height: auto;
    padding-top: 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 14px;
`
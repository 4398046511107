import styled from "styled-components"

import { fade } from "../../styles/animations";
import { ButtonBase, Paper } from "@mui/material";

const typeColors = {
    canceled: "#FFFFFF",
    pendent: "#F8CBAD",
    invoiced: "#A9D08E"
}

export const Container = styled.div`
    width: 100%;

    .MuiToolbar-root{
        display: none !important;
    }

    .MuiTableCell-root{
        padding-right: 0px !important;
    }

    .row-custom-class-pendente{
        background-color: ${ typeColors.pendent };
    }

    .row-custom-class-faturado{
        background-color: ${ typeColors.invoiced };
    }
`

export const Header = styled.header`
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-bottom: 8px;

    svg{
        color: ${ props => props.theme["blue-500"] } !important;
    }
`

export const ActionButton = styled(ButtonBase)`
    padding: 4px !important;
    border-radius: 5px !important;
`

export const DefaultTable = styled.div`
    width: 100%;
    height: calc(100vh - 148px);
    position: relative;
    animation: ${ fade } 200ms;
    border: 1px solid ${props => props.theme["blue-800"]};
    border-radius: 5px;
`

export const GridContainer = styled(Paper)`
    height: 100%;
    overflow-y: auto;
`
import { useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Form } from '@unform/web'
import { FormHandles } from '@unform/core'
import * as Yup from "yup"
import { toast } from 'react-toastify'

import { useTheme } from '../../hooks/theme'
import { useAuth } from '../../hooks/auth'
import { useInitialData } from '../../hooks/initialData'

import getValidationErros from '../../utils/validations/getValidationErros'

import FormInput from '../../components/Form/FormInput'
import Copyright from '../../components/Misc/Copyright'
import { Button } from '../../components/Buttons/Button'

import { CircularProgress } from '@mui/material'

import { 
    ActionsForm, 
    Container, 
    ForgotPassLink, 
    FormSection, 
    SigninCard 
} from './style'

interface LoginData{
    user: string
    password: string
}

export default function SignIn() {

    const navigate = useNavigate()

    const { appImages } = useTheme()
    const { authenticationUser } = useAuth()
    const { getData } = useInitialData()

    const formRef = useRef<FormHandles>(null)

    const [isLoading, setIsLoading] = useState<boolean>(false)

    const handleSubmit = async(data: LoginData) => {
        try{
            const schema = Yup.object().shape({
                user: Yup.string().required("Usuário obrigatório"),
                password: Yup.string().required("Senha obrigatório")
            })

            await schema.validate(data, {
                abortEarly: false
            })

            setIsLoading(true);
            formRef.current?.setErrors({})
    
            const responseUser = await authenticationUser({
                email: data.user,
                password: data.password
            });
    
            if (responseUser.status === "success") {
                getData(responseUser.token || "")
                navigate("/")
            } else{
                setIsLoading(false)
    
                if(responseUser.description.includes("cadastrado")){
                    formRef.current?.setErrors({
                        user: responseUser.description
                    })
                }
    
                if(responseUser.description.includes("inválidos")){
                    formRef.current?.setErrors({
                        user: responseUser.description,
                        password: responseUser.description
                    })
                }

                toast.error(responseUser.description)
            }
        } catch(error: any){
            if(error instanceof Yup.ValidationError){
                const errors = getValidationErros(error)

                formRef.current?.setErrors(errors)

                Object.keys(errors).forEach(err => {
                    toast.warn(errors[err])
                })
            }

            setIsLoading(false)
        }
    }

    return (
        <Container>
            <SigninCard>
                <Form ref={formRef} onSubmit={ handleSubmit }>
                    <header>
                        <img src={ appImages.home } alt="enterprise"/>
                    </header>
                    <FormSection>
                        <FormInput
                            name='user'
                            label="Usuário"
                            size="small"
                            fullWidth
                            hiddenErrorMessage
                        />
                        <FormInput
                            name='password'
                            label="Senha"
                            type="password"
                            size="small"
                            fullWidth
                            hiddenErrorMessage
                        />
                        <ForgotPassLink to="/signup">
                            Esqueci a senha
                        </ForgotPassLink>
                    </FormSection>
                    <ActionsForm>
                        <Button
                            fullWidth
                            size="large"
                            type="submit"
                            variant="contained"
                            color="primary"
                        >
                            { isLoading ? <CircularProgress color="inherit" size={26}/> : "Entrar" }
                        </Button>
                    </ActionsForm>
                </Form>
            </SigninCard>
            <Copyright />
        </Container>
    )
}
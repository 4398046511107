import { IconProps } from "../../../@types/icons";

export const ReprovedNFIcon: React.FC<IconProps> = ({ color, height, size }) => (
    <svg 
        width={ size || "32"} 
        height={ height || "32"}  
        color={ color }
        viewBox="0 0 32 32" 
        fill="none" 
        xmlns="http://www.w3.org/2000/svg"
    >
        <g clip-path="url(#clip0_301_1369)">
            <path d="M15.9996 0.00012207C7.16335 0.00012207 0 7.1638 0 16C0 24.8362 7.16335 31.9998 15.9996 31.9998C24.8358 31.9998 32 24.8359 32 16C31.9999 7.16348 24.8358 0.00012207 15.9996 0.00012207ZM23.1687 23.1686C23.1687 24.2423 22.2984 25.1126 21.2249 25.1126H10.775C9.70108 25.1126 8.83099 24.2423 8.83099 23.1686V8.83101C8.83099 7.75729 9.70129 6.887 10.775 6.887H18.9769V10.775H23.1687V23.1686ZM19.8069 9.94483V6.88678L23.1687 9.94483H19.8069Z" fill="#C53030"/>
            <path d="M15.9996 0.00012207C7.16335 0.00012207 0 7.1638 0 16C0 24.8362 7.16335 31.9998 15.9996 31.9998C24.8358 31.9998 32 24.8359 32 16C31.9999 7.16348 24.8358 0.00012207 15.9996 0.00012207ZM23.1687 23.1686C23.1687 24.2423 22.2984 25.1126 21.2249 25.1126H10.775C9.70108 25.1126 8.83099 24.2423 8.83099 23.1686V8.83101C8.83099 7.75729 9.70129 6.887 10.775 6.887H18.9769V10.775H23.1687V23.1686ZM19.8069 9.94483V6.88678L23.1687 9.94483H19.8069Z" fill="#C53030"/>
            <path d="M16.0001 14.6134L14.2153 12.827L14.2151 12.8268C14.0819 12.6936 13.9238 12.588 13.7498 12.5159C13.5758 12.4438 13.3893 12.4067 13.201 12.4067C13.0126 12.4067 12.8261 12.4438 12.6521 12.5159C12.4781 12.588 12.32 12.6936 12.1868 12.8268C12.0536 12.96 11.948 13.1181 11.8759 13.2921C11.8039 13.4661 11.7668 13.6526 11.7668 13.841C11.7668 14.0293 11.8039 14.2158 11.8759 14.3898C11.948 14.5638 12.0536 14.7219 12.1868 14.8551L12.187 14.8553L13.9734 16.6401L12.187 18.4249L12.1868 18.4251C12.0536 18.5583 11.948 18.7164 11.8759 18.8904C11.8039 19.0644 11.7668 19.2509 11.7668 19.4392C11.7668 19.6276 11.8039 19.8141 11.8759 19.9881L12.3379 19.7967L11.8759 19.9881C11.948 20.1621 12.0536 20.3202 12.1868 20.4534C12.32 20.5866 12.4781 20.6922 12.6521 20.7643L12.8435 20.3023L12.6521 20.7643C12.8261 20.8364 13.0126 20.8735 13.201 20.8735C13.3893 20.8735 13.5758 20.8364 13.7498 20.7643C13.9238 20.6922 14.0819 20.5866 14.2151 20.4534L14.2153 20.4532L16.0001 18.6668L17.7849 20.4532L17.7851 20.4534C18.0541 20.7223 18.4189 20.8735 18.7992 20.8735C19.1796 20.8735 19.5444 20.7223 19.8134 20.4534C20.0824 20.1844 20.2335 19.8196 20.2335 19.4392C20.2335 19.0589 20.0824 18.6941 19.8134 18.4251L19.8132 18.4249L18.0269 16.6401L19.8132 14.8553L19.8134 14.8551C20.0824 14.5861 20.2335 14.2213 20.2335 13.841C20.2335 13.4606 20.0824 13.0958 19.8134 12.8268C19.5444 12.5578 19.1796 12.4067 18.7992 12.4067C18.4189 12.4067 18.0541 12.5578 17.7851 12.8268L17.7849 12.827L16.0001 14.6134Z" fill="white" stroke="#C53030"/>
        </g>
        <defs>
            <clipPath id="clip0_301_1369">
                <rect width="32" height="32" fill="white"/>
            </clipPath>
        </defs>
    </svg>
)
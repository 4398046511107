import styled from "styled-components"

import { ButtonBase } from "@mui/material"

export const StatusHeader = styled.header`
    margin-bottom: 10px;
`

export const Container = styled.div`
    width: 100%;
    padding: 25px;
    height: auto;
    max-height: 400px;
    overflow-y: auto;
`

export const ShowDescriptionButton = styled(ButtonBase)`
    position: absolute !important;
    bottom: 10px;
    right: 5px;
    padding: 5px !important;
    border-radius: 50% !important;
`

export const ClosePaperButton = styled(ButtonBase)`
    position: absolute !important;
    top: 5px;
    right: 5px;
    padding: 5px !important;
    border-radius: 50% !important;
`
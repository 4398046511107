import React from 'react'
import { useState, useCallback, useEffect, useRef } from 'react'

import { useInitialData } from '../../../hooks/initialData'
import { useAuth } from '../../../hooks/auth'
import { Button } from '../../Buttons/Button'

import { 
    Dialog, 
    DialogProps,
    Autocomplete,
    TextField
} from '@mui/material'

import { DialogTitleCustom } from '../../../styles/globalStyles'
import { Content } from './style'
import { toast } from 'react-toastify'

interface ChangeBranchDialogProps extends DialogProps{
    onClose: (event?: {}, reason?: "backdropClick" | "escapeKeyDown") => void
}

const ChangeBranchDialog: React.FC<ChangeBranchDialogProps> = ({ onClose ,...rest }) => {

    const confirmButtonRef = useRef<HTMLButtonElement>(null)

    const { filiaisData } = useInitialData()
    const { changeBranch, userData } = useAuth()

    const [branchsOptions, setBranchsOptions] = useState<Array<string>>([])
    const [value, setValue] = useState<string | null>(() => userData.selectedBranch ? (
        `${userData.selectedBranch?.id} - ${userData.selectedBranch?.name}`
    ):(null))

    const handleSelected = useCallback((): void => {
        if(value === "" || value === undefined || value === null){
            toast.info("Por favor selecione uma filial")
            return
        }

        const branch = value.split("-")

        const selected = {
            id: branch[0].trim(),
            name: branch[branch.length - 1].trim()
        }

        changeBranch(selected)
        onClose()
    },[value, changeBranch, onClose])

    useEffect(() => {
        if(filiaisData.length === 1){
            setValue(`${filiaisData[0].filial} - ${filiaisData[0].nome}`)
            confirmButtonRef.current?.focus()
        }
        setBranchsOptions(filiaisData.map(branch => `${branch.filial} - ${branch.nome}`))

        return () => {
            setBranchsOptions([])
            setValue(null)
        }
    },[filiaisData, confirmButtonRef])

    return(
        <Dialog 
            {...rest}
            onClose={ onClose }
        >
            <DialogTitleCustom>
                Selecione a Filial
            </DialogTitleCustom>
            <Content>
                <Autocomplete
                    value={ value }
                    options={ branchsOptions }
                    fullWidth
                    onChange={(_, option) => setValue(option || "")}
                    renderInput={props => 
                        <TextField
                            {...props} 
                            hiddenLabel 
                            label="Filiais"
                            size="small"
                            fullWidth
                        />
                    }
                />
                <Button
                    ref={ confirmButtonRef }
                    color="success"
                    variant="contained"
                    fullWidth
                    onClick={ handleSelected }
                >
                    Confirmar
                </Button>
            </Content>
        </Dialog>
    )
}

export default ChangeBranchDialog
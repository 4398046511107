import React from "react";
import { useEffect, useState, useRef, useCallback } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Form } from "@unform/web";
import { FormHandles } from "@unform/core";
import * as Yup from "yup";
import { AnyObject } from "yup/lib/types";
import { Supplier } from "../../../@types/supplier";

import api from "../../../services/api.services";

import { useSupplier } from "../../../hooks/supplier";
import { useAuth } from "../../../hooks/auth";
import { useBilling } from "../../../hooks/billing";

import { Button } from "../../../components/Buttons/Button";
import { getErrorMessage } from "../../../utils/validations/getErrorMessage";
import { removeSymbolsOnString } from "../../../utils/format/removeSymbolsOnString";
import getValidationErros from "../../../utils/validations/getValidationErros";
import { validadeCNPJ, validadeCPF } from "../../../utils/validations/validateDocuments";
import { SupplierFormDefaultValue } from "../../..//data/formValues";

import FormInput, { FormInputRef } from "../../../components/Form/FormInput";
import FormSelect, { FormSelectRef } from "../../../components/Form/FormSelect";
import FormAutoComplete, { FormAutoCompleteRef } from "../../../components/Form/FormAutoComplete";

import { Grid, CircularProgress } from "@mui/material";

import { Container, FormActions, LoadingCointainer } from "./style";
import { toast } from "react-toastify";
import { FormCard } from "../../../components/Form/FormCard";
import FormInputMask from "../../../components/Form/FormInputMask";

interface FormData {
  id: string;
  nome: string;
  fantasia: string;
  email: string;
  cnpj: string;
  inscricao: string;
  ddd: string;
  telefone: string;
  contato: string;
  integra: string;
  banco: string;
  agencia: string;
  conta: string;
  condicao_pagamento: string;
  descr_compra: string;
  pais: string;
  estado: string;
  codigo_municipio: string;
  bairro: string;
  numero: string;
  endereco: string;
  cep: string;
  chave: string;
  tipo_chave: string;
}

const SupplierEdit: React.FC = () => {
  const navigate = useNavigate();
  const routeParams = useParams();
  const { userData } = useAuth();

  const { getPaymentConditions, getBanks } = useBilling();
  const { selectedSupplier, searchSupplier, updateSupplier, selectASupplier, removeSupplier } = useSupplier();

  const formRef = useRef<FormHandles>(null);
  const cityRef = useRef<FormAutoCompleteRef>(null);

  const pixRef = useRef<FormInputRef>(null);
  const pixSelectRef = useRef<FormSelectRef>(null);

  const [isLoadingPage, setIsLoadingPage] = useState<boolean>(true);
  const [isLoadingForm, setIsLoadingForm] = useState<boolean>(false);
  const [isLoadingBanks, setIsLoadingBanks] = useState<boolean>(false);
  const [isLoadingCities, setIsLoadingCities] = useState<boolean>(false);
  const [isLoadingCountries, setIsLoadingCountries] = useState<boolean>(false);
  const [isLoadingPaymentConditions, setIsLoadingPaymentConditions] = useState<boolean>(false);

  const [documentMask, setDocumentMask] = useState<string>("999.999.999-999");
  const [phoneMask, setPhoneMask] = useState<string>("9999-99999");
  const [pixMask, setPixMask] = useState<string>("");

  const [controledPaymentInfo, setControledPaymentInfo] = useState({
    agency: "",
    account: "",
  });

  const [description, setDescription] = useState<string>("");

  const [supplier, setSupplier] = useState<Supplier>({} as Supplier);
  const [citiesOptions, setCitiesOptions] = useState<Array<string>>([]);
  const [paymentOptions, setPaymentOptions] = useState<Array<string>>([]);
  const [countriesOptions, setCountriesOptions] = useState<Array<string>>([]);
  const [bankOptions, setBankOptions] = useState<string[]>([]);

  const handleSubmit = useCallback(
    async (data: FormData): Promise<void> => {
      setIsLoadingForm(true);

      if (formRef.current) {
        formRef.current.setErrors({});
      }

      let filteredData = {} as FormData;
      let attribute: keyof FormData;

      for (attribute in data) {
        filteredData[attribute] = removeSymbolsOnString(data[attribute]);
      }

      const yupValidations: AnyObject = {
        id: Yup.string(),
        nome: Yup.string().required("Razão Social Obrigatória"),
        fantasia: Yup.string().required("Nome Obrigatório"),
        email: Yup.string().email("E-mail inválido").required("E-mail Obrigatório"),
        cnpj: Yup.string().validateDocument("Documento Inválido").required("CNPJ Obrigatório"),
        inscricao: Yup.string().notRequired(),
        ddd: Yup.string().max(3, "Maxímo 3 numeros").required("DDD Obrigatório"),
        telefone: Yup.string().required("Telefone Obrigatório"),
        contato: Yup.string().required("Nome Contato Obrigatório"),
        integra: Yup.string().required("Obrigatório"),

        banco: Yup.string().required("Banco Obrigatório"),
        agencia: Yup.string().required("Agência Obrigatório"),
        conta: Yup.string().required("Conta Obrigatória"),
        condicao_pagamento: Yup.string().required("Condição Obrigatória"),
        tipo_chave: Yup.string().notRequired(),
        descr_compra: Yup.string().required("Descrição obrigatória"),

        pais: Yup.string().required("País Obrigatório"),
        estado: Yup.string().required("Estado Obrigatório"),
        codigo_municipio: Yup.string().required("Município Obrigatório"),
        bairro: Yup.string().required("Bairro Obrigatório"),
        numero: Yup.string().required("Obrigatório"),
        endereco: Yup.string().required("Endereço Obrigatório"),
        cep: Yup.string().length(9, "CEP Inválido").required("CEP Obrigatório"),
      };

      switch (data.tipo_chave) {
        case "01":
        case "04":
          yupValidations.chave = Yup.string().required("Campo obrigatório");
          break;
        case "02":
          yupValidations.chave = Yup.string().email("E-mail inválido").required("Campo obrigatório");
          break;
        case "03":
          yupValidations.chave = Yup.string().validateDocument("Documento Inválido").required("Campo obrigatório");
          break;
        default:
          break;
      }

      try {
        const schema = Yup.object().shape(yupValidations);

        await schema.validate(filteredData, {
          abortEarly: false,
        });

        const requestData: Supplier = {
          ...filteredData,
          banco: filteredData.banco.split("-")[0].trim(),
          cnpj: filteredData.cnpj.replace(/\D/g, ""),
          cep: filteredData.cep.replace(/\D/g, ""),
          chave: filteredData.chave?.replace(/\D/g, ""),
          telefone: filteredData.telefone.replace(/\D/g, ""),
          codigo_municipio: filteredData.codigo_municipio.split(" ")[0].trim(),

          pais: filteredData.pais.split(" ")[0].trim(),
          condicao_pagamento: filteredData.condicao_pagamento.split(" ")[0].trim(),
          descr_compra: filteredData.descr_compra,
        };

        await updateSupplier(requestData);

        navigate("/suppliers/");
      } catch (error: any) {
        console.log(error);

        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErros(error);
          if (formRef.current) {
            formRef.current.setErrors(errors);
          } else {
            console.log("Não foi possível enviar o formulário");
          }
          toast.warning("Por favor verifique se todos os campos foram preenchidos corretamente");

          return;
        }

        toast.error(getErrorMessage(error));
      } finally {
        setIsLoadingForm(false);
      }
    },
    [navigate, updateSupplier]
  );

  const deleteSupllier = useCallback(async (): Promise<void> => {
    try {
      await removeSupplier(selectedSupplier);
      navigate("/suppliers/");
    } catch (error: any) {
      console.log(error);
      toast.error(getErrorMessage(error));
    }
  }, [selectedSupplier, navigate, removeSupplier]);

  const loadingCitiesPerState = useCallback(
    async (selectedCity: string) => {
      setIsLoadingCities(true);
      setCitiesOptions([]);

      if (cityRef.current) {
        cityRef.current.changeValue("");
      }

      try {
        const response = await api.get("/consultapadrao", {
          params: {
            tabela: "CC2",
            campos: "CC2_EST,CC2_CODMUN,CC2_MUN",
            pesquisa: selectedCity,
            ordem: "CC2_MUN",
            procura: "CC2_EST",
            branch: userData.selectedBranch?.id || "",
          },
        });

        if (response.data.data) {
          setCitiesOptions(response.data.data.consultapadrao.map((city: any) => `${city.CC2_CODMUN} - ${city.CC2_MUN}`));
        }
      } catch (error: any) {
        console.log(error);
        toast.error(getErrorMessage(error));
      } finally {
        setIsLoadingCities(false);
      }
    },
    [userData]
  );

  const loadingCountries = useCallback(async (): Promise<void> => {
    setIsLoadingCountries(true);

    try {
      const response = await api.get("/consultapadrao", {
        params: {
          tabela: "SYA",
          campos: "YA_CODGI,YA_DESCR",
          ordem: "YA_DESCR",
          branch: userData.selectedBranch?.id || "",
        },
      });

      if (response.data.data) {
        setCountriesOptions(response.data.data.consultapadrao.map((city) => `${city.YA_CODGI} - ${city.YA_DESCR}`));
      }
    } catch (error: any) {
      console.log(error);
      toast.error(getErrorMessage(error));
    } finally {
      setIsLoadingCities(false);
    }
  }, [userData]);

  const loadBankOptions = async () => {
    setIsLoadingBanks(true);

    try {
      const banks = await getBanks();

      setBankOptions(banks.map((bank) => `${bank.id} - ${bank.name}`));
    } catch (error: any) {
      console.log(error);
      toast.error(getErrorMessage(error));
    } finally {
      setIsLoadingBanks(false);
    }
  };

  const addDocumentMask = (value: string) => {
    const filteredValue = value.replace(/\D+/g, "");

    if (filteredValue.length <= 11) {
      setDocumentMask("999.999.999-999");
    } else {
      setDocumentMask("99.999.999/9999-99");
    }
  };

  const addPhoneMask = (value: string) => {
    const filteredValue = value.replace(/\D+/g, "");

    if (filteredValue.length <= 8) {
      setPhoneMask("9999-99999");
    } else {
      setPhoneMask("99999-9999");
    }
  };

  const getPixMask = (value: string) => {
    switch (value) {
      case "01":
        setPixMask("99999-9999");
        break;
      case "03":
        setPixMask("999.999.999-999");
        break;
      default:
        setPixMask("");
    }
  };

  const handleDocumentPixMask = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (pixSelectRef.current?.getValue() === "03") {
      const filteredValue = event.target.value.replace(/\D+/g, "");

      if (filteredValue.length <= 11) {
        setPixMask("999.999.999-999");
      } else {
        setPixMask("99.999.999/9999-99");
      }
    }
  };

  const loadingPaymentsConditions = useCallback(async (): Promise<void> => {
    setIsLoadingPaymentConditions(true);

    try {
      const paymentConditions = await getPaymentConditions();
      setPaymentOptions(paymentConditions.map((options) => `${options.id} - ${options.descricao}`));
    } catch (error: any) {
      console.log(error);
      toast.error(getErrorMessage(error));
    } finally {
      setIsLoadingPaymentConditions(false);
    }
  }, [getPaymentConditions]);

  useEffect(() => {
    if (routeParams.type !== "edit" && routeParams.type !== "view" && routeParams.type !== "remove") {
      navigate("/suppliers");
    }

    if (!selectedSupplier) {
      navigate("/suppliers");
    }
  }, [routeParams, navigate, selectedSupplier]);

  useEffect(() => {
    const init = async () => {
      try {
        loadingCountries();
        loadingPaymentsConditions();
        loadBankOptions();

        const supplierInfo = await searchSupplier(selectedSupplier);

        if (routeParams.type === "edit" && supplierInfo.status !== "1") {
          toast.error("Este registro não pode ser alterado");

          navigate("/suppliers");
        }

        setSupplier(supplierInfo);
        addDocumentMask(supplierInfo.cnpj);
        addPhoneMask(supplierInfo.telefone);
        setIsLoadingPage(false);
        await loadingCitiesPerState(supplierInfo.estado);
      } catch (error: any) {
        toast.error(getErrorMessage(error));
      }
    };

    init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    Yup.addMethod(Yup.string, "validateDocument", function (errorMessage) {
      return this.test("test-document-type", errorMessage, function (value) {
        const { path, createError } = this;

        const document = value as string;
        const filteredValue = document.replace(/\D/g, "");

        if (filteredValue.length === 14) {
          return validadeCNPJ(filteredValue) || createError({ path, message: "CNPJ inválido" });
        }

        if (filteredValue.length === 11) {
          return validadeCPF(filteredValue) || createError({ path, message: "CPF inválido" });
        }

        return createError({ path, message: errorMessage });
      });
    });
  }, []);

  useEffect(() => {
    return () => selectASupplier("");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Container>
      {isLoadingPage ? (
        <LoadingCointainer>
          <CircularProgress />
        </LoadingCointainer>
      ) : (
        <Form
          ref={formRef}
          onSubmit={handleSubmit}
          initialData={{
            ...supplier,
            status: SupplierFormDefaultValue.status.find((status) => status.value === supplier.status)?.label || "---",
            codigo: supplier.codigo || "---",
            loja: supplier.loja || "---",
            condicao_pagamento: paymentOptions.find((opt) => opt.includes(supplier.condicao_pagamento)),
            banco: bankOptions.find((opt) => opt.split("-")[0].trim() === supplier.banco),
            pais: countriesOptions.find((opt) => opt.includes(supplier.pais)),
            codigo_municipio: citiesOptions.find((opt) => opt.includes(supplier.codigo_municipio)),
          }}
        >
          <FormCard title="Dados Gerais">
            <Grid container>
              <Grid item xs={6} md={3}>
                <FormInput variant="outlined" name="id" label="ID" type="text" size="small" fullWidth disabled />
              </Grid>
              <Grid item xs={6} md={3}>
                <FormInput variant="outlined" name="status" label="Status" type="text" size="small" fullWidth disabled />
              </Grid>
              <Grid item xs={12} md={3}>
                <FormInput variant="outlined" name="codigo" label="Código" type="text" size="small" fullWidth disabled />
              </Grid>
              <Grid item xs={6} md={3}>
                <FormInput variant="outlined" name="loja" label="Loja" type="text" size="small" fullWidth disabled />
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={12} md={3}>
                <FormInputMask
                  mask={documentMask}
                  name="cnpj"
                  label="CNPJ/CPF *"
                  type="text"
                  size="small"
                  fullWidth
                  onChange={(event) => addDocumentMask(event.target.value)}
                  disabled={routeParams.type === "edit" ? false : true}
                />
              </Grid>
              <Grid item xs={12} md={9}>
                <FormInput
                  variant="outlined"
                  name="nome"
                  label="Razão Social"
                  type="text"
                  size="small"
                  fullWidth
                  disabled={routeParams.type === "edit" ? false : true}
                  inputProps={{ maxLength: 40 }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <FormInput
                  variant="outlined"
                  name="fantasia"
                  label="Nome Fantasia *"
                  type="text"
                  size="small"
                  fullWidth
                  disabled={routeParams.type === "edit" ? false : true}
                  inputProps={{ maxLength: 40 }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <FormInput
                  variant="outlined"
                  name="email"
                  label="Email *"
                  size="small"
                  fullWidth
                  disabled={routeParams.type === "edit" ? false : true}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <FormInput
                  variant="outlined"
                  name="inscricao"
                  label="Incrição Estadual"
                  type="text"
                  size="small"
                  fullWidth
                  disabled={routeParams.type === "edit" ? false : true}
                  inputProps={{ maxLength: 15 }}
                />
              </Grid>
              <Grid item xs={4} md={1}>
                <FormInputMask
                  name="ddd"
                  label="DDD *"
                  type="text"
                  size="small"
                  mask="99"
                  fullWidth
                  disabled={routeParams.type === "edit" ? false : true}
                />
              </Grid>
              <Grid item xs={8} md={5}>
                <FormInputMask
                  mask={phoneMask}
                  name="telefone"
                  label="Telefone *"
                  type="text"
                  size="small"
                  fullWidth
                  onChange={(event) => addPhoneMask(event.target.value)}
                  disabled={routeParams.type === "edit" ? false : true}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <FormInput
                  variant="outlined"
                  name="contato"
                  label="Contato *"
                  type="text"
                  size="small"
                  fullWidth
                  disabled={routeParams.type === "edit" ? false : true}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <FormSelect
                  name="integra"
                  label="Integra LUFT? *"
                  size="small"
                  fullWidth
                  disabled={routeParams.type === "edit" ? false : true}
                  options={SupplierFormDefaultValue.integra}
                />
              </Grid>
              <Grid item xs={12} md={9}>
                <FormAutoComplete
                  name="condicao_pagamento"
                  label="Condição de Pagamento *"
                  options={paymentOptions}
                  loading={isLoadingPaymentConditions}
                  disabled={routeParams.type === "edit" ? false : true}
                />
              </Grid>
              <Grid item xs={12} md={12}>
                <p className="w-full text-end" style={{ marginTop: "-22px" }}>{description.length} / 250</p>
                <FormInput
                  variant="outlined"
                  name="descr_compra"
                  label="Descrição do serviço ou produto *"
                  type="text"
                  size="small"
                  onChange={(event: any) => setDescription(event.target.value)}
                  disabled={routeParams.type === "edit" ? false : true}
                  fullWidth
                  inputProps={{ maxLength: 250 }}
                />
              </Grid>
            </Grid>
          </FormCard>
          <FormCard title="Dados Bancários">
            <Grid container>
              <Grid item xs={12} md={6}>
                <FormAutoComplete
                  name="banco"
                  label="Banco *"
                  options={bankOptions}
                  loading={isLoadingBanks}
                  disabled={routeParams.type === "edit" ? false : true}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <FormInput
                  variant="outlined"
                  name="agencia"
                  label="Agência *"
                  size="small"
                  type="text"
                  fullWidth
                  disabled={routeParams.type === "edit" ? false : true}
                  inputProps={{ maxLength: 5 }}
                  value={controledPaymentInfo.agency}
                  onChange={(event: any) => {
                    const value = event.target.value.replace(/\D+/g, "");
                    setControledPaymentInfo((prev) => ({ ...prev, agency: value }));
                  }}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <FormInput
                  variant="outlined"
                  name="conta"
                  label="Conta *"
                  size="small"
                  type="text"
                  fullWidth
                  disabled={routeParams.type === "edit" ? false : true}
                  value={controledPaymentInfo.account}
                  onChange={(event: any) => {
                    const value = event.target.value.replace(/\D+/g, "");
                    setControledPaymentInfo((prev) => ({ ...prev, account: value }));
                  }}
                />
              </Grid>
              <Grid item xs={12} md={2}>
                <FormSelect
                  ref={pixSelectRef}
                  name="tipo_chave"
                  label="Tipo Chave"
                  fullWidth
                  size="small"
                  enableClearValue={routeParams.type === "edit" ? true : false}
                  disabled={routeParams.type === "edit" ? false : true}
                  onClean={() => {
                    pixRef.current?.changeValue("");
                    setPixMask("");
                  }}
                  onChange={(event: any) => {
                    pixRef.current?.changeValue("");
                    getPixMask(event.target.value);
                  }}
                  options={[
                    { label: "Telefone", value: "01" },
                    { label: "E-mail", value: "02" },
                    { label: "CPF/CNPJ", value: "03" },
                    { label: "Chave Aleatória", value: "04" },
                  ]}
                />
              </Grid>
              <Grid item xs={12} md={10}>
                <FormInputMask
                  ref={pixRef}
                  mask={pixMask}
                  name="chave"
                  label="Chave Pix"
                  size="small"
                  type="text"
                  fullWidth
                  onChange={handleDocumentPixMask}
                  disabled={routeParams.type === "edit" ? false : true}
                />
              </Grid>
            </Grid>
          </FormCard>
          <FormCard title="Endereço">
            <Grid container>
              <Grid item xs={6} md={3}>
                <FormAutoComplete
                  label="País *"
                  name="pais"
                  options={countriesOptions}
                  loading={isLoadingCountries}
                  disabled={routeParams.type === "edit" ? false : true}
                />
              </Grid>
              <Grid item xs={6} md={3}>
                <FormSelect
                  name="estado"
                  label="Estado *"
                  size="small"
                  fullWidth
                  disabled={routeParams.type === "edit" ? false : true}
                  options={SupplierFormDefaultValue.states}
                  onChange={(event) => loadingCitiesPerState(event.target.value)}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <FormAutoComplete
                  ref={cityRef}
                  label="Município *"
                  name="codigo_municipio"
                  options={citiesOptions}
                  loading={isLoadingCities}
                  disabled={routeParams.type === "edit" ? false : true}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <FormInputMask
                  label="CEP *"
                  name="cep"
                  size="small"
                  fullWidth
                  mask="99999-999"
                  disabled={routeParams.type === "edit" ? false : true}
                />
              </Grid>
              <Grid item xs={9} md={8}>
                <FormInput
                  variant="outlined"
                  label="Endereço *"
                  name="endereco"
                  size="small"
                  fullWidth
                  disabled={routeParams.type === "edit" ? false : true}
                />
              </Grid>
              <Grid item xs={3} md={1}>
                <FormInput
                  variant="outlined"
                  label="Nº *"
                  name="numero"
                  size="small"
                  fullWidth
                  type="number"
                  inputProps={{ maxLength: 5 }}
                  disabled={routeParams.type === "edit" ? false : true}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <FormInput
                  variant="outlined"
                  label="Bairro"
                  name="bairro"
                  size="small"
                  fullWidth
                  disabled={routeParams.type === "edit" ? false : true}
                />
              </Grid>
            </Grid>
          </FormCard>
          <FormActions>
            <Button type="button" size="large" variant="text" disabled={isLoadingForm} onClick={() => navigate("/suppliers/")}>
              Fechar
            </Button>
            {routeParams.type === "edit" && (
              <Button type="submit" variant="contained" color="primary" size="large" disabled={isLoadingForm}>
                {isLoadingForm ? "Aguarde" : "Gravar"}
              </Button>
            )}
            {routeParams.type === "remove" && (
              <Button type="button" variant="contained" color="error" size="large" disabled={isLoadingForm} onClick={deleteSupllier}>
                Excluir
              </Button>
            )}
          </FormActions>
        </Form>
      )}
    </Container>
  );
};

export default SupplierEdit;

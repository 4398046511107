import { IconProps } from "../../../@types/icons";

export const AwaitNFIcon: React.FC<IconProps> = ({ color, height, size }) => (
    <svg 
        width={ size || "32"} 
        height={ height || "32"}  
        color={ color }
        viewBox="0 0 32 32" 
        fill="none" 
        xmlns="http://www.w3.org/2000/svg"
    >
        <g clip-path="url(#clip0_301_1361)">
            <path d="M15.9996 0.00012207C7.16335 0.00012207 0 7.1638 0 16C0 24.8362 7.16335 31.9998 15.9996 31.9998C24.8358 31.9998 32 24.8359 32 16C31.9999 7.16348 24.8358 0.00012207 15.9996 0.00012207ZM23.1687 23.1686C23.1687 24.2423 22.2984 25.1126 21.2249 25.1126H10.775C9.70108 25.1126 8.83099 24.2423 8.83099 23.1686V8.83101C8.83099 7.75729 9.70129 6.887 10.775 6.887H18.9769V10.775H23.1687V23.1686ZM19.8069 9.94483V6.88678L23.1687 9.94483H19.8069Z" fill="#E3E309"/>
            <path d="M15.9996 0.00012207C7.16335 0.00012207 0 7.1638 0 16C0 24.8362 7.16335 31.9998 15.9996 31.9998C24.8358 31.9998 32 24.8359 32 16C31.9999 7.16348 24.8358 0.00012207 15.9996 0.00012207ZM23.1687 23.1686C23.1687 24.2423 22.2984 25.1126 21.2249 25.1126H10.775C9.70108 25.1126 8.83099 24.2423 8.83099 23.1686V8.83101C8.83099 7.75729 9.70129 6.887 10.775 6.887H18.9769V10.775H23.1687V23.1686ZM19.8069 9.94483V6.88678L23.1687 9.94483H19.8069Z" fill="#E3E309"/>
            <path d="M12.3246 17.7593L14.8571 17.7454L14.8433 20.278L14.8433 20.2782C14.8424 20.4671 14.8786 20.6542 14.9503 20.829C15.022 21.0038 15.1276 21.1626 15.2612 21.2962C15.3948 21.4298 15.5536 21.5354 15.7283 21.6071C15.9031 21.6787 16.0903 21.715 16.2792 21.714L16.2767 21.214L16.2792 21.714C16.468 21.7131 16.6548 21.675 16.829 21.6021C17.0031 21.5292 17.1614 21.423 17.2949 21.2895C17.4284 21.156 17.5346 20.9978 17.6075 20.8236C17.6803 20.6494 17.7184 20.4626 17.7194 20.2738L17.7194 20.2736L17.731 17.731L20.2736 17.7194L20.2738 17.7194C20.6551 17.7175 21.0201 17.5643 21.2895 17.2949C21.559 17.0254 21.7121 16.6604 21.7141 16.2792C21.716 15.8977 21.5661 15.5311 21.2962 15.2612C21.0262 14.9912 20.6597 14.8414 20.2782 14.8433L20.278 14.8433L17.7455 14.8571L17.7593 12.3246L17.7593 12.3244C17.7612 11.9429 17.6114 11.5763 17.3414 11.3064C17.0715 11.0364 16.7049 10.8866 16.3234 10.8885C15.9422 10.8904 15.5772 11.0436 15.3077 11.313C15.0383 11.5825 14.8851 11.9475 14.8832 12.3288L14.8832 12.329L14.8716 14.8716L12.329 14.8832L12.3288 14.8832C12.1399 14.8841 11.9532 14.9223 11.779 14.9951C11.6048 15.068 11.4465 15.1742 11.3131 15.3077C11.1796 15.4412 11.0734 15.5995 11.0005 15.7736C10.9276 15.9478 10.8895 16.1346 10.8886 16.3234C10.8876 16.5123 10.9239 16.6995 10.9955 16.8742C11.0672 17.049 11.1728 17.2078 11.3064 17.3414C11.44 17.475 11.5988 17.5806 11.7736 17.6523C11.9483 17.7239 12.1355 17.7602 12.3244 17.7593L12.3246 17.7593Z" fill="white" stroke="#E3E309"/>
        </g>
        <defs>
            <clipPath id="clip0_301_1361">
                <rect width="32" height="32" fill="white"/>
            </clipPath>
        </defs>
    </svg>
)
import React from "react"
import { useEffect, useRef, useState, forwardRef, useImperativeHandle } from "react"
import { useField } from "@unform/core"
import ReactInputMask, { Props as InputProps } from 'react-input-mask'

import { TextFieldProps } from "@mui/material"

import { CheckIcon, CloseIcon, Container, ErrorMessage, Input } from "./style"

interface FormInputProps extends Omit<InputProps, "size">{
    label: string
    name: string
    fullWidth?: boolean
    size?: "small" | "medium"
    loading?: boolean
    hiddenErrorMessage?: boolean
}

export interface FormInputRef{
    changeValue(value: string): void
    getValue(): string | unknown
}

const FormInputMask: React.ForwardRefRenderFunction<FormInputRef, FormInputProps> = ({ 
    name, 
    label,
    hiddenErrorMessage,
    children,
    fullWidth,
    size,
    loading,
    disabled,
    ...rest 
}, ref) => {

    const inputRef = useRef<TextFieldProps>(null)
    const {fieldName, registerField, defaultValue, error} = useField(name)

    const [hasAnError, setHasAnError] = useState(false)

    useImperativeHandle(ref, () => ({
        changeValue: (value) => {
            if(inputRef.current?.value){
                inputRef.current.value = value || ""
            }
        },
        getValue: () => ( inputRef.current?.value as string)
    }))

    useEffect(() => {
        registerField({
            name: fieldName,
            ref: inputRef.current,
            path: "value"
        })
    }, [fieldName, registerField])

    useEffect(() => {
        if(!!error){ setHasAnError(true) }
    }, [error])
    
    return(
        <Container>
            <ReactInputMask {...rest} disabled={ disabled } defaultValue={ defaultValue }>
                {/* @ts-ignore: Unreachable code error */}
                {(inputProps) => (
                    <Input
                        {...inputProps}
                        disabled={ disabled }
                        fullWidth={ fullWidth }
                        variant="outlined"
                        size={ size }
                        label={label}
                        $isError={ !!error }
                        $hasAnError={ hasAnError }
                        inputRef={ inputRef }
                        name={ name }
                        defaultValue={ defaultValue }
                    />
                )}
            </ReactInputMask>
            { !!error && !hiddenErrorMessage ?
                <>
                <CloseIcon />
                <ErrorMessage> { error } </ErrorMessage>
                </>
            : (!error && hasAnError) &&
                <CheckIcon />
            }
        </Container>
    )
}

export default forwardRef(FormInputMask)
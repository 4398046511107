import { Skeleton } from "@mui/material";
import styled from "styled-components";
import { fade } from "../../../../styles/animations";

interface LegendProps{
    color: string
}

export const Container = styled.div`
    width: 100%;
    height: 100vh;
    max-height: 300px;
    border-radius: 10px;
    overflow: hidden;
    background-color: ${ props => props.theme["gray-700"] };
    padding: 0px 18px;
    position: relative;
`

export const SkeletonChart = styled(Skeleton)`
    border-radius: 10px !important;
`

export const NotFoundContainer = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 18px;
    color: ${ props => props.theme["white-100"] };
    
    text-align: center;

    animation: ${ fade } 300ms;
`

export const LegendContainer = styled.div`
    position: absolute;
    bottom: 4px;
    left: 8px;

    color: ${ props => props.theme["white-100"] };
`

export const Legend = styled.div<LegendProps>`
    font-size: 12px;

    &::before{
        content: "aa";
        height: 12px;
        color: ${props => props.color};
        margin-right: 4px;
        background-color: ${props => props.color};
        border-radius: 50px;
    }
`
import React, { ReactElement } from 'react'
import { useState, useEffect, useRef } from 'react'

import { useAuth } from '../../../../hooks/auth'
import { useMenu } from '../../../../hooks/useMenu'
import { useInitialData } from '../../../../hooks/initialData'

import { getInitialLettersOnPhrase } from '../../../../utils/format/getInitialLettersOnPhrase'

import MenuTitle, { MenuTitleProps } from '../MenuTitle'
import { MenuItemProps } from '../MenuItem'
import ChangePasswordDialog from '../../../Modal/ChangePasswordDialog'
import ChangeBranchDialog from '../../../Modal/ChangeBranchDialog'
import UserMenu from '../../UserMenu'
import { MenuCategory } from '../MenuCategory'
import MenuButton from '../MenuButton'

import { 
    Button, 
    ButtonBase, 
    ClickAwayListener, 
    Collapse
} from '@mui/material'

import {
    ArrowForwardIos,
    ArrowBackIos,
    MenuRounded,
    Close,
    ExitToApp,
    Factory,
    LockOpenSharp,
    Check,
} from '@mui/icons-material'

import {
    NavMenu,
    MenuFooter,
    MenuList,
    DesktopLayout,
    MobileLayout,
    DrawerContent,
    ProfileMenu,
    MobileToggleMenu,
    MobileItem,
    MobileDrawer
} from './style'

interface MenuProps extends MenuTitleProps{
    children?: Array<ReactElement<MenuItemProps>> | ReactElement<MenuItemProps>
    showUserFooter?: boolean
    onSelectItem?: (eventKey: string) => void
    userData?: {
        username: string
        selectedBranch: string
        profileImage?: string
    }
}

const Menu: React.FC<MenuProps> = ({ 
    title, 
    image, 
    showUserFooter,
    userData,
    onSelectItem,
    children 
}) => {
    const { userData: authUser, signOut, changeBranch } = useAuth()
    const { isExpanded, toggleMenuExpanded } = useMenu()
    const { filiaisData } = useInitialData()

    const deskMenuProfileRef = useRef<HTMLDivElement>(null)

    const [expandedMenu, setExpandedMenu] = useState<boolean>(isExpanded || false)
    const [expandedMobileMenu, setExpandedMobileMenu] = useState<boolean>(isExpanded || false)

    const [showMenuProfile, setShowMenuProfile] = useState<boolean>(false)
    const [openChangePassDialog, setOpenChangePassDialog] = useState<boolean>(false)
    const [openChangeBranchDialog, setOpenChangeBranchDialog] = useState<boolean>(() => (
        localStorage.getItem("SELECTED_BRANCH") ? false : true
    ))

    const userInitialLetters = getInitialLettersOnPhrase(userData?.username || "").substring(0,2)

    const childrenWithProps = React.Children.map(children, (child) => {
        if (React.isValidElement(child)) {
            return React.cloneElement(child, { onSelect: onSelectItem } as any)
        }
        return child
    })

    const toggleMenu = () => {
        setExpandedMenu(prev => {
            if(isExpanded !== undefined){
                toggleMenuExpanded(!prev)
            }
            return !prev
        })
    }

    const toggleMobileMenu = () => {
        setExpandedMobileMenu(prev => {
            if(isExpanded !== undefined){
                toggleMenuExpanded(!prev)
            }
            return !prev
        })
    }

    const toggleUserMenu = () => {
        if(!isExpanded){
            setShowMenuProfile(prev => !prev)
        }
    }

    useEffect(() => {
        if(showUserFooter){
            if(!userData){
                throw new Error("User info must be passed if you use 'showUserFooter' prop")
            }

            if(Object.keys(userData).length <= 0){
                throw new Error("Missing porperties on userData object")
            }
        }
    },[showUserFooter, userData])

    useEffect(() => {
        setExpandedMenu(!!isExpanded)
        setShowMenuProfile(false)
        setExpandedMobileMenu(prev => !prev === !!isExpanded ? false : !!isExpanded)
    }, [isExpanded])

    return (
        <>
            <DesktopLayout>
                <Collapse 
                    in={ expandedMenu } 
                    orientation="horizontal" 
                    collapsedSize={65}
                >
                    <NavMenu expanded={ expandedMenu }>
                        <MenuTitle title={ title } image={ image } />
                        <div className='split' />
                        <MenuList>
                            { childrenWithProps }
                            { isExpanded && (
                                <MenuCategory title='Alterar Filial' icon={Factory}>
                                    { filiaisData.map(branch => (
                                        <MenuButton
                                            key={ branch.filial }
                                            action={() => { 
                                                changeBranch({
                                                    id: branch.filial,
                                                    name: branch.nome
                                                })
                                            }}
                                        >
                                            <div style={{ width: "100%", display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                                                { `${branch.filial} - ${branch.nome}` }  
                                                { authUser.selectedBranch?.id === branch.filial && (
                                                    <Check style={{ display: "flex", width: "18px", height: "18px", margin: 0 }} />
                                                )}
                                            </div>
                                        </MenuButton>
                                    )) }
                                </MenuCategory>
                            )}
                        </MenuList>
                        { showUserFooter && 
                            <>
                            <div className='split' />
                            <MenuFooter isExpanded={ !!isExpanded }>
                                <div ref={ deskMenuProfileRef } onClick={ toggleUserMenu }>
                                    { !!userData?.profileImage ? (
                                        <img data-testid="img-user" src={ userData.profileImage } alt="portal"/>
                                    ): (
                                        <h2>{ userInitialLetters }</h2>
                                    )}
                                </div>
                                { isExpanded && (
                                    <div>
                                        <p>{ userData?.username }</p>
                                        <span data-testid="change-branch" className="branch">
                                            { userData?.selectedBranch }
                                        </span>
                                        <div>
                                            <Button data-testid="change-pass" onClick={ () => setOpenChangePassDialog(true) }>
                                                Alterar senha
                                            </Button>
                                            <Button data-testid="logout" onClick={() => signOut()}>
                                                Sair
                                            </Button>
                                        </div>
                                    </div>
                                )}
                            </MenuFooter>
                            <ProfileMenu
                                data-testid="user-menu"
                                open={ showMenuProfile }
                                anchorEl={ deskMenuProfileRef.current }
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'right',
                                }}
                                transformOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'left',
                                }}
                            >
                                <ClickAwayListener onClickAway={() => setShowMenuProfile(false)}>
                                    <UserMenu
                                        onSelectOption={() => setShowMenuProfile(false)}
                                        onChangePass={() => {
                                            setOpenChangePassDialog(true)
                                            setShowMenuProfile(false)
                                        }}
                                    />
                                </ClickAwayListener>
                            </ProfileMenu>
                            </>
                        }
                    </NavMenu>
                </Collapse>
                <div className='border'>
                    { (isExpanded !== undefined) ? (
                        <button onClick={ toggleMenu }>
                            { expandedMenu ? <ArrowBackIos style={{ marginLeft: "5px" }}/> : <ArrowForwardIos /> }
                        </button>
                    ): (isExpanded === undefined && (
                        <button onClick={ toggleMenu }>
                            { expandedMenu ? <ArrowBackIos style={{ marginLeft: "5px" }}/> : <ArrowForwardIos /> }
                        </button>
                    ))}
                </div>
            </DesktopLayout>
            <MobileLayout>
                <MobileToggleMenu onClick={ toggleMobileMenu }>
                    <MenuRounded />
                </MobileToggleMenu>
                <MobileDrawer open={ expandedMobileMenu }>
                    <DrawerContent>
                        <ButtonBase onClick={ toggleMobileMenu }>
                            <Close />
                        </ButtonBase>
                        <MenuTitle title={ title } image={ image } />
                        <MenuList data-testid="mobile-title">
                            { childrenWithProps }
                            <MobileItem
                                data-testid="mobile-change-branch"
                                onClick={() => {
                                    setOpenChangeBranchDialog(true)
                                    setShowMenuProfile(false)
                                }}
                            >
                                <Factory/>
                                <p>Alterar Filial</p>
                            </MobileItem>
                            <MobileItem data-testid="mobile-change-pass" onClick={ () => setOpenChangePassDialog(true) }>
                                <LockOpenSharp/>
                                <p>Alterar Senha</p>
                            </MobileItem>
                            <MobileItem data-testid="mobile-logout" onClick={() => signOut()}>
                                <ExitToApp/>
                                <p>Sair</p>
                            </MobileItem>
                        </MenuList>
                        { showUserFooter && 
                            <>
                            <div className='split' />
                            <MenuFooter 
                                isExpanded={ !!isExpanded }
                                style={{
                                    position: "absolute",
                                    bottom: 15,
                                    left: 15
                                }}
                            >
                                <div>
                                    { !!userData?.profileImage ? (
                                        <img src={ userData.profileImage } alt="portal"/>
                                    ): (
                                        <h2>{ userInitialLetters }</h2>
                                    )}
                                </div>
                                <div>
                                    <p>{ userData?.username }</p>
                                    <p>{ userData?.selectedBranch }</p>
                                </div>
                            </MenuFooter>
                            </>
                        }
                    </DrawerContent>
                </MobileDrawer>
            </MobileLayout>
            <ChangePasswordDialog 
                open={ openChangePassDialog }
                onClose={() => setOpenChangePassDialog(false) }
            />
            <ChangeBranchDialog
                fullWidth
                maxWidth="sm"
                open={ openChangeBranchDialog }
                onClose={() => setOpenChangeBranchDialog(false) }
            />
        </>
    )
}

export default Menu
import { createContext, useState, useCallback, useContext, useEffect } from 'react'
import { toast } from 'react-toastify'

import api from "../services/api.services"

import { 
    AppImages, 
    ChangeImageProps, 
    Preferences, 
    RequestPreferencesParams, 
    ThemeContextProps 
} from '../@types/theme'

import { getErrorMessage } from '../utils/validations/getErrorMessage'

import DefaultLogo from '../assets/logithink.png' 
import DefaultIcon from '../assets/icone.jpeg' 

const ThemeContext = createContext({} as ThemeContextProps)

export const ThemeProvider: React.FC = ({ children }) => {
    const [appImages, setAppImages] = useState<AppImages>(() => ({
        home: (localStorage.getItem("HOME_LOGO")) ? (localStorage.getItem("HOME_LOGO") || undefined) : DefaultLogo,
        dash: (localStorage.getItem("DASH_LOGO")) ? (localStorage.getItem("DASH_LOGO") || undefined) : DefaultLogo,
        icon: (localStorage.getItem("ICON_LOGO")) ? (localStorage.getItem("ICON_LOGO") || undefined) : DefaultIcon
    }))

    const cleanData = useCallback((): void => {
        setAppImages({
            home: DefaultLogo,
            dash: DefaultLogo,
            icon: DefaultIcon
        })

        localStorage.getItem("ICON_LOGO")
        localStorage.removeItem("HOME_LOGO")
        localStorage.removeItem("DASH_LOGO")
    },[])

    const changeImageApp = useCallback(({ image, type }: ChangeImageProps): void => {
        switch(type){
            case "logo_home":
                localStorage.setItem("HOME_LOGO", image)
                setAppImages(prev => ({ ...prev, home: image }))
                break
            case "logo_dash":
                localStorage.setItem("DASH_LOGO", image)
                setAppImages(prev => ({ ...prev, dash: image }))
                break
            case "icon_logo":
                localStorage.setItem("ICON_LOGO", image)
                setAppImages(prev => ({ ...prev, icon: image }))
                break
            default:
                localStorage.removeItem("ICON_LOGO")
                localStorage.removeItem("DASH_LOGO")
                localStorage.removeItem("HOME_LOGO")
                throw new Error("Invalid format type, use 'home' or 'dash'")
        }
    },[])

    const savePreferences = useCallback(async ({ type, content, desc }: Preferences): Promise<void> => {

        if(!type){ throw new Error("Attribute 'type' is required") }

        if(!content){ throw new Error("Attribute 'content' is required") }

        const data: RequestPreferencesParams = {
            preferencia: {
                parametro: type,
                valor: content
            }
        }

        if(desc){
            data.preferencia.descricao = desc
        }

        try{
            await api.post("/preferencias", data, {
                headers:{
                    authorization: "d25c79cb-1823-4ed0-9250-35da7644e225"
                }
            })
        }catch(error: any){
            console.log(error)
            throw new Error(getErrorMessage(error))
        }
    },[])

    useEffect(() => {
        const getAppTheme = async () => {
            try{
                const response = await api.get("/preferencias", {
                    params: {
                        company: process.env.REACT_APP_COMPANY,
                        branch: process.env.REACT_APP_BRANCH,
                        parametro: "logo_dash,logo_home,icon_logo"
                    },
                    headers:{
                        authorization: "d25c79cb-1823-4ed0-9250-35da7644e225"
                    }
                })

                if(response.data.data){
                    if(response.data.data.preferencias.length > 0){
                        response.data.data.preferencias.forEach((preference: any) => {
                            response.data.data.preferencias.forEach((preference: any) => {
                                if(preference.parametro.trim() === "logo_dash"){
                                    localStorage.setItem("DASH_LOGO", preference.valor)
                                    setAppImages(prev => ({
                                        ...prev,
                                        dash: preference.valor,
                                    }))
                                }
        
                                if(preference.parametro.trim() === "logo_home"){
                                    localStorage.setItem("HOME_LOGO", preference.valor)
                                    setAppImages(prev => ({
                                        ...prev,
                                        home: preference.valor,
                                    }))
                                }
        
                                if(preference.parametro.trim() === "icon_logo"){
                                    localStorage.setItem("ICON_LOGO", preference.valor)
                                    setAppImages(prev => ({
                                        ...prev,
                                        icon: preference.valor,
                                    }))
                                }
                            })
                        })
                    }
                    else{
                        cleanData()
                    }
                } else{
                    cleanData()
                }

            }catch(error: any){
                console.log(error)
                cleanData()
                toast.error(getErrorMessage(error))
            }
        }
        getAppTheme()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    return (
        <ThemeContext.Provider value={{ appImages, changeImageApp, savePreferences }}>
            { children }
        </ThemeContext.Provider>
    )
}

export const useTheme = () => {
    const context = useContext(ThemeContext)

    if(!context){ throw new Error("The hook useTheme must be used inside a Theme Provider") }

    return context
}
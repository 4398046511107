import { createContext, useContext } from "react"

interface MenuContextProps {
    isExpanded: boolean
    toggleMenuExpanded: (currentValue: boolean) => void
}

export const MenuContext = createContext({} as MenuContextProps)

export const useMenu = () => {
    const context = useContext(MenuContext)

    if(!context){ throw new Error("The hook useTheme must be used inside a Menu Provider") }

    return context
}
import styled  from "styled-components"
import { lighten  } from "polished"

import { ButtonBase, FormControl } from "@mui/material"

export const Container = styled.div`
    width: 100%;
    position: relative;
`

export const GridOptionsHeader = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
`

export const Actions = styled.nav`
    display: flex;
    flex-direction: row;

    > div{
        border-bottom-right-radius: 20px;
        height: auto;
        background-color: ${props => props.theme["blue-50"]};
        z-index: 2;
        display: flex;
        align-items: center;
        gap: 8px;
        padding: 0px 14px;

        svg{
            color: ${ props => props.theme["blue-500"] } !important;
        }
    }

    > button{
        border: none;
        height: auto;
        padding: 8px;
        border-radius: 10px 10px 0px 0px;
        background-color: ${props => props.theme["green-700"] };
        color: ${ props => props.theme["white-100"] };
        position: relative;
        transition: 200ms;

        &::before{
            content: "";
            position: absolute;
            width: 20px;
            height: 20px;
            background-color: ${props => props.theme["green-700"] };
            left: -20px;
            bottom: 0;
            transition: 200ms;
            z-index: 1;
        }

        &:hover{
            background-color: ${ props => lighten(0.1, props.theme["green-700"])};

            &::before{
                background-color: ${ props => lighten(0.1, props.theme["green-700"])}; 
            }
        }

        &:active{
            background-color: ${ props => lighten(0.2, props.theme["green-700"])};

            &::before{
                background-color: ${ props => lighten(0.2, props.theme["green-700"])}; 
            }
        }
    }

    @media(max-width: 1200px){
        width: 100%;
        justify-content: flex-end;
        padding-bottom: 16px;
        background-color: ${ props => props.theme["blue-50"] };

        > div{
            border-radius: 0px;
        }

        > button{
            border-radius: 10px;
            &::before{
                content: "";
                display: none;
            }
        }
    }
`

export const ActionButton = styled(ButtonBase)`
    padding: 4px !important;
    border-radius: 5px !important;
`

export const FormField = styled(FormControl)`
    .MuiFormLabel-root{
        margin-top: -8px;
    }

    .MuiInputLabel-shrink{
        margin-top: 0px;
    }
`
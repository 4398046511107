import React from 'react'

import { 
    createContext, 
    useCallback, 
    useContext, 
    useState
} from 'react'

import { 
    LoginProps, 
    AuthInfo, 
    UserData, 
    AuthContextProps, 
    Branch, 
    ChangePass 
} from '../@types/auth'

import api from '../services/api.services'

import { getErrorMessage } from '../utils/validations/getErrorMessage'
import { queryClient } from '../services/queryClient'

const AuthContext = createContext({} as AuthContextProps)

export const AuthProvider: React.FC = ({ children }) => {
    const [userData, setUserData] = useState<UserData>(() => (
        sessionStorage.getItem("3467333a-0dfc-45d5-800a-b08eaa0d5ec1Token")? ({
            id: sessionStorage.getItem("USER_ID") || undefined,
            idErp: sessionStorage.getItem("USER_ID_ERP") || undefined,
            username: sessionStorage.getItem("USER_NAME") || undefined,
            token: sessionStorage.getItem("3467333a-0dfc-45d5-800a-b08eaa0d5ec1Token") || undefined,
            selectedBranch: JSON.parse(localStorage.getItem("SELECTED_BRANCH") || "null") || undefined,
            permissions: JSON.parse(sessionStorage.getItem("USER_PERMISSIONS") || "[]") || undefined,
            approver: JSON.parse(sessionStorage.getItem("APP") || "false")
        }):({} as UserData)
    ))

    const cleanStorage = (): void => {
        localStorage.removeItem("USER_ID")
        localStorage.removeItem("USER_ID_ERP")
        localStorage.removeItem("USER_NAME")
        localStorage.removeItem("3467333a-0dfc-45d5-800a-b08eaa0d5ec1Token")
        sessionStorage.clear()
    }

    const authenticationUser = useCallback(async (user: LoginProps): Promise<AuthInfo> => {
        try{
            const response = await api.post("/pttoken", {
                autenticacao: {
                    username: user.email,
                    password: user.password,
                    grant_type: "password"
                }
            })

            if(response.data.status.status !== 200){
                throw new Error(response.data.status.descricao)
            }

            setUserData({
                id: response.data.usuario.id,
                idErp: response.data.usuario.id_erp,
                username: `${response.data.usuario.nome} ${response.data.usuario.sobrenome}`,
                token: response.data.data.access_token,
                permissions: response.data.usuario.permissao.split(","),
                approver: response.data.usuario.aprovador,
                selectedBranch: JSON.parse(localStorage.getItem("SELECTED_BRANCH") || "null") || undefined
            })

            sessionStorage.setItem("USER_ID", response.data.usuario.id);
            sessionStorage.setItem("USER_ID_ERP", response.data.usuario.id_erp);
            sessionStorage.setItem("USER_NAME", `${response.data.usuario.nome} ${response.data.usuario.sobrenome}`);
            sessionStorage.setItem("3467333a-0dfc-45d5-800a-b08eaa0d5ec1Token", response.data.data.access_token);
            sessionStorage.setItem("USER_PERMISSIONS", JSON.stringify(response.data.usuario.permissao.split(",")))
            sessionStorage.setItem("APP", JSON.stringify(response.data.usuario.aprovador))
            sessionStorage.setItem("NEED_CHANGE", JSON.stringify(response.data.usuario.troca_senha))

            return ({
                status: "success",
                description: "Usuário logado com sucesso",
                changePass: response.data.usuario.troca_senha,
                token: response.data.data.access_token
            })

        }catch(error: any){
            cleanStorage()

            return ({
                status: "failed",
                description: getErrorMessage(error)
            })
        }
    },[])

    const signOut = useCallback((): boolean => {
        try{
            cleanStorage()
            queryClient.clear()
            setUserData({} as UserData)

            return true
        } catch(error){
            console.log(error)
            return false
        }
    },[])

    const verifyToken = useCallback(async(): Promise<boolean> => {
        if(!userData.token){
            setUserData({} as UserData)
            return false
        }

        try{
            const response = await api.get(`/cktoken`, {
                params: {
                    token: userData.token,
                    cempproc: "01",
                    cfilproc: "0101"
                }
            })
    
            if (!response.data) {
                cleanStorage()
                setUserData({} as UserData)
                return false
            }
    
            if (response.data.status) {
                switch(response.data.status.status){
                    case 401:
                        cleanStorage()
                        setUserData({} as UserData)

                        return false
                    case 200:
                        return true
                    default:
                        setUserData({} as UserData)
                        cleanStorage()

                        return false
                }
            } else{
                cleanStorage()
                setUserData({} as UserData)
                return false
            }
        } catch(error: any){
            console.log(error)
            cleanStorage()
            setUserData({} as UserData)

            return false
        }

        
    },[userData.token])

    const changeBranch = useCallback((branch: Branch): void => {
        setUserData(prev => ({
            ...prev,
            selectedBranch: branch
        }))

        localStorage.setItem("SELECTED_BRANCH", JSON.stringify(branch))
    },[])

    const changePass = useCallback(async({ currentPass, newPass }: ChangePass): Promise<string> => {
        try{    
            const response = await api.post("/changepass/",{
                changepass: {
                    usuario: userData.id,
                    senhaatual: currentPass,
                    novasenha: newPass
                }
            },{
                params: { branch: userData.selectedBranch?.id || "" }
            })
    
            sessionStorage.removeItem("NEED_CHANGE")

            setUserData(prev => ({
                ...prev,
                needChangesPass: false
            }))

            return response.data.status.descricao
    
        } catch(error: any){
            console.log(error)
            throw error
        }
    },[userData])

    return(
        <AuthContext.Provider value={{ userData, authenticationUser, signOut, verifyToken, changeBranch, changePass }}>
            { children }
        </AuthContext.Provider>
    )
}

export const useAuth = () => {
    const context = useContext(AuthContext)

    if(!context) throw new Error("the hook useAuth must be used inside a Auth Provider")

    return context
}
import { useState, useEffect } from "react"
import { ApexOptions } from "apexcharts"
import Chart from 'react-apexcharts'

import { Container, NotFoundContainer, SkeletonChart } from './style'
import { ChartLocales } from "../../../../data/chartLocales"
import { DefaultTheme } from "../../../../themes/defaultTheme"
import { formatValue } from "../../../../utils/format/formatValue"

interface ResumeBrandChartProps{
    subtitle: string
    title: string
    labels: string[]
    values: Array<string | number>
    width?: number
    height?: number
    isLoading?: boolean
    isSelected?: boolean
    onSelectCard?: () => void
}

const defaultOptions: ApexOptions = {
    theme: {
        mode: "dark"
    },
    chart: {
        defaultLocale: 'pt-br',
        locales: ChartLocales,
        group: 'sparklines',
        sparkline: {
            enabled: true
        },
    },
    stroke: {
        curve: 'straight'
    },
    fill: {
        opacity: 1,
    },
    colors: [DefaultTheme["yellow-500"]],
    yaxis: { 
        min: 0,
        labels: {
            formatter: (value) => isNaN(value) 
                ? value.toString()
                : formatValue(value)
        }
    },
    xaxis: { type: 'datetime' }
}

export const ResumeBrandChart: React.FC<ResumeBrandChartProps> = ({ 
    subtitle, 
    labels, 
    title, 
    values,
    height,
    width,
    isLoading,
    isSelected,
    onSelectCard
}) => {

    const [options, setOptions] = useState<ApexOptions>(defaultOptions)
    const [series, setSeries] = useState<any[]>([])

    const brand = title.split("-")[0]?.trim()

    useEffect(() => {
        setOptions(prev => ({
            ...prev,
            labels: labels,
            title: {
                text: title,
                offsetX: 5,
                style: { fontSize: '14px' }
            },
            subtitle: {
                text: subtitle,
                offsetX: 5,
                style: { fontSize: '12px' }
            }
        }))
    },[subtitle, title, labels])

    useEffect(() => {
        setSeries([{
            name: "% Atingida",
            data: values || []
        }])
    },[values])

    if(isLoading){
        return (
            <Container>
                <SkeletonChart 
                    variant="rectangular" 
                    height={height ? height + 16 : 176} 
                    width={width}
                    animation="wave"
                    style={{ marginTop: "-16px" }}
                />
            </Container>
        )
    }

    if(values.length <= 0){
        return (
            <Container 
                width={ width } 
                height={ height ? height + 16 : undefined }
                isSelected={ isSelected }
                onClick={() => {
                    if(onSelectCard){
                        onSelectCard()
                    }
                }}
            >
                <NotFoundContainer>
                    <h1>Nenhum Resultado encotrado</h1>
                    <p>{ brand }</p>
                </NotFoundContainer>
            </Container>
        )
    }

    return (
        <Container 
            width={ width }
            isSelected={ isSelected }
            onClick={() => {
                if(onSelectCard){
                    onSelectCard()
                }
            }}
        >
            <Chart 
                type="area"
                height={height || 160}
                width={width}
                options={options}
                series={series}
            />
        </Container>
    )
}
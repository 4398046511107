import { forwardRef } from 'react'
import { ButtonProps } from "@mui/material"
import { MuiCustomButton } from "./styled"

const ButtonBase: React.ForwardRefRenderFunction<HTMLButtonElement,ButtonProps> = ({ children, ...props }, ref) => {
    return (
        <MuiCustomButton { ...props } ref={ ref }>
            { children }
        </MuiCustomButton>
    )
}

export const Button = forwardRef(ButtonBase)
import styled from "styled-components"
import { fade } from '../../../styles/animations'

export const Container = styled.div`
    width: 100%;
    animation: ${ fade } 200ms;

    .MuiGrid-item{
        padding: 15px 7px !important;
    }
`

export const FormActions = styled.div`
    width: 100%;
    gap: 12px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;

    button{
        width: 100%;
        max-width: 300px;
    }

    @media(max-width: 750px){
        flex-direction: column-reverse;
        justify-content: center;

        button{
            max-width: 100%;
        }
    }
`

export const LoadingCointainer = styled.div`
    width: 100%;
    height: 70vh;
    display: flex;
    justify-content: center;
    align-items: center;
`
import React from 'react'
import { createContext, useContext, useCallback , useState } from 'react'

import api from '../services/api.services'

import { Filiais, InitialDataContextProps } from '../@types/initialData'

import { getErrorMessage } from '../utils/validations/getErrorMessage'
import { toast } from 'react-toastify'

const InitialDataContext = createContext({} as InitialDataContextProps)

export const InitialDataProvider: React.FC = ({ children }) => {
    const [filiaisData, setFiliaisData] = useState<Array<Filiais>>(() => sessionStorage.getItem("FILIAIS") ?
        JSON.parse(sessionStorage.getItem("FILIAIS") || "")
    : [])

    const getData = useCallback(async (token) => {
        try{
            const response = await api.get("/filial", {
                headers: { authorization: token }
            })

            if(response.data.status.status !== 200){
                toast.info(response.data.status.descricao)
                return
            }

            setFiliaisData(response.data.data.filial)
            sessionStorage.setItem("FILIAIS", JSON.stringify(response.data.data.filial))
        }catch(error: any){
            console.log(error)
            toast.error(getErrorMessage(error))
        }
    }, [])

    return(
        <InitialDataContext.Provider value={{ filiaisData, getData }}>
            { children }
        </InitialDataContext.Provider>
    )
}

export const useInitialData = () => {
    const context = useContext(InitialDataContext)

    if(!context)
        throw new Error("The hook useInitialData must be used inside a InitialDataProvider")

    return context
}
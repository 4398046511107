import React from 'react'
import { useState, useCallback } from 'react'
import filesize from 'filesize'
import { toast } from 'react-toastify'

import { removeSymbolsOnString } from '../../../utils/format/removeSymbolsOnString'

import Upload from '../../DropZone'
import OptionsButtons from '../../Buttons/OptionsButtons'
import { Button } from '../../Buttons/Button'

import { 
    Dialog,
    DialogProps
} from '@mui/material'

import { 
    Close as CloseIcon,
    Delete as TrashIcon
} from '@mui/icons-material' 

import { DialogTitleCustom } from '../../../styles/globalStyles'

import {
    Content,
    FileList, 
    FileCard, 
    Actions,
} from './style'

export interface UploadedFile{
    file: File
    name: string,
    filename: string
    readableSize: string
}

interface AttachDialogProps extends DialogProps{
    isLoading?: boolean
    enableMultiSelection?: boolean
    onUpload: (files: Array<UploadedFile>) => void | Promise<void>
}

const AttachDialog: React.FC<AttachDialogProps> = ({ 
    open, 
    maxWidth,
    fullWidth,
    isLoading,
    enableMultiSelection,
    onClose,
    onUpload,
    ...rest
}) => {

    const [uploadedFiles, setUploadedFiles] = useState<Array<UploadedFile>>([])
    
    const selectFile = useCallback((files: Array<File>): void => {
        const submitedFiles: Array<UploadedFile> = files.map(file => ({
            file: file,
            name: file.name,
            filename: file.name,
            readableSize: filesize(file.size)
        }))

        const uploadedFilesLength = uploadedFiles.length
        const submitedFilesLength = submitedFiles.length

        if(uploadedFilesLength === 10){
            toast.info("Só é permitido anexar 10 arquivos por upload")
            return
        }

        if((submitedFilesLength > 10) || ((submitedFilesLength + uploadedFilesLength) > 10)){
            toast.info("Só é permitido anexar 10 arquivos por upload")
        }

        if(!!enableMultiSelection){
            const itensToAdd = uploadedFilesLength - 10
            const indexToCompare = itensToAdd < 0 ? (itensToAdd * -1) : itensToAdd
    
            setUploadedFiles(prev => [...prev, ...submitedFiles.filter((item, index) => (index <= (indexToCompare - 1)))])
        } else{
            setUploadedFiles([submitedFiles[0]])
        }

    },[uploadedFiles, enableMultiSelection])

    const removeSelectedFile = useCallback((file: UploadedFile) => {
        setUploadedFiles(prev => prev.filter(item => item !== file))
    },[])

    const onChangeFilename = useCallback((selectedFile: UploadedFile, value: string): void => {
        setUploadedFiles(prev => prev.map(file => {
            const nameInArray = file.name.split(".")
            const fileNameInArray = value.substring(0,100).split(".")
            const fileExtendsion = nameInArray[nameInArray.length - 1].trim()

            let filteredName = ""

            if(value.length > 100){
                if(fileNameInArray[fileNameInArray.length - 1].trim() === fileExtendsion){
                    filteredName = value.substring(0,100)
                } else{
                    filteredName = `${value.substring(0,100)}.${fileExtendsion}`
                }
            } else{
                if(fileNameInArray[fileNameInArray.length - 1].trim() === fileExtendsion){
                    filteredName = value
                } else{
                    filteredName = `${value}.${fileExtendsion}`
                }
            }

            if(file === selectedFile){
                return({
                    ...file,
                    filename: removeSymbolsOnString(filteredName)
                })
            } else{
                return file
            }
        }))
    },[])

    return(
        <Dialog
            { ...rest }
            open={ open }
            onClose={ onClose }
            maxWidth={ maxWidth || false }
            fullWidth={ fullWidth }
        >
            <DialogTitleCustom>
                Adicionar Anexo
                <button onClick={ onClose as any }><CloseIcon/></button>
            </DialogTitleCustom>
            <Content>
                <Upload height="200px" onUpload={ selectFile } accept="application/pdf" multiple={ !!enableMultiSelection }/>
                <FileList>
                    { uploadedFiles.length > 0 ? (
                        uploadedFiles.map((file, index) => (
                            <FileCard key={ index }>
                                <div>
                                    <strong
                                        role="textbox" 
                                        contentEditable
                                        suppressContentEditableWarning={true}
                                        onInput={(event: any) => { onChangeFilename(file, event.target.innerHTML) }}
                                    >
                                        { file.name } 
                                    </strong>
                                    <span>{ file.readableSize }</span>
                                </div>
                                <div>
                                    <OptionsButtons
                                        action="remove"
                                        type="button"
                                        title="Remover"
                                        onClick={() => removeSelectedFile(file) }
                                        icon={ TrashIcon }
                                    />
                                </div>
                            </FileCard>
                        ))
                    ): (
                        <p>Nenhum arquivo selecionado</p>
                    )}
                </FileList>
                <Actions>
                    <Button
                        color="primary"
                        size="large"
                        variant="text"
                        disabled={ isLoading }
                        onClick={ onClose as any }
                    >
                        Fechar
                    </Button>
                    <Button
                        color="primary"
                        size="large"
                        variant="contained"
                        disabled={ isLoading }
                        onClick={ async() => {
                            await onUpload(uploadedFiles)
                            setUploadedFiles([])
                        }}
                    >
                        Salvar
                    </Button>
                </Actions>
            </Content>
        </Dialog>
    )
}

export default AttachDialog
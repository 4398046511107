import { useCallback, useRef, useState } from "react"
import { validate } from "uuid"
import { useReactToPrint } from "react-to-print"
import { useMediaQuery, useTheme as useMuiTheme } from "@mui/material"
import { toast } from "react-toastify"

import { BuyOrder } from "../../../../../@types/buyOrder"

import api from "../../../../../services/api.services"

import { useAuth } from "../../../../../hooks/auth"
import { OcStatus, useBuyOrder } from "../../../../../hooks/buyOrders"
import { useBilling } from "../../../../../hooks/billing"

import { getErrorMessage } from "../../../../../utils/validations/getErrorMessage"
import { OCFormDefaultValue } from "../../../../../data/formValues"

import ListMobileCard, { OptionsMobileCard } from "../../../../../components/Mobile/ListMobileCard"
import PrintOC from "../PrintOC"
import { DotOption, DotOptions } from "../../../../../components/Buttons/DotOptions"

import { DataGrid, GridColumns, ptBR } from "@mui/x-data-grid"
import { Stack, Tooltip } from "@mui/material"

import { 
    AddTask, 
    AttachFile, 
    Close, 
    CurrencyExchange, 
    Edit, 
    Gavel, 
    Print, 
    Receipt, 
    Visibility 
} from "@mui/icons-material"

import { Container, GridContainer } from "./style"
import { useNavigate } from "react-router-dom"
import DialogSubtitles from "../../../../../components/Modal/DialogSubtitles"

interface RequestsGridProps {
    gridData: BuyOrder[]
    currentPage: number
    totalRows: number
    isLoading: boolean
    selectCategory?: string
    onImplements: () => void
    onCancel: (buyOrder: BuyOrder) => void
    onPageChange: (newPage: number) => void
    onAttach: () => void
    onVerify: (action: "add" | "aprove") => void
    onStartBillingProcess: () => void
}

const RequestsGrid: React.FC<RequestsGridProps> = ({
    isLoading, 
    onImplements, 
    onStartBillingProcess, 
    onVerify,
    onAttach,
    onPageChange,
    onCancel,
    currentPage,
    totalRows,
    gridData,
    selectCategory
}) => {

    const navigate = useNavigate()
    
    const { userData } = useAuth()
    const { selectAnOrder } = useBuyOrder()
    const { getPaymentConditions } = useBilling()

    const muiTheme = useMuiTheme()
    const isMobileView = useMediaQuery(muiTheme.breakpoints.down("lg"))

    const printRef = useRef<HTMLDivElement>(null)

    const [dataToPrint, setDataToPrint] = useState<BuyOrder>({} as BuyOrder)
    const [isLoadingPrinting, setIsLoadingPrinting] = useState<boolean>(false)

    const [openDialogSubtitleStatus, setOpenDialogSubtitleStatus] = useState<boolean>(false)
    const [openDialogSubtitleStatusNF, setOpenDialogSubtitleStatusNF] = useState<boolean>(false)
    
    const handlePrint = useReactToPrint({
        content: () => printRef.current
    })

    const prepareDataToPrint = useCallback(async(currentOrder: BuyOrder) => {

        if(isLoadingPrinting){
            return
        }

        setDataToPrint({} as BuyOrder)
        setIsLoadingPrinting(true)
        toast.info("Iniciando Processo de Impressão, por favor aguarde.")

        try{
            const { documentos } = currentOrder

            let documentsRegisty: any[] = []

            const responsedocumentsRegisty = await api.get("/retnotasxoc", {
                params: {
                    ordemcompra: currentOrder.id,
                    branch: userData.selectedBranch?.id
                }
            })

            if(responsedocumentsRegisty.data){
                documentsRegisty = responsedocumentsRegisty.data.data?.documents || []
            }

            //verificar cnpj emit
            const responseValidations = await api.post("/divergOCxNF", {
                oc: currentOrder.id,
                documents: documentsRegisty.map(document => ({
                    idNF: validate(document.idNF || "") ? "" : document.idNF,
                    number: document.number,
                    serie: document.serie,
                    type: document.type,
                    value: document.value,
                    issueDate: document.issueDate,
                    validDate: document.validDate,
                    cnpj_emit: document.cnpj_dest || "",
                    associatedItens: document.associatedItens?.map(item => ({
                        item: item.itemOC,
                        qtd: item.qtd,
                        unit_value: item.unit_value,
                        total_value: item.total_value,
                        partial: false
                    }))
                }))
            }, {
                params: { branch: userData.selectedBranch?.id || "" }
            })

            //verificar para imprimir sem as validações
            
            if(!responseValidations.data.data){
                throw new Error("Falha ao carregar validações")
            }
            
            const { validacoes } = responseValidations.data.data

            const payments = await getPaymentConditions()

            const data: BuyOrder = {
                ...currentOrder,
                condicao_pagamento: payments.find(item => item.id === currentOrder.condicao_pagamento.trim())?.descricao || currentOrder.condicao_pagamento,
                documentos: documentos.map(document => {
                    return {
                        ...document,
                        itens: document.itens?.map((item: any) => {
                            const currentItem = currentOrder.itens?.find(ocItem => ocItem.item === item)
    
                            return currentItem
                        }),
                        validacoes: validacoes.find(validation => validation.numnot.trim() === document.numero_nf.trim())?.itens || []
                    }
                }) as any
            }
            setDataToPrint(data)

        } catch(error: any){
            console.log(error)
            throw error
        } finally{
            setIsLoadingPrinting(false)
        }
    },[userData, isLoadingPrinting, getPaymentConditions])

    const printOC = useCallback(async(info: BuyOrder) => {
        try{
            await prepareDataToPrint(info)
            setTimeout(() =>{
                handlePrint()
            },250)
        } catch(error: any){
            toast.error(getErrorMessage(error))
        }
    },[prepareDataToPrint, handlePrint])

    // editar aqui
    const containsAprovePermissions = useCallback((info: BuyOrder): boolean => {
        const orderStatus = Number(info.status)

        const isFirstAprover = (
            info.aprovadores.aprovador1?.id === userData.id || 
            info.aprovadores.aprovador1?.Substitutos.some(user => user.id === userData.id)
        )

        const isSecondAprover = (
            info.aprovadores.aprovador2?.id === userData.id || 
            info.aprovadores.aprovador2?.Substitutos.some(user => user.id === userData.id)
        )

        const isThirdAprover = (
            info.aprovadores.aprovador3?.id === userData.id || 
            info.aprovadores.aprovador3?.Substitutos.some(user => user.id === userData.id)
        )

        const isCostCenterAprover = info.aprovadores.aprovador_ccusto.some(user => (
            user.id === userData.id ||
            user.Substitutos.some(subUser => subUser.id === userData.id)
        ))

        if(orderStatus === OcStatus.APROVE && info.codigo_solicitante !== userData.id){
            const hasThirdAprover = !!info.aprovadores.aprovador3
            const isAprovedForFirstAprover = !!info.hitorico_aprov.aprovador1
            const isAprovedForSecondAprover = !!info.hitorico_aprov.aprovador2

            if(hasThirdAprover){
                if(!isAprovedForFirstAprover && isFirstAprover){
                    return true
                }

                if(!isAprovedForSecondAprover && isSecondAprover){
                    return true
                }

                if(isAprovedForSecondAprover && isThirdAprover){
                    return true
                }
            } else{
                if(!isAprovedForFirstAprover && isFirstAprover){
                    return true
                }

                if(isAprovedForFirstAprover && isSecondAprover){
                    return true
                }
            }
        }

        if(orderStatus === OcStatus.APROVE_COAST_CENTER && isCostCenterAprover){
            let subAproverInfo: any
            info.aprovadores.aprovador_ccusto.forEach(aprover => {
                const subAprover = aprover.Substitutos.find(subAprover => subAprover.id === userData.id)
                if(subAprover){
                    subAproverInfo = {
                        ...subAprover
                    }
                }
            })
    
            const aproverInfo = subAproverInfo ? ({ 
                ...subAproverInfo, 
                centro_custo: info.aprovadores.aprovador_ccusto
                    .filter(aprover => aprover.Substitutos.some(subUser => subUser.id === userData.id))
                    .reduce((accumulator, currentValue) => {
                        if(!currentValue.centro_custo){
                            return accumulator
                        }

                        return [...accumulator, ...currentValue.centro_custo]
                    },[] as any)
            }) : info.aprovadores.aprovador_ccusto.find(aprover => aprover.id === userData.id)

            const hasItensToAprove = aproverInfo.centro_custo.some(item => (
                item.status_aprov !== "A" &&
                item.status_aprov !== "R"
            ))
                
            return hasItensToAprove
        }

        return false
    },[userData])

    const containsEditPermissions = useCallback((info: BuyOrder): boolean => {
        const orderStatus = Number(info.status)

        if(orderStatus === OcStatus.MAINTENER && info.codigo_solicitante === userData.id){
            return true
        }

        return false
    },[userData])

    const canVerifyDocument = useCallback((info: BuyOrder): boolean => {
        if(Number(info.status) === OcStatus.APROVED || Number(info.status) === OcStatus.PARTIAL_BILLING_PROCESS){
            if(userData.id === info.codigo_solicitante){ 
                return true
            }

            const firstAprover = info.aprovadores.aprovador1?.Substitutos.find(
                user => user.id === userData.id
            ) || info.aprovadores.aprovador1

            if(
                userData.id === firstAprover?.id && 
                info.status_diver_nfxoc === "6"
            ){
                return true
            }
        }

        return false
    },[userData.id])

    /* const isAproverLogged = useCallback((approverrId?: string): "add" | "aprove" => {
        if(userData.id === approverrId){
            return "aprove"
        } else{
            return "add"
        }
    },[userData.id]) */

    const generateActionsButton = (props: BuyOrder) => {
        const ocStatus = Number(props.status)
        let options: DotOption[] = []

        if(userData.permissions?.includes("ANEVOC")){
            options = [{
                eventKey: "attach",
                label: "Anexo",
                icon: AttachFile, 
                action: () => {
                    selectAnOrder(props)
                    onAttach()
                }
            }]
        }

        if(Number(props.status) > 2){
            options = [...options, {
                label: "Imprimir",
                eventKey: "print",
                icon: Print, 
                action: () => { printOC(props) }
            }]
        }
        
        if(containsEditPermissions(props)){
            options = [...options,
                {
                    label: "Efetivar",
                    eventKey: "aprove", 
                    icon: AddTask, 
                    action: () => {
                        selectAnOrder(props)
                        onImplements()
                    }
                },
                {
                    label: "Vizualizar", 
                    eventKey: "view", 
                    icon: Visibility, 
                    action: () => { 
                        selectAnOrder(props)
                        navigate("/buy-orders/details/view")
                    } 
                },
                {
                    label: "Editar", 
                    eventKey: "edit", 
                    icon: Edit, 
                    action: () => { 
                        selectAnOrder(props) 
                        navigate("/buy-orders/details/edit")
                    } 
                },
                { 
                    label: "Remover",
                    eventKey: "remove", 
                    icon: Close, 
                    action: () => { 
                        selectAnOrder(props) 
                        navigate("/buy-orders/details/remove")
                    } 
                }
            ]
        }

        /* if(
            ocStatus !== OcStatus.MAINTENER &&
            ocStatus !== OcStatus.CANCELED &&
            ocStatus !== OcStatus.BILLING_PROCESS &&
            ocStatus !== OcStatus.REPROVED &&
            ocStatus !== OcStatus.PARTIAL_BILLING_PROCESS &&
            (
                props.codigo_solicitante === userData.id ||
                userData.permissions?.includes("FINANC")
            )
        ){
            options = [...options,
                {
                    label: "Editar", 
                    eventKey: "edit", 
                    icon: Edit, 
                    action: () => { 
                        selectAnOrder(props) 
                        navigate("/buy-orders/details/edit")
                    } 
                }
            ]
        } */

        if(containsAprovePermissions(props)){
            options = [...options,
                {
                    label: "Aprovar",
                    eventKey: "aprove", 
                    icon: Gavel, 
                    action: () => { 
                        selectAnOrder(props) 
                        navigate("/buy-orders/details/aprove")
                    } 
                }
            ]
        }

        const isFirstAprover = (
            props.aprovadores.aprovador1?.id === userData.id || 
            props.aprovadores.aprovador1?.Substitutos.some(user => user.id === userData.id)
        )

        if(canVerifyDocument(props)) {
            options = [...options, {
                label: (isFirstAprover && props.status_diver_nfxoc === "6") ? "Reaprovar" : "Verificar NF",
                eventKey: "aprove",
                icon: Receipt, 
                action: () => { 
                    selectAnOrder(props)
                    onVerify(isFirstAprover ? "aprove" : "add")
                }
            }]
        }

        if(ocStatus === OcStatus.APROVED) {
            if((props.status_diver_nfxoc === "2" || props.status_diver_nfxoc === "3") && userData.permissions?.includes("FINANC")){
                options = [...options, {
                    label: "Iniciar Fluxo Financeiro",
                    eventKey: "startBilling", 
                    icon: CurrencyExchange, 
                    action: () => { 
                        selectAnOrder(props)
                        onStartBillingProcess()
                    }
                }]
            }
        }

        if(ocStatus !== OcStatus.MAINTENER /* && ocStatus !== "await" */){
            options = [...options, {
                label: "Vizualizar",
                eventKey: "view", 
                icon: Visibility, 
                action: () => { 
                    selectAnOrder(props)
                    navigate("/buy-orders/details/view") 
                } 
            }]
        }

        if(ocStatus === OcStatus.APROVED){
            const aprover = props.aprovadores.aprovador1
            const canCancelRequest = (
                aprover?.id === userData.id ||
                aprover?.Substitutos.some(subAprover => subAprover.id === userData.id)
            )

            if(canCancelRequest){
                options = [...options, {
                    label: "Cancelar",
                    eventKey: "cancel", 
                    icon: Close, 
                    action: () => {
                        onCancel(props)
                    } 
                }]
            }
        }

        return options
    }

    const renderStatusIcon = useCallback((props: BuyOrder, type: "request" | "document") => {
        switch(type){
            case "document":
                const currentNFStatus = OCFormDefaultValue.statusNF.find(item => item.value === props.status_diver_nfxoc)
        
                if(!currentNFStatus){
                    return ""
                }
        
                const { Icon: NFIcon, label: NFLabel } = currentNFStatus
        
                return (
                    <Tooltip title={ NFLabel }>
                        <button 
                            type="button"
                            style={{ cursor: "pointer", border: "none", backgroundColor: "transparent", outline: "none" }} 
                            onClick={() => setOpenDialogSubtitleStatusNF(true)}
                        >
                            <NFIcon/>
                        </button>
                    </Tooltip>
                )
            case "request":
                const currentStatus = OCFormDefaultValue.status.find(item => item.value === props.status)
        
                if(!currentStatus){
                    return ""
                }
        
                const { Icon, label } = currentStatus
        
                return (
                    <Tooltip title={ label }>
                        <button 
                            type="button"
                            style={{ cursor: "pointer", border: "none", backgroundColor: "transparent", outline: "none" }} 
                            onClick={() => setOpenDialogSubtitleStatus(true)}
                        >
                            <Icon/>
                        </button>
                    </Tooltip>
                )
            default: return ""
        }
    },[])

    const renderGridName = (name: string) => {
        if(!name){
            return ""
        }
        
        const nameWords = name.split(" ")

        return (
            <p>
                {`${ nameWords[0] } ${ nameWords[1]?.slice(0,1).toLocaleUpperCase() }`}
            </p>
        )
    }

    const renderProviderName = (name: string) => (
        <Tooltip title={ name }>
            <p>{ name }</p>
        </Tooltip>
    )

    const generateOptionsArrayMobile = (info: BuyOrder): Array<any> => {
        const ocStatus = Number(info.status)
        let options: Array<OptionsMobileCard> = []

        if(userData.permissions?.includes("ANEVOC")){
            options = [{
                title: "Anexo",
                type: "button", 
                action: "attach",
                icon: AttachFile, 
                onSelect: onAttach
            }]
        }

        if(Number(info.status) > 2){
            options = [...options, {
                title: "Imprimir",
                type: "button", 
                action: "print",
                icon: Print, 
                onSelect: () => { printOC(info) }
            }]
        }
        

        if(containsEditPermissions(info)){
            options = [...options,
                {
                    title: "Efetivar",
                    type: "button",
                    action: "aprove", 
                    icon: AddTask, 
                    onSelect: () => {
                        selectAnOrder(info)
                        onImplements()
                    }
                },
                {
                    title: "Vizualizar",
                    type: "link", 
                    link: "/buy-orders/details/view", 
                    action: "view", 
                    icon: Visibility, 
                    onSelect: () => { selectAnOrder(info) } 
                },
                {
                    title: "Editar",
                    type: "link", 
                    link: "/buy-orders/details/edit", 
                    action: "edit", 
                    icon: Edit, 
                    onSelect: () => { selectAnOrder(info) } 
                },
                { 
                    title: "Remover",
                    type: "link", 
                    link: "/buy-orders/details/remove", 
                    action: "remove", 
                    icon: Close, 
                    onSelect: () => { selectAnOrder(info) } 
                }
            ]
        }

        /* if(
            ocStatus !== OcStatus.MAINTENER &&
            ocStatus !== OcStatus.CANCELED &&
            ocStatus !== OcStatus.BILLING_PROCESS &&
            ocStatus !== OcStatus.REPROVED &&
            ocStatus !== OcStatus.PARTIAL_BILLING_PROCESS &&
            (
                info.codigo_solicitante === userData.id ||
                userData.permissions?.includes("FINANC")
            )
        ){
            options = [...options,
                {
                    title: "Editar",
                    type: "link", 
                    link: "/buy-orders/details/edit", 
                    action: "edit", 
                    icon: Edit, 
                    onSelect: () => { selectAnOrder(info) } 
                }
            ]
        } */

        if(containsAprovePermissions(info)){
            options = [...options,
                {
                    title: "Aprovar",
                    type: "link", 
                    link: "/buy-orders/details/aprove", 
                    action: "aprove", 
                    icon: Gavel, 
                    onSelect: () => { selectAnOrder(info) } 
                }
            ]
        }

        const isFirstAprover = (
            info.aprovadores.aprovador1?.id === userData.id || 
            info.aprovadores.aprovador1?.Substitutos.some(user => user.id === userData.id)
        )

        if(canVerifyDocument(info)) {
            options = [...options, {
                title: (isFirstAprover && info.status_diver_nfxoc === "6") ? "Reaprovar" : "Verificar NF",
                type: "button",
                action: "aprove", 
                icon: Receipt, 
                onSelect: () => { 
                    selectAnOrder(info)
                    onVerify(isFirstAprover ? "aprove" : "add")
                }
            }]
        }

        if(ocStatus === OcStatus.APROVED) {
            if((info.status_diver_nfxoc === "2" || info.status_diver_nfxoc === "3") && userData.permissions?.includes("FINANC")){
                options = [...options, {
                    title: "Iniciar Fluxo Financeiro",
                    type: "button",
                    action: "default", 
                    icon: CurrencyExchange, 
                    onSelect: () => { 
                        selectAnOrder(info)
                        onStartBillingProcess()
                    }
                }]
            }
        }

        if(ocStatus !== OcStatus.MAINTENER){
            options = [...options, {
                title: "Vizualizar",
                type: "link", 
                link: "/buy-orders/details/view", 
                action: "view", 
                icon: Visibility, 
                onSelect: () => { selectAnOrder(info) } 
            }]
        }

        if(ocStatus === OcStatus.APROVED){
            const aprover = info.aprovadores.aprovador1
            const canCancelRequest = (
                aprover?.id === userData.id ||
                aprover?.Substitutos.some(subAprover => subAprover.id === userData.id)
            )

            if(canCancelRequest){
                options = [...options, {
                    title: "Cancelar",
                    type: "button",
                    action: "cancel", 
                    icon: Close, 
                    onSelect: () => { onCancel(info) } 
                }]
            }
        }

        return options

    }

    const columns: GridColumns = selectCategory !== "2" ? [
        { 
            field: "status", 
            headerName: "Status", 
            width: 80, 
            headerAlign: "center", 
            align: "center", 
            renderCell: ({ row }) => renderStatusIcon(row, "request")
        },
        { 
            field: 'nf_status', 
            headerName: 'NF', 
            width: 80, 
            headerAlign: "center", 
            align: "center", 
            renderCell: ({ row }) => renderStatusIcon(row, "document")
        },
        { 
            field: 'id',
            headerName: 'Número', 
            headerAlign: "center", 
            align: "center",
            width: 120,
            renderCell: ({ row }) => (
                <Tooltip title={ row.observacao }>
                    <p>{ row.id }</p>
                </Tooltip>
            )
        },
        { 
            field: 'nome_solicitante', 
            headerName: 'Solicitante',
            minWidth: 120, 
            renderCell: ({ row }) => renderGridName(row.nome_solicitante) 
        },
        { 
            field: 'nome_aprovador', 
            headerName: 'Aprovador', 
            minWidth: 120, 
            renderCell: ({ row }) => {
                const order = row as BuyOrder
                const substitute = order.aprovadores.aprovador1?.Substitutos[0]
                const aprover = order.aprovadores.aprovador1

                if(!!aprover){
                    return (
                        <div>
                            <p>{ renderGridName(aprover.name) }</p>
                            { !!substitute && (
                                <p style={{ display: "flex", flexDirection: "row"}}>({ renderGridName(substitute.name) })</p>
                            )}
                        </div>
                    )
                }

                return ""
            }
        },
        { 
            field: 'nome_aprovador2', 
            headerName: 'Aprovador N2', 
            minWidth: 120, 
            renderCell: ({ row }) => {
                const order = row as BuyOrder
                const substitute = order.aprovadores.aprovador2?.Substitutos[0]
                const aprover = order.aprovadores.aprovador2

                if(!!aprover){
                    return (
                        <div>
                            <p>{ renderGridName(aprover.name) }</p>
                            { !!substitute && (
                                <p style={{ display: "flex", flexDirection: "row"}}>({ renderGridName(substitute.name) })</p>
                            )}
                        </div>
                    )
                }

                return ""
            }
        },
        { 
            field: 'nome_fornecedor', 
            headerName: 'Fornecedor', 
            minWidth: 120, 
            flex: 1, 
            renderCell: ({ row }) => renderProviderName(row.nome_fornecedor) 
        },
        { 
            field: 'data_emissao', 
            headerName: 'Data Solicitação', 
            headerAlign: "center", 
            align: "center", 
            width: 140, 
            renderCell: ({ row }) => row.data_emissao?.toLocaleDateString("pt-BR", {
                day: "2-digit",
                month: "2-digit",
                year: "numeric"
            })
        },
        { 
            field: 'data_entrega', 
            headerName: 'Data Entrega', 
            headerAlign: "center", 
            align: "center", 
            width: 140, 
            renderCell: ({ row }) => row.data_entrega?.toLocaleDateString("pt-BR", {
                day: "2-digit",
                month: "2-digit",
                year: "numeric"
            })
        },
        { 
            field: 'total', 
            headerName: 'Total (R$)', 
            headerAlign: "center", 
            align: "right", 
            width: 150 
        },
        { 
            field: 'actions', 
            headerName: 'Opções', 
            headerAlign: "center", 
            align: "center",
            renderCell: ({ row }) => <DotOptions disabled={ isLoading } options={ generateActionsButton(row) }/>
        }
    ]: [
        { 
            field: "status", 
            headerName: "Status", 
            width: 80, 
            headerAlign: "center", 
            align: "center", 
            renderCell: ({ row }) => renderStatusIcon(row, "request")
        },
        { 
            field: 'nf_status', 
            headerName: 'NF', 
            width: 80, 
            headerAlign: "center", 
            align: "center", 
            renderCell: ({ row }) => renderStatusIcon(row, "document")
        },
        { 
            field: 'id',
            headerName: 'Número', 
            headerAlign: "center", 
            align: "center",
            width: 120,
            renderCell: ({ row }) => (
                <Tooltip title={ row.observacao }>
                    <p>{ row.id }</p>
                </Tooltip>
            )
        },
        { 
            field: 'nome_solicitante', 
            headerName: 'Solicitante',
            minWidth: 120, 
            renderCell: ({ row }) => renderGridName(row.nome_solicitante) 
        },
        { 
            field: 'nome_aprovador', 
            headerName: 'Aprovador', 
            minWidth: 120, 
            renderCell: ({ row }) => {
                const order = row as BuyOrder
                const substitute = order.aprovadores.aprovador1?.Substitutos[0]
                const aprover = order.aprovadores.aprovador1

                if(!!aprover){
                    return (
                        <div>
                            <p>{ renderGridName(aprover.name) }</p>
                            { !!substitute && (
                                <p style={{ display: "flex", flexDirection: "row"}}>({ renderGridName(substitute.name) })</p>
                            )}
                        </div>
                    )
                }

                return ""
            }
        },
        { 
            field: 'nome_aprovador2', 
            headerName: 'Aprovador N2', 
            minWidth: 120, 
            renderCell: ({ row }) => {
                const order = row as BuyOrder
                const substitute = order.aprovadores.aprovador2?.Substitutos[0]
                const aprover = order.aprovadores.aprovador2

                if(!!aprover){
                    return (
                        <div>
                            <p>{ renderGridName(aprover.name) }</p>
                            { !!substitute && (
                                <p style={{ display: "flex", flexDirection: "row"}}>({ renderGridName(substitute.name) })</p>
                            )}
                        </div>
                    )
                }

                return ""
            }
        },
        { 
            field: 'nome_aprovador3', 
            headerName: 'Aprovador N3', 
            minWidth: 120, 
            renderCell: ({ row }) => {
                const order = row as BuyOrder
                const substitute = order.aprovadores.aprovador3?.Substitutos[0]
                const aprover = order.aprovadores.aprovador3

                if(!!aprover){
                    return (
                        <div>
                            <p>{ renderGridName(aprover.name) }</p>
                            { !!substitute && (
                                <p style={{ display: "flex", flexDirection: "row"}}>({ renderGridName(substitute.name) })</p>
                            )}
                        </div>
                    )
                }

                return ""
            }
        },
        { 
            field: 'nome_fornecedor', 
            headerName: 'Fornecedor', 
            minWidth: 120, 
            flex: 1, 
            renderCell: ({ row }) => renderProviderName(row.nome_fornecedor) 
        },
        { 
            field: 'data_emissao', 
            headerName: 'Data Solicitação', 
            headerAlign: "center", 
            align: "center", 
            width: 140, 
            renderCell: ({ row }) => row.data_emissao?.toLocaleDateString("pt-BR", {
                day: "2-digit",
                month: "2-digit",
                year: "numeric"
            })
        },
        { 
            field: 'data_entrega', 
            headerName: 'Data Entrega', 
            headerAlign: "center", 
            align: "center", 
            width: 140, 
            renderCell: ({ row }) => row.data_entrega?.toLocaleDateString("pt-BR", {
                day: "2-digit",
                month: "2-digit",
                year: "numeric"
            })
        },
        { 
            field: 'total', 
            headerName: 'Total (R$)', 
            headerAlign: "center", 
            align: "right", 
            width: 150 
        },
        { 
            field: 'actions', 
            headerName: 'Opções', 
            headerAlign: "center", 
            align: "center",
            renderCell: ({ row }) => <DotOptions disabled={ isLoading } options={ generateActionsButton(row) }/>
        }
    ]

    return (
        <>
        <Container>
            { !isMobileView  ? (
                <GridContainer>
                    <DataGrid
                        columns={ columns }
                        localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
                        rows={ gridData }
                        paginationMode="server"
                        page={ currentPage }
                        pageSize={30}
                        rowsPerPageOptions={[30]}
                        disableColumnMenu
                        disableSelectionOnClick
                        rowCount={ totalRows }
                        loading={ isLoading }
                        onPageChange={ onPageChange }
                        components={{
                            NoRowsOverlay: () => (
                                <Stack height="100%" alignItems="center" justifyContent="center">
                                    {/* Nenhum resultado foi encontrado */}
                                </Stack>
                            )
                        }}
                    />
                </GridContainer>
            ):(
                gridData.map(info => (
                    <ListMobileCard 
                        key={info.id}
                        options={ generateOptionsArrayMobile(info) }
                    >
                        <p style={{ 
                            display: "flex", 
                            justifyContent: "flex-end",
                            zIndex: 1 
                        }}>
                            { renderStatusIcon(info, "request") }
                            { renderStatusIcon(info, "document") }
                        </p>
                        <p style={{ marginTop: "-27px" }}><b>OC:{ info.id }</b></p>
                        <p><b>Tipo: </b>{OCFormDefaultValue.typeRequest.find(type => type.value === info.tipo)?.label || ""}</p>
                        <p><b>Solicitante: </b>{ info.nome_solicitante }</p>
                        { info.aprovadores.aprovador1 && 
                            <p><b>Aprovador: </b>{info.aprovadores.aprovador1?.name } { info.aprovadores.aprovador1?.Substitutos[0]?.name && `(${info.aprovadores.aprovador1?.Substitutos[0]?.name})` } </p>
                        }
                        { info.aprovadores.aprovador2 && (
                            <p><b>Aprovador2: </b>{ info.aprovadores.aprovador2.name } { info.aprovadores.aprovador2?.Substitutos[0]?.name && `(${info.aprovadores.aprovador2?.Substitutos[0]?.name})` }</p>
                        )}
                        { info.aprovadores.aprovador3 && (
                            <p><b>Aprovador3: </b>{ info.aprovadores.aprovador3.name } { info.aprovadores.aprovador3?.Substitutos[0]?.name && `(${info.aprovadores.aprovador3?.Substitutos[0]?.name})` }</p>
                        )}
                        <p><b>Fornecedor: </b>{ info.nome_fornecedor }</p>
                        <p><b>Data Solicitação: </b>{ new Date(info.data_emissao).toLocaleDateString("pt-BR", {
                            day: "2-digit",
                            month: "2-digit",
                            year: "numeric"
                        })}</p>
                        <p><b>Total: R$ </b>{ info.total }</p>
                    </ListMobileCard>
                ))
            )}
        </Container>
        <div style={{ display: "none" }}>
            <PrintOC ref={ printRef } selectedOrder={ dataToPrint } />
        </div>
        <DialogSubtitles
            open={ openDialogSubtitleStatus }
            onClose={() => setOpenDialogSubtitleStatus(false) }
            fullWidth
            maxWidth="sm"
            title="Status Solicitação"
            options={ OCFormDefaultValue.status.map(status => ({
                label: status.label,
                icon: status.Icon as any
            }))}
        />
        <DialogSubtitles
            open={ openDialogSubtitleStatusNF }
            onClose={() => setOpenDialogSubtitleStatusNF(false) }
            fullWidth
            maxWidth="sm"
            title="Status Documentos"
            options={ OCFormDefaultValue.statusNF.map(status => ({
                label: status.label,
                icon: status.Icon as any
            }))}
        />
    </>
    )
}

export default RequestsGrid
import { useCallback, useRef, useState, useEffect, useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Form } from "@unform/web";
import { FormHandles } from "@unform/core";
import * as Yup from "yup";
import Reference from "yup/lib/Reference";
import { toast } from "react-toastify";

import { Products, BuyOrder, NewBuyOrderRequest } from "../../../@types/buyOrder";

import { OcStatus, useBuyOrder } from "../../../hooks/buyOrders";
import { useAuth } from "../../../hooks/auth";
import { useBilling } from "../../../hooks/billing";
import { useInitialData } from "../../../hooks/initialData";
import { DocumentsProvider } from "../../../hooks/documents";

import getValidationErros from "../../../utils/validations/getValidationErros";
import { validadeCNPJ, validadeCPF } from "../../../utils/validations/validateDocuments";
import { formatValue, transformValueToNumberType } from "../../../utils/format/formatValue";
import { cnpjMask, cpfMask } from "../../../utils/format/masks";
import { getOCStatusMask, OCFormDefaultValue } from "../../../data/formValues";
import { getErrorMessage } from "../../../utils/validations/getErrorMessage";
import { convertDateToEnglishPattern } from "../../../utils/format/convertDateToEnglishPattern";

import DocumentAssignment, { DocumentAssignmentDialogRef } from "../components/DocumentAssignment";
import FormInput, { FormInputRef } from "../../../components/Form/FormInput";
import FormSelect from "../../../components/Form/FormSelect";
import FormDatePicker from "../../../components/Form/FormDataPicker";
import { Product, SelectProductModal } from "../../../components/Modal/SelectProduct";
import { Supplier, SelectSupplierModal } from "../components/SelectSupplier";
import RefuseModal, { RefuseModalRef } from "../components/RefuseModal";
import FormAutoComplete, { FormAutoCompleteRef } from "../../../components/Form/FormAutoComplete";
import { FormCard } from "../../../components/Form/FormCard";
import { AddProductButton } from "../components/AddProductButton";
import { Button } from "../../../components/Buttons/Button";

import { GridValueSetterParams } from "@mui/x-data-grid";

import { Grid, Autocomplete, TextField, CircularProgress, Switch } from "@mui/material";

import { Close as CloseIcon, Info, Search as SearchIcon } from "@mui/icons-material";

import { Container, FormActions, RemoveButton, CNPJActions, LoadingContainer, ValidationsInfoButton, ProductsSelection } from "./style";
import { compareAsc, differenceInDays } from "date-fns";
import { GridColumn } from "../../../@types/datagrid";
import { DataTable } from "../../../components/Tables/Datagrid";
import apiService from "../../../services/api.services";
import { queryClient } from "../../../services/queryClient";
import { useAppParams } from "../../../hooks/appParams";
import { formatText } from "../../../utils/format/formatText";

interface SetValueGridProps extends GridValueSetterParams {
  row: Products;
}

interface CentroCusto {
  id: string;
  descricao: string;
  pel: string;
  nome_aprovador?: string;
  substitutos: {
    id: string;
    nome: string;
  }[];
}

interface FormData {
  type: string;
  brand: string;
  condicao_pagamento: string;
  dateDelivery: string | Date;
  dateOC: string | Date;
  sede: string;
  delivery: string;
  cnpj: string;
  razao: string;
  nome: string;
  ie: string;
  email: string;
  observation: string;
  cod: string;
  loja: string;
  obs_tipo_pagto: string;
  valor_orcamento1: string;
  valor_orcamento2: string;
  valor_orcamento3: string;
  [key: string]: any;
}

interface Budget {
  valor_orcamento1: string | number;
  valor_orcamento2: string | number;
  valor_orcamento3: string | number;
}

const patternItem = "000";

export default function EditRequest() {
  const navigate = useNavigate();
  const routeParams = useParams();

  const { userData } = useAuth();
  const { params } = useAppParams();
  const { filiaisData } = useInitialData();
  const { getCostCenter, getPaymentConditions } = useBilling();

  const { selectedOrder, getBuyOrder, aproveOrder, updateOrder, removeOrder, selectAnOrder } = useBuyOrder();

  const formRef = useRef<FormHandles>(null);
  const refuseModalRef = useRef<RefuseModalRef>(null);

  const cnpjRef = useRef<FormInputRef>(null);
  const reasonRef = useRef<FormInputRef>(null);
  const nameRef = useRef<FormInputRef>(null);
  const subRef = useRef<FormInputRef>(null);
  const emailRef = useRef<FormInputRef>(null);
  const codRef = useRef<FormInputRef>(null);
  const lojaRef = useRef<FormInputRef>(null);

  const paymentConditionRef = useRef<FormAutoCompleteRef>(null);

  const documentsDioalogRef = useRef<DocumentAssignmentDialogRef>(null);

  const [openProductsDialog, setOpenProductsDialog] = useState<boolean>(false);
  const [openSuppliersDialog, setOpenSuppliersDialog] = useState<boolean>(false);
  const [productsList, setProductsList] = useState<Array<Products>>([]);
  const [totalProducts, setTotalProducts] = useState<string>(formatValue(0));
  const [buyOrder, setBuyOrder] = useState<Omit<BuyOrder, "products">>({} as Omit<BuyOrder, "products">);
  const [tableColumns, setTableColumns] = useState<GridColumn[]>([]);

  const [initialFormValues, setInitialFormValues] = useState<FormData>({} as FormData);

  const [selectedOcType, setSelectedOCType] = useState<string>("");

  const [hasChangedCostCenter, setHasChangedCostCenter] = useState<boolean>(false);

  const [ccOptions, setCcOptions] = useState<Array<CentroCusto>>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isLoadingSubmit, setIsLoadingSubmit] = useState<boolean>(false);
  const [isLoadingCC, setIsLoadingCC] = useState<boolean>(false);
  const [paymentOptions, setPaymentOptions] = useState<Array<string>>([]);

  const [isLoadingPaymentConditions, setIsLoadingPaymentConditions] = useState<boolean>(false);

  const [initialSearchSupplier, setInitialSearchSupplier] = useState<string>("");

  const [isNewProvider, setIsNewProvider] = useState<boolean>(false);

  const [budgets, setBudgets] = useState<Budget>({} as Budget);

  const consistInBudgetsRules = useMemo(() => {
    let isActive = false;

    const activeNewProviderRule = params.find((param) => param.parametro === "ZZ_CONEWFO");

    const maxQuantityWithoutRule = Number(params.find((param) => param.parametro === "ZZ_VLIMORC")?.conteudo || 20000);

    if (!activeNewProviderRule) {
      isActive = true;
    }

    isActive = activeNewProviderRule?.conteudo === ".T." ? true : false;

    const showBudegtsRules = isActive
      ? transformValueToNumberType(totalProducts) > maxQuantityWithoutRule && isNewProvider
      : transformValueToNumberType(totalProducts) > maxQuantityWithoutRule;

    return showBudegtsRules;
  }, [isNewProvider, params, totalProducts]);

  const canEditFields = useMemo(() => {
    const orderStatus = Number(buyOrder.status);

    if (routeParams.type === "edit" && orderStatus === OcStatus.MAINTENER) {
      return true;
    }

    return false;
  }, [buyOrder, routeParams]);

  const onQuantityChanges = useCallback(({ value, row }: SetValueGridProps): Products => {
    setProductsList((prev) =>
      prev.map((tableRow) => {
        if (tableRow.id === row.id) {
          return {
            ...tableRow,
            quantidade: !!value ? Math.trunc(value as number) : 0,
            valor_total: formatValue(
              isNaN(row.valor_unitario as number)
                ? transformValueToNumberType(row.valor_unitario as string) * (!!value ? Math.trunc(value as number) : 0)
                : (row.valor_unitario as number) * (!!value ? Math.trunc(value as number) : 0)
            ),
          };
        } else {
          return tableRow;
        }
      })
    );

    return row as Products;
  }, []);

  const onValueChanges = useCallback(({ value, row }: SetValueGridProps): Products => {
    if (isNaN(Number(value))) {
      return row;
    }

    const filteredValue = value?.toString().replaceAll(".", "").replaceAll(",", ".") || "";
    const regexMatches = filteredValue.match("^[0-9.]*");

    if (regexMatches === null) {
      return row;
    }

    if (!!regexMatches[0]) {
      setProductsList((prev) =>
        prev.map((tableRow) => {
          if (tableRow.id === row.id) {
            const unitValue = formatValue(Number(regexMatches[0]) <= 0 ? 0 : Number(Number(regexMatches[0])), 4);
            const totalValue = transformValueToNumberType(unitValue) * (tableRow.quantidade as number);

            return {
              ...tableRow,
              valor_unitario: unitValue,
              valor_total: formatValue(totalValue),
            };
          } else {
            return tableRow;
          }
        })
      );
    }

    return row;
  }, []);

  const onSelectCC = useCallback(
    (id: string, value: string): void => {
      if (!!value) {
        const selectedCC = ccOptions.find((cc) => cc.id === value.split("-")[0].trim());

        if (!selectedCC?.nome_aprovador) {
          toast.warn("Centro de custo selecionado não possui aprovador, por favor contate o financeiro.");
        }

        setProductsList((prev) =>
          prev.map((tableRow) => {
            if (tableRow.id === id) {
              return {
                ...tableRow,
                codigo_cc: selectedCC?.id!,
                cc_pel: selectedCC?.pel,
                nome_aprovador: selectedCC?.nome_aprovador,
              };
            } else {
              return tableRow;
            }
          })
        );
      }
    },
    [ccOptions]
  );

  const getSelectedCCValue = useCallback(
    (row) => {
      if (ccOptions.length <= 0) {
        return null;
      }

      const selectedCC = ccOptions.find((option) => row.codigo_cc === option.id);

      if (!selectedCC) {
        return null;
      }

      return `${selectedCC.id} - ${selectedCC.descricao}`;
    },
    [ccOptions]
  );

  const getSelectedCCAprover = useCallback(
    (row) => {
      if (ccOptions.length <= 0) {
        return null;
      }

      const selectedCC = ccOptions.find((option) => row.codigo_cc === option.id);

      if (!selectedCC) {
        return null;
      }

      const hasSubstitute = !!selectedCC?.substitutos[0];

      return `${selectedCC?.nome_aprovador}${hasSubstitute ? ` (${selectedCC?.substitutos[0]?.nome})` : ""}`;
    },
    [ccOptions]
  );

  const onSelectSupplier = useCallback(
    (row: Supplier): void => {
      setIsNewProvider(!!row.isNew);

      cnpjRef.current?.changeValue(row.cnpj.length === 14 ? cnpjMask(row.cnpj) : cpfMask(row.cnpj));
      reasonRef.current?.changeValue(row.reason);
      nameRef.current?.changeValue(row.name);
      subRef.current?.changeValue(row.ie || "ISENTO");
      emailRef.current?.changeValue(row.email);
      codRef.current?.changeValue(row.cod);
      lojaRef.current?.changeValue(row.loja);
    },
    [cnpjRef]
  );

  const verifyProductsSelected = useCallback((): boolean => {
    if (productsList.length <= 0) {
      toast.info("Por favor adicione um novo produto");

      return false;
    }

    let containValue = true;
    let containCC = true;
    let containQuantity = true;

    productsList.forEach((item) => {
      if (transformValueToNumberType(item.valor_unitario as string) <= 0) {
        containValue = false;
      }
      if (item.codigo_cc === "" || item.codigo_cc === undefined || item.codigo_cc === null) {
        containCC = false;
      }
      if (item.quantidade <= 0) {
        containQuantity = false;
      }
    });

    if (!containValue) {
      toast.warning("Por favor verifique o valor unitário de cada produto selecionado");
    }

    if (!containCC) {
      toast.warning("Por favor verifique o centro de custo de cada produto selecionado");
    }

    if (!containQuantity) {
      toast.warning("Por favor verifique a quantidade de cada produto selecionado");
    }

    if (containValue && containCC && containQuantity) {
      return true;
    } else {
      return false;
    }
  }, [productsList]);

  const handleSubmit = async (data: FormData): Promise<void> => {
    if (isLoadingSubmit) {
      return;
    }

    try {
      if (formRef.current) {
        formRef.current.setErrors({});
      }

      let filteredData = {} as FormData;
      let attribute: keyof FormData;

      for (attribute in data) {
        /* if(attribute !== "dateOC" && attribute !== "dateDelivery"){
                    filteredData[attribute] = removeSymbolsOnString(data[attribute])
                } else{ */
        filteredData[attribute] = data[attribute];
        /* } */
      }

      const validationShape: any = {
        type: Yup.string().required("Tipo Obrigatório"),
        brand: Yup.string().required("Marca Obrigatório"),
        dateOC: Yup.string().notRequired(),
        dateDelivery: Yup.string().compareDate(Yup.ref("dateOC"), "Data não pode ser menor que emissão").required("Data Obrigatória"),
        sede: Yup.string().required("Sede Obrigatória"),
        delivery: Yup.string().required("Local de Entrega Obrigatório"),
        condicao_pagamento: Yup.string().required("Condicao de Pagamento Obrigatório"),
        ie: Yup.string().notRequired(),
        email: Yup.string().notRequired(),
        observation: Yup.string().required("Descrição Obrigatória"),
        cnpj: Yup.string().required("Fornecedor Obrigatório"),
        cod: Yup.string().required("Código Obrigatório"),
        loja: Yup.string().required("Loja Obrigatória"),
        razao: Yup.string().required("Razão Obrigatória"),
        nome: Yup.string().required("Nome Obrigatório"),
      };

      if (consistInBudgetsRules && canEditFields) {
        validationShape.valor_orcamento2 = Yup.string().required("Por favor preencha a opção de orçamento");
        validationShape.valor_orcamento3 = Yup.string().required("Por favor preencha a opção de orçamento");
      }

      if (data.type !== "1") {
        validationShape.obs_tipo_pagto = Yup.string().max(240, "Número limite de caracteres é 240").required("Por favor informe o motivo");
      }

      const schema = Yup.object().shape(validationShape);

      await schema.validate(filteredData, {
        abortEarly: false,
      });

      const deliveryDate = new Date(convertDateToEnglishPattern(filteredData.dateDelivery as string));

      const differenceInDaysOfToday = differenceInDays(deliveryDate, new Date());

      if (differenceInDaysOfToday < 0) {
        toast.warn("A data de entrega está anterior a data de hoje");
        return;
      }

      if (!verifyProductsSelected()) {
        return;
      }

      const itensToValidate = productsList.map((item) => {
        const selectedCC = ccOptions.find((cc) => cc.id === item.codigo_cc);

        return {
          ...item,
          nome_aprovador: selectedCC?.nome_aprovador,
        };
      });

      //@ts-ignore
      const hasInvalidCostCenterSelected = itensToValidate.some((product) => !product.nome_aprovador);

      if (hasInvalidCostCenterSelected) {
        toast.warn("Alguns dos itens selecionados não possui centro de custo com aprovador vinculado, por favor contate o financeiro.");
        return;
      }

      const postData: NewBuyOrderRequest = {
        ...buyOrder,
        id: buyOrder.id,
        tipo: filteredData.type,
        marca: filteredData.brand,
        faturar_em: filteredData.sede,
        data_emissao: buyOrder.data_emissao,
        data_entrega: new Date(convertDateToEnglishPattern(filteredData.dateDelivery as string)),
        local_entrega: filteredData.delivery,
        codigo_fornecedor: filteredData.cod,
        loja_fornecedor: filteredData.loja,
        codigo_solicitante: buyOrder.codigo_solicitante,
        condicao_pagamento: filteredData.condicao_pagamento.split("-")[0].trim(),
        total: transformValueToNumberType(totalProducts),
        //observacao: filteredData.observation?.replaceAll("\n", " ").replaceAll('"', "").replaceAll("'", "").replaceAll("/", "-").replaceAll("\\", ""),
        observacao: filteredData.observation ? formatText(filteredData.observation) : "",
        obs_tipo_pagto: filteredData.obs_tipo_pagto ?  formatText(filteredData.obs_tipo_pagto) : "",
        valor_orcamento1: transformValueToNumberType(totalProducts),
        valor_orcamento2:
          consistInBudgetsRules && canEditFields ? transformValueToNumberType(data.valor_orcamento2) : buyOrder.valor_orcamento2,
        valor_orcamento3:
          consistInBudgetsRules && canEditFields ? transformValueToNumberType(data.valor_orcamento3) : buyOrder.valor_orcamento3,
        itens: productsList.map((product, index) => ({
          item: patternItem.substring(0, patternItem.length - (index + 1).toString().length) + (index + 1).toString(),
          produto: product.codigo_produto,
          centro_custo: product.codigo_cc,
          data_venc_titulo: product.data_venc_titulo ? new Date(product.data_venc_titulo) : "",
          quantidade: product.quantidade as number,
          valor_total: transformValueToNumberType(product.valor_total as string),
          valor_unitario: transformValueToNumberType(product.valor_unitario as string),
          cartao_corporativo: !!product.cartao_corporativo ? "S" : "N",
        })),
      };

      setIsLoadingSubmit(true);

      await updateOrder(postData, "buy-order");

      setIsLoadingSubmit(false);

      toast.success("A solicitação foi alterada com sucesso");

      navigate("/buy-orders");
    } catch (error: any) {
      if (error instanceof Yup.ValidationError) {
        const errors = getValidationErros(error);
        console.log(errors);

        if (formRef.current) {
          formRef.current.setErrors(errors);
        } else {
          console.log("Não foi possível enviar o formulário");
        }

        verifyProductsSelected();

        toast.warning("Por favor verifique se todos os campos foram preenchidos corretamente");
        return;
      }

      setIsLoadingSubmit(false);

      toast.error(getErrorMessage(error));
    }
  };

  const getIdItemConvertedWithPattern = (id: number) => patternItem.substring(0, patternItem.length - id.toString().length) + id.toString();

  const handleMarkCorpCardProduct = useCallback((productId) => {
    setProductsList((prev) =>
      prev.map((item) =>
        item.id === productId
          ? {
              ...item,
              cartao_corporativo: !item.cartao_corporativo,
            }
          : item
      )
    );
  }, []);

  const onSelectProduct = useCallback(
    (products: Array<Product>): void => {
      const newProducts = products.map((product, index) => ({
        id: productsList.length === 0 ? index + 1 : productsList.length + (index + 1),
        codigo_cc: product.cc,
        codigo_produto: product.codigo,
        descriao_produto: product.descricao,
        item: getIdItemConvertedWithPattern(productsList.length === 0 ? index + 1 : productsList.length + (index + 1)),
        ncm_produto: product.ncm,
        unidade_produto: product.um,
        quantidade: 0,
        valor_unitario: formatValue(0),
        valor_total: formatValue(0 * 1),
      }));

      setProductsList((prev) => [...prev, ...newProducts]);
    },
    [productsList]
  );

  const removeProduct = useCallback((id) => {
    setProductsList((prev) =>
      prev.filter((product) => product.id !== id).map((filteredProduct, index) => ({ ...filteredProduct, id: index + 1 }))
    );
  }, []);

  const getCCOptions = useCallback(async (): Promise<void> => {
    setIsLoadingCC(true);
    try {
      const costCenters = await getCostCenter();

      setCcOptions(costCenters);
    } catch (error: any) {
      console.log(error);
      toast.error(getErrorMessage(error));
    } finally {
      setIsLoadingCC(false);
    }
  }, [getCostCenter]);

  const onAprove = async () => {
    setIsLoadingSubmit(true);
    try {
      if (Number(buyOrder.status) === OcStatus.APROVE_COAST_CENTER) {
        const aproveData = {
          po_number: buyOrder.id,
          response: "A",
          justification: "",
          approver_id: userData.id,
        };

        const response = await apiService.post(
          "/aprovaccusto",
          {
            purchase_order: aproveData,
          },
          {
            params: {
              branch: userData.selectedBranch?.id as string,
            },
          }
        );

        queryClient.invalidateQueries({ queryKey: ["requests"] });

        toast.success(response.data.status?.descricao || "Centros de custo aprovados com sucesso");
      } else {
        const status = await aproveOrder(buyOrder.id);
        toast.success(status);
      }

      setIsLoadingSubmit(false);
      navigate("/buy-orders");
    } catch (error: any) {
      setIsLoadingSubmit(false);
      console.log(error);
      toast.error(getErrorMessage(error));
    }
  };

  const onRemoveOC = async () => {
    setIsLoadingSubmit(true);
    try {
      await removeOrder(buyOrder);
      toast.success("Registro excluido com sucesso");

      setIsLoadingSubmit(false);
      navigate("/buy-orders");
    } catch (error: any) {
      console.log(error);
      setIsLoadingSubmit(false);
      toast.error(getErrorMessage(error));
    }
  };

  const loadingPaymentsConditions = useCallback(
    async (ocType: string): Promise<string[] | void> => {
      if (!ocType) {
        return;
      }

      setIsLoadingPaymentConditions(true);
      setPaymentOptions([]);

      try {
        const paymentConditions = await getPaymentConditions(ocType);
        setPaymentOptions(paymentConditions.map((options) => `${options.id} - ${options.descricao}`));

        return paymentConditions.map((options) => `${options.id} - ${options.descricao}`);
      } catch (error: any) {
        console.log(error);
        toast.error(getErrorMessage(error));
      } finally {
        setIsLoadingPaymentConditions(false);
      }
    },
    [getPaymentConditions]
  );

  const getNFxOCStatus = (status: number): string => {
    switch (status) {
      case 1:
        return "Aguardando Aprovação da Solicitação";
      case 2:
        return "NFs Aprovadas";
      case 3:
        return "NFs Aprovadas com Divergencia";
      case 4:
        return "Aguardando vínculo com NF";
      case 5:
        return "Reprovado";
      case 6:
        return "Aguardando aprovação";
      default:
        return "";
    }
  };

  const getNFStatus = (status: string): string => {
    switch (Number(status)) {
      case 1:
        return "Aguardando vínculo";
      case 2:
        return "Ok";
      case 3:
        return "Divergente";
      case 4:
        return "Aprovado com divergencia";
      case 5:
        return "Reprovado";
      default:
        return "";
    }
  };

  const getNFType = (type: string) => {
    switch (type) {
      case "NF-E":
        return "NF-E";
      case "NFS-E":
        return "Nota de Serviço";
      default:
        return "-------";
    }
  };

  const getStatusLabel = (status: number, type: "date" | "hour"): string => {
    switch (status) {
      case 2:
        return type === "date" ? "Data Aprovação" : "Hora Aprovação";
      case 3:
        return type === "date" ? "Data Aprovação" : "Hora Aprovação";
      case 4:
        return !!buyOrder.hitorico_aprov.aprovador1?.hora_aprovou
          ? type === "date"
            ? "Data Aprovação"
            : "Hora Aprovação"
          : type === "date"
          ? "Data Reprovação"
          : "Hora Reprovação";
      case 5:
        return type === "date" ? "Data Aprovação" : "Hora Aprovação";
      case 7:
        return type === "date" ? "Data Aprovação" : "Hora Aprovação";
      default:
        return type === "date" ? "Data Reprovação" : "Hora Reprovação";
    }
  };

  const getCurrentDocument = useCallback(
    (row: Products): string => {
      const currentDocument = buyOrder.documentos.find((document) => document.itens?.includes(row.item as any));

      if (!currentDocument) {
        return "";
      }

      return `${currentDocument.numero_nf}${currentDocument.serie_nf ? `-${currentDocument.serie_nf}` : ""}`;
    },
    [buyOrder]
  );

  const loadInitialFormData = useCallback(
    (order: Omit<BuyOrder, "products">, paymnetsInfo: string[]) => {
      const principalAprover = !!order.hitorico_aprov.aprovador1?.nome_aprovacao
        ? order.hitorico_aprov.aprovador1?.nome_aprovacao
        : `${order.aprovadores.aprovador1?.name} ${
            order.aprovadores.aprovador1?.Substitutos[0] ? `(${order.aprovadores.aprovador1?.Substitutos[0]})` : ""
          }`;

      const secondaryAprover = !!order.hitorico_aprov.aprovador2?.nome_aprovacao
        ? order.hitorico_aprov.aprovador2?.nome_aprovacao
        : `${order.aprovadores.aprovador2?.name} ${
            order.aprovadores.aprovador2?.Substitutos[0] ? `(${order.aprovadores.aprovador2?.Substitutos[0]})` : ""
          }`;

      const tertiaryAprover = !!order.hitorico_aprov.aprovador3?.nome_aprovacao
        ? order.hitorico_aprov.aprovador3?.nome_aprovacao
        : `${order.aprovadores.aprovador3?.name} ${
            order.aprovadores.aprovador3?.Substitutos[0] ? `(${order.aprovadores.aprovador3?.Substitutos[0]})` : ""
          }`;

      const initialData: FormData = {
        id: `${order.id}`,
        type: order.tipo,
        brand: order.marca,
        dateOC: order.data_emissao,
        dateDelivery: order.data_entrega,
        requester: order.nome_solicitante,
        sede: `${order.faturar_em} - ${filiaisData.find((filial) => filial.filial === order.faturar_em)?.nome}`,
        delivery: order.local_entrega,
        condicao_pagamento: paymnetsInfo.find((option) => option.split("-")[0].trim() === order.codigo_condicao_pagamento) || "",
        cnpj: order.cnpj_fornecedor
          ? order.cnpj_fornecedor.length === 14
            ? cnpjMask(order.cnpj_fornecedor)
            : cpfMask(order.cnpj_fornecedor)
          : "",
        cod: order.codigo_fornecedor,
        loja: order.loja_fornecedor,
        razao: order.nome_fornecedor,
        nome: order.fantasia_fornecedor,
        ie: order.inscricao_fornecedor,
        email: order.email_fornecedor,
        observation: order.observacao,
        obs_tipo_pagto: order.obs_tipo_pagto || "",
        status: getOCStatusMask(order.status),
        status_diver_nfxoc: getNFxOCStatus(Number(order.status_diver_nfxoc)),
        aprovador: principalAprover,
        dateApprove: order.hitorico_aprov.aprovador1?.data_aprovacao,
        approveHour: order.hitorico_aprov.aprovador1?.hora_aprovou,
        aprovador2: secondaryAprover,
        aprovador3: tertiaryAprover,
        dateApprove2: order.hitorico_aprov.aprovador2?.data_aprovacao,
        approveHour2: order.hitorico_aprov.aprovador2?.hora_aprovou,
        dateApprove3: order.hitorico_aprov.aprovador3?.data_aprovacao,
        approveHour3: order.hitorico_aprov.aprovador3?.hora_aprovou,
        justify: order.justificativa,
        nome_aprovador_divergencia: order.hitorico_aprov.aprovador_divergencia?.nome_aprovacao || order.aprovadores.aprovador1?.name,
        nome_aprovador_financeiro: order.aprovadores.aprovador_financeiro?.name,
        data_aprovacao_financeiro: order.hitorico_aprov.aprovador_financeiro?.data_aprovacao ? new Date(order.hitorico_aprov.aprovador_financeiro?.data_aprovacao).toLocaleDateString("pt-br", { day: "2-digit", month: "2-digit", year: "numeric"}) : "--/--/----",
        hora_aprovacao_financeiro: order.hitorico_aprov.aprovador_financeiro?.hora_aprovou ? order.hitorico_aprov.aprovador_financeiro?.hora_aprovou : "--:--",
        valor_orcamento1: formatValue(order.valor_orcamento1 || 0),
        valor_orcamento2: formatValue(order.valor_orcamento2 || 0),
        valor_orcamento3: formatValue(order.valor_orcamento3 || 0),
      };

      order.documentos.forEach((doc, index) => {
        initialData[`numero_nf${index + 1}`] = doc.numero_nf;
        initialData[`serie${index + 1}`] = doc.serie_nf;
        initialData[`status${index + 1}`] = getNFStatus(doc.status as string);
        initialData[`tipo_nf${index + 1}`] = getNFType(doc.tipo_nf);
        initialData[`emissao${index + 1}`] = doc.emissao.replaceAll("-", "/");
        initialData[`vencimento${index + 1}`] = doc.vencimento.replaceAll("-", "/");
        initialData[`data_aprovacao_divergencia${index + 1}`] = doc.data_aprovacao_divergencia.replaceAll("-", "/");
        initialData[`hora_aprovacao_divergencia${index + 1}`] = doc.hora_aprovacao_divergencia;
        initialData[`justificativa_divergencia${index + 1}`] = doc.justificativa_divergencia;
      });

      setInitialFormValues(initialData);
      setSelectedOCType(order.tipo);
    },
    [filiaisData]
  );

  const handleCancelCostCenter = () => {
    setProductsList(buyOrder.itens!);
    setHasChangedCostCenter(false);
  };

  const getBudgetValue = useCallback((content: string | number): number => {
    const currentValue = isNaN(Number(content)) ? transformValueToNumberType(content as string) : Number(content);

    return currentValue;
  }, []);

  const handleBudgetChanges = (value: string | number, key: keyof Budget) => {
    setBudgets((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  const handleBudgetOnBlur = (value: string | number, key: keyof Budget) => {
    const currentValue = getBudgetValue(value);

    setBudgets((prev) => ({
      ...prev,
      [key]: formatValue(currentValue),
    }));
  };

  const savings = useMemo(() => {
    const total = transformValueToNumberType(totalProducts);

    const budgetsValues = {
      budget1: getBudgetValue(budgets.valor_orcamento1 || 0),
      budget2: getBudgetValue(budgets.valor_orcamento2 || 0),
      budget3: getBudgetValue(budgets.valor_orcamento3 || 0),
    };

    const savingsValues = {
      saving1: formatValue(budgetsValues.budget1 - total),
      saving2: formatValue(budgetsValues.budget2 - total),
      saving3: formatValue(budgetsValues.budget3 - total),
    };

    return savingsValues;
  }, [totalProducts, budgets, getBudgetValue]);

  const userCostCenterItems = useMemo(() => {
    const orderStatus = Number(buyOrder.status);

    const isCostCenterAprover = buyOrder.aprovadores?.aprovador_ccusto.some(
      (user) => user.id === userData.id || user.Substitutos.some((subUser) => subUser.id === userData.id)
    );

    if (orderStatus === OcStatus.APROVE_COAST_CENTER && isCostCenterAprover) {
      let subAproverInfo: any;
      buyOrder.aprovadores?.aprovador_ccusto.forEach((aprover) => {
        const subAprover = aprover.Substitutos.find((subAprover) => subAprover.id === userData.id);

        if (subAprover) {
          subAproverInfo = {
            ...subAprover,
          };
        }
      });
      const aproverInfo = subAproverInfo
        ? {
            ...subAproverInfo,
            centro_custo: buyOrder.aprovadores.aprovador_ccusto
              .filter((aprover) => aprover.Substitutos.some((subUser) => subUser.id === userData.id))
              .reduce((accumulator, currentValue) => {
                if (!currentValue.centro_custo) {
                  return accumulator;
                }

                return [...accumulator, ...currentValue.centro_custo];
              }, [] as any),
          }
        : buyOrder.aprovadores.aprovador_ccusto.find((aprover) => aprover.id === userData.id);

      const editableItens = buyOrder.itens?.filter((product) => {
        const asYouCostCenter = aproverInfo?.centro_custo?.some((costCenter) => costCenter.id_cc === product.codigo_cc);

        if (asYouCostCenter && product.status_aprova !== "A" && product.status_aprova !== "R") {
          return true;
        }

        return false;
      });

      return editableItens;
    }

    return null;
  }, [buyOrder, userData.id]);

  useEffect(() => {
    if (canEditFields) {
      setTableColumns([
        {
          field: "document",
          headerName: "Nota Vinculada",
          minWidth: 140,
          align: "left",
          renderCell: (row) => getCurrentDocument(row),
        },
        {
          field: "codigo_produto",
          headerName: "Código Produto",
          minWidth: 140,
          align: "center",
        },
        {
          field: "descriao_produto",
          headerName: "Descrição",
          minWidth: 400,
        },
        {
          field: "codigo_cc",
          headerName: "Centro de Custo",
          minWidth: 400,
          renderCell: (row) => (
            <Autocomplete
              fullWidth
              value={
                ccOptions?.length > 0
                  ? ccOptions
                      .filter((option) => {
                        if (option.id.includes("DIGIMED")) {
                          if (row.codigo_cc === "DIGIMED_IE - DIGITAL MEDIA_IE" || row.codigo_cc === "DIGIMED_IE") {
                            return option.id === "DIGIMED_IE";
                          }

                          if (row.codigo_cc === "DIGIMED_EP - DIGITAL MEDIA_EP" || row.codigo_cc === "DIGIMED_EP") {
                            return option.id === "DIGIMED_EP";
                          }

                          if (row.codigo_cc === "DIGIMED - DIGITAL MEDIA") {
                            return option.id === "DIGIMED";
                          }
                        }
                        return row.codigo_cc.includes(option.id);
                      })
                      .map((filteredOption) => {
                        return `${filteredOption.id} - ${filteredOption.descricao}`;
                      })[0]
                  : null
              }
              options={["", ...ccOptions.map((item) => `${item.id} - ${item.descricao}`)]}
              loading={isLoadingCC}
              loadingText="Carregando..."
              noOptionsText="Sem resultados"
              onChange={(_, option) => {
                onSelectCC(row.id, option || "");
              }}
              renderInput={(props) => (
                <TextField
                  {...props}
                  hiddenLabel
                  variant="standard"
                  size="small"
                  placeholder="Selecione"
                  fullWidth
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              )}
            />
          ),
        },
        {
          field: "cc_pel",
          headerName: "P&L",
          minWidth: 400,
          renderCell: (row) => {
            let option: any = undefined;
            if (!!ccOptions) {
              option = ccOptions.find((option) => row.codigo_cc.split("-")[0].trim() === option.id);
            }
            return <p>{option?.pel || ""}</p>;
          },
        },
        {
          field: "cc_approver",
          headerName: "Responsável",
          minWidth: 200,
          renderCell: (row) => row.cc_approver || getSelectedCCAprover(row),
        },
        {
          field: "data_venc_titulo",
          headerName: "Data Venc. Título",
          minWidth: 400,
          renderCell: (row) => (
            <div style={{ marginTop: "-12px" }}>
              <FormDatePicker
                name="data_venc_titulo"
                views={["day"]}
                inputFormat="dd/MM/yyyy"
                label=" "
                variant="standard"
                size="small"
                fullWidth
                initialValue={!!row.data_venc_titulo ? new Date(row.data_venc_titulo) : undefined}
                disabled={row.codigo_produto !== "ADTO"}
                onChange={(date) => {
                  setProductsList((prev) =>
                    prev.map((tableRow) => {
                      if (tableRow.id === row.id) {
                        return {
                          ...tableRow,
                          data_venc_titulo: date as string,
                        };
                      } else {
                        return tableRow;
                      }
                    })
                  );
                }}
              />
            </div>
          ),
        },
        {
          field: "ncm_produto",
          headerName: "NCM",
          align: "center",
          headerAlign: "center",
          minWidth: 150,
        },
        {
          field: "cartao_corporativo",
          headerName: "Cartão Corporativo?",
          align: "center",
          headerAlign: "center",
          minWidth: 150,
          renderCell: (row) => <Switch defaultChecked={row.cartao_corporativo} onChange={() => handleMarkCorpCardProduct(row.id)} />,
        },
        {
          field: "unidade_produto",
          headerName: "Unidade de Medida",
          align: "center",
          headerAlign: "center",
          minWidth: 170,
        },
        {
          field: "quantidade",
          headerName: "Quantidade",
          align: "center",
          headerAlign: "center",
          minWidth: 120,
          renderCell: (row) => (
            <TextField
              size="small"
              fullWidth
              variant="standard"
              value={row.quantidade}
              onChange={(event) => {
                onQuantityChanges({
                  value: event.target.value.replace(/\D+/g, ""),
                  row,
                });
              }}
            />
          ),
        },
        {
          field: "valor_unitario",
          headerName: "Valor Unitário",
          headerAlign: "center",
          align: "center",
          minWidth: 170,
          renderCell: (row) => (
            <TextField
              size="small"
              fullWidth
              variant="standard"
              value={row.valor_unitario}
              onChange={(event) => {
                setProductsList((prev) =>
                  prev.map((tableRow) => {
                    if (tableRow.id === row.id) {
                      return {
                        ...tableRow,
                        valor_unitario: event.target.value,
                      };
                    } else {
                      return tableRow;
                    }
                  })
                );
              }}
              onBlur={(event) => {
                onValueChanges({
                  value: event.target.value,
                  row,
                });
              }}
            />
          ),
        },
        {
          field: "valor_total",
          headerName: "Total",
          align: "center",
          headerAlign: "center",
          minWidth: 170,
        },
        /* {
                    field: 'aprover', 
                    headerName: "Aprovador",
                    minWidth: 170,
                    renderCell: (row) => {
                        const aprover = buyOrder.aprovadores.aprovador_ccusto.find(item => (
                            item.centro_custo?.some(cc => cc.id_cc === row.codigo_cc)
                        ))

                        //@ts-ignore
                        return aprover?.nome || row.nome_aprovador
                    }
                }, */
        {
          field: "aproved_by",
          headerName: "Aprovado por",
          minWidth: 170,
          renderCell: (row) => {
            const aprover = buyOrder.hitorico_aprov?.aprovador_ccusto?.find((item) => item.id_cc === row.codigo_cc);
            return aprover?.nome_aprovacao;
          },
        },
        {
          field: "aproved_date",
          headerName: "Data aprovação",
          minWidth: 170,
          renderCell: (row) => {
            const aprover = buyOrder.hitorico_aprov?.aprovador_ccusto?.find((item) => item.id_cc === row.codigo_cc);

            return !!aprover?.data_aprovacao
              ? new Date(aprover?.data_aprovacao).toLocaleDateString("pt-BR", {
                  day: "2-digit",
                  month: "2-digit",
                  year: "numeric",
                })
              : "";
          },
        },
        {
          field: "aproved_hour",
          headerName: "Hora aprovação",
          minWidth: 170,
          renderCell: (row) => {
            const aprover = buyOrder.hitorico_aprov?.aprovador_ccusto?.find((item) => item.id_cc === row.codigo_cc);
            return aprover?.hora_aprovou;
          },
        },
        {
          field: "remove",
          headerName: "Opções",
          align: "center",
          headerAlign: "center",
          renderCell: (row) => (
            <RemoveButton type="button" onClick={() => removeProduct(row.id)}>
              <CloseIcon />
            </RemoveButton>
          ),
        },
      ]);
    } else {
      setTableColumns([
        {
          field: "document",
          headerName: "Nota Vinculada",
          width: 140,
          align: "left",
          renderCell: (row) => getCurrentDocument(row),
        },
        { field: "codigo_produto", headerName: "Código Produto", minWidth: 140, align: "left" },
        { field: "descriao_produto", headerName: "Descrição", minWidth: 400 },
        {
          field: "codigo_cc",
          headerName: "Centro de Custo",
          minWidth: 400,
          renderCell: (row) => {
            if (userCostCenterItems?.some((item) => item.id === row.id)) {
              return (
                <Autocomplete
                  fullWidth
                  value={getSelectedCCValue(row)}
                  options={["", ...ccOptions.map((item) => `${item.id} - ${item.descricao}`)]}
                  loading={isLoadingCC}
                  loadingText="Carregando..."
                  noOptionsText="Sem resultados"
                  onChange={(_, option) => {
                    onSelectCC(row.id, option || "");
                    setHasChangedCostCenter(true);
                  }}
                  renderInput={(props) => (
                    <TextField
                      {...props}
                      hiddenLabel
                      variant="standard"
                      size="small"
                      placeholder="Selecione"
                      fullWidth
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  )}
                />
              );
            } else {
              return ccOptions?.length > 0
                ? `${row.codigo_cc} - ${ccOptions.filter((option) => option.id === row.codigo_cc)[0]?.descricao || ""}`
                : "";
            }
          },
        },
        {
          field: "cc_pel",
          headerName: "P&L",
          minWidth: 400,
          renderCell: (row) => {
            const option = ccOptions?.find((option) => row.codigo_cc.split("-")[0].trim() === option.id);
            return option?.pel || "";
          },
        },
        {
          field: "cc_approver",
          headerName: "Responsável",
          minWidth: 200,
          renderCell: (row) => row.cc_approver || getSelectedCCAprover(row),
        },
        {
          field: "data_venc_titulo",
          headerName: "Data Venc. Título",
          minWidth: 400,
          renderCell: (row) => {
            return (
              <div style={{ marginTop: "-12px" }}>
                <FormDatePicker
                  name="data_venc_titulo"
                  views={["day"]}
                  inputFormat="dd/MM/yyyy"
                  label=" "
                  variant="standard"
                  size="small"
                  fullWidth
                  disabled
                  initialValue={!!row.data_venc_titulo ? new Date(row.data_venc_titulo) : undefined}
                />
              </div>
            );
          },
        },
        { field: "ncm_produto", headerName: "NCM", align: "center", headerAlign: "center", minWidth: 150 },
        {
          field: "cartao_corporativo",
          headerName: "Cartão Corporativo?",
          align: "center",
          headerAlign: "center",
          minWidth: 150,
          renderCell: (row) => <Switch defaultChecked={row.cartao_corporativo} disabled />,
        },
        { field: "unidade_produto", headerName: "Unidade de Medida", align: "center", headerAlign: "center", minWidth: 170 },
        { field: "quantidade", headerName: "Quantidade", align: "center", headerAlign: "center", minWidth: 120 },
        { field: "valor_unitario", headerName: "Valor Unitário", headerAlign: "center", align: "center", minWidth: 170 },
        { field: "valor_total", headerName: "Total", align: "center", headerAlign: "center", minWidth: 170 },
        /* {
                    field: 'aprover', 
                    headerName: "Aprovador",
                    minWidth: 170,
                    renderCell: (row) => {
                        const aprover = buyOrder.aprovadores.aprovador_ccusto.find(item => (
                            item.centro_custo?.some(cc => cc.id_cc === row.codigo_cc)
                        ))
                        //@ts-ignore
                        return aprover?.nome
                    }
                }, */
        {
          field: "aproved_by",
          headerName: "Aprovado por",
          minWidth: 170,
          renderCell: (row) => {
            const aprover = buyOrder.hitorico_aprov?.aprovador_ccusto?.find((item) => item.id_cc === row.codigo_cc);
            return aprover?.nome_aprovacao;
          },
        },
        {
          field: "aproved_date",
          headerName: "Data aprovação",
          minWidth: 170,
          renderCell: (row) => {
            const aprover = buyOrder.hitorico_aprov?.aprovador_ccusto?.find((item) => item.id_cc === row.codigo_cc);
            return !!aprover?.data_aprovacao
              ? new Date(aprover?.data_aprovacao).toLocaleDateString("pt-BR", {
                  day: "2-digit",
                  month: "2-digit",
                  year: "numeric",
                })
              : "";
          },
        },
        {
          field: "aproved_hour",
          headerName: "Hora aprovação",
          minWidth: 170,
          renderCell: (row) => {
            const aprover = buyOrder.hitorico_aprov?.aprovador_ccusto?.find((item) => item.id_cc === row.codigo_cc);
            return aprover?.hora_aprovou;
          },
        },
      ]);
    }
  }, [
    buyOrder,
    routeParams,
    userData.id,
    onQuantityChanges,
    onValueChanges,
    removeProduct,
    getCurrentDocument,
    ccOptions,
    isLoadingCC,
    onSelectCC,
    getSelectedCCValue,
    userCostCenterItems,
    canEditFields,
    handleMarkCorpCardProduct,
    getSelectedCCAprover,
  ]);

  useEffect(() => {
    Yup.addMethod(Yup.string, "validateDocument", function (errorMessage) {
      return this.test("test-document-type", errorMessage, function (value) {
        const { path, createError } = this;

        const cnpj = value || "";

        const filteredValue = cnpj.replace(/\D/g, "");

        if (filteredValue.length === 14) {
          return validadeCNPJ(filteredValue) || createError({ path, message: "CNPJ inválido" });
        }

        if (filteredValue.length === 11) {
          return validadeCPF(filteredValue) || createError({ path, message: "CPF inválido" });
        }

        return createError({ path, message: errorMessage });
      });
    });

    Yup.addMethod(Yup.string, "compareDate", function (dateToCompare: Reference<string> | string, errorMessage) {
      return this.test("test-document-type", errorMessage, function (currentDate) {
        const { path, createError, parent } = this;

        let date: string;

        if (typeof dateToCompare === "object") {
          date = parent[dateToCompare.key];
        } else {
          date = dateToCompare as string;
        }

        if (!currentDate) {
          return createError({ path, message: errorMessage });
        }

        const compareResult = compareAsc(new Date(convertDateToEnglishPattern(date)), new Date(convertDateToEnglishPattern(currentDate)));

        if (compareResult <= 0) {
          return true;
        }

        /* const currentDatetime = new Date(convertDateToEnglishPattern(currentDate)).getTime()
                const dateToCompareDatetime = new Date(convertDateToEnglishPattern(date)).getTime()

                if(currentDatetime >= dateToCompareDatetime){
                    return true
                } */

        return createError({ path, message: errorMessage });
      });
    });
  }, []);

  useEffect(() => {
    const total = formatValue(
      productsList.reduce((accumulator, currentValue) => accumulator + transformValueToNumberType(currentValue.valor_total as string), 0)
    );
    setTotalProducts(total);
  }, [productsList]);

  useEffect(() => {
    if (routeParams.type !== "edit" && routeParams.type !== "view" && routeParams.type !== "remove" && routeParams.type !== "aprove") {
      navigate("/buy-order");
    }
  }, [routeParams, navigate, selectedOrder]);

  useEffect(() => {
    const init = async () => {
      try {
        const response = await getBuyOrder(selectedOrder.id);

        if (!response) {
          return navigate("/buy-orders");
        }

        const ocStatus = Number(response.status);

        if (routeParams.type === "edit" || routeParams.type === "remove" || routeParams.type === "aprove") {
          if (
            ocStatus === OcStatus.CANCELED ||
            ocStatus === OcStatus.BILLING_PROCESS ||
            ocStatus === OcStatus.REPROVED ||
            ocStatus === OcStatus.PARTIAL_BILLING_PROCESS
          ) {
            toast.error(`Não é possível alterar o registro ${response.id}`);
            return navigate("/buy-orders");
          }
        }

        const { itens, tipo, data_primeira_compra, ...rest } = response;

        const paymentsInfo = await loadingPaymentsConditions(tipo);

        await getCCOptions();

        console.log(itens);

        const parsedItens = itens?.map((product, index) => {
          return {
            ...product,
            id: index,
            valor_total: formatValue(product.valor_total as number),
            valor_unitario: formatValue(product.valor_unitario as number, 4),
            cartao_corporativo: (product.cartao_corporativo as any) === "S",
          };
        });

        setBudgets({
          valor_orcamento1: formatValue(rest.valor_orcamento1),
          valor_orcamento2: formatValue(rest.valor_orcamento2),
          valor_orcamento3: formatValue(rest.valor_orcamento3),
        });

        //Ajustar data aprovação financ

        const order = {
          ...rest,
          tipo,
          data_primeira_compra,
          itens: parsedItens,
          /* data_aprovacao_financeiro: new Date(data_aprovacao_financeiro).toLocaleDateString("pt-BR", {
                        day: "2-digit",
                        month: "2-digit",
                        year: "numeric"
                    }) */
        };

        console.log(parsedItens);

        setBuyOrder(order);
        setIsNewProvider(!data_primeira_compra.trim());
        loadInitialFormData(order, paymentsInfo || []);
        setProductsList(parsedItens || []);

        setIsLoading(false);
      } catch (error) {
        console.log(error);
        toast.error("Não foi possível carregar os dados da ordem");
        navigate("/buy-orders");
      }
    };

    init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedOrder]);

  useEffect(() => {
    if (!isLoading) {
      loadingPaymentsConditions(selectedOcType);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedOcType]);

  return (
    <>
      <Container>
        {isLoading ? (
          <LoadingContainer>
            <CircularProgress />
          </LoadingContainer>
        ) : (
          <Form
            ref={formRef}
            onSubmit={handleSubmit}
            onKeyPress={(event) => {
              if (event.key === "Enter") {
                event.preventDefault();
              }
            }}
            initialData={initialFormValues}
          >
            <FormCard title="Dados Gerais">
              <Grid container>
                <Grid item xs={12} md={2}>
                  <FormInput variant="outlined" name="id" label="Numero Solic." size="small" fullWidth disabled />
                </Grid>
                <Grid item xs={12} md={3}>
                  <FormSelect
                    name="type"
                    label="Tipo Solicitação *"
                    type="text"
                    size="small"
                    fullWidth
                    options={OCFormDefaultValue.typeRequest}
                    disabled={!canEditFields}
                    onChange={(event) => {
                      paymentConditionRef.current?.changeValue(null);
                      setSelectedOCType(event.target.value);
                      loadingPaymentsConditions(event.target.value);
                      /* setBuyOrder(prev => ({ ...prev, codigo_condicao_pagamento: "" })) */
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <FormSelect
                    name="brand"
                    label="Marca *"
                    type="text"
                    size="small"
                    fullWidth
                    disabled={!canEditFields}
                    options={OCFormDefaultValue.brands}
                  />
                </Grid>
                <Grid item xs={12} md={2}>
                  <FormDatePicker
                    name="dateOC"
                    views={["day"]}
                    inputFormat="dd/MM/yyyy"
                    label="Data da Ordem"
                    size="small"
                    fullWidth
                    disabled
                  />
                </Grid>
                <Grid item xs={12} md={2} style={{ position: "relative" }}>
                  <FormDatePicker
                    name="dateDelivery"
                    views={["day"]}
                    inputFormat="dd/MM/yyyy"
                    label="Data de Entrega *"
                    size="small"
                    fullWidth
                    disabled={!canEditFields}
                    minDate={new Date(buyOrder.data_emissao)}
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <FormInput variant="outlined" name="requester" label="Solicitante" size="small" fullWidth disabled />
                </Grid>
                <Grid item xs={12} md={3}>
                  <FormInput variant="outlined" name="sede" label="Faturar em: *" size="small" fullWidth disabled />
                </Grid>
                <Grid item xs={12} md={3}>
                  <FormSelect
                    name="delivery"
                    label="Local Entrega *"
                    size="small"
                    fullWidth
                    disabled={!canEditFields}
                    options={OCFormDefaultValue.localeDelivery.find((item) => item.branch === buyOrder.faturar_em)?.itens || []}
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <FormAutoComplete
                    ref={paymentConditionRef}
                    name="condicao_pagamento"
                    label="Condição de Pagamento *"
                    options={paymentOptions}
                    disabled={!canEditFields}
                    loading={isLoadingPaymentConditions}
                  />
                </Grid>
                {selectedOcType !== "1" && selectedOcType && (
                  <Grid item xs={12} md={12}>
                    <FormInput
                      variant="outlined"
                      name="obs_tipo_pagto"
                      label="Informe o motivo desta solicitação*"
                      size="small"
                      fullWidth
                      disabled={!canEditFields}
                    />
                  </Grid>
                )}
              </Grid>
            </FormCard>
            <FormCard title="Fornecedor">
              <Grid container>
                <Grid item xs={12} md={3} style={{ position: "relative" }}>
                  <FormInput
                    ref={cnpjRef}
                    variant="outlined"
                    name="cnpj"
                    label={`CNPJ/CPF *`}
                    type="text"
                    size="small"
                    fullWidth
                    disabled
                    inputProps={{ maxLength: 18 }}
                  />
                  {canEditFields && (
                    <CNPJActions>
                      <button type="button" onClick={() => setOpenSuppliersDialog(true)}>
                        <SearchIcon />
                      </button>
                    </CNPJActions>
                  )}
                </Grid>
                <Grid item xs={8} md={2}>
                  <FormInput ref={codRef} variant="outlined" name="cod" label={`Código *`} type="text" size="small" fullWidth disabled />
                </Grid>
                <Grid item xs={4} md={1}>
                  <FormInput ref={lojaRef} variant="outlined" name="loja" label={`Loja *`} type="text" size="small" fullWidth disabled />
                </Grid>
                <Grid item xs={12} md={6}>
                  <FormInput
                    variant="outlined"
                    ref={reasonRef}
                    name="razao"
                    label={`Razão Social *`}
                    type="text"
                    size="small"
                    fullWidth
                    disabled
                  />
                </Grid>
                <Grid item xs={12} md={7}>
                  <FormInput
                    variant="outlined"
                    ref={nameRef}
                    name="nome"
                    label={`Nome Fantasia *`}
                    type="text"
                    size="small"
                    fullWidth
                    disabled
                  />
                </Grid>
                <Grid item xs={12} md={5}>
                  <FormInput
                    variant="outlined"
                    ref={subRef}
                    name="ie"
                    label="Inscrição Estadual"
                    type="text"
                    size="small"
                    fullWidth
                    disabled
                  />
                </Grid>
                <Grid item xs={12} md={12}>
                  <FormInput variant="outlined" ref={emailRef} name="email" label="E-mail" type="text" size="small" fullWidth disabled />
                </Grid>
              </Grid>
            </FormCard>
            <FormCard title="Produtos">
              <ProductsSelection>
                <DataTable
                  columns={tableColumns}
                  rows={productsList}
                  paginationType="disabled"
                  disableCheckboxSelection
                  setRowClassName={(row) => {
                    if (
                      Number(buyOrder.status) === OcStatus.APROVE_COAST_CENTER &&
                      userCostCenterItems?.every((item) => item.id !== row.id)
                    ) {
                      return "unselectable-cost-center";
                    }

                    return "";
                  }}
                />
                {canEditFields && <AddProductButton onSelect={() => setOpenProductsDialog(true)} />}
                {productsList.length > 0 && (
                  <p style={{ float: "right" }}>
                    <b>Total:</b> {totalProducts}
                  </p>
                )}
              </ProductsSelection>
              <FormInput
                variant="outlined"
                name="observation"
                label={`Descreva o item ou o serviço e finalidade * (Caracters proibídos: ', ", \\, /)`}
                type="text"
                size="small"
                fullWidth
                multiline
                maxRows={4}
                disabled={!canEditFields}
              />
            </FormCard>
            {buyOrder.valor_orcamento2 || buyOrder.valor_orcamento2 ? (
              <FormCard title="Orçamentos">
                <Grid container>
                  {buyOrder.valor_orcamento2 && (
                    <Grid item xs={12} md={6}>
                      <FormInput
                        variant="outlined"
                        name="valor_orcamento2"
                        label="Orçamento 2"
                        type="text"
                        size="small"
                        fullWidth
                        disabled={!canEditFields}
                        value={budgets.valor_orcamento2}
                        onChange={(event: any) => {
                          if (!canEditFields) {
                            return;
                          }

                          handleBudgetChanges(event.target.value.replace(/[^0-9., ]+/g, ""), "valor_orcamento2");
                        }}
                        onBlur={(event: any) => {
                          handleBudgetOnBlur(event.target.value, "valor_orcamento2");
                        }}
                      />
                    </Grid>
                  )}
                  {buyOrder.valor_orcamento3 && (
                    <Grid item xs={12} md={6}>
                      <FormInput
                        variant="outlined"
                        name="valor_orcamento3"
                        label="Orçamento 3"
                        type="text"
                        size="small"
                        fullWidth
                        disabled={!canEditFields}
                        value={budgets.valor_orcamento3}
                        onChange={(event: any) => {
                          if (!canEditFields) {
                            return;
                          }

                          handleBudgetChanges(event.target.value.replace(/[^0-9., ]+/g, ""), "valor_orcamento3");
                        }}
                        onBlur={(event: any) => {
                          handleBudgetOnBlur(event.target.value, "valor_orcamento3");
                        }}
                      />
                    </Grid>
                  )}
                  {buyOrder.valor_orcamento2 && (
                    <Grid item xs={12} md={6}>
                      <FormInput
                        variant="outlined"
                        name="saving2"
                        label="Saving 2"
                        type="text"
                        size="small"
                        fullWidth
                        disabled
                        value={savings.saving2}
                      />
                    </Grid>
                  )}
                  {buyOrder.valor_orcamento3 && (
                    <Grid item xs={12} md={6}>
                      <FormInput
                        variant="outlined"
                        name="saving3"
                        label="Saving 3"
                        type="text"
                        size="small"
                        fullWidth
                        disabled
                        value={savings.saving3}
                      />
                    </Grid>
                  )}
                </Grid>
              </FormCard>
            ) : (
              <></>
            )}
            {Number(buyOrder.status) >= 2 && (
              <FormCard title="Status Solicitação">
                <Grid container>
                  <Grid item xs={12} md={3}>
                    <FormInput name="status" label="Status" size="small" fullWidth disabled />
                  </Grid>
                  {!!buyOrder.status_diver_nfxoc && (
                    <Grid item xs={12} md={3}>
                      <FormInput name="status_diver_nfxoc" label="Status NF x Solic." size="small" fullWidth disabled />
                    </Grid>
                  )}
                  {/* <Grid item xs={ 12 } md={ 12 }>
                                    <FormInput
                                        name="status"
                                        label="Justificativa Cancelamento"
                                        size="small"
                                        fullWidth
                                        disabled
                                    />
                                </Grid> */}
                </Grid>
                <Grid container>
                  <Grid item xs={12} md={!!buyOrder.hitorico_aprov.aprovador1 ? 6 : 12}>
                    <FormInput name="aprovador" label="Aprovador" size="small" fullWidth disabled />
                  </Grid>
                  {!!buyOrder.hitorico_aprov.aprovador1 && (
                    <>
                      <Grid item xs={7} md={3}>
                        <FormDatePicker
                          name="dateApprove"
                          views={["day"]}
                          inputFormat="dd/MM/yyyy"
                          size="small"
                          fullWidth
                          disabled
                          label={getStatusLabel(Number(buyOrder.status), "date")}
                        />
                      </Grid>
                      <Grid item xs={5} md={3}>
                        <FormInput
                          name="approveHour"
                          size="small"
                          fullWidth
                          disabled
                          label={getStatusLabel(Number(buyOrder.status), "hour")}
                        />
                      </Grid>
                    </>
                  )}
                  {((!!buyOrder.hitorico_aprov.aprovador1?.hora_aprovou &&
                    !!buyOrder.aprovadores.aprovador2?.id &&
                    buyOrder.status !== "4") ||
                    !!buyOrder.hitorico_aprov.aprovador2?.hora_aprovou) && (
                    <>
                      <Grid item xs={12} md={!!buyOrder.hitorico_aprov.aprovador2?.hora_aprovou ? 6 : 12}>
                        <FormInput name="aprovador2" label="Segundo Aprovador" size="small" fullWidth disabled />
                      </Grid>
                      {!!buyOrder.hitorico_aprov.aprovador2?.hora_aprovou && (
                        <>
                          <Grid item xs={7} md={3}>
                            <FormDatePicker
                              name="dateApprove2"
                              views={["day"]}
                              inputFormat="dd/MM/yyyy"
                              label={Number(buyOrder.status) === 4 ? "Data Reprovação" : "Data Aprovação"}
                              size="small"
                              fullWidth
                              disabled
                            />
                          </Grid>
                          <Grid item xs={5} md={3}>
                            <FormInput
                              name="approveHour2"
                              label={Number(buyOrder.status) === 4 ? "Hora Reprovação" : "Hora Aprovação"}
                              size="small"
                              fullWidth
                              disabled
                            />
                          </Grid>
                        </>
                      )}
                    </>
                  )}
                  {((!!buyOrder.hitorico_aprov.aprovador1?.hora_aprovou &&
                    !!buyOrder.aprovadores.aprovador3?.id &&
                    buyOrder.status !== "4") ||
                    !!buyOrder.hitorico_aprov.aprovador3?.hora_aprovou) && (
                    <>
                      <Grid item xs={12} md={!!buyOrder.hitorico_aprov.aprovador3?.hora_aprovou ? 6 : 12}>
                        <FormInput name="aprovador3" label="Terceiro Aprovador" size="small" fullWidth disabled />
                      </Grid>
                      {!!buyOrder.hitorico_aprov.aprovador3?.hora_aprovou && (
                        <>
                          <Grid item xs={7} md={3}>
                            <FormDatePicker
                              name="dateApprove3"
                              views={["day"]}
                              inputFormat="dd/MM/yyyy"
                              label={Number(buyOrder.status) === 4 ? "Data Reprovação" : "Data Aprovação"}
                              size="small"
                              fullWidth
                              disabled
                            />
                          </Grid>
                          <Grid item xs={5} md={3}>
                            <FormInput
                              name="approveHour2"
                              label={Number(buyOrder.status) === 4 ? "Hora Reprovação" : "Hora Aprovação"}
                              size="small"
                              fullWidth
                              disabled
                            />
                          </Grid>
                        </>
                      )}
                    </>
                  )}
                  {!!buyOrder.justificativa?.trim() && (
                    <Grid item xs={12} md={12}>
                      <FormInput name="justify" label="Justificativa" size="small" fullWidth disabled />
                    </Grid>
                  )}
                </Grid>
              </FormCard>
            )}
            {buyOrder.documentos.length > 0 && (
              <FormCard title="NF x Solicitação">
                <Grid container display="flex" flexDirection={["column-reverse", "row"]}>
                  <Grid item xs={12} md={6}>
                    <FormInput name="nome_aprovador_divergencia" label="Aprovador" type="text" size="small" fullWidth disabled />
                    <br />
                  </Grid>

                  <Grid
                    item
                    xs={12}
                    md={12}
                    display="flex"
                    justifyContent={["center", "flex-end"]}
                    alignItems="center"
                    position={["relative", "absolute"]}
                    right={["0px", "25px"]}
                  >
                    <p style={{ fontSize: "18px", marginRight: "5px", color: "#bdbdbd" }}>Validações</p>
                    <ValidationsInfoButton
                      size="small"
                      color="primary"
                      onClick={() => {
                        selectAnOrder(buyOrder);
                        documentsDioalogRef.current?.openDialog();
                      }}
                    >
                      <Info color="inherit" />
                    </ValidationsInfoButton>
                  </Grid>
                </Grid>
                {buyOrder.documentos?.map((doc, index) => (
                  <>
                    {index > 0 && <br />}
                    <Grid container>
                      <Grid item xs={12} md={3}>
                        <FormInput name={`numero_nf${index + 1}`} label="Número da nota" type="text" size="small" fullWidth disabled />
                      </Grid>
                      <Grid item xs={12} md={3}>
                        <FormInput name={`serie${index + 1}`} label="Série" type="text" size="small" fullWidth disabled />
                      </Grid>
                    </Grid>
                    <Grid container>
                      <Grid item xs={12} md={!!doc.data_aprovacao_divergencia ? 2 : 4}>
                        <FormInput name={`status${index + 1}`} label="Status" type="text" size="small" disabled fullWidth />
                      </Grid>
                      <Grid item xs={12} md={2}>
                        <FormInput name={`tipo_nf${index + 1}`} label="Tipo Documento" type="text" size="small" disabled fullWidth />
                      </Grid>
                      <Grid item xs={12} md={!!doc.data_aprovacao_divergencia ? 2 : 3}>
                        <FormInput name={`emissao${index + 1}`} label="Data de Emissão" type="text" size="small" fullWidth disabled />
                      </Grid>
                      <Grid item xs={12} md={!!doc.data_aprovacao_divergencia ? 2 : 3}>
                        <FormInput name={`vencimento${index + 1}`} label="Data de Vencimento" type="text" size="small" fullWidth disabled />
                      </Grid>
                      {!!doc.data_aprovacao_divergencia && (
                        <>
                          <Grid item xs={12} md={2}>
                            <FormInput
                              name={`data_aprovacao_divergencia${index + 1}`}
                              label="Data Aprovação"
                              type="text"
                              size="small"
                              fullWidth
                              disabled
                            />
                          </Grid>
                          <Grid item xs={12} md={2}>
                            <FormInput
                              name={`hora_aprovacao_divergencia${index + 1}`}
                              label="Hora Aprovação"
                              type="text"
                              size="small"
                              fullWidth
                              disabled
                            />
                          </Grid>
                        </>
                      )}
                      {!!doc.justificativa_divergencia && (
                        <Grid item xs={12} md={12}>
                          <FormInput
                            name={`justificativa_divergencia${index + 1}`}
                            label="Justificativa Divergencia"
                            type="text"
                            size="small"
                            fullWidth
                            disabled
                          />
                        </Grid>
                      )}
                    </Grid>
                  </>
                ))}
              </FormCard>
            )}
            {!!buyOrder.aprovadores.aprovador_financeiro?.id && (
              <FormCard title="Status Financeiro">
                <Grid container>
                  <Grid item xs={12} md={7}>
                    <FormInput
                      name="nome_aprovador_financeiro"
                      label="Iniciou Processo Financeiro"
                      type="text"
                      size="small"
                      fullWidth
                      disabled
                    />
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <FormInput
                      name="data_aprovacao_financeiro"
                      label="Data Início Financeiro"
                      type="text"
                      size="small"
                      fullWidth
                      disabled
                    />
                  </Grid>
                  <Grid item xs={12} md={2}>
                    <FormInput
                      name="hora_aprovacao_financeiro"
                      label="Hora Início Financeiro"
                      type="text"
                      size="small"
                      fullWidth
                      disabled
                    />
                  </Grid>
                </Grid>
              </FormCard>
            )}
            <FormActions>
              <Button
                type="button"
                size="large"
                variant="text"
                color="primary"
                onClick={() => {
                  if (!isLoadingSubmit) {
                    navigate("/buy-orders/");
                  }
                }}
              >
                Fechar
              </Button>
              {(routeParams.type === "edit" || buyOrder.status === "maintener") && (
                <Button type="submit" variant="contained" color="primary" size="large">
                  {isLoadingSubmit ? <CircularProgress color="inherit" size={26} /> : "Gravar"}
                </Button>
              )}
              {routeParams.type === "remove" && (
                <Button
                  type="button"
                  variant="contained"
                  color="primary"
                  size="large"
                  onClick={() => {
                    if (!isLoadingSubmit) {
                      onRemoveOC();
                    }
                  }}
                >
                  {isLoadingSubmit ? <CircularProgress color="inherit" size={26} /> : "Excluir"}
                </Button>
              )}
              {routeParams.type === "aprove" &&
                (!hasChangedCostCenter ? (
                  <>
                    <Button
                      type="button"
                      variant="contained"
                      color="error"
                      size="large"
                      onClick={() => {
                        if (!isLoadingSubmit) {
                          refuseModalRef.current?.openDialog();
                        }
                      }}
                    >
                      Reprovar
                    </Button>
                    <Button
                      type="button"
                      variant="contained"
                      color="success"
                      size="large"
                      onClick={() => {
                        if (!isLoadingSubmit) {
                          onAprove();
                        }
                      }}
                    >
                      {isLoadingSubmit ? <CircularProgress color="inherit" size={26} /> : "Aprovar"}
                    </Button>
                  </>
                ) : (
                  <>
                    <Button type="button" variant="contained" color="error" size="large" onClick={handleCancelCostCenter}>
                      Cancelar
                    </Button>
                    <Button type="submit" variant="contained" color="success" size="large">
                      {isLoadingSubmit ? <CircularProgress color="inherit" size={26} /> : "Alterar Centro de Custo"}
                    </Button>
                  </>
                ))}
            </FormActions>
          </Form>
        )}
      </Container>
      <SelectProductModal onSelect={onSelectProduct} open={openProductsDialog} onClose={() => setOpenProductsDialog(false)} />
      <SelectSupplierModal
        open={openSuppliersDialog}
        initialSearch={initialSearchSupplier}
        onSelect={onSelectSupplier}
        onClose={() => {
          setInitialSearchSupplier("");
          setOpenSuppliersDialog(false);
        }}
      />
      <RefuseModal ref={refuseModalRef} open={false} codigoOrdem={buyOrder.id} orderStatus={Number(buyOrder.status)} />
      <DocumentsProvider>
        <DocumentAssignment ref={documentsDioalogRef} initialStage="validation" onlyView />
      </DocumentsProvider>
      {/* <VerifyOCWithNFDialog
            open={ false }
            ref={ documentsDioalogRef }
            type={ "view" }
        /> */}
    </>
  );
}

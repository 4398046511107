import { Close } from "@mui/icons-material"
import { Request } from "../.."
import ListMobileCard from "../../../../components/Mobile/ListMobileCard"
import { formatValue } from "../../../../utils/format/formatValue"

import { DetailsContainer, DocumentItem, PendentsItem } from './style'
import { Dialog, DialogProps } from '@mui/material'
import { DialogTitleCustom } from '../../../../styles/globalStyles'

interface RequestsMobileListProps extends DialogProps{
    selectedRequest: Request
}

export const DetailsDialog: React.FC<RequestsMobileListProps> = ({ selectedRequest, onClose, ...props}) => {    
    return (
        <Dialog {...props} onClose={ onClose }>
            <DialogTitleCustom>
                Pedido: { selectedRequest.id }
                { onClose && (
                    <button onClick={() => onClose({}, "escapeKeyDown") }>
                        <Close/>
                    </button>
                )}
            </DialogTitleCustom>
            <DetailsContainer>
                { selectedRequest.documents?.length > 0 && (
                    <DocumentItem
                        rows={[{ name: "Faturados" }]}
                        columns={[{ name: 'name', title: 'Name' }]}
                        disableHeader
                        childToExpand={() => (
                            <>
                            {selectedRequest.documents.map(document => (
                                <ListMobileCard key={document.id}>
                                    <p style={{ 
                                        display: "flex", 
                                        justifyContent: "flex-end",
                                        zIndex: 1 
                                    }}>
                                        <b>{document.id}</b>
                                        { document.serie && (
                                            <>
                                            <b>-{ document.serie }</b> 
                                            </>
                                        )}
                                    </p>
                                    <p><b>Status:</b> { document.status }</p>
                                    <p><b>Data de Emissão:</b> { document.created_at }</p>
                                    <p><b>QTD TOTAL:</b> { document.total_qtd }</p>
                                    <p><b>Valor Total:</b> { formatValue(document.total_value) }</p>
                                    <br/>
                                    <p><b>Itens</b></p>
                                    { document.itens.map(item => (
                                        <ListMobileCard 
                                            key={item.id}
                                            color="#f2f2f2"
                                        >
                                            <p style={{ 
                                                display: "flex", 
                                                justifyContent: "flex-end",
                                                zIndex: 1 
                                            }}>
                                                <b>{item.id}</b>
                                            </p>
                                            <p><b>Código Produto:</b> { item.product_cod }</p>
                                            <p><b>Descrição:</b> { item.description }</p>
                                            <p><b>QTD:</b> { item.QTD }</p>
                                            <p><b>Valor Total:</b> { formatValue(item.total_value) }</p>
                                        </ListMobileCard>
                                    )) }
                                </ListMobileCard>   
                            ))}
                            </>
                        )}
                    />
                )}
                { !!selectedRequest.pendent_resquest && (
                    <PendentsItem
                        rows={[{ name: "Pendentes" }]}
                        columns={[{ name: 'name', title: 'Name' }]}
                        disableHeader
                        childToExpand={() => (
                            <>
                            {selectedRequest.pendent_resquest.itens?.map(item => (
                                <ListMobileCard key={item.id}>
                                    <p style={{ 
                                        display: "flex", 
                                        justifyContent: "flex-end",
                                        zIndex: 1 
                                    }}>
                                        <b>{item.id}</b>
                                    </p>
                                    <p><b>Código Produto:</b> { item.product_cod }</p>
                                    <p><b>Status:</b> { item.status_id }</p>
                                    <p><b>QTD:</b> { item.QTD }</p>
                                    <p><b>Valor Total:</b> { formatValue(item.total_value) }</p>
                                </ListMobileCard>
                            ))}
                            </>
                        )}
                    />
                )}
            </DetailsContainer>
        </Dialog>
    )
}
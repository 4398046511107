import { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import * as Yup from 'yup';
import { Form } from "@unform/web";
import { FormHandles } from "@unform/core";
import { toast } from "react-toastify";

import { useAuth } from "../../hooks/auth";

import { useTheme } from "../../hooks/theme";
import { getErrorMessage } from "../../utils/validations/getErrorMessage";

import { CircularProgress } from "@mui/material";

import { Container, ActionsForm, FormSection, ResetPassCard} from "./styles"
import FormInput from "../../components/Form/FormInput";
import Copyright from "../../components/Misc/Copyright";
import { Button } from "../../components/Buttons/Button";

interface ChangePassFields{
  currentPass: string
  newPass: string
  confirmPass: string
}

const formValidations = Yup.object().shape({
  currentPass: Yup.string()
    .required("Senha atual obrigatória"),
  newPass: Yup.string()
    .min(6, "No mínimo 6 carracteres")
    .notOneOf([
      null, Yup.ref("currentPass")
    ], "A senha não pode ser a mesma da anterior")
    .required("Nova senha obrigatória"),
  confirmPass: Yup.string().oneOf([
    null, Yup.ref("newPass")
  ], "As senhas precisam ser iguais").required("Confirmar senha obrigatório")
})

export const ResetPass: React.FC = () => {
  const navigate = useNavigate()

  const { changePass } = useAuth()
  const { appImages } = useTheme()

  const formRef = useRef<FormHandles>(null)
  
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const handleSubmitNewPass = async (data: ChangePassFields) => {
    if(isLoading){ return }

    try{
      await formValidations.validate(data, { 
        abortEarly: false 
      })

      setIsLoading(true)
      const message = await changePass({
        currentPass: data.currentPass,
        newPass: data.newPass
      })

      toast.success(message)

      navigate("/")
    } catch(error: any){

      if(error instanceof Yup.ValidationError){
        let validationErrors: ChangePassFields = {} as ChangePassFields
        error.inner.forEach(error => {
          if(error.path) { 
            validationErrors[error.path] = error.message 
            toast.error(error.message )
          }
        })

        formRef.current?.setErrors(validationErrors as any)

        return
      }
      setIsLoading(false)
      toast.error(getErrorMessage(error))
    }
  }

  useEffect(() => {
    toast.info("Por favor para entrar no sistema altere sua senha!")
  },[])

  return (
    <Container>
      <ResetPassCard>
        <Form ref={formRef} onSubmit={ handleSubmitNewPass }>
          <header>
            <img src={ appImages.home } alt="enterprise"/>
          </header>
          <FormSection>
            <FormInput
              name='currentPass'
              label="Senha Atual"
              type="password"
              size="small"
              fullWidth
              hiddenErrorMessage
            />
            <FormInput
              name='newPass'
              label="Nova Senha"
              type="password"
              size="small"
              fullWidth
              hiddenErrorMessage
            />
            <FormInput
              name='confirmPass'
              label="Confirmar Senha"
              type="password"
              size="small"
              fullWidth
              hiddenErrorMessage
            />
          </FormSection>
          <ActionsForm>
            <Button
              fullWidth
              size="large"
              type="submit"
              variant="contained"
              color="primary"
            >
              { isLoading ? <CircularProgress color="inherit" size={26}/> : "Entrar" }
            </Button>
          </ActionsForm>
        </Form>
      </ResetPassCard>
      <Copyright />
    </Container>
  )
}
import { Card } from 'reactstrap'
import styled from 'styled-components'
import { fade } from '../../../../../styles/animations'

export const Container = styled.div`
    width: 100%;
    height: auto;
    overflow-y: auto;
    overflow-x: visible;
    display: flex;
    flex-direction: column;

    .MuiDataGrid-selectedRowCount{
        visibility: hidden !important;
    }

    .MuiDataGrid-cell:focus{
        outline: none !important;
    }
`

export const GridContainer = styled.div`
    height: calc(100vh - 148px);
`

export const ListCard = styled(Card)`
    animation: ${ fade } 300ms;
`
import { Container, InputContainer } from './style'

export type InventoryLimit = "5days" | "15days" | "30days" | "45days" | "45plus" | "all"

interface DashboardFilterProps{
    isLoading?: boolean
    onChangeSelection: (item: InventoryLimit) => void
}

export const SupplyFilter: React.FC<DashboardFilterProps> = ({ isLoading, onChangeSelection }) => {

    const handleSelection = (event: React.ChangeEvent<HTMLInputElement>) => {
        onChangeSelection(event.target.value as InventoryLimit)
    }

    return(
        <Container>
            <InputContainer>
                <input
                    type="radio"
                    name="supply"
                    value="5days" 
                    onChange={ handleSelection }
                    defaultChecked
                    disabled={ isLoading }
                />
                0 a 5 dias
            </InputContainer>
            <InputContainer>
                <input
                    type="radio"
                    name="supply"
                    value="15days" 
                    onChange={ handleSelection }
                    disabled={ isLoading }
                />
                6 a 15 dias
            </InputContainer>
            <InputContainer>
                <input
                    type="radio"
                    name="supply"
                    value="30days" 
                    onChange={ handleSelection }
                    disabled={ isLoading }
                />
                16 a 30 dias
            </InputContainer>
            <InputContainer>
                <input
                    type="radio"
                    name="supply"
                    value="45days" 
                    onChange={ handleSelection }
                    disabled={ isLoading }
                />
                31 a 45 dias
            </InputContainer>
            <InputContainer>
                <input
                    type="radio"
                    name="supply"
                    value="45plus" 
                    onChange={ handleSelection }
                    disabled={ isLoading }
                />
                +45 dias
            </InputContainer>
            <InputContainer>
                <input
                    type="radio"
                    name="supply"
                    value="all" 
                    onChange={ handleSelection }
                    disabled={ isLoading }
                />
                Todos
            </InputContainer>
        </Container>
    )
}
import styled from 'styled-components'
import { Breadcrumbs } from "@mui/material"
import { Link } from 'react-router-dom'
import { lighten } from 'polished'

export const BreadCrumbsCustom = styled(Breadcrumbs)`
  width: auto;
  height: auto;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  background-color: #FFFFFF00 !important;

  a{
    font-weight: 600 !important;
    color: ${ lighten(0.4, "#000000")};
    transition: color 200ms;

    &:hover{
      color: ${ lighten(0.6, "#000000")};
    }
  }
`

export const LinkCustom = styled(Link)`
  width: auto;
  text-decoration: none;
  display: flex;
  color: #262626;

  svg{
    margin-right: 5%;
    color: #262626;
  }
`

export const TextCustom = styled.div`
  width: auto;
  text-decoration: none;
  display: flex;
  color: ${ lighten(0.4, "#000000")};
  font-weight: 600;

  svg{
    margin-right: 5%;
    color: #262626;
  }

  &:hover{
    cursor: default
  }
`
import styled from "styled-components";
import { css } from 'styled-components';
import { fade } from "../../../../../styles/animations";
import { shade, lighten } from "polished";
import * as RaCheckbox from '@radix-ui/react-checkbox'
 
import { DialogActions, DialogContent, Grid } from "@mui/material";

export type AlertProps = "ok" | "alerta" | "divergente" | "info" | "cancelar"

interface ErrorPaperProps {
    open: boolean
    type: AlertProps
}

interface InfoProps{
    status: AlertProps
}

export const Content = styled(DialogContent)`
    display: flex;
    flex-direction: column;
    gap: 8px;
    height: auto;
    max-height: 640px;
`

export const ProviderInfo = styled.div`
    margin-top: 25px;
    width: 100%;
    display: flex;
    flex-direction: column;
    position: relative;

    section {
        div {
            display: flex;
            flex-direction: row;
            gap: 22px;
        }
    }
`

export const ProviderActions = styled.div`
    margin: 12px 0px;

    label{
        width: auto;
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-left: -12px;
    }

    p{
        font-size: 12px;
        position: absolute;
        right: 0px;
        bottom: 12px;
        color: #005176;
        text-decoration: underline;
        cursor: pointer;
    }
`

export const GridNav = styled.nav`
    display: flex;
    flex-direction: column;
    gap: 15px;
    height: auto;
    max-height: 500px;
    overflow-y: auto;
`

export const Actions = styled(DialogActions)`
`

export const DocumentCard = styled.div`
    width: 100%;
    background-color: #d6d6d6;
    padding: 15px;
    border-radius: 10px;
    animation: ${ fade } 200ms;
    
    header{
        position: relative;
        margin-bottom: 10px;

        p:first-child{
            position: absolute;
            top: 0px;
            right: 0px;
        }
    }
`

export const InfosHeader = styled.p`
    font-size: 1.3rem;
    font-weight: bold;
`

export const InfoContainer = styled(Grid)<InfoProps>`
    width: 100% !important;
    padding-left: 15px;
    border-radius: 5px;
    color: #3172b7;
    background: #ebf9ff;
    margin-left: 0px !important;
    overflow: visible !important;

    ${ props => props.status === "ok" && css`
        color: #008518;
        background: #e6fffa;
    `}

    ${ props => props.status === "divergente" && css`
        color: #c53030;
        background: #fddede;
    `}

    ${ props => props.status === "alerta" && css`
        color: #FFCC00;
        background: #faf3d9;
    `}

    ${ props => props.status === "cancelar" && css`
        color: ${({ theme }) => theme.danger};
        background: #fddede;
    `}

    .MuiGrid-item{
        padding: 5px !important;
    }
`

export const InfoField = styled.div<InfoProps>`
    background-color: #f9f9f9;
    border: 2px solid #f9f9f9;
    width: 100%;
    height: 40px;
    border-radius: 5px;
    padding: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #3172b7;
    border-color: #3172b7;

    p{
        font-size: 1.2rem;

        &:hover{
            cursor: default;
        }
    }

    ${ props => props.status === "ok" && css`
        color: #008518;
        border-color: #008518;
    `}

    ${ props => props.status === "divergente" && css`
        color: #c53030;
        border-color: #c53030;
    `}

    ${ props => props.status === "alerta" && css`
        color: #FFCC00;
        border-color: #FFCC00;
    `}

    ${ props => props.status === "cancelar" && css`
        color: ${({ theme }) => theme.danger};
        border-color: ${({ theme }) => theme.danger};
    `}
`

export const InfoButton = styled.div<InfoProps>`
    transition: 200ms;
    &:hover{
        cursor: pointer;
        color: ${ shade(0.2, "#3172b7") };
    }

    ${ props => props.status === "ok" && css`
        &:hover{
            cursor: pointer;
            color: ${ shade(0.2, "#008518") };
        }
    `}

    ${ props => props.status === "divergente" && css`
        &:hover{
            cursor: pointer;
            color: ${ shade(0.2, "#c53030") };
        }
    `}

    ${ props => props.status === "alerta" && css`
        &:hover{
            cursor: pointer;
            color: ${ shade(0.2, "#FFCC00") };
        }
    `}

    ${ props => props.status === "cancelar" && css`
        &:hover{
            cursor: pointer;
            color: ${ lighten(0.2, "#1a1a19") };
        }
    `}
`

export const ErrorPaper = styled.div<ErrorPaperProps>`
    position: absolute;
    width: max-content;
    max-width: 230px;
    z-index: 999999 !important;
    background-color: #3172b7;
    right: 15px;
    border-radius: 7px 0px 7px 7px;
    visibility: ${props => props.open ? "visible" : "hidden" };
    top: 50px;
    transition: 200ms;

    ${props => props.open && css`
        padding: 15px;
        animation: ${fade} 200ms;
    `}

    &::before{
        content: "";
        border-top: 15px solid #ffffff00;
        border-right: 15px solid #3172b7;
        position: absolute;
        top: -10px;
        right: 0px;
    }

    p{
        font-family: 'Roboto Slab', sans-serif !important;
        font-size: 600;
        color: #f9f9f9;
        transition: 120ms;

        &:hover{
            cursor: default;
        }

        ${props => !props.open && css`
            opacity: 0;
        `}
    }

    ${ props => props.type === "ok" && css`
        background-color: #008518;
        &::before{
            content: "";
            border-top: 15px solid #ffffff00;
            border-right: 15px solid #008518;
            position: absolute;
            top: -10px;
            right: 0px;
        }
    `}

    ${ props => props.type === "divergente" && css`
        background-color: #c53030;
        &::before{
            content: "";
            border-top: 15px solid #ffffff00;
            border-right: 15px solid #c53030;
            position: absolute;
            top: -10px;
            right: 0px;
        }
    `}

    ${ props => props.type === "alerta" && css`
        background-color: #FFCC00;
        &::before{
            content: "";
            border-top: 15px solid #ffffff00;
            border-right: 15px solid #FFCC00;
            position: absolute;
            top: -10px;
            right: 0px;
        }
    `}

    ${ props => props.type === "cancelar" && css`
        background-color: #1a1a19;
        &::before{
            content: "";
            border-top: 15px solid #ffffff00;
            border-right: 15px solid #1a1a19;
            position: absolute;
            top: -10px;
            right: 0px;
        }
    `}
`

export const Checkbox = styled(RaCheckbox.Root)`
  background-color: white;
  width: 25px;
  height: 25px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 2px 2px 4px #f2f2f2;
  transition: 200ms;
  border: 1px solid #f2f2f2;
  pointer-events: painted;

  &[data-state="checked"]{
    background-color: #007bff;
  }
`

export const Indicator = styled(RaCheckbox.Indicator)`
  color: white;
`
import { IconProps } from "../../../@types/icons";

export const AwaitAproveIcon: React.FC<IconProps> = ({ color, height, size }) => (
    <svg 
        width={ size || "32"} 
        height={ height || "32"}  
        color={ color }
        viewBox="0 0 32 32" 
        fill="none" 
        xmlns="http://www.w3.org/2000/svg"
    >
        <path d="M9.33331 29.3334C8.59998 29.3334 7.97242 29.0725 7.45065 28.5507C6.92798 28.028 6.66665 27.4 6.66665 26.6667C6.66665 25.9334 6.92798 25.3054 7.45065 24.7827C7.97242 24.2609 8.59998 24 9.33331 24C10.0666 24 10.6942 24.2609 11.216 24.7827C11.7386 25.3054 12 25.9334 12 26.6667C12 27.4 11.7386 28.028 11.216 28.5507C10.6942 29.0725 10.0666 29.3334 9.33331 29.3334ZM22.6666 29.3334C21.9333 29.3334 21.3058 29.0725 20.784 28.5507C20.2613 28.028 20 27.4 20 26.6667C20 25.9334 20.2613 25.3054 20.784 24.7827C21.3058 24.2609 21.9333 24 22.6666 24C23.4 24 24.028 24.2609 24.5506 24.7827C25.0724 25.3054 25.3333 25.9334 25.3333 26.6667C25.3333 27.4 25.0724 28.028 24.5506 28.5507C24.028 29.0725 23.4 29.3334 22.6666 29.3334ZM9.33331 22.6667C8.33331 22.6667 7.57776 22.2276 7.06665 21.3494C6.55554 20.472 6.53331 19.6 6.99998 18.7334L8.79998 15.4667L3.99998 5.33335H2.63331C2.25554 5.33335 1.94442 5.20535 1.69998 4.94935C1.45554 4.69424 1.33331 4.3778 1.33331 4.00002C1.33331 3.62224 1.46131 3.30535 1.71731 3.04935C1.97242 2.79424 2.28887 2.66669 2.66665 2.66669H4.83331C5.07776 2.66669 5.31109 2.73335 5.53331 2.86669C5.75554 3.00002 5.9222 3.18891 6.03331 3.43335L6.93331 5.33335H26.6C27.2 5.33335 27.6111 5.55558 27.8333 6.00002C28.0555 6.44446 28.0444 6.91113 27.8 7.40002L23.0666 15.9334C22.8222 16.3778 22.5 16.7222 22.1 16.9667C21.7 17.2111 21.2444 17.3334 20.7333 17.3334H10.8L9.33331 20H24.0333C24.4111 20 24.7222 20.1276 24.9666 20.3827C25.2111 20.6387 25.3333 20.9556 25.3333 21.3334C25.3333 21.7111 25.2053 22.0276 24.9493 22.2827C24.6942 22.5387 24.3778 22.6667 24 22.6667H9.33331Z" fill="#E3E309"/>
        <path d="M17.1108 7.27273L16.9148 13.3281H15.1634L14.9673 7.27273H17.1108ZM16.0369 16.1236C15.733 16.1236 15.4716 16.0156 15.2528 15.7997C15.0369 15.5838 14.929 15.3224 14.929 15.0156C14.929 14.7145 15.0369 14.4574 15.2528 14.2443C15.4716 14.0284 15.733 13.9205 16.0369 13.9205C16.3295 13.9205 16.5866 14.0284 16.8082 14.2443C17.0327 14.4574 17.1449 14.7145 17.1449 15.0156C17.1449 15.2202 17.0923 15.4062 16.9872 15.5739C16.8849 15.7415 16.75 15.875 16.5824 15.9744C16.4176 16.0739 16.2358 16.1236 16.0369 16.1236Z" fill="white"/>
    </svg>
)
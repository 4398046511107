import styled from "styled-components"
import { css } from "styled-components"
import { shade } from "polished"

import { fade } from '../../../../styles/animations'

interface ProgressItemProps{
    displayName: string
    isSelected: boolean
}

interface ProgressItemContainerProps{
    startTransition?: boolean
    barSize?: number
}

export const Container = styled.header`
    padding: 15px;
    margin-bottom: 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    position: relative;
`

export const ProgressItemContainer = styled.div<ProgressItemContainerProps>`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    div.bar{
        position: absolute;
        background-color: #6e85fa;
        background-color: ${ shade(0.2, "#f9f9f9") };
        width: ${props => props.barSize ? props.barSize - 1 : "30"}%;
        height: 15px;
        margin-right: calc(2px - 30%);
        box-shadow: 4px 5px 5px ${ shade(0.1, "#f9f9f9") };
        display: flex;
        justify-content: flex-start;

        span{
            width: 0%;
            height: 15px;
            background-color: ${props => props.theme["blue-800"] };
            transition: 200ms

            ${ props => !!props.startTransition && css`
                width: 100%;
                -webkit-transition: width 1s ease-in;
                -moz-transition: width 1s ease-in;
                -o-transition: width 1s ease-in;
            `}
        }

    }

    div.check{
        position: absolute;
        top: 10px;
        margin-right: -6%;
        background-color: #28a745;
        width: 20px;
        height: 20px;
        border-radius: 50%;
        animation: ${ fade } 200ms;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 3;
    }

`

export const ProgressItem = styled.div<ProgressItemProps>`
    background-color: ${ shade(0.2, "#f9f9f9") };
    border-radius: 50%;
    width: 70px;
    height: 70px;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
    color: ${ shade(0.9, "#f9f9f9") };
    font-family: sans-serif;
    font-size: 18px;
    font-weight: 600;
    box-shadow: 0px 5px 5px  ${ shade(0.3, "#f9f9f9") };

    &::before{
        content: "${props => props.displayName}";
        position: absolute;
        color: #000;
        bottom: -15px;
        font-weight: 500;
    }

    &:hover{
        cursor: default;
    }

    ${ props => props.isSelected && css`
        background-color: ${ props.theme["blue-800"] };
        color: #f9f9f9;
    `}

`
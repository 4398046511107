import { IconProps } from "../../../@types/icons";

export const BuyOrderIcon: React.FC<IconProps> = ({ color, height, size }) => (
    <svg 
        width={ size || "50"} 
        height={ height || "32"}  
        color={ color }
        viewBox="0 0 32 32" 
        fill="none" 
        xmlns="http://www.w3.org/2000/svg"
    >
        <path d="M9.7778 31.1667C9.00955 31.1667 8.35211 30.8895 7.80548 30.3351C7.25793 29.7798 6.98415 29.1125 6.98415 28.3333C6.98415 27.5542 7.25793 26.8869 7.80548 26.3316C8.35211 25.7772 9.00955 25.5 9.7778 25.5C10.5461 25.5 11.2035 25.7772 11.7501 26.3316C12.2977 26.8869 12.5715 27.5542 12.5715 28.3333C12.5715 29.1125 12.2977 29.7798 11.7501 30.3351C11.2035 30.8895 10.5461 31.1667 9.7778 31.1667ZM23.7461 31.1667C22.9778 31.1667 22.3204 30.8895 21.7737 30.3351C21.2262 29.7798 20.9524 29.1125 20.9524 28.3333C20.9524 27.5542 21.2262 26.8869 21.7737 26.3316C22.3204 25.7772 22.9778 25.5 23.7461 25.5C24.5143 25.5 25.1722 25.7772 25.7198 26.3316C26.2664 26.8869 26.5397 27.5542 26.5397 28.3333C26.5397 29.1125 26.2664 29.7798 25.7198 30.3351C25.1722 30.8895 24.5143 31.1667 23.7461 31.1667ZM9.7778 24.0833C8.73018 24.0833 7.93865 23.6168 7.4032 22.6837C6.86775 21.7515 6.84447 20.825 7.33336 19.9042L9.21907 16.4333L4.1905 5.66668H2.75876C2.36299 5.66668 2.03706 5.53068 1.78098 5.25868C1.52489 4.98762 1.39685 4.6514 1.39685 4.25001C1.39685 3.84862 1.53095 3.51193 1.79914 3.23993C2.0664 2.96887 2.39791 2.83334 2.79368 2.83334H5.06352C5.3196 2.83334 5.56405 2.90418 5.79685 3.04584C6.02965 3.18751 6.20426 3.3882 6.32066 3.64793L7.26352 5.66668H27.8667C28.4953 5.66668 28.9259 5.90279 29.1588 6.37501C29.3916 6.84723 29.3799 7.34307 29.1238 7.86251L24.1651 16.9292C23.909 17.4014 23.5715 17.7674 23.1524 18.0271C22.7334 18.2868 22.2561 18.4167 21.7207 18.4167H11.3143L9.7778 21.25H25.1778C25.5736 21.25 25.8995 21.3855 26.1556 21.6566C26.4117 21.9286 26.5397 22.2653 26.5397 22.6667C26.5397 23.0681 26.4056 23.4043 26.1374 23.6753C25.8702 23.9473 25.5386 24.0833 25.1429 24.0833H9.7778Z" fill="black"/>
        <path d="M38.1 23.7564C38.1 28.9312 33.9795 33.1 28.9285 33.1C23.8776 33.1 19.7571 28.9312 19.7571 23.7564C19.7571 18.5816 23.8776 14.4128 28.9285 14.4128C33.9795 14.4128 38.1 18.5816 38.1 23.7564Z" fill="black" stroke="white" strokeWidth="1.8"/>
        <path d="M30.2227 22.9347L30.2408 19.5375C30.2414 19.3998 30.2154 19.2635 30.164 19.1365C30.1127 19.0095 30.037 18.8943 29.9415 18.7974C29.8459 18.7005 29.7323 18.6238 29.6071 18.5717C29.4818 18.5196 29.3475 18.4931 29.2117 18.4938C29.0759 18.4945 28.9412 18.5223 28.8155 18.5757C28.6898 18.629 28.5754 18.7068 28.4789 18.8047C28.3824 18.9026 28.3056 19.0186 28.253 19.1462C28.2004 19.2737 28.173 19.4102 28.1723 19.548L28.1571 22.9451L24.8076 22.9605C24.6718 22.9611 24.5372 22.9889 24.4114 23.0423C24.2857 23.0956 24.1713 23.1735 24.0748 23.2714C23.9783 23.3692 23.9015 23.4853 23.8489 23.6128C23.7963 23.7403 23.7689 23.8768 23.7683 24.0146C23.7676 24.1523 23.7937 24.2886 23.845 24.4156C23.8964 24.5426 23.972 24.6578 24.0675 24.7547C24.1631 24.8517 24.2767 24.9283 24.402 24.9804C24.5272 25.0325 24.6615 25.059 24.7973 25.0583L28.1469 25.0399L28.1288 28.4371C28.1274 28.7152 28.2351 28.9815 28.4281 29.1772C28.6211 29.373 28.8836 29.4821 29.1579 29.4808C29.4322 29.4794 29.6958 29.3675 29.8907 29.1699C30.0856 28.9722 30.1959 28.7048 30.1972 28.4266L30.2124 25.0295L33.5619 25.0141C33.8362 25.0128 34.0998 24.9009 34.2948 24.7032C34.4897 24.5055 34.5999 24.2382 34.6013 23.96C34.6027 23.6818 34.495 23.4156 34.302 23.2198C34.109 23.0241 33.8465 22.9149 33.5722 22.9163L30.2227 22.9347Z" fill="white"/>
    </svg>
)
import React from 'react'
import { createContext, useContext, useCallback } from 'react'
import { Bank, BillingContextProps, CostCenter, PaymentConditions } from '../@types/billing'

import api from '../services/api.services'

import { useAuth } from './auth'

const BillingContext = createContext({} as BillingContextProps)

export const BillingProvider: React.FC = ({ children }) => {

    const { userData } = useAuth()

    const getCostCenter = useCallback(async(): Promise<CostCenter[]> => {
        try{
            const response = await api.get("/centrocusto",{
                params: { branch: userData.selectedBranch?.id || "" }
            })

            if(!response.data.data){
                throw new Error("Nenhum centro de custo encontrado")
            }
            
            return response.data.data.centrocusto
        } catch(error: any){
            throw error
        }
    },[userData.selectedBranch])

    const getPaymentConditions = useCallback(async(ocType?: string): Promise<PaymentConditions[]> => {

        const params: any = {
            branch: userData.selectedBranch?.id || ""
        }

        if(!!ocType){
            params.tipo = ocType
        }
        try{
            const response = await api.get("/condicaopagamento",{
                params: params
            })

            if(!response.data.data){
                throw new Error("Nenhuma condição de pagamento encontrada")
            }

            return response.data.data.condicaopagamento

        } catch(error: any){
            console.log(error)
            throw error
        }
    },[userData.selectedBranch])

    const getBanks = useCallback(async(): Promise<Bank[]> => {
        const requestParams = {
            tabela: "SX5",
            campos: "X5_CHAVE,X5_DESCRI",
            procura: "X5_TABELA",
            pesquisa: "ZW"
        }

        try{
            const response = await api.get("/consultapadrao", {
                params: {
                    ...requestParams,
                    branch: userData.selectedBranch?.id as string
                }
            })

            if(!response.data.data){
                return []
            }

            /* const banks = new Set<string>(response.data.data.consultapadrao.map((info: any) => JSON.stringify({
                id: info.X5_CHAVE,
                name: info.X5_DESCRI
            })))

            return Array.from(banks).map(bank => JSON.parse(bank)) */

            return response.data.data.consultapadrao.map((info: any) => ({
                id: info.X5_CHAVE,
                name: info.X5_DESCRI
            }))
            
        }catch(error: any){
            throw error
        }
    },[userData.selectedBranch])

    return (
        <BillingContext.Provider value={{ getCostCenter, getPaymentConditions, getBanks }}>
            { children }
        </BillingContext.Provider>
    )
}

export const useBilling = () => {
    const context = useContext(BillingContext)

    if(!context){
        throw new Error("Hook 'useBilling' must be used inside a Billing Provider")
    }

    return context
}
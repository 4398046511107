import { useState, useRef, useEffect, useMemo } from 'react'
import { toast } from 'react-toastify'
import { Column, TableColumnWidthInfo } from '@devexpress/dx-react-grid'
import { useQuery } from 'react-query'
import qs from 'qs'

import apiService from '../../services/api.services'
import { queryClient } from '../../services/queryClient'

import { getErrorMessage } from '../../utils/validations/getErrorMessage'
import { formatValue } from '../../utils/format/formatValue'
import { getDate } from '../../utils/format/getData'
import { exportExcel } from '../../utils/misc/exportExcel'

import { DrilldownTable } from '../../components/Tables/DrilldownTable'
import { DocumentsGrid } from './components/DocumentsGrid'
import RequestsMobileList from './components/RequestsMobileLists'
import { RequestsDetailsFilter, RequestsDetailsFilters } from './components/Filters'

import { useTheme as useMuiTheme, useMediaQuery, Tooltip, CircularProgress } from '@mui/material'
import { Download, Refresh } from '@mui/icons-material'

import { DefaultTheme } from '../../themes/defaultTheme'
import { 
    ActionButton,
    Container,
    DefaultTable,
    Header
} from './style'

interface DocItens{
    id: string
    product_cod: string
    description: string
    QTD: number
    total_value: number
}

export interface Document {
    id: string
    number: string
    serie: string
    status: string
    created_at: string
    total_qtd: number
    total_value: number
    itens: DocItens[]
    luft: {
        status: string
        exp_status: string
        delivery_status: string
        delivery_forecast: string
        delivery_date: string
    }
}

export interface Pendent{
    total_products: number
    total_qtd: number
    total_value: number
    itens: PendentItens[]
}

interface PendentItens{
    id: string
    product_cod: string
    description: string
    QTD: number
    total_value: number
    status_id: string
}

interface ItemValues{
    qtd: number
    value: number
}

export interface Request{
    id: string
    status: string
    status_luft: string
    delivered_at: string
    realized: number
    total: ItemValues
    oInvoiced: ItemValues
    pendent: ItemValues
    documents: Document[],
    pendent_resquest: Pendent
    client: {
        id: string
        shop: string
        uf: string
        nome_display: string
    }
}

interface RequestsAxiosResponse{
    total_registers: string
    atual_page: string
    data: Request[]
}

interface QueryData{
    requests: Request[]
    totalRegisters: number
}

interface FilterParams{
    entradade: string
    entradaate: string
    display?: string[] | null
    codproduto: string
    marca: string
    anomes: string
    periodo: string
}

const columns: Column[] = [
    { name: 'status_label', title: 'Status' },
    { name: 'id', title: 'Pedido' },
    { name: 'client', title: 'Cliente' },
    { name: 'delivered_at', title: 'Recebimento' },
    { name: 'state', title: 'Estado' },
    { name: 'qtd_loss', title: 'QTD Pend.' },
    { name: 'qtd_confirmed', title: 'QTD Confi.' },
    { name: 'sales_loss', title: 'Vendas Pend.' },
    { name: 'sales_confirmed', title: 'Vendas Confi.' },
    { name: 'qtd_total', title: 'QTD Total' },
    { name: 'sales_total', title: 'Vendas Total' },
    { name: 'realized', title: '% Realizado' }
]

const defaultColumnsWidth: TableColumnWidthInfo[] = [
    { columnName: 'status_label', width: 150 },
    { columnName: 'id', width: 150 },
    { columnName: 'client', width: 350},
    { columnName: 'delivered_at', width: 150 },
    { columnName: 'state', width: 80 },
    { columnName: 'qtd_loss', width: 150 },
    { columnName: 'qtd_confirmed', width: 150 },
    { columnName: 'sales_loss', width: 150 },
    { columnName: 'sales_confirmed', width: 150 },
    { columnName: 'qtd_total', width: 150 },
    { columnName: 'sales_total', width: 150 },
    { columnName: 'realized', width: 150 }
]

export const RequestDetails: React.FC = () => {
    const pageLimiter = 20
    const muiTheme = useMuiTheme()
    const isMobileView = useMediaQuery(muiTheme.breakpoints.down("sm"))

    const mobileListRef = useRef<HTMLDivElement>(null)

    const [currentPage, setCurrentPage] = useState<number>(1)
    const [totalPages, setTotalPages] = useState<number>(1)
    const [isLoadingReport, setIsLoadingReport] = useState<boolean>(false)

    const [filters, setFilters] = useState<FilterParams>({} as FilterParams)

    const { data, isLoading, error, isFetching, refetch } = useQuery<QueryData>(
        ["requests", currentPage], 
        () => getRequests(currentPage, filters), 
        { staleTime: 1000 * 60 * 10 } // 10min 
    )

    const getStatusMask = (status: string): string => {
        switch(status){
            case "faturado": return "Faturado"
            case "pendente": return "Pendente"
            case "cancelado": return "Cancelado"
            default: return ""
        }
    }

    const handleFilterSubmit = (data: RequestsDetailsFilters) => {
        queryClient.removeQueries(["requests"])

        const parsedFilters = {
            entradaate: data.dataate,
            entradade: data.datade,
            cliente: data.client,
            vendedor: data.seller,
            marca: data.brand,
            anomes: data.period,
            pedidocli: data.request
        }

        const currentFilters = {} as FilterParams
        
        for(let filter in parsedFilters){
            if(!!parsedFilters[filter]){
                currentFilters[filter] = parsedFilters[filter]
            }
        }

        setFilters(currentFilters)
        
        setCurrentPage(1)

        if(mobileListRef.current){
            mobileListRef.current.scrollTop = 0
        }

        setTimeout(() => refetch(), 300)
    }

    const cleanFilters = () => {
        queryClient.removeQueries(["requests"])

        setFilters({} as FilterParams)

        setCurrentPage(1)

        if(mobileListRef.current){
            mobileListRef.current.scrollTop = 0
        }

        setTimeout(() => refetch(), 300)
    }

    const getRequests = async(page: number, filterParams: FilterParams): Promise<QueryData> => {
        const currentDate = new Date()
        const currentPeriod = {
            start: `${currentDate.getFullYear()}${String(currentDate.getMonth() + 1).padStart(2, "0")}01`,
            finish: getDate(currentDate)
        }

        const response = await apiService.get<RequestsAxiosResponse>("/movvenda_emfaturamento", {
            params: {
                ...filterParams,
                entradade: filterParams.entradade || currentPeriod.start,
                entradaate: filterParams.entradaate || currentPeriod.finish,
                pagina: page,
                totalreg: pageLimiter
            },
            paramsSerializer: params => qs.stringify(params, { arrayFormat: "repeat" })
        })

        return {
            requests: response.data.data,
            totalRegisters: Number(response.data.total_registers)
        }
    }

    const onChangePage = (event: "next" | "prev") => {
        if(isFetching){ return }

        if(event === "next"){
            setCurrentPage(prev => prev === totalPages ? prev : prev + 1)
        }

        if(event === "prev"){
            setCurrentPage(prev => prev <= 1 ? 1 : prev - 1)
        }
    }

    const exportDataToExcel = async() => {
        if(isLoadingReport){
            return
        }

        setIsLoadingReport(true)
        const currentDate = new Date()

        const currentPeriod = {
            entradade: filters.entradade || `${currentDate.getFullYear()}${(currentDate.getMonth() + 1).toString().padStart(2, "0")}01`,
            entradaate: filters.entradaate || getDate(currentDate)
        }

        try{
            toast.info("Iniciando geração de relatório, por favor aguarde.")

            const response = await apiService.get("/relvenda_emfaturamento", {
                params: {
                    ...filters,
                    ...currentPeriod
                }
            })

            if(!response.data.data){
                toast.error("Nenhum resultado encontrado para emitir o relatório.")
                return
            }

            toast.info("Informações obtidas, gerando relatório.")

            exportExcel(response.data.data)
        } catch(error: any){
            console.log(error)
            toast.error(getErrorMessage(error))
        } finally{
            setIsLoadingReport(false)
        }
    }

    const tableRows = useMemo(() => (
        data?.requests.map(request => ({
            status: request.status,
            status_label: getStatusMask(request.status),
            id: request.id,
            status_luft: request.status_luft,
            client: request.client.nome_display,
            delivered_at: request.delivered_at.replaceAll("-", "/"),
            state: request.client.uf,
            qtd_loss: request.pendent.qtd,
            qtd_confirmed: request.oInvoiced.qtd,
            sales_loss: formatValue(request.pendent.value),
            sales_confirmed: formatValue(request.oInvoiced.value),
            qtd_total: request.total.qtd,
            sales_total: formatValue(request.total.value),
            realized: `${request.realized}%`
        })) || []
    ),[data])

    useEffect(() => {
        if(data?.totalRegisters){
            setTotalPages(Math.ceil(data?.totalRegisters ? data.totalRegisters/pageLimiter : 1))
        }
    },[data])

    useEffect(() => {
        setCurrentPage(1)
        setTotalPages(1)
    },[isMobileView])

    if(error){
        toast.error(getErrorMessage(error))
    }

    return(
        <>
        <Container>
            <Header>
                <Tooltip title="Recarregar">
                    <ActionButton onClick={() => refetch()}>
                        <Refresh />
                    </ActionButton>
                </Tooltip>
                <Tooltip title="Exportar Excel">
                    <ActionButton onClick={ exportDataToExcel }>
                        { isLoadingReport 
                            ? <CircularProgress size={18} style={{ color: DefaultTheme["blue-500"] }}/>
                            : <Download />
                        }
                    </ActionButton>
                </Tooltip>
                <RequestsDetailsFilter 
                    onCleanFilter={ cleanFilters }
                    onStartFilter={ handleFilterSubmit }
                />
            </Header>
            { !isMobileView ? (
                <DefaultTable>
                    <DrilldownTable 
                        rows={ tableRows }
                        columns={columns}
                        setRowClassname={(row: Request) => `row-custom-class-${row.status}`}
                        usePagination
                        loading={ isLoading || isFetching }
                        defaultColumnsWidth={ defaultColumnsWidth }
                        onPageChange={ onChangePage }
                        currentPage={ currentPage }
                        totalPages={ totalPages }
                        childToExpand={({ row }) => (
                            <DocumentsGrid
                                documents={ data?.requests.find(request => request.id === row.id)?.documents || [] }
                                pendents={ data?.requests.find(request => request.id === row.id)?.pendent_resquest }
                            />
                        )}
                    />
                </DefaultTable>
            ):(
                <RequestsMobileList 
                    ref={ mobileListRef }
                    currentPage={ currentPage }
                    requests={ data?.requests || [] } 
                    totalRegisters={ data?.totalRegisters || 0 }
                    onPageGoToTheEnd={ onChangePage }
                    isLoading={ isFetching }
                />
            )}
        </Container>
        </>
    )
}
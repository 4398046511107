import styled from "styled-components"
import { css } from "styled-components"

import { ButtonBase, Popover } from "@mui/material"
import { lighten, shade } from "polished"


interface SubMenuProps {
    hiddenIcon?: boolean
}

export const Container = styled.div`
    width: 100%;
    max-width: 220px;

    &+&{
        margin-top: 10px;
    }
`

export const CategoryTitle = styled(ButtonBase)`
    width: 23% !important;
    display: flex;
    justify-content: flex-start !important;
    align-items: center;
    position: relative;
    border-radius: 5px !important;
    transition: 500ms !important;
    font-size: 18px;
    font-weight: bold;
    font-family: sans-serif;
    
    svg{
        width: 32px;
        height: 32px;
        margin: 9px;
    }

    p{
        min-width: 150px;
        text-align: left;
    }

    &:hover{
        cursor: pointer !important;
    }

    @media(max-width: 750px){
        width: 100% !important;
    }

`

export const SubMenuContainer = styled.div<SubMenuProps>`
    padding-left: 40px;
    
    button{
        padding: 10px 10px;
        background-color: ${props => shade(0.1, props.theme["blue-500"])};
        color: #FFF !important;
    }

    button + button{
        margin-top: 10px !important;
    }

    span.custom-selectable{
        left: 38px;
        width: 7px;
        height: 45px;
        margin-top: -2px;
        z-index: 2;
    }

    ${ props => props.hiddenIcon && css`
        svg{
            display: none;
        }
    ` }
`

export const SubMenuPoppover = styled(Popover)`
    button{
        width: 100% !important;
    }
    
    .MuiPaper-root{
        max-width: 230px;
        max-height: 300px;
        overflow-y: auto;
        padding: 5px;
        position: relative;
        margin-left: 8px;
        background-color: ${props => lighten(0.1, props.theme["blue-500"])};
        color: #FFF !important;
    }

    .MuiMenuItem-root{
        height: 30px;
        border-radius: 5px;
        margin: 3px;
        transition: 200ms;
        display: flex !important;
        flex-direction: row !important;
        align-items: center !important;
        color: #FFF;
        
        svg{
            margin-right: 10px;
        }
        
        &:hover{
            background-color: ${props => lighten(0.2, props.theme["blue-800"])};
        }
    }
`
import axios, { AxiosError } from "axios"

const accessType: any = "PROD"
// const accessType: any = "STAGING"
// const accessType: any = "DEV"
 
const accessUrls = {
  DEV: process.env.REACT_APP_API_URL_DEV,
  STAGING: process.env.REACT_APP_API_URL_STAGING,
  PROD: process.env.REACT_APP_API_URL_PROD,
}

const apiService = axios.create({
  baseURL: accessUrls[accessType]
})

apiService.defaults.params = {
  company: process.env.REACT_APP_COMPANY,
  branch: process.env.REACT_APP_BRANCH
}

apiService.interceptors.request.use((config) => {
  const token = sessionStorage.getItem("3467333a-0dfc-45d5-800a-b08eaa0d5ec1Token") || ""

  if(!!config.headers){
    config.headers.authorization = config.headers.authorization || token
    config.headers["Accept-Language"] = "pt-BR"
  }

  return config
})

apiService.interceptors.response.use((config) => {
  if (config.data.status?.status === 401) {
    localStorage.removeItem("USER_ID")
    localStorage.removeItem("USER_NAME")
    localStorage.removeItem("3467333a-0dfc-45d5-800a-b08eaa0d5ec1Token")
    sessionStorage.clear()

    throw new Error("Token inválido ou expirado, por favor faça login novamente")
  }

  return config
}, (error: AxiosError) => {
  if(error.response?.status === 500){
    throw new Error(`Erro no servidor.\nContate o administrador`)
  }

  throw error
})

export default apiService

import React from "react"
import { useState, useEffect } from "react"
import { useNavigate } from "react-router-dom"

import { useAuth } from "../../../hooks/auth"
import { useSupplier } from '../../../hooks/supplier'

import { SupplierGrid } from "./components/SupplierGrid"
import { FilterSupplier } from "./components/FilterSupplier"

import { Tooltip } from '@mui/material'

import { Add, Refresh } from '@mui/icons-material'

import { 
    ActionButton,
    Actions,
    Container
} from "./style"

const Suppliers: React.FC = () => {
    const navigate = useNavigate()

    const { userData } = useAuth()
    const { getSuppliers } = useSupplier()

    const [isLoading, setIsLoading] = useState<boolean>(true)    

    const refeshData = async() => {
        setIsLoading(true)
        await getSuppliers()
        setIsLoading(false)
    }

    useEffect(() => {
        const init = async() =>{
            await getSuppliers()
            setIsLoading(false)
        }

        init()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[userData.selectedBranch])

    return(
        <Container>
            <Actions>
                <div>
                    <Tooltip title="Recarregar">
                        <ActionButton onClick={ refeshData }>
                            <Refresh />
                        </ActionButton>
                    </Tooltip>
                    <FilterSupplier
                        onStartFilter={() => setIsLoading(true)}
                        onFinishFilter={() => setIsLoading(false)}
                    />
                </div>
                <button onClick={() => navigate("/suppliers/addNew")}>
                    <Add />
                    Novo Fornecedor
                </button>
            </Actions>
            <SupplierGrid isLoading={ isLoading } />
        </Container>
    )
}

export default Suppliers
import { IconProps } from "../../../@types/icons";

export const DivergNFIcon: React.FC<IconProps> = ({ color, height, size }) => (
    <svg 
        width={ size || "32"} 
        height={ height || "32"}  
        color={ color }
        viewBox="0 0 32 32" 
        fill="none" 
        xmlns="http://www.w3.org/2000/svg"
    >
        <g clip-path="url(#clip0_301_1347)">
            <path d="M15.9996 0.00012207C7.16335 0.00012207 0 7.1638 0 16C0 24.8362 7.16335 31.9998 15.9996 31.9998C24.8358 31.9998 32 24.8359 32 16C31.9999 7.16348 24.8358 0.00012207 15.9996 0.00012207ZM23.1687 23.1686C23.1687 24.2423 22.2984 25.1126 21.2249 25.1126H10.775C9.70108 25.1126 8.83099 24.2423 8.83099 23.1686V8.83101C8.83099 7.75729 9.70129 6.887 10.775 6.887H18.9769V10.775H23.1687V23.1686ZM19.8069 9.94483V6.88678L23.1687 9.94483H19.8069Z" fill="#FF9000"/>
            <rect x="14.5" y="9.5" width="3" height="9" fill="white" stroke="#FF9000"/>
            <rect x="14.5" y="20.5" width="3" height="3" fill="white" stroke="#FF9000"/>
        </g>
        <defs>
            <clipPath id="clip0_301_1347">
                <rect width="32" height="32" fill="white"/>
            </clipPath>
        </defs>
    </svg>
)
import { IconProps } from "../../../@types/icons";

export const ReprovedIcon: React.FC<IconProps> = ({ color, height, size }) => (
    <svg 
        width={ size || "32"} 
        height={ height || "32"}  
        color={ color }
        viewBox="0 0 32 32" 
        fill="none" 
        xmlns="http://www.w3.org/2000/svg"
    >
        <path d="M9.33331 29.3334C8.59998 29.3334 7.97242 29.0725 7.45065 28.5507C6.92798 28.028 6.66665 27.4 6.66665 26.6667C6.66665 25.9334 6.92798 25.3054 7.45065 24.7827C7.97242 24.2609 8.59998 24 9.33331 24C10.0666 24 10.6942 24.2609 11.216 24.7827C11.7386 25.3054 12 25.9334 12 26.6667C12 27.4 11.7386 28.028 11.216 28.5507C10.6942 29.0725 10.0666 29.3334 9.33331 29.3334ZM22.6666 29.3334C21.9333 29.3334 21.3058 29.0725 20.784 28.5507C20.2613 28.028 20 27.4 20 26.6667C20 25.9334 20.2613 25.3054 20.784 24.7827C21.3058 24.2609 21.9333 24 22.6666 24C23.4 24 24.028 24.2609 24.5506 24.7827C25.0724 25.3054 25.3333 25.9334 25.3333 26.6667C25.3333 27.4 25.0724 28.028 24.5506 28.5507C24.028 29.0725 23.4 29.3334 22.6666 29.3334ZM9.33331 22.6667C8.33331 22.6667 7.57776 22.2276 7.06665 21.3494C6.55554 20.472 6.53331 19.6 6.99998 18.7334L8.79998 15.4667L3.99998 5.33335H2.63331C2.25554 5.33335 1.94442 5.20535 1.69998 4.94935C1.45554 4.69424 1.33331 4.3778 1.33331 4.00002C1.33331 3.62224 1.46131 3.30535 1.71731 3.04935C1.97242 2.79424 2.28887 2.66669 2.66665 2.66669H4.83331C5.07776 2.66669 5.31109 2.73335 5.53331 2.86669C5.75554 3.00002 5.9222 3.18891 6.03331 3.43335L6.93331 5.33335H26.6C27.2 5.33335 27.6111 5.55558 27.8333 6.00002C28.0555 6.44446 28.0444 6.91113 27.8 7.40002L23.0666 15.9334C22.8222 16.3778 22.5 16.7222 22.1 16.9667C21.7 17.2111 21.2444 17.3334 20.7333 17.3334H10.8L9.33331 20H24.0333C24.4111 20 24.7222 20.1276 24.9666 20.3827C25.2111 20.6387 25.3333 20.9556 25.3333 21.3334C25.3333 21.7111 25.2053 22.0276 24.9493 22.2827C24.6942 22.5387 24.3778 22.6667 24 22.6667H9.33331Z" fill="#C53030"/>
        <path d="M16 9.58645L13.7087 7.29317C13.6158 7.20022 13.5054 7.12649 13.384 7.07619C13.2626 7.02589 13.1324 7 13.0009 7C12.8695 7 12.7393 7.02589 12.6179 7.07619C12.4965 7.12649 12.3861 7.20022 12.2932 7.29317C12.2002 7.38612 12.1265 7.49646 12.0762 7.6179C12.0259 7.73934 12 7.8695 12 8.00095C12 8.13239 12.0259 8.26255 12.0762 8.38399C12.1265 8.50543 12.2002 8.61578 12.2932 8.70872L14.5864 11L12.2932 13.2913C12.2002 13.3842 12.1265 13.4946 12.0762 13.616C12.0259 13.7374 12 13.8676 12 13.9991C12 14.1305 12.0259 14.2607 12.0762 14.3821C12.1265 14.5035 12.2002 14.6139 12.2932 14.7068C12.3861 14.7998 12.4965 14.8735 12.6179 14.9238C12.7393 14.9741 12.8695 15 13.0009 15C13.1324 15 13.2626 14.9741 13.384 14.9238C13.5054 14.8735 13.6158 14.7998 13.7087 14.7068L16 12.4136L18.2913 14.7068C18.479 14.8945 18.7336 15 18.9991 15C19.2645 15 19.5191 14.8945 19.7068 14.7068C19.8945 14.5191 20 14.2645 20 13.9991C20 13.7336 19.8945 13.479 19.7068 13.2913L17.4136 11L19.7068 8.70872C19.8945 8.52101 20 8.26641 20 8.00095C20 7.73548 19.8945 7.48088 19.7068 7.29317C19.5191 7.10546 19.2645 7 18.9991 7C18.7336 7 18.479 7.10546 18.2913 7.29317L16 9.58645Z" fill="white"/>
    </svg>
)
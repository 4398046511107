import React from 'react'
import { useEffect } from 'react'
import { Outlet, useNavigate } from 'react-router-dom'

import { useAuth } from '../../hooks/auth'

import { AttachFilesProvider } from '../../hooks/attachFiles'
import { SupplierProvider } from '../../hooks/supplier'
import { toast } from 'react-toastify'

const Supplier: React.FC = () => {

    const { userData } = useAuth()

    const navigate = useNavigate()

    useEffect(() => {
        if(!userData.permissions?.includes('CADFOR')){
            toast.info("Você não tem permissão para acessar essa pagina")
            navigate("/")
        }
    },[userData, navigate])

    return (
        <SupplierProvider>
            <AttachFilesProvider>
                <Outlet />
            </AttachFilesProvider>
        </SupplierProvider>
    )
}

export default Supplier
export const ChartLocales = [
    {
        name: 'pt-br',
        options: {
            months: ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'],
            shortMonths: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'],
            days: ['Domingo', 'Segunda', 'Terça', 'Quarta', 'Quinta', 'Sexta', 'Sábado'],
            shortDays: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sab'],
            toolbar: {
                download: 'Baixar SVG',
                selection: 'Seleção',
                selectionZoom: 'Seleção Zoom',
                zoomIn: 'Aumentar Zoom',
                zoomOut: 'Diminiuir Zoom',
                pan: 'Panorâmico',
                reset: 'Reiniciar Zoom',
                exportToCSV: "Exportar para CSV"
            }
        }
    }
]
import styled from "styled-components"

import BGImage from '../../assets/bg-image.png'

export const AppContainer = styled.div`
    height: 100vh;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    background-color: ${ props => props.theme["blue-800"] };
    background-image: url(${ BGImage });
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
`

export const AppInfo = styled.div`
    width: 100%;
    margin-bottom: 8px;
    height: 24px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    @media(max-width: 750px){
        width: calc(100% - 86px);
        position: absolute;
        color: ${ props => props.theme["white-100"] } !important;
        top: -54px;
        right: 16px;
        align-items: center;
    }
`

export const Content = styled.div`
    height: calc(100vh - 106px);
    overflow: auto;

    @media(max-width: 750px){
        height: calc(100vh - 150px);
    }
`

export const MenuContainer = styled.div`
    position: fixed;
    width: auto;
    z-index: 5;
    left: 0;
`

export const SelectedBranch = styled.h1`
    font-weight: bold;
    font-size: 16px;
    z-index: 1;

    @media(max-width: 750px){
        font-size: 12px;
    }
`

export const AppContentContainer = styled.div`
    width: calc(100% - 92px);
    background-color: ${ props => props.theme["blue-50"] };
    flex: 1;
    margin: 10px 10px 10px 2px;
    margin-left: 102px;
    padding: 16px;
    height: calc(100vh - 40px);
    border-radius: 20px;
    position: relative;

    @media(max-width: 750px){
        margin: 0px;
        margin-top: 72px;
        padding: 10px;
        border-radius: 15px 15px 0px 0px;
        height: calc(100vh - 100px);
    }
`

export const NavContainer = styled.div`
    width: 100%;
    position: fixed;
    background-color: #e4eaf7;
    top: 10px;
    z-index: 2;

    @media(max-width: 750px){
        width: auto;
        right: 0px;
    }
`
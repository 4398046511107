import { useState, useEffect } from "react"
import { ApexOptions } from "apexcharts"
import Chart from 'react-apexcharts'

import { ChartLocales } from "../../../../data/chartLocales"
import { Container, LabelChart, SkeletonChart } from "./style"
import { formatValue } from "../../../../utils/format/formatValue"

interface InvoicedPercentChartProps{
    label: string
    value: number
    width?: number
    height?: number
    isLoading?: boolean
}

const defaultOptions: ApexOptions = {
    theme: {
        mode: "dark"
    },
    chart: {
        defaultLocale: 'pt-br',
        locales: ChartLocales,
        offsetY: -20,   
        sparkline: {
            enabled: true
        }
    },
    plotOptions: {
        radialBar: {
            startAngle: -90,
            endAngle: 90,
            track: {
                background: "#e7e7e7",
                strokeWidth: '97%',
                margin: 5, // margin is in pixels
                dropShadow: {
                    enabled: true,
                    top: 2,
                    left: 0,
                    color: '#999',
                    opacity: 1,
                    blur: 2
                }
            },
            dataLabels: {
                name: {
                    show: false,
                },
                value: {
                    offsetY: -2,
                    fontSize: '22px',
                    formatter: (value) => formatValue(value)
                },
            },
        },
    },
    grid: {
        padding: {
            top: -10
        }
    },
    fill: {
        type: 'solid'
    },
    dataLabels: {
        formatter: (value) => formatValue(Number(value))
    },
}

export const InvoicedPercentChart: React.FC<InvoicedPercentChartProps> = ({
    label,
    value,
    height,
    width,
    isLoading
}) => {
    const [options, setOptions] = useState<ApexOptions>(defaultOptions)
    const [series, setSeries] = useState<number>(0)

    useEffect(() => {
        setOptions(prev => ({
            ...prev,
            labels: [label]
        }))
    },[label])

    useEffect(() => {
        setSeries(value)
    },[value])

    if(isLoading){
        return (
            <Container>
                <SkeletonChart 
                    variant="rectangular" 
                    height={height || 180}
                    width={width}
                    animation="wave"
                    style={{ marginTop: "-16px" }}
                />
            </Container>
        )
    }

    return (
        <Container width={ width }>
            <Chart 
                type="radialBar"
                height={418}
                width={width}
                options={options}
                series={[series]}
            />
            <LabelChart>
                { label }
            </LabelChart>
        </Container>
    )
}
export const AppBreadCrumbs = [
    {
        route: "/buy-orders/list",
        labels: [
            {
                to: "/buy-orders/list",
                name: "Solicitações"
            }
        ]
    },
    {
        route: "/buy-orders/addNew",
        labels: [
            {
                to: "/buy-orders/addNew",
                name: "Nova Solicitação"
            }
        ]
    },
    {
        route: "/buy-orders/details/view",
        labels: [
            {
                to: "/buy-orders/list",
                name: "Solicitações"
            },
            {
                to: "/buy-orders/details/view",
                name: "Visualizar"
            }
        ]
    },
    {
        route: "/buy-orders/details/edit",
        labels: [
            {
                to: "/buy-orders/list",
                name: "Solicitações"
            },
            {
                to: "/buy-orders/details/view",
                name: "Editar"
            }
        ]
    },
    {
        route: "/buy-orders/details/aprove",
        labels: [
            {
                to: "/buy-orders/list",
                name: "Solicitações"
            },
            {
                to: "/buy-orders/details/view",
                name: "Aprovar"
            }
        ]
    },
    {
        route: "/suppliers/list",
        labels: [
            {
                to: "/suppliers/list",
                name: "Fornecedores"
            }
        ]
    },
    {
        route: "/suppliers/addNew",
        labels: [
            {
                to: "/suppliers/addNew",
                name: "Novo Fornecedor"
            }
        ]
    },
    {
        route: "/suppliers/details/view",
        labels: [
            {
                to: "/suppliers/list",
                name: "Fornecedores"
            },
            {
                to: "/suppliers/details/view",
                name: "Visualizar"
            }
        ]
    },
    {
        route: "/suppliers/details/edit",
        labels: [
            {
                to: "/suppliers/list",
                name: "Fornecedores"
            },
            {
                to: "/suppliers/details/view",
                name: "Editar"
            }
        ]
    },
    {
        route: "/suppliers/details/view",
        labels: [
            {
                to: "/suppliers/list",
                name: "Fornecedores"
            },
            {
                to: "/suppliers/details/view",
                name: "Visualizar"
            }
        ]
    },
    {
        route: "/dashboard",
        labels: [
            {
                to: "/dashboard",
                name: "Dashboard Resumida"
            },
        ]
    },
    {
        route: "/dashboard/quote",
        labels: [
            {
                to: "/dashboard/quote",
                name: "Cota x Real"
            },
        ]
    },
    {
        route: "/dashboard/supply",
        labels: [
            {
                to: "/dashboard/supply",
                name: "Supply SLA"
            },
        ]
    },
    {
        route: "/invoicing/details/client",
        labels: [
            {
                to: "/invoicing",
                name: "Faturamento"
            },
            {
                to: "/invoicing/details",
                name: "Detalhes de Venda"
            },
        ]
    },
    {
        route: "/invoicing/details/products",
        labels: [
            {
                to: "/invoicing",
                name: "Faturamento"
            },
            {
                to: "/invoicing/details",
                name: "Detalhes de Venda"
            },
        ]
    },
    {
        route: "/requests",
        labels: [
            {
                to: "/requests",
                name: "Faturamento"
            },
            {
                to: "/requests",
                name: "Detalhes de Pedidos"
            },
        ]
    },
    {
        route: "/preferences",
        labels: [
            {
                to: "/preferences",
                name: "Preferencias"
            }
        ]
    },
    {
        route: "/records/motives",
        labels: [
            {
                to: "/records/motives",
                name: "Cadastros"
            },
            {
                to: "/records/motives",
                name: "Motivos Recusas"
            }
        ]
    },
    {
        route: "/tutorials",
        labels: [
            {
                to: "/tutorials",
                name: "Tutoriais"
            }
        ]
    }
]
import styled from 'styled-components'
import { DialogContent } from '@mui/material'

type DetailsType = "canceled" | "pendent" | "invoiced" | "in_progress" 

interface GridCustomCellProps{
    align?: "center" | "flex-end" | "flex-start",
    type?: DetailsType
}

interface InfoProps{
    type: DetailsType
}

const typeColors = {
    canceled: "#FFFFFF",
    pendent: "#F8CBAD",
    invoiced: "#A9D08E",
    in_progress: "#FFE699"
}

export const DialogContainer = styled(DialogContent)`
    width: 100%;
    display: flex;
    flex-direction: column;
`

export const SearchField = styled.div`
    width: 100%;
    margin: 18px 0px;
    position: relative;
    
    button{
        position: absolute;
        right: 0px;
        top: 0px;
        width: 40px;
        height: 40px;
        border-radius: 0px 5px 5px 0px;
        border: none;
        padding: 2px;
        background-color: #2e7d32;
        transition: 200ms;
        
        svg{
            color: #f9f9f9;
            font-size: 1.5rem;
        }
    }
`

export const Infos = styled.fieldset<InfoProps>`
    position: relative;
    border: 2px solid ${props => props.type === "canceled" ? "#000" : typeColors[props.type]};
    padding-top: 18px;
    border-radius: 10px;

    legend{
        position: absolute;
        top: -10px;
        color: ${props => props.type === "canceled" ? "#000" : typeColors[props.type]};
        font-size: 1rem;
        background-color: #fff;
        width: min-content;
        padding: 0px 6px;
    }
`

export const GridContainer = styled.div`
    width: 100%;
    height: 60vh;

    .MuiDataGrid-cell{
        padding: 0px !important;
    }
`

export const GridCustomCell = styled.div<GridCustomCellProps>`
    width: 100%;
    height: 100%;
    padding: 0px 10px;
    background-color: ${ props => props.type ? typeColors[props.type] : "#FFF" };;
    display: flex;
    justify-content: ${ props => props.align ? props.align : "center" };
    align-items: center;
`
import React, { useEffect, useRef } from 'react'
import { useState, useImperativeHandle, forwardRef } from 'react'
import * as Yup from 'yup'
import { toast } from 'react-toastify'
import { Form } from '@unform/web'
import { FormHandles } from '@unform/core'

import getValidationErros from '../../../../../../utils/validations/getValidationErros'
import { getErrorMessage } from '../../../../../../utils/validations/getErrorMessage'

import { Button } from '../../../../../../components/Buttons/Button'

import {
    Dialog,
    DialogActions, 
    DialogProps,
    CircularProgress,
    Grid
} from '@mui/material'

import { Close } from '@mui/icons-material'

import { DialogTitleCustom } from '../../../../../../styles/globalStyles'
import { Content } from './style'
import FormInput from '../../../../../../components/Form/FormInput'
import FormAutoComplete from '../../../../../../components/Form/FormAutoComplete'
import { useBilling } from '../../../../../../hooks/billing'
import apiService from '../../../../../../services/api.services'
import { useAuth } from '../../../../../../hooks/auth'

interface FormData{
    providerId: string
    providerName: string
    providerCNPJ: string
    banco: string
    agencia: string
    conta: string
}

export interface ChangeBankProviderRequestDialogRef {
    isOpen: boolean
    openDialog: () => void
    closeDialog: () => void
}

interface ChangeBankProviderRequestDialogProps extends Omit<DialogProps, "open"> {
    onFinishProcess: () => void
    provider: {
        id: string
        branch: string
        name: string
        cnpj: string
    }
}

const ChangeBankProviderRequestDialog: React.ForwardRefRenderFunction<
    ChangeBankProviderRequestDialogRef,
    ChangeBankProviderRequestDialogProps
> = ({ 
    provider, 
    onFinishProcess,
    ...rest 
}, ref) => {
    const { getBanks } = useBilling()
    const { userData } = useAuth()

    const formRef = useRef<FormHandles>(null)
    
    const [open, setOpen]           = useState<boolean>(false)
    const [isLoading, setIsLoading] = useState<boolean>(false)

    const [bankOptions, setBankOptions] = useState<string[]>([])
    const [isLoadingBanks, setIsLoadingBanks] = useState<boolean>(false)

    const handleClose = () => {
        if(isLoading){
            return
        }

        formRef.current?.reset()
        setIsLoading(false)
        setOpen(false)
    }

    const handleSubmit = async(data: FormData) => {
        const schema = Yup.object().shape({
            banco: Yup.string().required("Banco Obrigatório"),
            agencia: Yup.string().required("Agência Obrigatório"),
            conta: Yup.string().required("Conta Obrigatória"),
        })

        try{
            await schema.validate(data, {
                abortEarly: false
            })

            const [bank, bankName] = data.banco.split("-")

            const updatedData = {
                supplier_id: provider.id,
                supplier_branch: provider.branch,
                bank: bank.trim(),
                bank_name: bankName.trim(),
                bank_branch: data.agencia,
                bank_account: data.conta
            }

            await apiService.post("/atualbank", updatedData, {
                params: {
                    branch: userData.selectedBranch?.id
                }
            })

            toast.success("Solicitação de alteração de dados bancarios enviada com sucesso")
            onFinishProcess()
            handleClose()

        } catch(error){
            console.log(error)

            if(error instanceof Yup.ValidationError){
                const errors = getValidationErros(error)
                formRef.current?.setErrors(errors)
                return
            }

            console.log(error)
            toast.error(getErrorMessage(error))
        }
    }

    const loadBankOptions = async() => {
        setIsLoadingBanks(true)

        try{
            const banks = await getBanks()

            setBankOptions(banks.map(bank => `${bank.id} - ${bank.name}`))
        } catch(error: any){
            console.log(error)
            toast.error(getErrorMessage(error))
        } finally{
            setIsLoadingBanks(false)
        }
    }

    useImperativeHandle(ref, () => ({
        isOpen: open,
        openDialog: () => setOpen(true),
        closeDialog: handleClose
    }))

    useEffect(() => {
        loadBankOptions()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return(
        <Dialog
            { ...rest }
            open={ open }
            onClose={ handleClose }
        >
            <DialogTitleCustom>
                Solicitação de alteração dos dados bancários
                <button onClick={ handleClose }><Close/></button>
            </DialogTitleCustom>
            <Form 
                ref={ formRef }
                onSubmit={ handleSubmit }
                initialData={{
                    providerName: provider.name,
                    providerCNPJ: provider.cnpj
                }}  
            >
                <Content>
                    <Grid container pt={1} spacing={2}>
                        <Grid item xs={12} md={ 12 }>
                            <FormInput 
                                name="providerName"
                                label="Fornecedor"
                                size='small'
                                fullWidth
                                disabled
                            />
                        </Grid>
                        <Grid item xs={12} md={ 12 }>
                            <FormInput 
                                name="providerCNPJ"
                                label="CNPJ"
                                size='small'
                                fullWidth
                                disabled
                            />
                        </Grid>
                        <Grid item xs={12} md={12}>
                            <FormAutoComplete
                                name="banco"
                                label="Banco *"
                                options={ bankOptions }
                                loading={ isLoadingBanks }
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <FormInput
                                variant="outlined"
                                name="agencia"
                                label="Agência *"
                                size="small"
                                type="number"
                                fullWidth
                                inputProps={{ maxLength: 5 }}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <FormInput
                                variant="outlined"
                                name="conta"
                                label="Conta *"
                                size="small"
                                type="number"
                                fullWidth
                            />
                        </Grid>
                    </Grid>
                </Content>
                <DialogActions>
                    <Button
                        variant="text"
                        fullWidth
                        disabled={ isLoading }
                        onClick={ handleClose }
                    >
                        Cancelar
                    </Button>
                    <Button
                        color="success"
                        type='submit'
                        variant="contained"
                        fullWidth
                        disabled={ isLoading }
                        >
                        { isLoading ? <CircularProgress size={ 24 } color="inherit" /> : "Confirmar" }
                    </Button>
                </DialogActions>
            </Form>
        </Dialog>
    )
}

export default forwardRef(ChangeBankProviderRequestDialog)
import React from 'react'
import { useLocation } from 'react-router-dom'

import { AppBreadCrumbs } from '../../../data/appBreadCrumbs'

import {
    BreadCrumbsCustom,
    LinkCustom,
    TextCustom
} from './style'

const BreadCrumbs: React.FC = () => {
    const location = useLocation()

    const data = AppBreadCrumbs.find(item => item.route === location.pathname)?.labels || []

    return(
        <BreadCrumbsCustom aria-label="breadcrumbs">
            { data.map((info, index) => {
                if(index === (data.length - 1)){
                    return(
                        <TextCustom key={ index }>
                            <p>{ info.name }</p>
                        </TextCustom> 
                    )
                } else {
                    return(
                        <LinkCustom key={ index } to={ info.to }>
                            <p>{ info.name }</p>
                        </LinkCustom>
                    )
                }
            })}
        </BreadCrumbsCustom>
    )
}

export default BreadCrumbs
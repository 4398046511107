import styled from "styled-components"
import { fade } from "../../styles/animations"

import BGImage from '../../assets/bg-image.png'

export const Container = styled.div`
    width: 100%;
    height: 100vh;
    background-color: ${ props => props.theme["blue-800"] };
    background-image: url(${ BGImage });
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;

    @media(max-widht: 800px){
        width: 100%;
        margin-left: 0px;
    }
`

export const SignupCard = styled.div`
    width: 95%;
    max-width: 494px;
    padding: 24px;
    background: linear-gradient(149.3deg, rgba(255, 255, 255, 0.4) 14.38%, rgba(255, 255, 255, 0.1) 89.8%);
    background-blend-mode: soft-light;
    box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.25);
    backdrop-filter: blur(4px);
    border-radius: 20px;
    animation: ${ fade } 500ms;

    header{
        width: 100%;
        min-height: 150px;
        margin-bottom: 16px;
        display: flex;
        justify-content: center;
        align-items: center;

        img{
            object-fit: contain;
            width: 100%;
            border-radius: 5px;
            background-color: #FFFFFF00;
        }
    }

    form{
        display: flex;
        flex-direction: column;
        align-items: center;
    }
`

export const FormSection = styled.section`
    width: 100%;
    height: 105px;
    margin-bottom: 44px;
    padding: 0px 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 16px;
    position: relative;

    @media(max-width: 800px){
        padding: 0px;
    }
`

export const ActionsForm = styled.div`
    width: 100%;
    padding: 0px 20px;
    gap: 12px;
    margin-bottom: 16px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;


    @media(max-width: 800px){
        padding: 0px;
        flex-direction: column-reverse;
    }
`
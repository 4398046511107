export const checkColorClarity = (color: string): boolean => {
    if(!color.match("^#[0-9A-F]{6}$")){
        throw new Error("Invalid color string value")
    }

    const red = parseInt(color.substring(1, 3), 16)
    const green = parseInt(color.substring(3, 5), 16)
    const blue = parseInt(color.substring(5, 7), 16)

    const clarity = ((red * 299) + (green * 587) + (blue * 114)) / 1000

    if(clarity > 128){
        return true
    } else{
        return false
    }
}
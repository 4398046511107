import { Outlet, useLocation } from 'react-router-dom'

import { useAuth } from '../../hooks/auth'

import Menu from '../../components/Navigation/Menu'
import Copyright from '../../components/Misc/Copyright'
import BreadCrumbs from '../../components/Navigation/BreadCrumbs'

import { 
    AppContainer, 
    AppContentContainer,
    AppInfo,
    Content,
    MenuContainer, 
    SelectedBranch 
} from './style'

export default function Home(){    
    const location = useLocation()
    const { userData } = useAuth()

    return(
        <AppContainer>
            <MenuContainer>
                <Menu expanded={location.pathname === "/"}/>
            </MenuContainer>
            <AppContentContainer>
                <AppInfo>
                    <BreadCrumbs />
                    <SelectedBranch>
                        { userData.selectedBranch?.id && `Filial: ${ userData.selectedBranch?.id } - ${ userData.selectedBranch?.name }` }
                    </SelectedBranch>
                </AppInfo>
                <Content>
                    <Outlet />
                </Content>
            </AppContentContainer>
            <Copyright />
        </AppContainer>
    )
}
import styled, { css } from "styled-components";
import * as RaCheckbox from '@radix-ui/react-checkbox';

interface GridHeaderColumnProps{
  contentAlign?: "center" | "left" | "right"
}

interface GridContainerProps{
  hasPagination?: boolean
}

interface VariantProps {
  variant?: "ligth" | "dark"
}

interface GridRowProps extends VariantProps{
  isSelected?: boolean
}

export const Container = styled.div<VariantProps>`
  width: 100%;
  height: 100%;
  border: 1px solid #001d2a;
  border-radius: 0px 4px 4px 4px;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  overflow: hidden;

  ${ props => props.variant === "dark" && css`
    background-color: ${ props => props.theme['gray-700'] };
    border-radius: 10px;
    color: #fff;
    border: none;
  `}
`

export const GridContainer = styled.div<GridContainerProps>`
  width: 100%;
  height: ${props => props.hasPagination ? "calc(100% - 45px)" : "100%"};
  overflow: auto;
`

export const Grid = styled.table`
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
`

export const GridHeader = styled.thead<VariantProps>`
  height: 45px;
  
  th{
    position: sticky; 
    top: 0;
    background-color: #001d2a;
    color: #f2f2f2;
    z-index: 2;
    border-bottom: 2px solid #001d2a;

    ${ props => props.variant === "dark" && css`
      background-color: ${ props.theme['gray-700'] };
      color: ${ props.theme['yellow-500'] };
      border-bottom: 2px solid ${ props.theme['yellow-500'] };
    `}

  }
`

export const GridRow = styled.tr<GridRowProps>`
  height: 45px;
  font-size: 0.8rem;
  transition: 200ms;
  /* overflow: hidden; */
  
  ${props => props.isSelected && css`
    background-color: #007bff0f;
  `}

  ${ props => props.variant === "dark" && css`
    background-color: ${ props.theme['gray-700'] };
    color: ${ props.theme['white'] };
    border-bottom: 2px solid ${ props.theme['yellow-500'] };
  `}
`

export const GridColumnItem = styled.div<VariantProps>`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
  cursor: pointer;

  &::before{
    content: "";
    width: 1px;
    height: 12px;
    background-color: #f2f2f2;;
  }

  &::after{
    content: "";
    width: 1px;
    height: 12px;
    background-color: #f2f2f2;;
  }

  ${ props => props.variant === "dark" && css`
    &::before{
      background-color: ${ props.theme['yellow-500'] };
    }

    &::after{
      background-color: ${ props.theme['yellow-500'] };
    }
  `}
`

export const GridHeaderColumn = styled.div<GridHeaderColumnProps>`
  display: flex;
  width: 100%;
  position: relative;
  font-size: 0.8rem;
  justify-content: space-between;
  align-items: center;

  &:hover {
    /* background: #d9d9d9; */
    border: none;
    border-radius: 10px;
  }

  label{
    flex: 1;
    text-align: ${ props => props.contentAlign || "left"};
    cursor: pointer;
  }
`

export const GridRowHeaderItem = styled.th`
  padding: 0px 8px;
  border-bottom: 1px solid #f2f2f2;
  text-align: ${ props => props.align } !important;
`

export const GridRowItem = styled.td`
  padding: 0px 8px;
  border-bottom: 1px solid #f2f2f2;
  text-align: ${ props => props.align } !important;
`

export const GridFooter = styled.footer`
  width: 100%;
  height: 45px;
  border-top: 1px solid #f2f2f2;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 8px 12px;
  gap: 12px;
`

export const PinnedFooter = styled.tfoot`
  position: sticky;
  inset-block-end: 0;
  z-index: 2;
  background-color: #fff;
  
  td{
    border-top: 1px solid #f2f2f2;
  }
`

export const SelectionRowArea = styled.div`
  width: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const Checkbox = styled(RaCheckbox.Root)`
  background-color: white;
  width: 25px;
  height: 25px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 2px 2px 4px #f2f2f2;
  transition: 200ms;
  border: 1px solid #f2f2f2;
  pointer-events: painted;

  &[data-state="checked"]{
    background-color: #007bff;
  }
`

export const Indicator = styled(RaCheckbox.Indicator)`
  color: white;
`

export const ButtonsContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
`

export const ExpandRowButton = styled.div`
  width: 22px;
  height: 22px;
  border: none;
  background-color: #f2f2f2;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  &:hover{
    transition: 200ms;
    opacity: 0.9;
  }

  &:active{
    transition: 200ms;
    opacity: 0.8;
  }
`

export const ExpandedItem = styled.td`
  position: relative;
  z-index: 0;
`
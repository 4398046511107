import { useEffect, useState } from 'react';
import { useAutocomplete } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import { Tag, ListBox, InputWrapper, Container, Label } from './style';

interface Option{
    id: string
    label: string
}

interface CustomAutocompleteProps{
    options: Option[]
    label?: string
    fullwidth?: boolean
    maxHeigth?: number
    defaultValue?: any[]
    onChange?: (value: Option[]) => void
}

export const CustomAutocomplete: React.FC<CustomAutocompleteProps> = ({
    options,
    fullwidth,
    label,
    onChange,
    defaultValue,
    maxHeigth
}) => {
  const {
    getRootProps,
    getInputLabelProps,
    getInputProps,
    getTagProps,
    getListboxProps,
    getOptionProps,
    groupedOptions,
    value,
    focused,
    setAnchorEl,
  } = useAutocomplete({
    id: 'customized-hook-demo',
    multiple: true,
    options: options,
    defaultValue: defaultValue,
    getOptionLabel: (option) => option.label
  });

  const [hasChangedValue, setHasChangedValue] = useState(false)

  const handleValueChanges = (value: any) => {
    if(!hasChangedValue){
      if(JSON.stringify(defaultValue) === JSON.stringify(value)){
        return
      }
    }

    if(onChange){
      setHasChangedValue(true)
      onChange(value)
    }
  }

  useEffect(() => {
    handleValueChanges(value)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[value])

  return (
    <Container fullwidth={ fullwidth }>
      <div {...getRootProps()}>
        { label && (
            <Label {...getInputLabelProps()}>label</Label>
        )}
        <InputWrapper 
            ref={setAnchorEl} 
            fullwidth={ fullwidth } 
            className={focused ? 'focused' : ''}
            maxHeigth={ maxHeigth }
        >
          {value.map((option: Option, index: number) => {
            const { onDelete, ...rest } = getTagProps({ index })
            return (
                <Tag {...rest}>
                    <span>{ option.label }</span>
                    <CloseIcon onClick={onDelete}/>
                </Tag>
            )
          })}
          <input {...getInputProps()} />
        </InputWrapper>
      </div>
      {groupedOptions.length > 0 ? (
        <ListBox {...getListboxProps()}>
          {groupedOptions.map((option, index) => (
            <li {...getOptionProps({ option, index })}>
              <span>{option.label}</span>
              <CheckIcon fontSize="small" />
            </li>
          ))}
        </ListBox>
      ) : null}
    </Container>
  );
}
import React from 'react'

import { useAuth } from '../../../../hooks/auth'

import { SvgIconTypeMap } from '@mui/material'
import { OverridableComponent } from '@mui/material/OverridableComponent'

import { NavMenuItem } from './style'

export interface MenuButtonProps{
    access?: string
    children?: React.ReactNode
    style?: React.CSSProperties
    icon?: OverridableComponent<SvgIconTypeMap<{}, "svg">> & { muiName: string; }
    action: () => void
}

const MenuButton: React.FC<MenuButtonProps> = ({ 
    icon: Icon,
    action, 
    style,
    access,
    children
}) => {
    const { userData } = useAuth()

    if(access){
        if(!userData.permissions?.includes(access)){
            return <></>
        }
    }

    return (
        <NavMenuItem onClick={ action } style={ style }>
            { Icon && <Icon />}
            { children }
        </NavMenuItem>
    )
}

export default MenuButton
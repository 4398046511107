import styled from "styled-components"
import { fade } from "../../../styles/animations"

export const Container = styled.div`
    width: 100%;
    position: fixed;
    bottom: 0px;
    height: 30px;
    padding-right: 20px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    background-color: #FFFFFF00;
    
    div{
        display: flex;
        flex-direction: row;
        align-items: center;
        animation: ${ fade } 100ms;

        img{
            width: 80px;
            height: 20px;
            margin-right: 5px;
            margin-bottom: -3px;
        }

        svg{
            margin: 0px;
            margin-right: 2px;
            font-size: 15px;
        }

        p, svg{
            color: #b3b3b3;
        }
    }

    @media(max-width: 750px){
        width: 100%;
        margin-left: 0px;

        div{
            width: 100%;
            justify-content: center;
        }
    }
`;
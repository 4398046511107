import styled from "styled-components"
import { css } from "styled-components"
import { shake, fade } from '../../../styles/animations'

import { TextField } from '@mui/material'
import { Close, Check } from "@mui/icons-material"

interface InputProps{
    $isError: boolean
    $hasAnError: boolean
}

export const Container = styled.div`
    position: relative;

    .MuiTextField-root{    
        fieldset{
            transition: 200ms;
            border-color: ${props => props.theme["blue-800"] };
        }

        input{
            color: ${ props => props.theme["blue-800"] };

            &:disabled{
                background-color: ${ props => props.theme["gray-100"] } !important;
            }
        }

        &, fieldset{
            border-radius: 5px !important;
        }

        span, label{
            color: ${props => props.theme["blue-800"] };
        }

        .Mui-focused{
            color: ${props => props.theme["yellow-500"] } !important;
            
            input{ color: ${props => props.theme["yellow-500"] } !important; }

            fieldset{ border-color: ${props => props.theme["yellow-500"] } !important; }
        }
    }
`

export const Input = styled(TextField)<InputProps>`
    ${ props => props.$isError && css`
        fieldset{
            border-color: ${props => props.theme.danger} !important;
        }
        legend, label, span, input{
            color: ${props => props.theme.danger} !important;
        }
        animation: ${ shake } 500ms;
    `}

    ${ props => (!props.$isError && props.$hasAnError) && css`
        fieldset{
            border-color: ${ props => props.theme["green-500"]};
        }
        legend, label, span{
            color: ${ props => props.theme["green-500"]};
        }
    `}
`

export const CloseIcon = styled(Close)`
    color: #c53030;
    position: absolute;
    right: 5px;
    margin-top: 7px;
    animation: ${ fade } 200ms;
`

export const CheckIcon = styled(Check)`
    color: #0dba04;
    position: absolute;
    right: 5px;
    margin-top: 7px;
    animation: ${ fade } 200ms;
`

export const ErrorMessage = styled.p`
    position: absolute;
    bottom: -18px;
    color: #c53030;
    font-size: 12px;
    padding-left: 5px;
`
import styled from 'styled-components'
import { DialogContent } from '@mui/material'
import { shade } from 'polished'

type DetailsType = "canceled" | "pendent" | "invoiced" | "in_progress" 

interface GridCustomCellProps{
    align?: "center" | "flex-end" | "flex-start",
    type?: DetailsType
}

interface InfoProps{
    type: DetailsType
}

const typeColors = {
    canceled: "#FFFFFF",
    pendent: "#F8CBAD",
    invoiced: "#A9D08E",
    in_progress: "#FFE699",
    total: "#BFBFBF"
}

export const DialogContainer = styled(DialogContent)`
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-top: 24px;
`

export const Infos = styled.fieldset<InfoProps>`
    position: relative;
    border: 2px solid ${props => props.type === "canceled" ? "#000" : typeColors[props.type]};
    padding-top: 18px;
    border-radius: 10px;

    legend{
        position: absolute;
        top: -10px;
        color: ${props => props.type === "canceled" ? "#000" : typeColors[props.type]};
        font-size: 1rem;
        background-color: #fff;
        width: min-content;
        padding: 0px 6px;
    }
`

export const GridContainer = styled.div`
    width: 100%;
    height: 318px;

    .grid-row-invoicing{
        background-color: ${ typeColors.invoiced }; 

        &:hover{
            background-color: ${ shade(0.1, typeColors.invoiced) } !important; 
        }
    }

    .grid-row-in_progress{
        background-color: ${ typeColors.in_progress }; 

        &:hover{
            background-color: ${ shade(0.1, typeColors.in_progress) } !important; 
        }
    }

    .grid-row-pendent{
        background-color: ${ typeColors.pendent }; 

        &:hover{
            background-color: ${ shade(0.1, typeColors.pendent) } !important; 
        }
    }

    .grid-row-canceled{
        background-color: ${ typeColors.canceled }; 

        &:hover{
            background-color: ${ shade(0.1, typeColors.canceled) } !important; 
        }
    }

    .grid-row-total{
        background-color: ${ typeColors.total }; 
        font-weight: bold !important;

        &:hover{
            background-color: ${ shade(0.1, typeColors.total) } !important; 
        }
    }
`

export const GridCustomCell = styled.div<GridCustomCellProps>`
    width: 100%;
    height: 100%;
    padding: 0px 10px;
    background-color: ${ props => props.type ? typeColors[props.type] : "#FFF" };;
    display: flex;
    justify-content: ${ props => props.align ? props.align : "center" };
    align-items: center;
`
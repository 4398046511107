import { createContext, useCallback, useContext, useState } from "react"
import { toast } from "react-toastify"

import { useAuth } from "./auth"
import apiService from "../services/api.services"
import { getErrorMessage } from "../utils/validations/getErrorMessage"
import { formatValue } from "../utils/format/formatValue"
import { SellDetailsProduct, SellDetailsClient } from "../@types/sellDetailts"
import qs from "qs"

interface ApiResponse{
    status?:{
        status?: number
        descricao?: string
    }
}

interface ApiProductsResponse extends ApiResponse{
    data: SellDetailsProduct[]
    totais_marca: {
        marca: string
        net_sales: number
        qtd_total: number
    }[]
}

interface ApiClientsResponse extends ApiResponse{
    data: SellDetailsClient[]
    totais_marca: {
        marca: string
        net_sales: number
        qtd_total: number
    }[]
}

interface FilterParams{
    anomes?: string
    datade?: string
    dataate?: string
}

interface ClientParams extends FilterParams{}

interface ProductsParams extends FilterParams{
    marca?: string
    gama?: string
}

interface BrandsTotal{
    brand: string
    sales: number
    qtd: number
}

interface SellDetailsContextParams{
    clients: SellDetailsClient[]
    products: SellDetailsProduct[]
    totalBrands: BrandsTotal[]
    getClients: (params?: any) => Promise<void>
    getProducts: (params?: any) => Promise<void>
    isFetching: boolean
}

const SellDetailsContext = createContext({} as SellDetailsContextParams)

export const SellDetailsProvider: React.FC = ({ children }) => {
    const { userData } = useAuth()

    const [clients, setClients] = useState<SellDetailsClient[]>([])
    const [products, setProducts] = useState<SellDetailsProduct[]>([])
    const [isFetching, setIsFetching] = useState<boolean>(false)
    const [totalBrands, setTotalBrands] = useState<BrandsTotal[]>([])

    const getClients = useCallback(async(params?: ClientParams) => {
        setIsFetching(true)
        setClients([])

        try {
            const currentMonth = `${ new Date().getFullYear() }${ (new Date().getMonth() + 1).toString().padStart(2, "0")}`
            const response = await apiService.get<ApiClientsResponse>("/movvenda_cliente", {
                params: {
                    ...params,
                    anomes: params?.anomes|| currentMonth
                },
                paramsSerializer: params => qs.stringify(params, { arrayFormat: "repeat" })
            })

            if(response.data.status?.status){
                setTotalBrands([])
                throw new Error(response.data.status?.descricao)
            }

            const { data, totais_marca } = response.data

            setTotalBrands(totais_marca.map(brand => ({
                brand: brand.marca,
                sales: brand.net_sales,
                qtd: brand.qtd_total
            })))

            setClients(data.map(client => {
                const invoicedNetSales = client.products.reduce((total, currentValue) => total + currentValue.invoicing.net_sales, 0)
                const invoicedQuantity = client.products.reduce((total, currentValue) => total + currentValue.invoicing.qtd, 0)

                const pendentNetSales = client.products.reduce((total, currentValue) => total + currentValue.in_progress.net_sales, 0)
                const pendentQuantity = client.products.reduce((total, currentValue) => total + currentValue.in_progress.qtd, 0)
                /* const invoicedPRMed = invoicedNetSales / invoicedQuantity */

                return {
                    ...client,
                    net_sales: formatValue(client.net_sales as number),
                    /* pr_med: formatValue(client.pr_med as number), */
                    qtd_invoiced: invoicedQuantity,
                    net_sales_invoiced: formatValue(invoicedNetSales),
                    /* pr_med_invoiced: formatValue(invoicedPRMed) */
                    qtd_pendent: pendentQuantity,
                    net_sales_pendent: formatValue(pendentNetSales)
                }
            }))

        } catch (error: any) {
            console.log(error)
            toast.error(getErrorMessage(error))
        } finally{
            setIsFetching(false)
        }
    },[])

    const getProducts = useCallback(async(params?: ProductsParams) => {
        setIsFetching(true)
        setProducts([])
        
        try {
            const currentMonth = `${ new Date().getFullYear() }${ (new Date().getMonth() + 1).toString().padStart(2, "0")}`

            const response = await apiService.get<ApiProductsResponse>("/movvenda_produto",{
                params: {
                    ...params,
                    branch: userData.selectedBranch?.id,
                    anomes: params?.anomes || currentMonth,
                },
                paramsSerializer: params => qs.stringify(params, { arrayFormat: "repeat" })
            })

            /* const response = await apiService.post<ApiProductsResponse>("/movvenda_produto", {
                ...params,
                anomes: params?.anomes || currentMonth
            }, {
                params: {
                    ...params,
                    branch: userData.selectedBranch?.id,
                    anomes: params?.anomes || currentMonth,
                }
            }) */

            if(response.data.status?.status){
                setTotalBrands([])
                throw new Error(response.data.status?.descricao)
            }

            const { data, totais_marca } = response.data

            setTotalBrands(totais_marca.map(brand => ({
                brand: brand.marca,
                sales: brand.net_sales,
                qtd: brand.qtd_total
            })))

            setProducts(data.map((item: Omit<SellDetailsProduct, "total">) => ({
                ...item,
                total: {
                    qtd: item.invoicing.qtd + item.in_progress.qtd + item.pendent.qtd + item.canceled.qtd,
                    net_sales: item.invoicing.net_sales + item.in_progress.net_sales + item.pendent.net_sales + item.canceled.net_sales,
                    pr_med: item.invoicing.pr_med + item.in_progress.pr_med + item.pendent.pr_med + item.canceled.pr_med,
                }
            })))

        } catch (error: any) {
            console.log(error)
            toast.error(getErrorMessage(error))
        } finally{
            setIsFetching(false)
        }
    },[userData.selectedBranch?.id])

    return (
        <SellDetailsContext.Provider value={{ clients, totalBrands, products, isFetching, getClients, getProducts }}>
            { children }
        </SellDetailsContext.Provider>
    )
}

export const useSellDetails = () => {
    const context = useContext(SellDetailsContext)

    if(!context){ throw new Error("The hook useTheme must be used inside a SellDetails Provider") }

    return context
}
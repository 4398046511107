import styled, { css } from "styled-components"
import { lighten, shade } from "polished";
import { fade } from "../../../styles/animations";

interface SearchFieldProps{
    disabled?: boolean
}

export const Container = styled.div`
    width: 100%;
`

export const Header = styled.header`
    width: 100%;
    display: flex;
    padding-top: 8px;
    flex-direction: row;
    justify-content: space-between;

    .MuiTextField-root{    
        fieldset{
            transition: 200ms;
            border-color: ${props => props.theme["blue-800"] };
        }

        input{
            color: ${ props => props.theme["blue-800"] };

            &:disabled{
                background-color: ${ props => props.theme["gray-100"] } !important;
            }
        }

        &, fieldset{
            border-radius: 5px !important;
        }

        span, label{
            color: ${props => props.theme["blue-800"] };
        }

        .Mui-focused{
            color: ${props => props.theme["yellow-500"] } !important;
            
            input{ color: ${props => props.theme["yellow-500"] } !important; }

            fieldset{ border-color: ${props => props.theme["yellow-500"] } !important; }
        }
    }
`

export const Actions = styled.div`
    display: flex;
    flex-direction: row;
    position: relative;
    margin-top: 12px;

    &::before{
        content: "";
        border-bottom-right-radius: 20px;
        height: auto;
        background-color: ${props => props.theme["blue-50"]};
        z-index: 2;
        display: flex;
        align-items: center;
        gap: 8px;
        padding: 0px 14px;
        
        svg{
            color: ${ props => props.theme["blue-500"] } !important;
        }
    }

    > button{
        border: none;
        height: auto;
        padding: 8px;
        border-radius: 10px 10px 0px 0px;
        background-color: ${props => props.theme["green-700"] };
        color: ${ props => props.theme["white-100"] };
        position: relative;
        transition: 200ms;

        &::before{
            content: "";
            position: absolute;
            width: 20px;
            height: 20px;
            background-color: ${props => props.theme["green-700"] };
            left: -20px;
            bottom: 0;
            transition: 200ms;
            z-index: 1;
        }

        &:hover{
            background-color: ${ props => lighten(0.1, props.theme["green-700"])};

            &::before{
                background-color: ${ props => lighten(0.1, props.theme["green-700"])}; 
            }
        }

        &:active{
            background-color: ${ props => lighten(0.2, props.theme["green-700"])};

            &::before{
                background-color: ${ props => lighten(0.2, props.theme["green-700"])}; 
            }
        }
    }

    @media(max-width: 1200px){
        width: 100%;
        justify-content: flex-end;
        margin-top: 0px;
        padding-bottom: 16px;
        background-color: ${ props => props.theme["blue-50"] };

        &::before{
            display: none;
        }

        > button{
            border-radius: 10px;
            &::before{
                content: "";
                display: none;
            }
        }
    }
`

export const SearchField = styled.div<SearchFieldProps>`
    width: 100%;
    max-width: 500px;
    margin-right: 15px;
    margin-bottom: 12px;
    position: relative;
    
    button{
        position: absolute;
        right: 0px;
        top: 0px;
        width: 40px;
        height: 40px;
        border-radius: 0px 5px 5px 0px;
        border: none;
        padding: 2px;
        background-color: #2e7d32;
        transition: 200ms;
        
        svg{
            color: #f9f9f9;
            font-size: 1.5rem;
        }

        ${props => props.disabled === false && css`
            &:hover{
                cursor: pointer;
                background-color: ${ shade(0.2, "#2e7d32") };
            }
        `}
    }
`

export const DefaultTable = styled.div`
    width: 100%;
    height: calc(100vh - 166px);
    position: relative;
    animation: ${ fade } 200ms;

    > p{
        position: absolute;
        bottom: 15px;
        left: 15px;
        font-size: 15px;
        background-color: #e6e6e6;
        padding: 0px 5px;
        border-radius: 10px;
        transition: 200ms;
        z-index: 1;

        &:hover{
            cursor: pointer;
            background-color: #3a6df0;
        }
    }

    > button{
        position: absolute;
        top: 0;
        right: 0;
        background-color: #ffffff00;
        border: none;
    }

    > div{
        z-index: 0;
    }

    .MuiDataGrid-row:hover{
        cursor: pointer !important;
    }

    .MuiDataGrid-selectedRowCount{
        visibility: hidden !important;
    }

    .selected-row{
        background-color: #bbfcd0 !important;
    }
`

export const ActionButtons = styled.div`
    margin-top: 25px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    button{
        width: 48%;
    }
`
import { useEffect, useState, forwardRef, useImperativeHandle, useMemo } from "react"
import { useField } from "@unform/core"

import { ClickAwayListener, Fade, MenuItem } from "@mui/material"

import { CheckIcon, CloseIcon, Container, ErrorMessage, InputArea, OptionsList, SelectionArea, SelectionCard } from "./style"
import { Close } from "@mui/icons-material"

interface OptionItem{
    id: string
    label: string
}

interface FormInputMultiSelectionProps {
    name: string
    label: string
    options?: OptionItem[]
    shrink?: boolean
    hiddenErrorMessage?: boolean
    size?: "small" | "medium"
    variant?: "light" | "dark"
    onClick?: () => void
    hasButtonOption?: boolean
}

export interface FormInputMultiSelectionRef{
    changeValue(value: OptionItem[]): void
    getValue(): OptionItem[] | unknown
}

const FormInputMultiSelection: React.ForwardRefRenderFunction<FormInputMultiSelectionRef, FormInputMultiSelectionProps> = ({ 
    name, 
    label,
    hiddenErrorMessage,
    options,
    shrink = true,
    size = "medium",
    variant = "dark",
    hasButtonOption,
    onClick
}, ref) => {
    const {fieldName, registerField, defaultValue, error} = useField(name)

    const [hasAnError, setHasAnError] = useState(false)
    const [isFocused, setIsFocused] = useState(false)
    const [isFilled, setIsFilled] = useState(false)

    const [selectedOptions, setSelectedOptions] = useState<OptionItem[]>(defaultValue || [])

    const optionsList = useMemo(() => {
        return options?.filter(item => (
            !selectedOptions.some(selectedOption => selectedOption.id === item.id)
        ))
    },[options, selectedOptions])

    const removeOption = (id: string) => {
        setSelectedOptions(prev => prev.filter(option => option.id !== id))
    }

    const handleSelection = (option: OptionItem) => {
        setSelectedOptions(prev => [...prev, option])
    }

    const handleClickInput = () => {
        setIsFocused(true)

        if(onClick && !isFilled){
            onClick()
        }
    }

    useImperativeHandle(ref, () => ({
        changeValue: (value: OptionItem[]) => {
            setSelectedOptions(value)
        },
        getValue: () => selectedOptions
    }))

    useEffect(() => {
        registerField({
            name: fieldName,
            setValue(_, value) {
                setSelectedOptions(value as OptionItem[])
            },
            clearValue(_){
                setSelectedOptions([])
            },
            getValue(_) {
                return selectedOptions
            },
        })
    }, [fieldName, registerField, selectedOptions])

    useEffect(() => {
        if(!!error){ setHasAnError(true) }
    }, [error])

    useEffect(() => {
        if(selectedOptions.length > 0){
            setIsFilled(true)
        } else{
            setIsFilled(false)
        }
    },[selectedOptions])
    
    return(
        <ClickAwayListener onClickAway={() => setIsFocused(false)}>
            <Container 
                size={ size }
                onClick={ handleClickInput }
            >
                <InputArea 
                    size={ size }
                    isFocused={ isFocused }
                    isFilled={ isFilled }
                    variant={ variant }
                    disabledShrink={ !shrink }
                >
                    <legend>
                        <span>{ label }</span>
                    </legend>
                </InputArea>
                <SelectionArea hasButton={ hasButtonOption }>
                    { selectedOptions.map(option => (
                        <SelectionCard 
                            key={ option.id }
                            isFocused={ isFocused } 
                            variant={ variant }
                        >
                            { option.label }
                            <Close 
                                fontSize="small" 
                                onClick={() => removeOption(option.id)}
                            />
                        </SelectionCard>
                    )) }
                </SelectionArea>
                { !!options && (
                    <Fade in={ isFocused }>
                        <OptionsList size={ size }>
                            { optionsList?.map(option => (
                                <MenuItem 
                                    key={ option.id } 
                                    value={ option.id }
                                    onClick={() => handleSelection(option)}
                                >
                                    { option.label }
                                </MenuItem>
                            ))}
                        </OptionsList>
                    </Fade>
                )}
                { !hiddenErrorMessage && (
                    !!error ? (
                        <>
                        <CloseIcon />
                        <ErrorMessage> { error } </ErrorMessage>
                        </>

                    ):((!error && hasAnError) &&
                        <CheckIcon />
                    )
                ) }
            </Container>
        </ClickAwayListener>
    )
}

export default forwardRef(FormInputMultiSelection)
import * as XLSX from 'xlsx'
import * as FileSaver from 'file-saver'

type SheetData = Array<{ [key: string]: any }>

export const exportExcel = (data: SheetData): void => {
    const fileName = `${new Date().toLocaleDateString().replaceAll("/", "")}${new Date().toLocaleTimeString().replaceAll(":", "")}-portalexport`
    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
    const fileExtension = '.xlsx'

    const WorkBook: XLSX.WorkBook = {
        SheetNames: ["data"],
        Sheets: { data: XLSX.utils.json_to_sheet(data) }
    }

    const excelBuffer = XLSX.write(WorkBook, { bookType: "xlsx", type: "array" })
    const file = new Blob([excelBuffer], { type: fileType })

    FileSaver.saveAs(file, fileName + fileExtension)
}
import styled from "styled-components";

export const Container = styled.div`
    width: 100%;
    padding: 15px;
    display: flex;
    flex-direction: column;
    justify-content: start;
    gap: 8px;

    .MuiTextField-root{    
        fieldset{
            transition: 200ms;
            border-color: ${props => props.theme["blue-800"] };
        }

        input{
            color: ${ props => props.theme["blue-800"] };

            &:disabled{
                background-color: ${ props => props.theme["gray-100"] } !important;
            }
        }

        &, fieldset{
            border-radius: 5px !important;
        }

        span, label{
            color: ${props => props.theme["blue-800"] };
        }

        .Mui-focused{
            color: ${props => props.theme["yellow-500"] } !important;
            
            input{ color: ${props => props.theme["yellow-500"] } !important; }

            fieldset{ border-color: ${props => props.theme["yellow-500"] } !important; }
            
            svg{
                color: ${props => props.theme["yellow-500"] } !important;
            }
        }

    }
`

export const Header = styled.header`
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: end;

    input {
        padding-right: 32px !important;
    }
    
    div{
        width: 100%;
        max-width: 750px;
        position: relative;

        svg {
            width: 32px;
            position: absolute;
            right: 4px;
            top: 8px;
        }
    }
`

export const NavList = styled.nav`
    margin-top: 12px;
    width: 100%;
    height: auto;
    max-height: calc(100vh - 200px);
    display: grid;
    grid-template-columns: repeat(auto-fill,minmax(300px, 1fr));
    place-items: center;
    gap: 24px;
    overflow-y: auto;
`

export const NotFoundInfo = styled.div`
    margin-top: 12px;
    width: 100%;
    height: calc(100vh - 280px);
    display: flex;
    justify-content: center;
    align-items: center;

    img{
        @media (max-width: 800px){
            width: 280px !important;
        }
    }
`
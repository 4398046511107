import { useState, useRef } from 'react'
import { MoreVert } from "@mui/icons-material"
import { OptionButton, OptionsContainer, OptionsMenu } from "./style"
import { ClickAwayListener, MenuItem, SvgIconTypeMap } from '@mui/material'
import { OverridableComponent } from '@mui/material/OverridableComponent'

export interface DotOption{
    eventKey: string
    label: string
    icon?: OverridableComponent<SvgIconTypeMap<{}, "svg">> & { muiName: string; }
    action: () => void
}

interface DotOptionsProps{
    options: DotOption[]
    disabled?: boolean
}

export const DotOptions: React.FC<DotOptionsProps>= ({ 
    options,
    disabled
}) => {

    const buttonRef = useRef<HTMLButtonElement>(null)

    const [open, setOpen] = useState<boolean>(false)

    const toggleOpen = () => {
        if(disabled){
            setOpen(false)
            return
        }
        
        setOpen(prev => !prev)
    }

    return (
        <>
        <OptionButton ref={ buttonRef } onClick={ toggleOpen }>
            <MoreVert />
        </OptionButton>
        <OptionsMenu
            open={ open }
            anchorEl={ buttonRef.current }
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
        >
            <ClickAwayListener onClickAway={() => setOpen(false)}>
                <OptionsContainer>
                    { options.map(item => (
                        <MenuItem 
                            key={ item.eventKey } 
                            onClick={() => {
                                item.action()
                                setOpen(false) 
                            }}
                        >
                            { item.icon && <item.icon/> }
                            { item.label }
                        </MenuItem>
                    )) }
                </OptionsContainer>
            </ClickAwayListener>
        </OptionsMenu>
        </>
    )
}
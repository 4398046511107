import styled from "styled-components"

import { fade } from "../../styles/animations"

export const ImageContainer = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    animation: ${ fade } 500ms;
`

export const HomeImage = styled.img`
    width: 90%;
    max-width: 1000px;
    min-width: 280px;
    object-fit: contain;
    opacity: 0.3 !important;
`
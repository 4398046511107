import styled from "styled-components";

interface ButtonProps{
    color: string
    themeType: "dark" | "light"
}

export const Button = styled.button<ButtonProps>`
    border: none;
    height: 35px;
    padding: 0px 8px;
    border-radius: 6px;
    background-color: #f2f2f2;
    background-color: ${ props => props.color };
    color: ${ props => props.themeType === "dark" ? "#FFF" : "#000" };

    &:hover{
        transition: 200ms;
        opacity: 0.9;
    }

    &:active{
        transition: 200ms;
        opacity: 0.8;
    }
`
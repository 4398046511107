import styled, { css } from "styled-components"
import { fade } from '../../../styles/animations'

import { Close, Check } from "@mui/icons-material"
import { Paper } from "@mui/material"

interface SizeProps{
    size: "small" | "medium"
}

interface InputAreaProps extends SizeProps{
    isFocused: boolean
    isFilled: boolean
    variant: "light" | "dark"
    disabledShrink?: boolean
}

interface SelectionCardProps{
    isFocused: boolean
    variant: "light" | "dark"
}

interface SelectionAreaProps{
    hasButton?: boolean
}

const inputSizes = {
    small: "40px",
    medium: "56px"
}

export const Container = styled.div<SizeProps>`
    position: relative;
    width: 100%;
    height: ${ props => inputSizes[props.size] };
    overflow: visible;
`

export const InputArea = styled.fieldset<InputAreaProps>`
    width: 100%;
    display: block;
    height: 100%;
    border-radius: 5px;
    padding-left: 8px !important;
    padding-right: 8px !important;
    border: 1px solid ${props => props.theme["blue-800"] };
    color: ${props => props.theme["blue-800"] };
    transition: border 200ms ease;

    legend{
        font-family: "Roboto","Helvetica","Arial",sans-serif;
        font-weight: 400;
        font-size: 1rem;
        line-height: 1.4375em;
        letter-spacing: 0.00938em;
        display: block;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        margin-top: 2px;
        transition: 100ms;

        ${ props => props.size === "medium" && css`
            margin-top: 10px;
        `}

        span{
            padding-left: 5px;
            padding-right: 5px;
            display: inline-block;
        }
    }

    ${ props => props.variant === "light" && css`
        border: 1px solid ${props => props.theme["white-100"] };
        color: ${props => props.theme["white-100"] };
    ` }

    ${ props => (
        (props.isFilled || props.isFocused) &&
        !props.disabledShrink
    ) && css`
        height: calc(100% + 9px);

        text-align: left;
        position: absolute;
        top: -8px;
        pointer-events: none;

        legend{
            float: unset;
            left: 0;
            top: 0;
            padding: 0;
            display: block;
            width: auto;
            font-size: 0.75em;
        }
    `}

    ${ props => props.isFocused && css`
        color: ${ props.theme["yellow-500"] };
        border-color: ${ props.theme["yellow-500"] };
        border-width: 2px;
    `}
`

export const SelectionArea = styled.div<SelectionAreaProps>`
    width: 100%;
    height: calc(100% - 10px);
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 8px;
    flex-wrap: wrap;
    position: absolute;
    top: 6px;
    padding: 0px 6px;
    overflow: auto;
    z-index: 2;
    
    ${ props => props.hasButton && css`
        padding-right: 70px;
    ` }
`

export const SelectionCard = styled.div<SelectionCardProps>`
    background-color: ${ props => props.isFocused ? props.theme["yellow-500"] : props.theme["blue-800"]};
    color: ${ props => props.theme["white-100"] };
    border-radius: 4px;
    padding: 2px 4px;
    width: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 4px;

    ${ props => props.variant === "light" && css`
        background-color: ${props.isFocused ? props.theme["yellow-500"] : props.theme["white-100"]};
        color: ${props.isFocused ? props.theme["white-100"] : props.theme["blue-800"]};
    ` }

    svg:hover{
        cursor: pointer;
    }
`

export const OptionsList = styled(Paper)<SizeProps>`
    position: absolute;
    top: ${ props => inputSizes[props.size] };
    height: auto;
    max-height: 200px;
    width: 100%;
    z-index: 9999999;

    li{
        height: 30px;
    }
`

export const CloseIcon = styled(Close)`
    color: #c53030;
    position: absolute;
    right: 5px;
    margin-top: 7px;
    animation: ${ fade } 200ms;
`

export const CheckIcon = styled(Check)`
    color: #0dba04;
    position: absolute;
    right: 5px;
    margin-top: 7px;
    animation: ${ fade } 200ms;
`

export const ErrorMessage = styled.p`
    position: absolute;
    bottom: -18px;
    color: #c53030;
    font-size: 12px;
    padding-left: 5px;
`
import styled from "styled-components";

export const Container = styled.div`
    width: 300px;
    height: 280px;

    iframe {
        background-color: #bebebe;
        width: 100%;
        max-width: 300px;
        height: 200px;
        border-radius: 12px;
        overflow: hidden;
    }

    h2 {
        padding: 0px 4px;
        font-size: 18px;
    }
`
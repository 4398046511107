import React, { useRef } from "react";
import { useState, useImperativeHandle, forwardRef } from "react";
import * as Yup from "yup";
import { differenceInDays } from "date-fns";
import { toast } from "react-toastify";
import { Form } from "@unform/web";
import { FormHandles } from "@unform/core";

import apiService from "../../../../../services/api.services";

import getValidationErros from "../../../../../utils/validations/getValidationErros";
import { getErrorMessage } from "../../../../../utils/validations/getErrorMessage";
import { convertDateToEnglishPattern } from "../../../../../utils/format/convertDateToEnglishPattern";
import { getDate } from "../../../../../utils/format/getData";

import { Button } from "../../../../../components/Buttons/Button";
import FormDatePicker from "../../../../../components/Form/FormDataPicker";

import { Dialog, DialogActions, DialogProps, CircularProgress, Grid } from "@mui/material";

import { Close } from "@mui/icons-material";

import { DialogTitleCustom } from "../../../../../styles/globalStyles";
import { Content } from "./style";
import { exportExcel } from "../../../../../utils/misc/exportExcel";
import { OCFormDefaultValue } from "../../../../../data/formValues";
import { useAuth } from "../../../../../hooks/auth";

interface FormData {
  dateIn: string;
  dateAt: string;
}

export interface ReportDialogRef {
  isOpen: boolean;
  openDialog: () => void;
  closeDialog: () => void;
}

interface ReportDialogProps extends Omit<DialogProps, "open"> {}

const ReportDialog: React.ForwardRefRenderFunction<ReportDialogRef, ReportDialogProps> = ({ ...rest }, ref) => {
  const formRef = useRef<FormHandles>(null);

  const [open, setOpen] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { userData } = useAuth();

  const handleClose = () => {
    if (isLoading) {
      return;
    }

    formRef.current?.reset();
    setIsLoading(false);
    setOpen(false);
  };

  const handleStatusMapping = (value: string) => {
    const statusObj = OCFormDefaultValue.status.find((status) => status.value === value);
    return statusObj ? statusObj.label : value;
  };

  const handleNfStatusMapping = (value: string) => {
    const statusNfObj = OCFormDefaultValue.statusNF.find((status) => status.value === value);
    return statusNfObj ? statusNfObj.label : value;
  };

  const handleTypeMapping = (value: string) => {
    if (value === "3") {
      return "Solicitação de Adiantamento";
    }

    const statusObj = OCFormDefaultValue.typeRequest.find((status) => status.value === value);
    return statusObj ? statusObj.label : value;
  };

  const handleBrandMapping = (value: string) => {
    const statusNfObj = OCFormDefaultValue.brands.find((status) => status.value === value);
    return statusNfObj ? statusNfObj.label : value;
  };

  const handleExcelMapping = (data) => {
    return data.map((obj) => {
      return {
        ...obj,
        status: handleStatusMapping(obj.status),
        status_nf: handleNfStatusMapping(obj.status_nf),
        tipo: handleTypeMapping(obj.tipo),
        marca: handleBrandMapping(obj.marca),
      };
    });
  };

  const handleSubmit = async (data: FormData) => {
    const schema = Yup.object().shape({
      dateIn: Yup.string().required("Data de início obrigatória"),
      dateAt: Yup.string().required("Data final obrigatória"),
    });

    try {
      await schema.validate(data, {
        abortEarly: false,
      });

      formRef.current?.setErrors({});

      const startDate = convertDateToEnglishPattern(data.dateIn);
      const endDate = convertDateToEnglishPattern(data.dateAt);

      const differenceDays = differenceInDays(new Date(endDate), new Date(startDate));

      if (differenceDays < 0) {
        toast.warn("Data inicial deve ser menor ou igual a data final");
        return;
      }

      const response = await apiService.get("/rel_ordemcompra", {
        params: {
          branch: userData.selectedBranch?.id,
          emissaode: getDate(startDate),
          emissaoate: getDate(endDate),
        },
      });

      if (!response.data.purchase_order) {
        toast.warn(response.data.status?.descricao || "Nenhum dado com encontrado");
        return;
      }

      exportExcel(handleExcelMapping(response.data.purchase_order));

      toast.success("Relatório emitido com sucesso");
      handleClose();
    } catch (error) {
      console.log(error);

      if (error instanceof Yup.ValidationError) {
        const errors = getValidationErros(error);
        formRef.current?.setErrors(errors);
        return;
      }

      console.log(error);
      toast.error(getErrorMessage(error));
    }
  };

  useImperativeHandle(ref, () => ({
    isOpen: open,
    openDialog: () => setOpen(true),
    closeDialog: handleClose,
  }));

  return (
    <Dialog {...rest} open={open} onClose={handleClose}>
      <DialogTitleCustom>
        Baixar Relatório
        <button onClick={handleClose}>
          <Close />
        </button>
      </DialogTitleCustom>
      <Form ref={formRef} onSubmit={handleSubmit}>
        <Content>
          <Grid container pt={1} spacing={2}>
            <Grid item xs={12} md={6}>
              <FormDatePicker name="dateIn" views={["day"]} inputFormat="dd/MM/yyyy" label="Data de Início" size="small" fullWidth />
            </Grid>
            <Grid item xs={12} md={6}>
              <FormDatePicker name="dateAt" views={["day"]} inputFormat="dd/MM/yyyy" label="Data Fim" size="small" fullWidth />
            </Grid>
          </Grid>
        </Content>
        <DialogActions>
          <Button variant="text" fullWidth disabled={isLoading} onClick={handleClose}>
            Cancelar
          </Button>
          <Button color="success" type="submit" variant="contained" fullWidth disabled={isLoading}>
            {isLoading ? <CircularProgress size={24} color="inherit" /> : "Confirmar"}
          </Button>
        </DialogActions>
      </Form>
    </Dialog>
  );
};

export default forwardRef(ReportDialog);

import { useState } from 'react';

import {
    Grid as Datagrid,
    Table,
    TableHeaderRow,
    TableRowDetail,
} from '@devexpress/dx-react-grid-material-ui';

import { Column, IntegratedSorting, RowDetailState, Sorting, SortingState, TableColumnResizing, TableColumnWidthInfo } from '@devexpress/dx-react-grid'

import { GridContainer, GridLoadingContainer, GridPagination, PaginationButton } from "./style"
import { ArrowLeft, ArrowRight } from '@mui/icons-material';
import { CircularProgress } from '@mui/material';

interface Row {
    [key: string]: any
}

interface DrilldownTableProps{
    rows: Row[],
    columns: Column[],
    childToExpand: React.ComponentType<TableRowDetail.ContentProps> | undefined
    disableHeader?: boolean
    defaultSorting?: Sorting[]
    defaultColumnsWidth?: TableColumnWidthInfo[]
    className?: string
    loading?: boolean
    setRowClassname?: (row: Row | any) => string
    usePagination?: boolean
    currentPage?: number
    totalPages?: number
    onPageChange?: (event: "next" | "prev") => void
}

interface TableRowProps extends Table.DataRowProps{
    getCustomClassname: (row: Row | any) => string 
}

const TableRow: React.FC<TableRowProps> = ({ getCustomClassname, row, ...restProps }) => {      
    return (
        <Table.Row 
            {...restProps}
            row={ row }
            className={ getCustomClassname(row) }
        />
    )
}

export const DrilldownTable: React.FC<DrilldownTableProps> = ({ 
    rows, 
    columns, 
    childToExpand, 
    disableHeader, 
    defaultSorting,
    className,
    loading,
    setRowClassname,
    defaultColumnsWidth,
    currentPage,
    onPageChange,
    usePagination,
    totalPages
}) => {
    const [expandedRowIds, setExpandedRowIds] = useState<Array<string | number>>([]);

    if(!!usePagination && 
        (currentPage === undefined ||
        onPageChange === undefined || 
        totalPages === undefined )
    ){
        throw new Error("When use pagination you must inform 'onPageChange', 'totalPages' and 'currentPage' atributes")
    }

    return (
        <>
        <GridContainer 
            className={ className } 
            showPagination={ !!usePagination }
            isLoading={ loading }
        >
            <Datagrid
                rows={ rows }
                columns={ columns }
            >
                <SortingState defaultSorting={defaultSorting} />
                <IntegratedSorting />
                <RowDetailState
                    expandedRowIds={expandedRowIds}
                    onExpandedRowIdsChange={setExpandedRowIds}
                />
                { setRowClassname ? (
                    <Table 
                        rowComponent={(props) => (
                            <TableRow {...props} getCustomClassname={ setRowClassname }/> 
                        )}
                    />
                ): (
                    <Table /> 
                )}
                <TableColumnResizing defaultColumnWidths={ defaultColumnsWidth } />
                { !disableHeader && (
                    <TableHeaderRow showSortingControls />
                ) }
                <TableRowDetail contentComponent={ childToExpand } />
            </Datagrid>
            { loading && (
                <GridLoadingContainer>
                    <CircularProgress />
                </GridLoadingContainer>
            ) }
        </GridContainer>
        { (usePagination && onPageChange) &&(
            <GridPagination>
                <span>{ currentPage } de { totalPages }</span>
                <PaginationButton 
                    onClick={() => {
                        onPageChange("prev") 
                        setExpandedRowIds([])
                    }}
                >
                    <ArrowLeft />
                </PaginationButton>
                <PaginationButton 
                    onClick={() => {
                        onPageChange("next")
                        setExpandedRowIds([]) 
                    }}
                >
                    <ArrowRight />
                </PaginationButton>
            </GridPagination>
        ) }
        </>
    )
}
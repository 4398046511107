import { useEffect, useState } from 'react'


import { Dialog, DialogProps, Stack } from "@mui/material";

import { DialogTitleCustom } from "../../../../styles/globalStyles";
import { DialogContainer, GridContainer } from "./style";
import { Close } from '@mui/icons-material';
import { DataGrid, GridColumns, ptBR } from '@mui/x-data-grid';
import { formatValue } from '../../../../utils/format/formatValue';
import { SellDetailsProduct } from '../../../../@types/sellDetailts';

interface Details{
    type: string
    qtd: string
    value: string
    average: string
}

interface ProductDialiogProps extends DialogProps{
    selectedProduct: SellDetailsProduct
}

export const ProductDialiog: React.FC<ProductDialiogProps> = ({ selectedProduct, onClose, ...rest }) => {

    const [details, setDetails] = useState<Details[]>([])

    useEffect(() => {
        const productsValuesKeys = ["invoicing", "in_progress", "pendent", "canceled"]

        const selectedProductsKeys = Object.keys(selectedProduct)

        if(selectedProductsKeys.length > 0){
            const productDetails = selectedProductsKeys.map(item => (
                productsValuesKeys.includes(item) ? ({
                    type: item,
                    qtd: selectedProduct[item].qtd,
                    value: formatValue(selectedProduct[item].net_sales),
                    average: formatValue(selectedProduct[item].pr_med)
                }): null
            ))
            .filter(item => item !== null)
    
            setDetails([
                ...productDetails as Details[],
                {
                    type: "total",
                    qtd: selectedProduct.total.qtd.toString(),
                    value: formatValue(selectedProduct.total.net_sales),
                    average: formatValue(selectedProduct.total.pr_med),
                }
            ])
        }
    },[selectedProduct])

    const getValuetype = (type: string) => {
        switch(type){
            case "invoicing": return "Faturado"
            case "in_progress": return "Processamento"
            case "pendent": return "Pendente"
            case "canceled": return "Cancelado"
            case "total": return "Total"
            default: return ""
        }
    }

    const columns: GridColumns = [
        { 
            field: "type", 
            headerName: "Tipo",
            flex: 1,
            renderCell: ({ row }) => getValuetype(row.type)
        },
        { 
            field: "qtd", 
            headerName: "Qtd",
            headerAlign: "center", 
            align: "right", 
            flex: 1
        },
        { 
            field: "value", 
            headerName: "NET SALES", 
            headerAlign: "center", 
            align: "right",  
            flex: 1,
        },
        { 
            field: "average", 
            headerName: "PR MED",
            headerAlign: "center",
            align: "right", 
            flex: 1
        }
    ]

    return (
        <Dialog { ...rest } onClose={ onClose }>
            <DialogTitleCustom>
                Detalhes: { selectedProduct.desc }
                { onClose && (
                    <button onClick={event => onClose(event, "escapeKeyDown") }>
                        <Close/>
                    </button>
                )}
            </DialogTitleCustom>
            <DialogContainer>
                <GridContainer>
                    <DataGrid
                        columns={ columns }
                        localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
                        rows={ details }
                        pageSize={30}
                        getRowId={(row) => row.type }
                        getRowClassName={({ row }) => `grid-row-${row.type}` }
                        rowsPerPageOptions={[30]}
                        disableColumnMenu
                        hideFooter
                        disableSelectionOnClick
                        components={{
                            NoRowsOverlay: () => (
                                <Stack height="100%" alignItems="center" justifyContent="center">
                                    Nenhum resultado foi encontrado
                                </Stack>
                            )
                        }}
                    />
                </GridContainer>
            </DialogContainer>
        </Dialog>
    )
}
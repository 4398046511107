import { useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { Form } from '@unform/web'
import { FormHandles } from '@unform/core'
import * as Yup from 'yup'

import apiService from '../../services/api.services'

import { useTheme } from '../../hooks/theme'

import getValidationErros from '../../utils/validations/getValidationErros'
import { getErrorMessage } from '../../utils/validations/getErrorMessage'

import FormInput from '../../components/Form/FormInput'
import Copyright from '../../components/Misc/Copyright'

import { CircularProgress } from '@mui/material'

import { 
    ActionsForm, 
    Container, 
    FormSection, 
    SignupCard 
} from './style'
import { Button } from '../../components/Buttons/Button'

interface ChangePassData{
    user: string
    email: string
}

export default function SignUp (){

    const navigate = useNavigate()

    const { appImages } = useTheme()

    const formRef = useRef<FormHandles>(null)

    const [isLoading, setIsLoading] = useState<boolean>(false)

    const handleSubmit = async(data: ChangePassData) => {
        try{
            const schema = Yup.object().shape({
                user: Yup.string().required("Nome de usuário obrigatório"),
                email: Yup.string().email("E-mail inválido").required("E-mail obrigatório")
            })

            await schema.validate(data, {
                abortEarly: false
            })

            formRef.current?.setErrors({})
            
            setIsLoading(true)

            const response = await apiService.post("/resetpass", {
                resetPass: {
                    usuario: data.user,
                    email: data.email
                }
            }, {
                headers: { authorization: 'd25c79cb-1823-4ed0-9250-35da7644e225' }
            })

            const { descricao, status } = response.data.status

            if(status !== 200 && status !== 201){
                throw new Error(descricao)
            }

            toast.success(descricao)

            navigate("/login")
        } catch(error: any){
            if(error instanceof Yup.ValidationError){
                const erros = getValidationErros(error)

                return formRef.current?.setErrors(erros)
            }

            console.log(error)
            toast.error(getErrorMessage(error))
        } finally{
            setIsLoading(false)
        }
    }

    return (
        <Container>
            <SignupCard>
                <Form ref={formRef} onSubmit={ handleSubmit }>
                    <header>
                        <img src={ appImages.home } alt="enterprise"/>
                    </header>
                    <FormSection>
                        <FormInput 
                            data-testid='username'
                            name='user'
                            label="Usuário"
                            size="small"
                            fullWidth
                        />
                        <FormInput 
                            data-testid='email'
                            name='email'
                            label="E-mail"
                            size="small"
                            fullWidth
                        />
                    </FormSection>
                    <ActionsForm>
                        <Button
                            fullWidth
                            size="large"
                            type="button"
                            variant="outlined"
                            color="primary"
                            disabled={ isLoading }
                            onClick={() => navigate("/login")}
                        >
                            Voltar
                        </Button>
                        <Button
                            fullWidth
                            size="large"
                            type="submit"
                            variant="contained"
                            color="primary"
                        >
                            { isLoading ? <CircularProgress color="inherit" size={26}/> : "Enviar" }
                        </Button>
                    </ActionsForm>
                </Form>
            </SignupCard>
            <Copyright />
        </Container>
    )
}
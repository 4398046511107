import React from "react"
import { useEffect, useRef, useState, forwardRef, useImperativeHandle } from "react"
import { useField } from "@unform/core"

import { TextFieldProps, BaseTextFieldProps, MenuItem } from "@mui/material"

import { CheckIcon, CloseIcon, Container, ErrorMessage, Input, ClearButton } from "./style"
import { Cancel } from "@mui/icons-material"

interface FormSelectProps extends BaseTextFieldProps{
    name: string
    hiddenErrorMessage?: boolean
    initialValue?: any
    loading?: boolean
    enableClearValue?: boolean
    onChange?: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void
    onClean?: () => void
    cleanRules?: () => void
    onChangeRule?: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, input: TextFieldProps | null, value?: any) => void
    onPasteRule?: (event: any, input: TextFieldProps | null) => void,
    options: Array<{
        value: string | number | readonly string[] | undefined
        label: string
    }>
}

export interface FormSelectRef{
    changeValue(value: string): void
    getValue(): string | unknown
}

const FormSelect: React.ForwardRefRenderFunction<FormSelectRef, FormSelectProps> = ({ 
    name, 
    label,
    hiddenErrorMessage,
    enableClearValue,
    options,
    children,
    loading, 
    onChange,
    onClean,
    ...rest
}, ref) => {

    const inputRef = useRef<TextFieldProps>(null)
    const {fieldName, registerField, defaultValue, error} = useField(name)

    const [value, setValue] = useState<string>("")

    const [hasAnError, setHasAnError] = useState(false)
    const [hasSelected, setHasSelected] = useState(false)

    useImperativeHandle(ref, () => ({
        changeValue: (value) => {
            setValue(value)

            if(value === ""){
                setHasAnError(false)
            }
        },
        getValue: () => ( inputRef.current?.value as string)
    }))

    useEffect(() => {
        registerField({
            name: fieldName,
            ref: inputRef.current,
            getValue(ref) {
                return value
            },
            clearValue(ref, newValue) {
                setValue("")
                setHasSelected(false)
            },
        })
    }, [fieldName, registerField, value])

    useEffect(() => {
        if(!!error){ setHasAnError(true) }
    }, [error])

    useEffect(() => {
        setValue(defaultValue || "")
    },[defaultValue])
    
    return(
        <Container>
            <Input 
                {...rest}
                label={label}
                $isError={ !!error }
                $hasAnError={ hasAnError }
                inputRef={ inputRef }
                name={ name }
                value={ value }
                select
                SelectProps={{
                    displayEmpty: true,
                    defaultValue: ""
                }}
                onChange={(event) => {
                    setHasSelected(true)
                    setValue(event.target.value)
                    if(onChange){
                        onChange(event)
                    }
                }}
            >
                <MenuItem defaultChecked hidden value=""/>
                { options.map((option, index) => (
                    <MenuItem key={ index } value={ option.value }>
                        { option.label }
                    </MenuItem>
                ))}
            </Input>
            { (enableClearValue && hasSelected) && 
                <ClearButton type="button" onClick={() => {
                    if(onClean){ onClean() }
                    setValue("")
                    setHasSelected(false)
                }}>
                    <Cancel />
                </ClearButton>
            }
            { !hiddenErrorMessage && (
                !!error ? (
                    <>
                    <CloseIcon />
                    <ErrorMessage> { error } </ErrorMessage>
                    </>

                ):((!error && hasAnError) &&
                    <CheckIcon />
                )
            ) }
        </Container>
    )
}

export default forwardRef(FormSelect)
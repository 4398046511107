import styled, { css } from "styled-components"
import { Button } from "@mui/material"
import { DefaultTheme } from "../../../themes/defaultTheme"
import { checkColorClarity } from "../../../utils/misc/verifyColorClarity"
import { lighten } from "polished"

const buttonColor = {
    primary: DefaultTheme["blue-800"],
    secondary: DefaultTheme["blue-500"],
    warning: DefaultTheme.warning,
    error: DefaultTheme.danger,
    inherit: DefaultTheme["gray-300"],
    success: DefaultTheme["green-500"],
    info: DefaultTheme["blue-700"]
}

export const MuiCustomButton = styled(Button)`
    border-radius: 5px !important;

    ${ props => props.variant === "contained" && css`
        background-color: ${ buttonColor[props.color || "primary"] } !important;
        color: ${ checkColorClarity(buttonColor[props.color || "primary"]) ? props.theme["blue-800"] : props.theme["blue-50"] } !important;

        &:hover{
            border-color: ${ lighten(0.1, buttonColor[props.color || "primary"])} !important;
            background-color: ${ lighten(0.1, buttonColor[props.color || "primary"])} !important;
        }
    `}

    ${ props => props.variant === "text" && css`
        color: ${ buttonColor[props.color || "primary"] } !important;
    `}

    ${ props => props.variant === "outlined" && css`
        border-color: ${ buttonColor[props.color || "primary"] } !important;
        color: ${ buttonColor[props.color || "primary"] } !important;
    `}

    ${ props => props.disabled && css`
        cursor: not-allowed !important;
    `}
`
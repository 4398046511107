import styled from "styled-components"

export const Container = styled.div`
    width: 100%;
    height: auto;
    overflow-y: auto;
    overflow-x: visible;
    display: flex;
    flex-direction: column;

    .MuiDataGrid-selectedRowCount{
        visibility: hidden !important;
    }
`

export const ActionsButtons = styled.div`
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
`

export const GridContainer = styled.div`
    height: calc(100vh - 148px);
`
import { IconProps } from "../../../@types/icons";

export const PriorityPaymentIcon: React.FC<IconProps> = ({ color, height, size }) => (
    <svg 
        width={ size || "50"} 
        height={ height || "32"}  
        color={ color }
        viewBox="0 0 32 32" 
        fill="none" 
        xmlns="http://www.w3.org/2000/svg"
    >
        <path d="M13.8428 24.2034V22.4554C12.81 22.2089 11.9698 21.7553 11.3221 21.0946C10.6734 20.433 10.1953 19.5644 9.8877 18.4887L12.063 17.6146C12.3266 18.5335 12.7389 19.2224 13.2996 19.6814C13.8595 20.1412 14.5019 20.3712 15.227 20.3712C15.9521 20.3712 16.5726 20.1973 17.0885 19.8494C17.6053 19.5025 17.8637 18.9593 17.8637 18.2197C17.8637 17.5698 17.5948 17.0432 17.0569 16.6398C16.5181 16.2364 15.5566 15.7769 14.1723 15.2615C12.876 14.7909 11.9259 14.2306 11.3221 13.5807C10.7174 12.9308 10.415 12.0791 10.415 11.0258C10.415 10.107 10.7284 9.27196 11.355 8.52075C11.9808 7.77043 12.832 7.28322 13.9087 7.05911V5.3783H16.2158V7.05911C17.0068 7.12634 17.7266 7.4513 18.3752 8.03398C19.023 8.61667 19.4677 9.3002 19.7094 10.0846L17.6001 10.9586C17.4243 10.4432 17.1386 10.012 16.7431 9.66505C16.3476 9.31723 15.7983 9.14332 15.0952 9.14332C14.3261 9.14332 13.7386 9.3114 13.3325 9.64757C12.9256 9.98373 12.7222 10.4432 12.7222 11.0258C12.7222 11.6085 12.9748 12.0679 13.4802 12.4041C13.9856 12.7403 14.8974 13.1325 16.2158 13.5807C17.7978 14.1634 18.8525 14.8469 19.3798 15.6313C19.9072 16.4156 20.1708 17.2785 20.1708 18.2197C20.1708 18.8696 20.061 19.4411 19.8413 19.9342C19.6215 20.4272 19.3306 20.8472 18.9685 21.1941C18.6055 21.5419 18.1823 21.822 17.6989 22.0345C17.2155 22.2479 16.6992 22.4106 16.1499 22.5226V24.2034H13.8428Z" fill="black"/>
        <path d="M28.3129 15.1273C28.3129 22.7616 22.2521 28.9047 14.8315 28.9047C7.41087 28.9047 1.35 22.7616 1.35 15.1273C1.35 7.49306 7.41087 1.35 14.8315 1.35C22.2521 1.35 28.3129 7.49306 28.3129 15.1273Z" stroke="black" strokeWidth="2.7"/>
        <path d="M37.1 22.3618C37.1 27.7544 32.8053 32.0999 27.5393 32.0999C22.2733 32.0999 17.9786 27.7544 17.9786 22.3618C17.9786 16.9692 22.2733 12.6236 27.5393 12.6236C32.8053 12.6236 37.1 16.9692 37.1 22.3618Z" fill="black" stroke="white" strokeWidth="1.8"/>
        <path d="M27.9026 22.5406L29.6909 24.6436C29.8221 24.7978 29.8877 24.9941 29.8877 25.2324C29.8877 25.4708 29.8221 25.667 29.6909 25.8213C29.5598 25.9755 29.3929 26.0526 29.1902 26.0526C28.9875 26.0526 28.8206 25.9755 28.6895 25.8213L26.6865 23.4659C26.615 23.3818 26.5613 23.287 26.5256 23.1816C26.4898 23.0767 26.4719 22.9682 26.4719 22.856V19.5122C26.4719 19.2739 26.5406 19.074 26.6779 18.9124C26.8148 18.7515 26.9846 18.671 27.1873 18.671C27.3899 18.671 27.56 18.7515 27.6973 18.9124C27.8342 19.074 27.9026 19.2739 27.9026 19.5122V22.5406Z" fill="white"/>
    </svg>
)
export const getErrorMessage = (error: any) => {
    if(!!error.response){
        if(error.response.status === 404){
            return "Não foi possível encontrar a requisição"
        }

        return error.response.data.errorMessage
    }

    return error.message
}
import styled from 'styled-components'

export const Container = styled.div`
    width: 100%;
    padding: 5px 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
`

export const MenuSection = styled.div`
    width: 100%;

    p{
        font-weight: bold;
        font-size: 12px;
        margin-top: 7px;
        padding-bottom: 5px;
        border-bottom: 1px solid #c2c2c2;
    }
`
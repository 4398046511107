import { useState, useEffect } from 'react'
import { ApexOptions } from 'apexcharts'
import Chart from 'react-apexcharts'

import { ChartLocales } from "../../../../data/chartLocales"
import { Container, Legend, LegendContainer, NotFoundContainer, SkeletonChart } from "./style"
import { formatValue } from '../../../../utils/format/formatValue'

export interface PercentChartOptions{
    label: string,
    value: number
}

interface PercentChartProps{
    labels: {
        name: string
        color: string
        value: number
    }[]
    width?: number
    height?: number
    isLoading?: boolean
    onSelectDataPoint?: (selectedPoint: PercentChartOptions) => void
}

const defaultOptions: ApexOptions = {
    theme: {
        mode: "dark"
    },
    chart: {
        defaultLocale: 'pt-br',
        locales: ChartLocales,
        type: 'radialBar',
    },
    plotOptions: {
        radialBar: {
            inverseOrder: false,
            hollow: {
                margin: 5,
                size: '48%',
                background: 'transparent',
            },
            track: {
                show: true,
                background: '#aaaaaa',
                strokeWidth: '10%',
                opacity: 1,
                margin: 3, // margin is in pixels
            },
            dataLabels: {
                show: true,
                name: {
                    color: "#FFFFFF"
                },
                value: {
                    formatter: (value) => formatValue(value)
                }
            }
        }
    },
    legend: {
        show: false
    }
}

export const PercentChart: React.FC<PercentChartProps> = ({ 
    labels, 
    height, 
    width, 
    isLoading, 
    onSelectDataPoint 
}) => {

    const [options, setOptions] = useState<ApexOptions>(defaultOptions)
    const [series, setSeries] = useState<any[]>([])

    useEffect(() => {
        setSeries(labels.map(label => label.value))
        setOptions(prev => {
            const colors = labels.map(label => label.color)
            return ({
                ...prev,
                chart: {
                    ...prev.chart,
                    events: {
                        dataPointSelection: onSelectDataPoint ? (
                            function (_, __, options){
                                const selectedLabel = options.w.config.labels[options.dataPointIndex]
                                const selectedValue = options.w.config.series[options.dataPointIndex]

                                onSelectDataPoint({ label: selectedLabel, value: selectedValue })
                            }
                        ): undefined
                    },
                },
                labels: labels.map(label => label.name),
                fill: {
                    type: 'solid',
                    colors: colors
                }
            })
        })
    },[labels, onSelectDataPoint])

    if(isLoading){
        return (
            <Container>
                <SkeletonChart 
                    variant="rectangular" 
                    height={"100%"}
                    animation="wave"
                    style={{ marginTop: "-16px" }}
                />
            </Container>
        )
    }

    if(labels.length <= 0){
        return (
            <Container>
                <NotFoundContainer>
                    <h1>Nenhum Resultado Encontrado</h1>
                </NotFoundContainer>
            </Container>
        )
    }

    return (
        <Container>
            <Chart 
                type="radialBar"
                options={options}
                series={series}
                height={height || "auto"}
                width={width}
            />
            <LegendContainer>
                { labels.map(label => (
                    <Legend key={ label.name } color={ label.color }>
                        { label.name } - { formatValue(label.value) }%
                    </Legend>
                )) }
            </LegendContainer>
        </Container>
    )
}
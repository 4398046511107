import { useState, useEffect, useCallback, useImperativeHandle, forwardRef, useRef } from "react";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";

import { AttachRequestData } from "../../../@types/attachFiles";

import { useAttachFiles } from "../../../hooks/attachFiles";
import { useAuth } from "../../../hooks/auth";

import { convertBase64ToBlob, convertFileToBase64 } from "../../../utils/format/convertFiles";
import { getErrorMessage } from "../../../utils/validations/getErrorMessage";

import DefaultDialog, { DefaultDialogRef } from "../DefaultDialog";

import AttachDialog, { UploadedFile } from "../AttachDialog";
import OptionsButtons from "../../Buttons/OptionsButtons";
import { NotFoundFile } from "../../Misc/Icons/NotFoundFile";

import { Dialog, CircularProgress, Button, Breakpoint } from "@mui/material";

import { Add as AddIcon, Close as CloseIcon, Delete as TrashIcon, Visibility as VisibilityIcon } from "@mui/icons-material";

import { Content, FileCard, Actions, LoadingContainer, NotFoundFileContainer } from "./style";

import { DialogTitleCustom } from "../../../styles/globalStyles";

interface AttachPermissions {
  ANEEFO: boolean;
  ANEIFO: boolean;
  ANEVFO: boolean;
  ANEEOC: boolean;
  ANEIOC: boolean;
  ANEVOC: boolean;
}

export interface DocumentsDialogRef {
  open: boolean;
  openDialog: () => void;
  closeDialog: () => void;
}

interface AttachList extends AttachRequestData {
  isLoadingViewer?: boolean;
  isLoadingRemove?: boolean;
}

interface DocumentsDialogProps {
  fullwidth?: boolean;
  maxWidth?: false | Breakpoint;
  listOrigin: {
    type: number;
    id: string;
    chave?: string;
    date_atual?: string;
    fileName?: string;
    number_vis?: string;
    user_atual?: string;
    user_name?: string;
  };
  status?: string;
}

const DocumentsDialog: React.ForwardRefRenderFunction<DocumentsDialogRef, DocumentsDialogProps> = (
  { maxWidth, fullwidth, listOrigin, status },
  ref
) => {
  const { userData } = useAuth();

  const { deleteFile, listFiles, downloadFile, onUpload } = useAttachFiles();

  const location = useLocation();

  const deleteAlertDialog = useRef<DefaultDialogRef>(null);

  const [open, setOpen] = useState<boolean>(false);

  const [uploadedFiles, setUploadedFiles] = useState<Array<AttachList>>([]);
  const [isLoadingFiles, setIsLoadingFiles] = useState<boolean>(false);
  const [isLoadingUpload, setIsLoadingUpload] = useState<boolean>(false);
  const [openAttachDialog, setOpenAttachDialog] = useState<boolean>(false);
  const [selectedItem, setSelectedItem] = useState<string>("");

  const [attachPermissions, setAttachPermissions] = useState<AttachPermissions>({} as AttachPermissions);

  const hasFiles = uploadedFiles.length > 0;

  const loadFiles = useCallback(async () => {
    setIsLoadingFiles(true);
    try {
      const files = await listFiles({
        id: listOrigin.id,
        type: listOrigin.type,
        chave: listOrigin.chave,
        date_atual: listOrigin.date_atual,
        fileName: listOrigin.fileName,
        number_vis: listOrigin.number_vis,
        user_atual: listOrigin.user_atual,
        user_name: listOrigin.user_atual,
      });
      setUploadedFiles(files.map((file) => ({ ...file, isLoadingViewer: false, isLoadingRemove: false, number_vis: file.number_vis || "0"})));
    } catch (error: any) {
      console.log(error);
      toast.error(getErrorMessage(error));
    }
    setIsLoadingFiles(false);
  }, [listFiles, listOrigin]);

  const viewFile = useCallback(
    async (id: string): Promise<void> => {
      setUploadedFiles((prev) => prev.map((item) => (item.id === id ? { ...item, isLoadingViewer: true } : item)));
      try {
        const fileBase64 = await downloadFile(id);
        const blobFile = convertBase64ToBlob({ b64Data: fileBase64, contentType: "application/pdf;base64" });
        const view = URL.createObjectURL(blobFile);

        const permission = userData.permissions?.includes("FINANC")

        if(permission){
          setUploadedFiles(prev => prev.map(item => item.id === id ? ({
            ...item,
            number_vis: (Number(item.number_vis) + 1).toString()
          }): item))
        }

        window.open(view, "_blank");
      } catch (error: any) {
        toast.error(getErrorMessage(error));
      }
      setUploadedFiles((prev) => prev.map((item) => (item.id === id ? { ...item, isLoadingViewer: false } : item)));
    },
    [downloadFile, userData]
  );

  const removeFile = useCallback(
    async (/* id: string */): Promise<void> => {
      setUploadedFiles((prev) => prev.map((item) => (item.id === selectedItem ? { ...item, isLoadingRemove: true } : item)));
      try {
        await deleteFile(selectedItem);

        setUploadedFiles((prev) => prev.filter((file) => file.id !== selectedItem));

        toast.success("Arquivo removido com sucesso.");
      } catch (error: any) {
        console.log(error);
        toast.error(getErrorMessage(error));
      }
      setUploadedFiles((prev) => prev.map((item) => (item.id === selectedItem ? { ...item, isLoadingRemove: false } : item)));
    },
    [selectedItem, deleteFile]
  );

  const onUploadAttach = useCallback(
    async (files: Array<UploadedFile>): Promise<void> => {
      setIsLoadingUpload(true);

      let data: Array<Omit<AttachRequestData, "id">> = [];

      for (let file of files) {
        data.push({
          fileName: file.filename,
          fileContent: (await convertFileToBase64(file.file)) as string,
        });
      }

      try {
        await onUpload({
          files: data,
          type: listOrigin.type,
          id: listOrigin.id,
        });
      } catch (error: any) {
        console.log(error);
        toast.error(getErrorMessage(error));
      } finally {
        setIsLoadingUpload(false);
        setOpenAttachDialog(false);
        loadFiles();
      }
    },
    [onUpload, loadFiles, listOrigin]
  );

  const handleClose = () => {
    setOpen(false);
    setSelectedItem("");
  };

  useImperativeHandle(ref, () => ({
    open: open,
    openDialog: () => setOpen(true),
    closeDialog: handleClose,
  }));

  useEffect(() => {
    if (open) {
      loadFiles();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  useEffect(() => {
    setAttachPermissions({
      ANEEFO: location.pathname.includes("suppliers") && userData.permissions?.includes("ANEEFO") ? true : false,
      ANEIFO: location.pathname.includes("suppliers") && userData.permissions?.includes("ANEIFO") ? true : false,
      ANEVFO: location.pathname.includes("suppliers") && userData.permissions?.includes("ANEVFO") ? true : false,
      ANEEOC: location.pathname.includes("buy-orders") && userData.permissions?.includes("ANEEOC") ? true : false,
      ANEIOC: location.pathname.includes("buy-orders") && userData.permissions?.includes("ANEIOC") ? true : false,
      ANEVOC: location.pathname.includes("buy-orders") && userData.permissions?.includes("ANEVOC") ? true : false,
    });
  }, [location, userData.permissions]);

  const displayTrash = () => {
    if (status === "7") return false;
    if (status === "5") return false;

    return true;
  };

  return (
    <>
      <Dialog open={open} onClose={handleClose} maxWidth={maxWidth || false} fullWidth={fullwidth}>
        <DialogTitleCustom>
          Anexos
          <button onClick={handleClose}>
            <CloseIcon />
          </button>
        </DialogTitleCustom>
        <Actions>
          {(attachPermissions.ANEIFO || attachPermissions.ANEIOC) && hasFiles && (
            <Button color="success" variant="contained" fullWidth disabled={isLoadingFiles} onClick={() => setOpenAttachDialog(true)}>
              <AddIcon />
              Adicionar
            </Button>
          )}
        </Actions>
        <Content>
          {isLoadingFiles ? (
            <LoadingContainer>
              <CircularProgress />
            </LoadingContainer>
          ) : hasFiles ? (
            uploadedFiles.map((file, index) => (
              <FileCard key={index}>
                <div className="file-content">
                  <strong>{ file.fileName }</strong>
                  <strong>Adicionado por: { file.user_name ? file.user_name : null }</strong>
                  <strong>Visualizações: { file.number_vis }</strong>
                  <strong>{ file.date_atual ? new Date(file.date_atual).toLocaleDateString() : null }</strong>
                </div>
                <div>
                  {(attachPermissions.ANEVFO || attachPermissions.ANEVOC) && (
                    <OptionsButtons
                      action="view"
                      type="button"
                      title="Visualizar"
                      loading={file.isLoadingViewer}
                      onClick={() => viewFile(file.id as string)}
                      icon={VisibilityIcon}
                    />
                  )}
                  {(attachPermissions.ANEEFO || attachPermissions.ANEEOC) && displayTrash() && (
                    <OptionsButtons
                      action="remove"
                      type="button"
                      title="Remover"
                      loading={file.isLoadingRemove}
                      icon={TrashIcon}
                      onClick={() => {
                        setSelectedItem(file.id as string);
                        deleteAlertDialog.current?.openDialog();
                      }}
                    />
                  )}
                </div>
              </FileCard>
            ))
          ) : (
            <NotFoundFileContainer onClick={() => setOpenAttachDialog(true)}>
              <NotFoundFile />
              <p>Nenhum arquivo encontrado, clique aqui para adicionar</p>
            </NotFoundFileContainer>
          )}
          <AttachDialog
            open={openAttachDialog}
            onClose={() => setOpenAttachDialog(false)}
            maxWidth="md"
            fullWidth
            isLoading={isLoadingUpload}
            onUpload={onUploadAttach}
          />
        </Content>
      </Dialog>
      <DefaultDialog
        ref={deleteAlertDialog}
        contentText={`Tem certeza que deseja remover esse anexo?`}
        open={false}
        titleText="Remover NF"
        action={removeFile}
      />
    </>
  );
};

export default forwardRef(DocumentsDialog);

interface ConvertParams{
    b64Data: string
    contentType?: string
    sliceSize?: number
}

export const convertFileToBase64 = (file: File): Promise<string | ArrayBuffer | null> => {
    return new Promise((resolve, reject) => {
        const fileReader = new FileReader()
    
        fileReader.readAsDataURL(file)
        fileReader.onload = () => resolve(fileReader.result)
        fileReader.onerror = (error) => reject(error)
    })
} 

export const convertBase64ToBlob = ({ b64Data, contentType = 'text/plain', sliceSize = 512 }: ConvertParams): Blob => {
    
    //Funcionando
    const byteCharacters = atob(b64Data)
    const byteArrays: Uint8Array[] = []
  
    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        const slice = byteCharacters.slice(offset, offset + sliceSize);

        const byteNumbers = new Array(slice.length)
        for (let i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i)
        }
    
        const byteArray = new Uint8Array(byteNumbers);
        byteArrays.push(byteArray);
    }

    return new Blob(byteArrays, {type: contentType})
}
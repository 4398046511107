import { ButtonBase } from "@mui/material"
import styled from "styled-components"

export const Container = styled.div`
    width: 100%;
    position: relative;
`

export const DetailsHeader = styled.nav`
    height: 40px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
`

export const DetailsContent = styled.section`
    width: 100%;
    overflow: auto;
    margin-top: -3px;
`

export const Actions = styled.div`
    display: flex;
    flex-direction: row;
    gap: 8px;

    svg{
        color: ${ props => props.theme["blue-500"] } !important;
    }
`

export const ActionButton = styled(ButtonBase)`
    padding: 4px !important;
    border-radius: 5px !important;
`
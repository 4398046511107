import { useState, useRef } from 'react'

import { Supplier } from '../../../../../@types/supplier'

import { SupplierFormDefaultValue } from '../../../../../data/formValues'

import { useSupplier } from '../../../../../hooks/supplier'
import { useAuth } from '../../../../../hooks/auth'

import { cnpjMask, cpfMask } from '../../../../../utils/format/masks'

import StatusIcon from '../../../../../components/Misc/StatusIcon'
import ListMobileCard from '../../../../../components/Mobile/ListMobileCard'
import DialogSubtitles from '../../../../../components/Modal/DialogSubtitles'

import { DataGrid, GridColumns, ptBR } from "@mui/x-data-grid"
import { Stack, useMediaQuery, useTheme as useMuiTheme } from '@mui/material'
import { AttachFile, Close, CurrencyExchange, Edit, Visibility } from '@mui/icons-material'

import { GridContainer, Container } from './style'
import { useNavigate } from 'react-router-dom'
import { DotOption, DotOptions } from '../../../../../components/Buttons/DotOptions'
import DocumentsDialog, { DocumentsDialogRef } from '../../../../../components/Modal/DocumentsDialog'
import DefaultDialog, { DefaultDialogRef } from '../../../../../components/Modal/DefaultDialog'
import { toast } from 'react-toastify'
import { getErrorMessage } from '../../../../../utils/validations/getErrorMessage'

interface SupplierGridProps{
    isLoading?: boolean
}

export const SupplierGrid: React.FC<SupplierGridProps> = ({ isLoading }) => {

    const navigate = useNavigate()

    const { userData } = useAuth()
    const { 
        selectASupplier, 
        suppliers, 
        selectedSupplier, 
        implementsSupplier 
    } = useSupplier()

    const muiTheme = useMuiTheme()
    const isMobileView = useMediaQuery(muiTheme.breakpoints.down("sm"))

    const attachDialogRef  = useRef<DocumentsDialogRef>(null)
    const confirmDialogRef = useRef<DefaultDialogRef>(null)

    const [openHintDialog, setOpenHintDialog] = useState<boolean>(false)

    const getStatus = (status: string | number) => {
        switch(Number(status)){
            case 1: return "maintener"
            case 2: return "await"
            case 3: return "active"
            default: return "default"
        }
    }

    const generateActionsButton = (props: Supplier) => {
        let options: DotOption[] = []

        if(userData.permissions?.includes("ANEVFO")){
            options = [{
                eventKey: "attach",
                label: "Anexo",
                icon: AttachFile, 
                action: () => {
                    selectASupplier(props.id)
                    attachDialogRef.current?.openDialog()
                }
            }]
        }

        options = [...options, {
            label: "Vizualizar",
            eventKey: "view", 
            icon: Visibility, 
            action: () => { 
                selectASupplier(props.id)
                navigate("/suppliers/details/view") 
            } 
        }]

        if(props.status === "1"){
            options = [...options, 
                {
                    label: "Efetivar",
                    eventKey: "implements", 
                    icon: CurrencyExchange, 
                    action: () => { 
                        selectASupplier(props.id)
                        confirmDialogRef.current?.openDialog()
                    } 
                },
                {
                    label: "Editar",
                    eventKey: "edit", 
                    icon: Edit, 
                    action: () => { 
                        selectASupplier(props.id)
                        navigate("/suppliers/details/edit")
                        confirmDialogRef.current?.openDialog() 
                    } 
                },
                {
                    label: "Remover",
                    eventKey: "remove", 
                    icon: Close, 
                    action: () => { 
                        selectASupplier(props.id)
                        navigate("/suppliers/details/remove")
                        confirmDialogRef.current?.openDialog()
                    } 
                }
            ]
        }

        return options
    }

    const renderStatusRow = (props: any): JSX.Element => (
        <StatusIcon 
            onPress={() => setOpenHintDialog(true)} 
            status={ getStatus(props.row.status || "")} 
        />
    )

    const generateOptionsArrayMobile = (info: Supplier): Array<any> => {

        let options: any = []

        if(userData.permissions?.includes("ANEVFO")){
            options.push({ 
                type: "button", 
                action: "attach", 
                icon: AttachFile, 
                onSelect: () => {
                    selectASupplier(info.id)
                    attachDialogRef.current?.openDialog()
                }
            })
        }

        options.push({ 
            type: "link", 
            link: "/suppliers/details/view", 
            action: "view", 
            icon: Visibility, 
            onSelect: () => {selectASupplier(info.id)} 
        })

        if(info.status === "1"){
            options.push(...[
                {
                    type: "button", 
                    action: "default", 
                    icon: CurrencyExchange, 
                    onSelect: () => {
                        selectASupplier(info.id)
                        confirmDialogRef.current?.openDialog()
                    }
                },
                { 
                    type: "link", 
                    link: "/suppliers/details/edit", 
                    action: "edit", 
                    icon: Edit, 
                    onSelect: () => {selectASupplier(info.id)} 
                },
                { 
                    type: "link", 
                    link: "/suppliers/details/remove", 
                    action: "remove", 
                    icon: Close, 
                    onSelect: () => {selectASupplier(info.id)} 
                }
            ])
        }

        return options

    }

    const handleImplements = async(id: string) => {
        try{
            await implementsSupplier(id)
        } catch (error: any){
            toast.error(getErrorMessage(error))
        }
    }
    
    const columns: GridColumns = [
        { 
            field: 'status', 
            headerName: "Status", 
            width: 80, 
            align: "center", 
            renderCell: renderStatusRow 
        },
        { 
            field: 'id', 
            headerName: 'ID', 
            width: 80 
        },
        { 
            field: 'nome', 
            headerName: 'Nome', 
            minWidth: 400, 
            flex: 1 
        },
        { 
            field: 'fantasia', 
            headerName: 'Nome Fantasia', 
            flex: 1 
        },
        { 
            field: 'cnpj', 
            headerName: 'CNPJ', 
            flex: 1, 
            renderCell: props => props.row.cnpj.length === 14 ? cnpjMask(props.row.cnpj) : cpfMask(props.row.cnpj) 
        },
        { 
            field: 'email', 
            headerName: 'E-mail', 
            flex: 1 
        },
        { 
            field: 'actions', 
            headerName: 'Opções', 
            headerAlign: "center", 
            align: "center",
            renderCell: ({ row }) => <DotOptions options={ generateActionsButton(row) }/>
        }
    ]
    
    return(
        <>
        <Container>
            { !isMobileView ? (
                <GridContainer>
                    <DataGrid
                        columns={ columns }
                        localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
                        rows={ suppliers }
                        pageSize={30}
                        rowsPerPageOptions={[30]}
                        disableColumnMenu
                        loading={ isLoading }
                        components={{
                            NoRowsOverlay: () => (
                                <Stack height="100%" alignItems="center" justifyContent="center">
                                    Nenhum resultado foi encontrado
                                </Stack>
                            )
                        }}
                    />
                </GridContainer>
            ): (
                suppliers.map(info => (
                    <ListMobileCard 
                        key={info.id}
                        options={ generateOptionsArrayMobile(info) }
                    >
                        <StatusIcon status={ getStatus(info.status || "") } onPress={() => setOpenHintDialog(true)} />
                        <p><b>{ info.id }</b></p>
                        <p><b>Nome: </b>{ info.nome }</p>
                        <p><b>Nome Fantasia: </b>{ info.fantasia }</p>
                        <p><b>CNPJ: </b>{ info.cnpj.length === 14 ? cnpjMask(info.cnpj) : cpfMask(info.cnpj) }</p>
                        <p><b>Email: </b>{ info.email }</p>
                    </ListMobileCard>
                ))
            )}
        </Container>
        <DialogSubtitles
            open={ openHintDialog }
            onClose={() => setOpenHintDialog(false) }
            options={ SupplierFormDefaultValue.status.map(status => ({
                legend: <StatusIcon status={ getStatus(status.value) }/>,
                label: status.label
            }))}
        />
        <DocumentsDialog
            ref={ attachDialogRef }
            listOrigin={{ id: selectedSupplier, type: 2 }}
            fullwidth
            maxWidth="md"
        />
        <DefaultDialog
            ref={ confirmDialogRef }
            open={ false } //valor inicial
            titleText="Submeter Financeiro"
            contentText={`Tem certeza que deseja submeter o registro para o financeiro?\nLembrete: após isso não será possivel editar.`}
            action={ () =>  handleImplements(selectedSupplier) }
        />
        </>
    )
}
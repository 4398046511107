import OptionsButtons, { ActionsButtonType } from '../../Buttons/OptionsButtons'

import { Info } from '@mui/icons-material'

import { SvgIconTypeMap } from '@mui/material'
import { OverridableComponent } from '@mui/material/OverridableComponent'

import { Actions, Container } from './style'

export interface OptionsMobileCard{
    title?: string
    type: "button" | "link"
    action: ActionsButtonType
    icon: OverridableComponent<SvgIconTypeMap<{}, "svg">> & { muiName: string }
    link?: string
    onSelect: (event: any) => void
}

interface ListMobileCardprops{
    color?: string
    onClick?: (currentRow: any) => void
    options?: Array<OptionsMobileCard>
}

const ListMobileCard: React.FC<ListMobileCardprops> = ({ children, options, color, onClick }) => (
    <Container onClick={ onClick } bgColor={ color }>
        { children }
        { options &&
            <Actions>
                { options.map(option => (
                    <OptionsButtons
                        key={ option.title }
                        title={ option.title || "" }
                        type={ option.type }
                        to={ option.link }
                        action={ option.action }
                        icon={ option.icon || Info }
                        onClick={ option.onSelect }
                    />
                ))}
            </Actions>
        }
    </Container>
)

export default ListMobileCard
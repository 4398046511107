import { IconProps } from "../../../@types/icons";

export const PartialBillingIcon: React.FC<IconProps> = ({ color, height, size }) => (
    <svg 
        width={ size || "32"} 
        height={ height || "32"}  
        color={ color }
        viewBox="0 0 32 32" 
        fill="none" 
        xmlns="http://www.w3.org/2000/svg"
    >
        <path d="M5.33333 10.6667C4.95556 10.6667 4.63911 10.5391 4.384 10.284C4.128 10.028 4 9.71111 4 9.33333C4 8.95556 4.128 8.63867 4.384 8.38267C4.63911 8.12756 4.95556 8 5.33333 8H18.6667C19.0444 8 19.3613 8.12756 19.6173 8.38267C19.8724 8.63867 20 8.95556 20 9.33333C20 9.71111 19.8724 10.028 19.6173 10.284C19.3613 10.5391 19.0444 10.6667 18.6667 10.6667H5.33333ZM5.33333 16C4.95556 16 4.63911 15.872 4.384 15.616C4.128 15.3609 4 15.0444 4 14.6667C4 14.2889 4.128 13.972 4.384 13.716C4.63911 13.4609 4.95556 13.3333 5.33333 13.3333H18.6667C19.0444 13.3333 19.3613 13.4609 19.6173 13.716C19.8724 13.972 20 14.2889 20 14.6667C20 15.0444 19.8724 15.3609 19.6173 15.616C19.3613 15.872 19.0444 16 18.6667 16H5.33333ZM5.33333 21.3333C4.95556 21.3333 4.63911 21.2053 4.384 20.9493C4.128 20.6942 4 20.3778 4 20C4 19.6222 4.128 19.3053 4.384 19.0493C4.63911 18.7942 4.95556 18.6667 5.33333 18.6667H13.3333C13.7111 18.6667 14.028 18.7942 14.284 19.0493C14.5391 19.3053 14.6667 19.6222 14.6667 20C14.6667 20.3778 14.5391 20.6942 14.284 20.9493C14.028 21.2053 13.7111 21.3333 13.3333 21.3333H5.33333ZM20.8667 24.4L18 21.5333C17.7556 21.2889 17.6333 20.9778 17.6333 20.6C17.6333 20.2222 17.7556 19.9111 18 19.6667C18.2444 19.4222 18.5502 19.3 18.9173 19.3C19.2836 19.3 19.6 19.4222 19.8667 19.6667L21.8 21.5333L26.5333 16.8C26.7778 16.5556 27.0831 16.4333 27.4493 16.4333C27.8164 16.4333 28.1333 16.5667 28.4 16.8333C28.6667 17.1 28.7947 17.4222 28.784 17.8C28.7724 18.1778 28.6333 18.5 28.3667 18.7667L22.7333 24.4C22.4889 24.6444 22.1778 24.7667 21.8 24.7667C21.4222 24.7667 21.1111 24.6444 20.8667 24.4Z" fill="#743AC9"/>
    </svg>
)
import styled from "styled-components" 

export const SearchSupplier = styled.div`
    position: absolute;
    right: 0px;
    top: 0px;
    
    button{
        width: 40px;
        height: 40px;
        border-radius: 0px 4px 4px 0px;
        border: none;
        padding: 2px;
        background-color: ${ props => props.theme["green-500"] };
        transition: 200ms;
        
        svg{
            color: #f9f9f9;
            font-size: 1.5rem;
        }
    }
`
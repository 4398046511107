import { ButtonBase, DialogActions, DialogContent } from '@mui/material'
import styled from 'styled-components'

export const Container = styled(DialogContent)`
    margin-top: 20px;
`

export const Actions = styled(DialogActions)`
    padding: 0px 24px 24px 24px !important;

    button{
        height: 37px;
        min-width: 120px;
    }
`

export const InitialAddIconContainer = styled(ButtonBase)`
    width: 100%;
    height: 35vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 15px !important;

    div{
        flex-direction: row;
        margin-bottom: 15px;

        svg{
            color: #c2c2c2;
    
            &:first-child{
                font-size: 10rem;
            }
        }
    }
`
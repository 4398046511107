import React from 'react'

import { Copyright as CopyrightIcon } from "@mui/icons-material"

import { Container } from './style'

import LogithinkLogo from '../../../assets/logithink.png'

const Copyright: React.FC = () => (
  <Container>
    <div>
      <img src={LogithinkLogo} alt="logithink" />
      <CopyrightIcon />
      <p>{new Date().getFullYear()} Todos os direitos reservados</p>
    </div>
  </Container>
)

export default Copyright

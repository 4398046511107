import styled from 'styled-components'

export const Container = styled.div`
    position: relative;
`

export const GridContainer = styled.div`
    width: 100%;
    height: calc(100vh - 160px);
    position: relative;
`
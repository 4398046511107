import React from "react"
import { useEffect, useRef, useState, forwardRef, useImperativeHandle } from "react"
import { useField } from "@unform/core"

import { CheckIcon, CloseIcon, Container, ErrorMessage, Input } from "./style"

import { Autocomplete, TextFieldProps } from '@mui/material'

interface FormAutoCompleteProps {
    name: string
    label: string
    loading: boolean
    options: Array<string>
    defaultValue?: string
    freeWrite?: boolean
    disabled?: boolean
    cleanRules?: () => void
    onPasteRule?: (event: any, input: TextFieldProps | null) => void
    onChangeRule?: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, input: TextFieldProps | null, value?: any) => void
}

export interface FormAutoCompleteRef{
    changeValue(value: string | null): void
    getValue(): string | unknown
}

const FormAutoComplete: React.ForwardRefRenderFunction<FormAutoCompleteRef,FormAutoCompleteProps> = ({ 
    name, 
    label,
    loading,
    options,
    freeWrite,
    disabled
}, ref) => {

    const inputRef = useRef<TextFieldProps>(null)
    const { fieldName, registerField, error, defaultValue } = useField(name)

    const [hasAnError, setHasAnError] = useState(false)
    const [value, setValue] = useState(defaultValue || null)

    useImperativeHandle(ref, () => ({
        changeValue: (value) => {
            setValue(value)

            if(value === ""){
                setHasAnError(false)
            }
        },
        getValue: () => ( inputRef.current?.value || null )
    }))

    useEffect(() => {
        registerField({
            name: fieldName,
            ref: inputRef.current,
            path: "value",
            clearValue(ref, newValue) {
                setValue(newValue)
            }
        })
    }, [fieldName, registerField])

    useEffect(() => {
        if(!!error){ setHasAnError(true) }
    }, [error])

    useEffect(() => { 
        setValue(defaultValue || null) 
    }, [defaultValue])

    useEffect(() => {
        if(value === ""){
            setHasAnError(false)
        }
    }, [value])

    return(
        <Container>
            <Autocomplete
                value={ value }
                options={ options }
                loadingText="Carregando..."
                noOptionsText="Sem resultados"
                fullWidth
                disabled={ disabled }
                freeSolo={ freeWrite }
                loading={ loading }
                onChange={(event, option) => setValue(option || null)}
                renderInput={props => 
                    <Input
                        {...props} 
                        hiddenLabel
                        name={ name } 
                        label={label}
                        $$isError={ !!error }
                        $hasAnError={ hasAnError }
                        size="small"
                        fullWidth
                        inputRef={inputRef}
                    />
                }
            />
            { !!error ?
                <>
                <CloseIcon />
                <ErrorMessage> { error } </ErrorMessage>
                </>
            : (!error && hasAnError) &&
                <CheckIcon />
            }
        </Container>
    )
}

export default forwardRef(FormAutoComplete)
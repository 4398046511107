import axios from "axios";
import { useState, useCallback, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import { useAuth } from "../../../hooks/auth";
import { useBuyOrder } from "../../../hooks/buyOrders";
import { DocumentsProvider } from "../../../hooks/documents";

import { getErrorMessage } from "../../../utils/validations/getErrorMessage";

import DocumentsDialog, { DocumentsDialogRef } from "../../../components/Modal/DocumentsDialog";
import DefaultDialog, { DefaultDialogRef } from "../../../components/Modal/DefaultDialog";
import ImplementsDialog, { ImplementsDialogRef } from "./components/ImplementsDialog";
import DocumentAssignmentDialog, { DocumentAssignmentDialogRef } from "../components/DocumentAssignment";
import RequestsGrid from "./components/RequestsGrid";
import { RequestFilter } from "./components/Filter";
import TabNav from "../../../components/Navigation/TabNav";

import { Tooltip, useMediaQuery, useTheme as useMuiTheme } from "@mui/material";

import { Add, Download } from "@mui/icons-material";

import { ActionButton, Actions, Container, GridOptionsHeader } from "./style";

import { useQuery } from "react-query";
import { BuyOrder, RequestParams } from "../../../@types/buyOrder";
import apiService from "../../../services/api.services";
import { formatValue } from "../../../utils/format/formatValue";
import CancelRequestModal, { CancelRequestModalRef } from "./components/CancelRequestModal";
import { queryClient } from "../../../services/queryClient";
import ReportDialog, { ReportDialogRef } from "./components/ReportDialog";

interface RequestsResponse {
  orders: BuyOrder[];
  currentPage: number;
  totalRows: number;
}

const isEmptyDate = (date: string) => {
  return !date?.replace("/", "").trim();
};

export default function Suppliers() {
  const navigate = useNavigate();

  const { implementOC, selectedOrder, startAccountRoutine } = useBuyOrder();

  const { userData } = useAuth();

  const muiTheme = useMuiTheme();
  const isMobileView = useMediaQuery(muiTheme.breakpoints.down("lg"));

  const attachDialogRef = useRef<DocumentsDialogRef>(null);
  const reportDialogRef = useRef<ReportDialogRef>(null);
  const implementsDialogRef = useRef<ImplementsDialogRef>(null);
  const verifyNFDioalogRef = useRef<DocumentAssignmentDialogRef>(null);
  const confirmStatusDialogRef = useRef<DefaultDialogRef>(null);
  const cancelRequestDialogRef = useRef<CancelRequestModalRef>(null);

  const [currentRequestType, setCurrentRequestType] = useState<string>();

  const [selectedRequestToCancel, setSelectedRequestToCancel] = useState<BuyOrder | null>(null);

  const [ordersFilters, setOrdersFilters] = useState<RequestParams | null>(null);
  const [currentPage, setCurrentPage] = useState<number>(0);

  const [verifyDocumentsType, setVerifyDocumentsType] = useState<"add" | "aprove">("add");

  const navMenuOptions = [
    {
      title: "Ordem de Compra",
      eventKey: "1",
    },
    {
      title: "Pagamento Urgente",
      eventKey: "2",
    },
    {
      title: "Solicitação de Adiantamento",
      eventKey: "3",
    },
  ];

  const {
    data,
    isLoading: loadingOrders,
    error: fetchinError,
    refetch,
  } = useQuery<RequestsResponse | null>(
    ["requests", userData.selectedBranch?.id, currentPage, currentRequestType, ordersFilters],
    () => getBuyOrders(currentRequestType, ordersFilters),
    {
      staleTime: 1000 * 60 * 5, // 5min
      refetchInterval: 1000 * 60 * 5, // 5min
      refetchOnWindowFocus: "always",
    }
  );

  const getBuyOrders = useCallback(async (
    type = "1",
    params?: RequestParams | null
  ): Promise<RequestsResponse | null> => {
      let requestParams = {} as RequestParams;

      if (params) {
        requestParams = params;
      }

      if (!userData.permissions?.includes("FINANC")) {
        if (userData.approver) {
          requestParams.aprovador = userData.id;
        } else {
          requestParams.solicitante = userData.id;
        }
      } else {
        requestParams.financeiro = userData.id;
      }

      try {
        const response = await apiService.get("/ordemcompra", {
          params: {
            ...requestParams,
            tipo: type,
            branch: userData.selectedBranch?.id as string,
            pagina: currentPage + 1,
            totalreg: 30,
          },
        });

        if (!!response.data.data) {
          const requests = response.data.data.ordem.map((ordem) => ({
            ...ordem,
            data_aprovacao: isEmptyDate(ordem.data_aprovacaoacao) ? null : new Date(ordem.data_aprovacaoacao),
            data_aprovacao2: isEmptyDate(ordem.data_aprovacaoacao2) ? null : new Date(ordem.data_aprovacaoacao2),
            data_aprovacao_divergencia: isEmptyDate(ordem.data_aprovacao_divergencia) ? null : new Date(ordem.data_aprovacao_divergencia),
            data_aprovacao_financeiro: isEmptyDate(ordem.data_aprovacaoacao_financeiro)
              ? null
              : new Date(ordem.data_aprovacaoacao_financeiro),
            data_emissao: new Date(ordem.data_emissao),
            data_entrega: new Date(ordem.data_entrega),
            total: formatValue(ordem.total),
          }));

          return {
            orders: requests,
            currentPage: Number(response.data.atual_page),
            totalRows: Number(response.data.total_registers),
          };
        } else {
          toast.error(response.data.status.descricao);
          return null;
        }
      } catch (error: any) {
        if (axios.isCancel(error)) {
          console.log("Request cancelada");
        }
        return null;
      }
    },
    [userData, currentPage]
  );

  const handlePagination = useCallback((newPage: number) => {
    setCurrentPage(newPage);
  }, []);

  const onImplementOC = useCallback(
    async (aproverId: string) => {
      try {
        await implementOC(selectedOrder.id, aproverId);
        toast.success("Solicitação efetivada com sucesso");
      } catch (error: any) {
        console.log(error);
        toast.error(getErrorMessage(error));
      } finally {
        implementsDialogRef.current?.closeDialog();
      }
    },
    [selectedOrder, implementsDialogRef, implementOC]
  );

  const changeToFinalStatus = useCallback(async () => {
    try {
      await startAccountRoutine(selectedOrder.id);
      confirmStatusDialogRef.current?.closeDialog();
    } catch (error: any) {
      console.log(error);
      toast.error(getErrorMessage(error));
    }
  }, [selectedOrder, confirmStatusDialogRef, startAccountRoutine]);

  const handleOpenReportDialog = () => {
    reportDialogRef.current?.openDialog();
  };

  const handleRequestType = useCallback(async (eventKey: string) => {
    setCurrentRequestType(eventKey);
  }, []);

  const handleFilters = useCallback((filters: RequestParams) => {
    setCurrentPage(0);
    setOrdersFilters(filters);
  }, []);

  const handleCancelFilters = useCallback(() => {
    setCurrentPage(0);
    setOrdersFilters(null);
  }, []);

  const handleCancelOrder = useCallback(async (order: BuyOrder) => {
    setSelectedRequestToCancel(order);
    cancelRequestDialogRef.current?.openDialog();
  }, []);

  const cancelRequest = useCallback(
    async (order: BuyOrder | null, justify: string) => {
      if (!order) {
        toast.error("Ocorreu uma falha ao cancelar a ordem. Por favor contate o administrador do sistema.");
        return;
      }

      const response = await apiService.post(
        "/canc_ordemcompra",
        {
          purchase_order: {
            po_number: order.id,
            requesting_user: userData.id,
            reason_cancel: justify,
          },
        },
        {
          params: {
            branch: userData.selectedBranch?.id,
          },
        }
      );

      if (response.data.status.status !== 200) {
        throw new Error(response.data.status.descricao);
      }

      queryClient.invalidateQueries({ queryKey: ["request"] });
      refetch();
    },
    [refetch, userData]
  );

  useEffect(() => {
    if (currentRequestType) {
      sessionStorage.setItem("tipo", currentRequestType);
    }
  }, [currentRequestType]);

  useEffect(() => {
    const type = sessionStorage.getItem("tipo");
    if (type) {
      setCurrentRequestType(type);
    }
  }, []);

  useEffect(() => {
    if (fetchinError) {
      toast.error(getErrorMessage(fetchinError));
    }
  }, [fetchinError]);

  useEffect(() => {
    const defaultFilters = JSON.parse(sessionStorage.getItem("@portalnaos.requests.filters") || "{}")
    setOrdersFilters(defaultFilters)
  }, [fetchinError]);

  return (
    <>
      <Container>
        <GridOptionsHeader>
          {!isMobileView && <TabNav defaultSelection={currentRequestType} options={navMenuOptions} onSelectOption={handleRequestType} />}
          <Actions>
            <div>
              <Tooltip title="Exportar Excel">
                <ActionButton onClick={handleOpenReportDialog}>
                  <Download />
                </ActionButton>
              </Tooltip>
              <RequestFilter currentType={currentRequestType as string} onCleanFilter={handleCancelFilters} onStartFilter={handleFilters} />
            </div>
            <button onClick={() => navigate("/buy-orders/addNew")}>
              <Add />
              Nova Solicitação
            </button>
          </Actions>
        </GridOptionsHeader>
        <RequestsGrid
          gridData={loadingOrders ? [] : data?.orders || []}
          currentPage={currentPage}
          totalRows={data?.totalRows || 0}
          isLoading={loadingOrders}
          selectCategory={ currentRequestType }
          onPageChange={handlePagination}
          onImplements={() => implementsDialogRef.current?.openDialog()}
          onStartBillingProcess={() => confirmStatusDialogRef.current?.openDialog()}
          onAttach={() => attachDialogRef.current?.openDialog()}
          onCancel={handleCancelOrder}
          onVerify={(action) => {
            setVerifyDocumentsType(action);
            verifyNFDioalogRef.current?.openDialog();
          }}
        />
      </Container>
      <DocumentsDialog
        ref={attachDialogRef}
        listOrigin={{ id: selectedOrder.id, type: 1 }}
        fullwidth
        maxWidth="md"
        status={selectedOrder.status}
      />
      <ImplementsDialog
        ref={implementsDialogRef}
        open={false} //valor inicial
        action={onImplementOC}
      />
      <DefaultDialog
        ref={confirmStatusDialogRef}
        open={false} //valor inicial
        titleText="Fluxo Financeiro"
        contentText={`Tem certeza que deseja iniciar o fluxo do financeiro?`}
        action={changeToFinalStatus}
      />
      <DocumentsProvider>
        <DocumentAssignmentDialog ref={verifyNFDioalogRef} initialStage={verifyDocumentsType} />
      </DocumentsProvider>
      <CancelRequestModal
        ref={cancelRequestDialogRef}
        open={false}
        onCancel={(justify) => cancelRequest(selectedRequestToCancel, justify)}
        selectedOrderId={selectedRequestToCancel?.id || ""}
      />
      <ReportDialog ref={reportDialogRef} />
    </>
  );
}

import React from 'react'
import { useEffect } from 'react'
import { Outlet, useNavigate } from 'react-router-dom'

import { useAuth } from '../../hooks/auth'

import { BuyOrderProvider } from '../../hooks/buyOrders'
import { AttachFilesProvider } from '../../hooks/attachFiles'
import { toast } from 'react-toastify'

const BuyOrders: React.FC = () => {

    const { userData } = useAuth()

    const navigate = useNavigate()

    useEffect(() => {
        if(!userData.permissions?.includes('FORMOC')){
            toast.info("Você não tem permissão para acessar essa pagina")

            navigate("/")
        }
    },[userData, navigate])

    return (
        <AttachFilesProvider>
            <BuyOrderProvider>
                <Outlet />
            </BuyOrderProvider>
        </AttachFilesProvider>
    )
}

export default BuyOrders
import {
    Grid as Datagrid,
    Table,
    TableHeaderRow,
} from '@devexpress/dx-react-grid-material-ui';

import { Column, IntegratedSorting, Sorting, SortingState } from '@devexpress/dx-react-grid'

import { GridContainer, GridLoadingContainer, GridPagination, PaginationButton } from "./style"
import { CircularProgress } from '@mui/material';
import { ArrowLeft, ArrowRight } from '@mui/icons-material';

interface Row{
    [key: string]: any
}

interface SimpleTableProps{
    rows: Row[],
    columns: Column[],
    defaultSorting?: Sorting[]
    disableHeader?: boolean
    className?: string
    loading?: boolean
    setRowClassname?: (row: Row | any) => string
    usePagination?: boolean
    currentPage?: number
    totalPages?: number
    onPageChange?: (event: "next" | "prev") => void
}

interface TableRowProps extends Table.DataRowProps{
    getCustomClassname: (row: Row | any) => string 
}

const TableRow: React.FC<TableRowProps> = ({ getCustomClassname, row, ...restProps }) => {      
    return (
        <Table.Row 
            {...restProps}
            row={ row }
            className={ getCustomClassname(row) }
        />
    )
}


export const SimpleTable: React.FC<SimpleTableProps> = ({ 
    rows, 
    columns, 
    disableHeader, 
    defaultSorting,
    className,
    loading,
    setRowClassname,
    currentPage,
    onPageChange,
    usePagination,
    totalPages
}) => {

    if(!!usePagination && 
        (currentPage === undefined ||
        onPageChange === undefined || 
        totalPages === undefined )
    ){
        throw new Error("When use pagination you must inform 'onPageChange', 'totalPages' and 'currentPage' atributes")
    }

    return (
        <>
        <GridContainer 
            className={ className } 
            showPagination={ !!usePagination }
            isLoading={ loading }
        >
            <Datagrid
                rows={rows}
                columns={columns}
            >
                <SortingState defaultSorting={ defaultSorting } />
                <IntegratedSorting />
                { setRowClassname ? (
                    <Table 
                        rowComponent={(props) => (
                            <TableRow {...props} getCustomClassname={ setRowClassname }/> 
                        )}
                    />
                ): (
                    <Table />
                )}
                { !disableHeader && (
                    <TableHeaderRow showSortingControls />
                ) }
            </Datagrid>
            { loading && (
                <GridLoadingContainer>
                    <CircularProgress />
                </GridLoadingContainer>
            ) }
        </GridContainer>
        { (usePagination && onPageChange) &&(
            <GridPagination>
                <span>{ currentPage } de { totalPages }</span>
                <PaginationButton onClick={() => onPageChange("prev") }>
                    <ArrowLeft />
                </PaginationButton>
                <PaginationButton onClick={() => onPageChange("next") }>
                    <ArrowRight />
                </PaginationButton>
            </GridPagination>
        ) }
        </>
    )
}
import styled from 'styled-components'

import { ButtonBase } from '@mui/material'
import { Button } from '../../../../components/Buttons/Button'

export const FilterContainer = styled.div`
    position: relative;
`

export const FilterPosition = styled.div`
    position: absolute;
    width: calc(100% - 180px);
    max-width: 800px;
    top: 36px;
    right: 0px;
    z-index: 5;
`

export const ActionButton = styled(ButtonBase)`
    padding: 4px !important;
    border-radius: 5px !important;
`

export const SearchButton = styled(Button)`
    position: absolute !important;
    color: #fff !important;
    right: 0;
    top: 16px;
    width: 40px !important;
    padding: 8px !important;
    border-top-left-radius: 0px !important;
    border-bottom-left-radius: 0px !important;
    z-index: 10;
    
    svg{
        width: 24px;
        color: #fff !important;
    }
`
import { PaginationItem } from "../PaginationItem"
import { Container, PaginationActions } from './style'

interface PaginationProps{
    totalCountOfRegisters: number
    registerPerPage?: number
    currentPage?: number
    onChangePage: (page: number) => void
}

const siblingsCount = 1

const generatePagesArray = (from: number, to: number) => {
    return [...new Array(to - from)].map((_, index) => {
        return from + index + 1
    })
    .filter(page => page > 0)
}

export const Pagination: React.FC<PaginationProps> = ({
    totalCountOfRegisters,
    currentPage = 1,
    onChangePage,
    registerPerPage = 10
}) => {

    const lastPage = Math.ceil(totalCountOfRegisters / registerPerPage)

    const initItemIndex = registerPerPage * currentPage - registerPerPage + 1
    const lastItemIndex = registerPerPage * currentPage

    const previousPages = currentPage > 1 ? (
        generatePagesArray(currentPage - 1 - siblingsCount, currentPage - 1)
    ): []

    const nextPages = currentPage < lastPage ? (
        generatePagesArray(currentPage, Math.min(currentPage + siblingsCount, lastPage))
    ): []

    return(
        <Container>
            <div>
                <strong>{ initItemIndex }</strong> - <strong>{ totalCountOfRegisters > lastItemIndex ? lastItemIndex : totalCountOfRegisters }</strong> de <strong>{ totalCountOfRegisters }</strong>
            </div>
            <PaginationActions>
                { currentPage > (1 + siblingsCount) && (
                    <>
                    <PaginationItem onChangePage={ onChangePage } number={1} />
                    { currentPage > (2 + siblingsCount) && (
                        <p>...</p>
                    )}
                    </>
                )}
                { previousPages.length > 0 && previousPages.map(page => (
                    <PaginationItem onChangePage={ onChangePage } key={ page } number={page} />
                )) }
                <PaginationItem onChangePage={ onChangePage } number={currentPage} isCurrent/>
                { nextPages.length > 0 && nextPages.map(page => (
                    <PaginationItem onChangePage={ onChangePage} key={ page } number={page} />
                )) }
                { (currentPage + siblingsCount) < lastPage && (
                    <>
                    { (currentPage + 1 + siblingsCount) < lastPage && (
                        <p>...</p>
                    )}
                    <PaginationItem onChangePage={ onChangePage } number={lastPage} />
                    </>
                )}
            </PaginationActions>
        </Container>
    )
}
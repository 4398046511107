import styled from "styled-components";
import { DrilldownTable } from "../../../../components/Tables/DrilldownTable";
import { SimpleTable } from "../../../../components/Tables/SimpleTable";
import { ButtonBase } from "@mui/material";

export const PendentsTable = styled(DrilldownTable)`
    width: 100% !important;

    .MuiTableCell-root{
        background-color: #F8CBAD !important;
    }
`

export const DocumentTable = styled(DrilldownTable)`
    width: 100% !important;

    .MuiTableCell-root{
        background-color: #A9D08E !important;
    }
`

export const DocumentItens = styled(SimpleTable)`
    width: 100% !important;

    .MuiTableCell-root{
        background-color: #f2f2f2 !important;
    }

    span{
        color: #000 !important;
    }
`

export const OpenDialogButton = styled(ButtonBase)`
    border-radius: 999999999px !important;
`
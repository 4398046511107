import styled from "styled-components";
import { ButtonBase, Paper } from "@mui/material";

interface GridContainerProps{
    showPagination?: boolean
    isLoading?: boolean
}

export const GridContainer = styled(Paper)<GridContainerProps>`
    width: calc(100vw - 144px);
    height: ${props => props.showPagination ? "calc(100% - 50px)" : "100%"};
    overflow: ${props => props.isLoading ? "hidden" : "auto"};
    border-radius: 4px 4px 0px 0px !important;
    position: relative;

    .TableContainer-root{
        overflow: visible !important;
    }

    @media (max-width: 750px){
        width: 100%;
    }
`

export const GridPagination = styled.div`
    width: calc(100vw - 144px);
    height: 50px;
    box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);;
    border-top: none;
    border-radius: 0px 0px 4px 4px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 0px 12px;

    span{
        margin-right: 10px;
    }

    @media (max-width: 750px){
        width: 100%;
    }
`

export const PaginationButton = styled(ButtonBase)`
    width: 32px !important;
    height: 32px !important;
    border-radius: 5px !important;
    display: flex !important;
    justify-content: flex-start !important;
    align-items: center !important;
`

export const GridLoadingContainer = styled.div`
    position: absolute;
    bottom: 0;
    background-color: #FFF;
    width: 100%;
    height: calc(100% - 58px);
    display: flex;
    justify-content: center;
    align-items: center;
`
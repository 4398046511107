import { useState, useEffect, useMemo } from 'react'

import { GridColumn } from '../../../@types/datagrid'
import { SellDetailsClient } from '../../../@types/sellDetailts'

import { useAuth } from '../../../hooks/auth'
import { useSellDetails } from '../../../hooks/useSellDetails'

import OptionsButtons from '../../../components/Buttons/OptionsButtons'
import { ClientsDialiog } from '../components/ClientsDialog'
import { DataTable } from '../../../components/Tables/Datagrid'

import { Tooltip } from '@mui/material'
import { Visibility } from '@mui/icons-material'

import { formatValue, transformValueToNumberType } from '../../../utils/format/formatValue'
import { Container, GridContainer } from "./style"

export const InvoincingClients: React.FC = () => {

    const { clients, getClients, isFetching } = useSellDetails()
    const { userData } = useAuth()

    const [selectedClient, setSelectedClient] = useState<SellDetailsClient>({} as SellDetailsClient)
    const [openClientDetails, setOpenClientDetails] = useState<boolean>(false)

    useEffect(() => {
        const currentMonth = !!sessionStorage.getItem("@portalnaos:selldetails:periodfilter")? (
            sessionStorage.getItem("@portalnaos:selldetails:periodfilter")?.replaceAll("-", "")
        ): (
            `${ new Date().getFullYear() }${ (new Date().getMonth() + 1).toString().padStart(2, "0")}`
        )

        getClients({
            anomes: currentMonth 
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[userData.selectedBranch])

    const summary = useMemo(() => {
        return clients.reduce((accumulator, client) => {

            const totalInvoiced = transformValueToNumberType(accumulator.net_sales_invoiced) + transformValueToNumberType(client.net_sales_invoiced as string)
            const total = transformValueToNumberType(accumulator.net_sales) + transformValueToNumberType(client.net_sales as string)
            const totalPendent = transformValueToNumberType(accumulator.net_sales_pendent) + transformValueToNumberType(client.net_sales_pendent as string)

            const realized = (totalInvoiced * 100)/total

            const newValues: any = {
                qtd_invoiced: accumulator.qtd_invoiced + Number(client.qtd_invoiced),
                net_sales_invoiced: formatValue(totalInvoiced),
                qtd_pendent: accumulator.qtd_pendent + Number(client.qtd_pendent),
                net_sales_pendent: formatValue(totalPendent),
                qtd_total: accumulator.qtd_total + Number(client.qtd_total),
                net_sales: formatValue(total),
                /* pr_med: formatValue(transformValueToNumberType(accumulator.pr_med) + transformValueToNumberType(client.pr_med as string)), */
                realized: formatValue(realized)
            }

            return newValues
        },{
            qtd_invoiced: 0,
            net_sales_invoiced: "0,00",
            qtd_pendent: 0,
            net_sales_pendent: "0,00",
            /* pr_med_invoiced: "0,00", */
            qtd_total: 0,
            net_sales: "0,00",
            /* pr_med: "0,00", */
            realized: "0,00"
        })
    },[clients])

    const columns: GridColumn[] = [
        { field: "client_name", headerName: "Cliente", minWidth: 300, renderCell: (row) => (
            <Tooltip title={ row.client_name }>
                <p>{ row.client_name }</p>
            </Tooltip>
        ) },
        { field: "nome_marca", headerName: "Marca", minWidth: 120 },
        { field: "qtd_invoiced", headerName: "QTD Faturado", headerAlign: "center", align: "center", minWidth: 150 },
        { field: "net_sales_invoiced", headerName: "NET SALE Faturado", align: "right", minWidth: 150 },
        { field: "qtd_pendent", headerName: "QTD Pendente", headerAlign: "center", align: "center", minWidth: 150 },
        { field: "net_sales_pendent", headerName: "NET SALE Pendente", align: "right", minWidth: 150 },
        /* { field: "pr_med_invoiced", headerName: "PR MED Faturado", align: "right", minWidth: 150 }, */
        { field: "qtd_total", headerName: "QTD Total", headerAlign: "center", align: "center", minWidth: 120 },
        { field: "net_sales", headerName: "NET SALE Total", align: "right", minWidth: 150 },
        /* { field: "pr_med", headerName: "PR MED Total", align: "right", minWidth: 150 }, */
        { field: "realized", headerName: "% Realizado", headerAlign: "center", align: "center", minWidth: 120, renderCell: (row) => formatValue(row.realized) },
        { field: "options", headerName: "Opções", disableSort: true, headerAlign: "center", align: "center", minWidth: 120, renderCell: (row) => (
            <OptionsButtons 
                action='view'
                icon={ Visibility } 
                type='button' 
                onClick={() => {
                    setSelectedClient(row)
                    setOpenClientDetails(true)
                }} 
            />
        )},
    ]

    return(
        <Container>
            <GridContainer>
                <DataTable
                    columns={ columns }
                    rows={ clients }
                    paginationType="disabled"
                    disableCheckboxSelection
                    loading={ isFetching }
                    pinnedRows={clients.length > 0 || !isFetching ? [
                        {
                            position: "bottom",
                            content: [
                                { value: "Total", field: "client_name", align: "left", colspan: 2 },
                                { value: summary.qtd_invoiced, field: "qtd_invoiced" },
                                { value: summary.net_sales_invoiced, field: "net_sales_invoiced", align: "right" },
                                { value: summary.qtd_pendent, field: "qtd_pendent" },
                                { value: summary.net_sales_pendent, field: "net_sales_pendent", align: "right"},
                                { value: summary.qtd_total, field: "qtd_total"},
                                { value: summary.net_sales, field: "net_sales", align: "right"},
                                { value: summary.realized, field: "realized"},
                                { value: "", field: "options"}
                            ]
                        }
                    ]: []}
                />
            </GridContainer>
            <ClientsDialiog 
                open={ openClientDetails }
                fullWidth
                maxWidth={"2xl" as any}
                clientName={ selectedClient.client_name }
                selectedClientDetails={ selectedClient.products }
                onClose={() => {
                    setOpenClientDetails(false)
                    setTimeout(() => setSelectedClient({} as SellDetailsClient), 200)
                }}
            />
        </Container>
    )
}
import { 
    useState, 
    useEffect, 
    useCallback,
    useMemo,
    ReactNode,
    CSSProperties
} from "react";

import { GridColumn, GridRows, PinnedRow } from '../../../@types/datagrid'

import { Pagination } from "./Pagination";

import { ArrowUp, ArrowDown, ArrowRight, IconProps, Check, Minus } from 'phosphor-react'

import { 
    Container, 
    Grid, 
    GridColumnItem, 
    GridContainer, 
    GridFooter, 
    GridHeader, 
    GridHeaderColumn, 
    GridRow, 
    GridRowItem, 
    PinnedFooter,
    Checkbox,
    Indicator,
    SelectionRowArea,
    ButtonsContainer,
    ExpandRowButton,
    ExpandedItem,
    GridRowHeaderItem
} from "./style";

import { GridOptionButton } from "./GridOptionButton";
import { CircularProgress } from "@mui/material";

interface ButtonOptions{
    label?: string
    show?: (row: GridRows) => boolean
    icon?: React.ForwardRefExoticComponent<IconProps & React.RefAttributes<SVGSVGElement>>
    color: string
    action: (row: GridRows) => Promise<void> | void
}

interface AllSelecionParams{
    eventType: "select" | "unselected"
    rows: GridRows[]
}

interface DatagridProps{
    columns: GridColumn[]
    rows: GridRows[]
    pinnedRows?: PinnedRow[]
    paginationType?: "server" | "auto" | "disabled"
    variant?: "ligth" | "dark"
    rowsPerPage?: number
    currentPage?: number
    totalRows?: number
    loading?: boolean
    rootStyle?: CSSProperties
    disableCheckboxAllSelection?: boolean
    disableCheckboxSelection?: boolean
    childToExpand?: (row: GridRows) => ReactNode
    onRowSelect?: (row: GridRows) => void 
    onSelectAll?: (params: AllSelecionParams) => void 
    onRowDoubleClick?: (row: GridRows) => void
    setRowClassName?: (row: GridRows) => string
    setRowId?: (row: GridRows) => string
    rowButtons?: {
        title: string
        width?: number
        options: ButtonOptions[]
    }
}

export const DataTable: React.FC<DatagridProps> = ({ 
    columns, 
    rows, 
    pinnedRows,
    rowButtons, 
    totalRows,
    loading,
    currentPage,
    rowsPerPage = 10,
    paginationType = "auto",
    disableCheckboxSelection,
    variant,
    rootStyle,
    setRowId,
    onRowSelect,
    onRowDoubleClick,
    onSelectAll,
    childToExpand,
    setRowClassName,
    disableCheckboxAllSelection
}) => {
    const [tableColumns, setTableColumns] = useState<GridColumn[]>([])
    const [tableRows, setTableRows] = useState<GridRows[]>([])
    const [page, setPage] = useState<number>(1)

    const [checkedHeader, setCheckedHeader] = useState(false)

    const initItemIndex = rowsPerPage * page - rowsPerPage
    const lastItemIndex = rowsPerPage * page

    const paginationItens = paginationType !== "disabled" ? (
        tableRows.slice(initItemIndex, lastItemIndex) || []
    ): tableRows

    const isAllSelected = paginationItens.every(item => item.isSelected)

    const topFixedRows = useMemo(() => {
        const topRows = pinnedRows?.filter(item => item.position === "top")

        return topRows || []
    },[pinnedRows])

    const bottomFixedRows = useMemo(() => {
        const bottomRows = pinnedRows?.filter(item => item.position === "bottom")

        return bottomRows || []
    },[pinnedRows])

    useEffect(() => {
        if(paginationType === "server"){
            if(!totalRows || !currentPage){
                throw new Error("Atributes 'totalRows' and 'currentPage' must be informed when pagination type is 'server'")
            }
        }
    },[paginationType, currentPage, totalRows])

    useEffect(() => {
        if(!columns){
            throw new Error("Table columns must be informed")
        }

        if(!rows){
            throw new Error("Table rows must be informed")
        }

        setTableColumns(columns)
        setTableRows(childToExpand ? (
            rows.map(row => ({ 
                ...row, 
                isExpanded: false 
            }))
        ): (
            rows
        ))
    },[rows, columns, childToExpand])

    useEffect(() => {
        if(tableRows.every(item => !item.isSelected)){
            setCheckedHeader(false)
        }
    },[tableRows])

    const sortData = useCallback((name: string) => {
        const selectedColumn = tableColumns.find(column => column.field === name)
    
        setTableRows(prev => {
            if (selectedColumn?.orderBy === "asc") {
                return prev.sort((a, b) => (a[name] > b[name] ? 1 : -1))
            } else {
                return prev.sort((a, b) => (a[name] < b[name] ? 1 : -1))
            }
        })
    
        setTableColumns(prev => prev.map(column => {
            if(column === selectedColumn){
                return ({
                    ...column,
                    orderBy: selectedColumn.orderBy === "asc" ? "desc" : "asc"
                })
            } else{
                return column
            }
        }))
    
    }, [tableColumns])

    const canRenderOptionButton = (row: GridRows, option: ButtonOptions) => {
        let canRender = true

        if(option.show !== undefined){
            canRender = option.show(row)
        }

        return canRender
    }

    const handleAllSelection = (checked: boolean) => {
        setCheckedHeader(checked)
        setTableRows(prev => prev.map(item => ({
            ...item,
            isSelected: checked
        })))

        if(onSelectAll){
            onSelectAll({
                eventType: checked ? "select" : "unselected",
                rows: tableRows
            })
        }
    }

    const handleRowSelection = (rowId: string) => {
        setCheckedHeader(true)
        setTableRows(prev => prev.map(item => item.id === rowId ? ({
            ...item,
            isSelected: !item.isSelected
        }): item))
    }

    const handleExpandRow = (row: GridRows) => {
        setTableRows(prev => prev.map(item => item.id === row.id ? ({
            ...item,
            isExpanded: !item.isExpanded
        }): item))
    }

    const getExpandedAditionalColumns = () => {
        let aditional = 0

        if(rowButtons){
            aditional += 1
        }

        if(!disableCheckboxSelection){
            aditional += 1
        }

        if(childToExpand){
            aditional += 1
        }

        return aditional
    }

    return (
        <Container variant={ variant } style={ rootStyle }>
            <GridContainer>
                <Grid className="text-center">
                    <GridHeader variant={ variant }>
                        <GridRow>
                            { !disableCheckboxSelection && (
                                <th style={{ width: "36px", borderBottom: "1px solid #f2f2f2" }}>
                                    <SelectionRowArea>
                                        { !disableCheckboxAllSelection && (
                                            <Checkbox
                                                checked={ checkedHeader }
                                                onCheckedChange={(checked: boolean) => handleAllSelection(checked)}
                                            >
                                                <Indicator>
                                                    { isAllSelected ? (
                                                        <Check fontWeight={"bold"} />
                                                    ): (
                                                        <Minus fontWeight={"bold"} />
                                                    ) }
                                                </Indicator>
                                            </Checkbox> 
                                        )}
                                    </SelectionRowArea>
                                </th>
                            )}
                            { childToExpand && (
                                <th style={{ width: "36px", borderBottom: "1px solid #f2f2f2" }}></th>
                            )}
                            {tableColumns.map((column) => (
                                <th
                                    style={{ 
                                        width: column.width,
                                        minWidth: `${column.minWidth}px`
                                    }}
                                >
                                    <GridColumnItem variant={ variant } key={ column.field }>
                                        <GridHeaderColumn
                                            contentAlign={ column.headerAlign }
                                            onClick={() => {
                                                if(!column.disableSort){
                                                    sortData(column.field)
                                                }
                                            }}
                                        >
                                            <label>{ column.headerName }</label>
                                            {!column.disableSort && (
                                                column.orderBy === "asc" 
                                                    ? <ArrowDown/>
                                                    : <ArrowUp />
                                            )}
                                        </GridHeaderColumn>
                                    </GridColumnItem>
                                </th>
                            ))}
                            { !!rowButtons &&
                                <th align="center">
                                    <label style={{ cursor: "pointer", fontSize: "12px" }}>
                                        { rowButtons.title }
                                    </label>
                                </th>
                            }
                        </GridRow>
                        { topFixedRows.length > 0 && (
                            topFixedRows.map((row, index)=> (
                                <GridRow key={ index } variant={ variant }>
                                    { !disableCheckboxSelection && (
                                        <th style={{ top: `${(index + 1)*45}px` }}></th>
                                    )}
                                    { childToExpand && (
                                        <th style={{ top: `${(index + 1)*45}px` }}></th>
                                    )}
                                    { tableColumns.map((column) => {
                                        const item = row.content.find(item => item.field === column.field)

                                        if(!item){
                                            return ""
                                        }

                                        return (
                                            <GridRowHeaderItem 
                                                key={ column.field } 
                                                align={ item?.align }
                                                colSpan={ item?.colspan }
                                                style={{ 
                                                    width: column.width,
                                                    minWidth: `${column.minWidth}px`,
                                                    top: `${(index + 1)*45}px`
                                                }}
                                            >
                                                { item?.renderCell ? (
                                                    item.renderCell({ value: item.value})
                                                ) : (
                                                    item?.value
                                                )}
                                            </GridRowHeaderItem>
                                        )
                                    })}
                                    { rowButtons && (
                                        <th style={{ top: `${(index + 1)*45}px` }}></th>
                                    )}
                                </GridRow>
                            )) 
                        )}
                    </GridHeader>
                    <tbody>
                        { loading ? (
                            <GridRow>
                                <GridRowItem 
                                    colSpan={ tableColumns.length }
                                    style={{ height: "calc(100vh - 252px)" }}
                                >
                                    <CircularProgress/>
                                </GridRowItem>
                            </GridRow>
                        ): paginationItens.map(row => (
                            <>
                            <GridRow
                                key={ setRowId ? setRowId(row) : row.id }
                                onClick={ (onRowSelect && disableCheckboxSelection) ? (
                                    () => {
                                        handleRowSelection(row.id)
                                        onRowSelect(row)
                                    }
                                ) : undefined }
                                onDoubleClick={ onRowDoubleClick ? () => onRowDoubleClick(row) : undefined }
                                className={ setRowClassName ? setRowClassName(row) : undefined }
                                isSelected={ row.isSelected }
                            >
                                { !disableCheckboxSelection && (
                                    <td style={{ width: "36px", borderBottom: "1px solid #f2f2f2" }}>
                                        <SelectionRowArea>
                                            <Checkbox 
                                                checked={ row.isSelected }
                                                onCheckedChange={ (checked) => {
                                                    handleRowSelection(row.id)
                                                    if(onRowSelect){
                                                        onRowSelect({
                                                            ...row,
                                                            isSelected: checked
                                                        }) 
                                                    }
                                                }}
                                            >
                                                <Indicator>
                                                    <Check fontWeight={"bold"} />
                                                </Indicator>
                                            </Checkbox> 
                                        </SelectionRowArea>
                                    </td>
                                ) }
                                { childToExpand && (
                                    <td align="center" style={{ width: "36px", borderBottom: "1px solid #f2f2f2" }}>
                                        <ExpandRowButton onClick={() => handleExpandRow(row)}>
                                            { row.isExpanded ? (
                                                <ArrowDown/>
                                            ) : (
                                                <ArrowRight />
                                            )}
                                        </ExpandRowButton>
                                    </td>
                                )}
                                {tableColumns.map(column => column.renderCell ? (
                                    <GridRowItem key={ column.field } align={ column.align || "left" }>
                                        { column.renderCell(row) }
                                    </GridRowItem>
                                ) : (
                                    <GridRowItem key={ column.field } align={ column.align || "left" }>
                                        <label style={{ position: "relative", left: "3px" }}>
                                            { row[column.field] }
                                        </label>
                                    </GridRowItem>
                                ))}
                                { !!rowButtons && 
                                    <GridRowItem 
                                        align="center" 
                                        style={{ width: rowButtons.width }}
                                    >
                                        <ButtonsContainer>
                                            { rowButtons.options.map(option => {
                                                if(!canRenderOptionButton(row, option)){
                                                    return ""
                                                }

                                                return (
                                                    <GridOptionButton
                                                        onClick={() => option.action(row) }
                                                        icon={ option.icon }
                                                        color={ option.color }
                                                    >
                                                        { option.label }
                                                    </GridOptionButton>
                                                )
                                            })}
                                        </ButtonsContainer>
                                    </GridRowItem>
                                }
                            </GridRow>
                            <GridRow key={ `${row.id}-expanded` } style={{ height: row.isExpanded ? "auto" :  "0px" }}>
                                { childToExpand && (
                                    <ExpandedItem
                                        colSpan={ tableColumns.length + getExpandedAditionalColumns() } 
                                        style={{ padding: row.isExpanded ? "12px" : "0px" }}
                                    >
                                        { row.isExpanded && childToExpand(row) }
                                    </ExpandedItem>
                                )}
                            </GridRow>
                            </>
                        ))}
                    </tbody>
                    { bottomFixedRows.length > 0 && (
                        <PinnedFooter style={{ marginTop: "2px solid red"}}>
                            { bottomFixedRows.map((row, index)=> (
                                <GridRow key={ index } variant={ variant }>
                                    { !disableCheckboxSelection && (
                                        <GridRowItem></GridRowItem>
                                    )}
                                    { childToExpand && (
                                        <GridRowItem></GridRowItem>
                                    )}
                                    { tableColumns.map((column) => {
                                        const item = row.content.find(item => item.field === column.field)

                                        if(!item){
                                            return ""
                                        }

                                        return (
                                            <GridRowItem 
                                                key={ column.field } 
                                                align={ item?.align }
                                                colSpan={ item?.colspan }
                                                style={{ 
                                                    border: "none",
                                                    borderTop: variant === "dark" ? "2px solid #FEBD11" : "none",
                                                    borderBottom: variant === "dark" ? "1px solid #FEBD11" : "none",
                                                    color: variant === "dark" ? "#FEBD11" : "#000000",
                                                }}
                                            >
                                                { item?.renderCell ? (
                                                    item.renderCell({ value: item.value})
                                                ) : (
                                                    item?.value
                                                )}
                                            </GridRowItem>
                                        )
                                    })}
                                    { rowButtons && (
                                        <GridRowItem></GridRowItem>
                                    )}
                                </GridRow>
                            )) }
                        </PinnedFooter>
                    )}
                </Grid>
            </GridContainer>
            { paginationType !== "disabled" && (
                <GridFooter>
                    <Pagination
                        totalCountOfRegisters={ totalRows || tableRows.length }
                        registerPerPage={ rowsPerPage }
                        currentPage={ page }
                        onChangePage={ setPage }
                    />
                </GridFooter>
            )}
        </Container>
    );
}
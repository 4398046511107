import styled from "styled-components"
import { css } from "styled-components"
import { shade } from "polished"

import { ActionsButtonType } from "./index"

interface ContainerProps{
    action: ActionsButtonType
}

export const Container = styled.div<ContainerProps>`
    margin: 3px;
    display: flex;
    justify-content: center;

    a, button{
        width: 35px;
        height: 35px;
        border: none;
        text-decoration: none;
        border-radius: 50%;
        padding: 5px;
        display: flex;
        justify-content: center;
        align-items: center;
        box-shadow: 3px 3px 3px ${ shade(0.2, "#F9F9F9")};
        transition: 200ms;

        svg{
            margin: 0px;
            color: #F9F9F9;
        }

        ${props => props.action === "view" && css`
            background-color: #272a52;

            &:hover{
                background-color: ${ shade(0.3, "#272a52")};
            }
            &:active{
                background-color: ${ shade(0.4, "#272a52")};
            }
        `}

        ${props => props.action === "edit" && css`
            background-color: #2e7d32;

            &:hover{
                background-color: ${ shade(0.3, "#2e7d32")};
            }
            &:active{
                background-color: ${ shade(0.4, "#2e7d32")};
            }
        `}

        ${props => props.action === "remove" && css`
            background-color: #e83f5b;
        
            &:hover{
                background-color: ${ shade(0.3, "#e83f5b")};
            }
            &:active{
                background-color: ${ shade(0.4, "#e83f5b")};
            }
        `}

        ${props => props.action === "aprove" && css`
            background-color: #f99100;
        
            &:hover{
                background-color: ${ shade(0.3, "#f99100")};
            }
            &:active{
                background-color: ${ shade(0.4, "#f99100")};
            }
        `}

        ${props => props.action === "attach" && css`
            background-color: #5d0dbf;
        
            &:hover{
                background-color: ${ shade(0.3, "#5d0dbf")};
            }
            &:active{
                background-color: ${ shade(0.4, "#5d0dbf")};
            }
        `}

        ${props => props.action === "default" && css`
            background-color: #1b7ccc;
        
            &:hover{
                background-color: ${ shade(0.3, "#1b7ccc")};
            }
            &:active{
                background-color: ${ shade(0.4, "#1b7ccc")};
            }
        `}

        ${props => props.action === "print" && css`
            background-color: #525252;
        
            &:hover{
                background-color: ${ shade(0.3, "#525252")};
            }
            &:active{
                background-color: ${ shade(0.4, "#525252")};
            }
        `}

        ${props => props.action === "cancel" && css`
            background-color: #e83f5b;
        
            &:hover{
                background-color: ${ shade(0.3, "#e83f5b")};
            }
            &:active{
                background-color: ${ shade(0.4, "#e83f5b")};
            }
        `}
        
    }

    @media(max-width: 1200px){
        width: 100%;
        button, a{
            border-radius: 5px;
            width: 100% !important;
            margin: 0px 2px;
        }
    }
`
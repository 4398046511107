import styled from "styled-components"

import { ButtonBase } from "@mui/material"

export const Container = styled.div`
    width: 100%;

    &+&{
        margin-top: 10px !important;
    }
`

export const NavMenuItem = styled(ButtonBase)`
    width: 23% !important;
    display: flex;
    flex-direction: row !important;
    justify-content: flex-start !important;
    align-items: center;
    position: relative;
    border-radius: 5px !important;
    transition: 500ms !important;
    font-size: 18px;
    font-weight: bold;
    font-family: sans-serif;
    margin-top: 10px !important;
    
    svg{
        width: 32px;
        height: 32px;
        margin: 9px;
    }

        text-align: left;

    &:hover{
        cursor: pointer !important;
    }

    @media(max-width: 750px){
        width: 100% !important;
    }
`
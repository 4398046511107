import { useEffect, useState, useMemo } from 'react'

import { Dialog, DialogProps, Grid, Stack, TextField, Tooltip } from "@mui/material";

import { DialogTitleCustom } from "../../../../styles/globalStyles";
import { DialogContainer, GridContainer, GridCustomCell, Infos, SearchField } from "./style";
import { Close, Search } from '@mui/icons-material';
import { DataGrid, GridColumns, ptBR } from '@mui/x-data-grid';
import { formatValue, transformValueToNumberType } from '../../../../utils/format/formatValue';
import { ClientProduct } from '../../../../@types/sellDetailts';

interface ProductDetails{
    id: string
    desc: string
    id_marca: string
    nome_marca: string
    invoicing_qtd: number
    invoicing_net_sales: string
    invoicing_pr_med: string
    in_progress_qtd: number
    in_progress_net_sales: string
    in_progress_pr_med: string
    pendent_qtd: number
    pendent_net_sales: string
    pendent_pr_med: string
    canceled_qtd: number
    canceled_net_sales: string
    canceled_pr_med: string
}

interface ClientsDialiogProps extends DialogProps{
    clientName: string
    selectedClientDetails: ClientProduct[]
}

export const ClientsDialiog: React.FC<ClientsDialiogProps> = ({selectedClientDetails, clientName, onClose, ...rest }) => {

    const [filterContent, setFilterContent] = useState<string>("")
    const [details, setDetails] = useState<ProductDetails[]>([])

    const filteredDetails = details.filter(detail => detail.id.includes(filterContent) || detail.desc.includes(filterContent))

    useEffect(() => {
        setDetails(selectedClientDetails?.map(clientItem => ({
            id: clientItem.id,
            desc: clientItem.desc,
            id_marca: clientItem.id_marca,
            nome_marca: clientItem.nome_marca,
            invoicing_qtd: clientItem.invoicing.qtd,
            invoicing_net_sales: formatValue(clientItem.invoicing.net_sales),
            invoicing_pr_med: formatValue(clientItem.invoicing.pr_med),
            in_progress_qtd: clientItem.in_progress.qtd,
            in_progress_net_sales: formatValue(clientItem.in_progress.net_sales),
            in_progress_pr_med: formatValue(clientItem.in_progress.pr_med),
            pendent_qtd: clientItem.pendent.qtd,
            pendent_net_sales: formatValue(clientItem.pendent.net_sales),
            pendent_pr_med: formatValue(clientItem.pendent.pr_med),
            canceled_qtd: clientItem.canceled.qtd,
            canceled_net_sales: formatValue(clientItem.canceled.net_sales),
            canceled_pr_med: formatValue(clientItem.canceled.pr_med)
        })) || [])
    },[selectedClientDetails])

    const totalInvoiced = useMemo(() => details.reduce((accumulator, currentDetail) => {
        accumulator.qtd = accumulator.qtd + currentDetail.invoicing_qtd
        accumulator.net_sales = accumulator.net_sales + transformValueToNumberType(currentDetail.invoicing_net_sales)
        accumulator.pr_med = accumulator.qtd ? accumulator.net_sales / accumulator.qtd : 0

        return accumulator
    },{
        qtd: 0,
        net_sales: 0,
        pr_med: 0
    }),[details])

    const totalInProgress = useMemo(() => details.reduce((accumulator, currentDetail) => {
        accumulator.qtd = accumulator.qtd + currentDetail.in_progress_qtd
        accumulator.net_sales = accumulator.net_sales + transformValueToNumberType(currentDetail.in_progress_net_sales)
        accumulator.pr_med = accumulator.qtd ? accumulator.net_sales / accumulator.qtd : 0

        return accumulator
    },{
        qtd: 0,
        net_sales: 0,
        pr_med: 0
    }),[details])

    const totalPendent = useMemo(() => details.reduce((accumulator, currentDetail) => {
        accumulator.qtd = accumulator.qtd + currentDetail.pendent_qtd
        accumulator.net_sales = accumulator.net_sales + transformValueToNumberType(currentDetail.pendent_net_sales)
        accumulator.pr_med = accumulator.qtd ? accumulator.net_sales / accumulator.qtd : 0

        return accumulator
    },{
        qtd: 0,
        net_sales: 0,
        pr_med: 0
    }),[details])

    const totalCanceled = useMemo(() => details.reduce((accumulator, currentDetail) => {
        accumulator.qtd = accumulator.qtd + currentDetail.canceled_qtd
        accumulator.net_sales = accumulator.net_sales + transformValueToNumberType(currentDetail.canceled_net_sales)
        accumulator.pr_med = accumulator.qtd ? accumulator.net_sales / accumulator.qtd : 0

        return accumulator
    },{
        qtd: 0,
        net_sales: 0,
        pr_med: 0
    }),[details])

    const columns: GridColumns = [
        { 
            field: "desc", 
            headerName: "Produto",
            minWidth: 300,
            flex: 1,
            renderCell: ({ row }) => (
                <Tooltip title={ `${ row.id } - ${row.desc}` }>
                    <p>{ row.desc }</p>
                </Tooltip>
            )
        },
        { 
            field: "nome_marca", 
            headerName: "Marca",
            width: 120
        },
        { 
            field: "invoicing_qtd", 
            headerName: "QTD", 
            headerAlign: "center", 
            align: "center", 
            flex: 1, 
            minWidth: 120,
            renderCell: ({ row }) => <GridCustomCell type='invoiced'>{row.invoicing_qtd}</GridCustomCell>
        },
        { 
            field: "invoicing_net_sales", 
            headerName: "NET SALES",
            headerAlign: "center",
            flex: 1,
            minWidth: 120,
            renderCell: ({ row }) => <GridCustomCell type='invoiced' align='flex-end'>{row.invoicing_net_sales}</GridCustomCell>
        },
        { 
            field: "invoicing_pr_med", 
            headerName: "PR MED",
            headerAlign: "center",
            flex: 1,
            minWidth: 120,
            renderCell: ({ row }) => <GridCustomCell type='invoiced' align='flex-end'>{row.invoicing_pr_med}</GridCustomCell>
        },
        { 
            field: "in_progress_qtd", 
            headerName: "QTD", 
            headerAlign: "center",
            flex: 1,
            minWidth: 120,
            renderCell: ({ row }) => <GridCustomCell type='in_progress'>{row.in_progress_qtd}</GridCustomCell>
        },
        { 
            field: "in_progress_net_sales", 
            headerName: "NET SALES", 
            headerAlign: "center",
            align: "right", 
            flex: 1,
            minWidth: 120,
            renderCell: ({ row }) => <GridCustomCell type='in_progress' align='flex-end'>{row.in_progress_net_sales}</GridCustomCell> 
        },
        { 
            field: "in_progress_pr_med", 
            headerName: "PR MED",
            headerAlign: "center",
            align: "right", 
            flex: 1,
            minWidth: 120,
            renderCell: ({ row }) => <GridCustomCell type='in_progress' align='flex-end'>{row.in_progress_pr_med}</GridCustomCell> 
        },
        { 
            field: "pendent_qtd", 
            headerName: "QTD",
            headerAlign: "center",
            flex: 1,
            minWidth: 120,
            renderCell: ({ row }) => <GridCustomCell type='pendent'>{row.pendent_qtd}</GridCustomCell>
        },
        { 
            field: "pendent_net_sales", 
            headerName: "NET SALES", 
            headerAlign: "center",
            align: "right", 
            flex: 1,
            minWidth: 120,
            renderCell: ({ row }) => <GridCustomCell type='pendent' align='flex-end'>{row.pendent_net_sales}</GridCustomCell> 
        },
        { 
            field: "pendent_pr_med", 
            headerName: "PR MED",
            headerAlign: "center", 
            align: "right", 
            flex: 1,
            minWidth: 120,
            renderCell: ({ row }) => <GridCustomCell type='pendent' align='flex-end'>{row.pendent_pr_med}</GridCustomCell> 
        },
        { 
            field: "canceled_qtd", 
            headerName: "QTD", 
            headerAlign: "center",
            flex: 1,
            minWidth: 120,
            renderCell: ({ row }) => <GridCustomCell type='canceled'>{row.canceled_qtd}</GridCustomCell>
        },
        { 
            field: "canceled_net_sales", 
            headerName: "NET SALES", 
            headerAlign: "center",
            align: "right", 
            flex: 1,
            minWidth: 120,
            renderCell: ({ row }) => <GridCustomCell type='canceled' align='flex-end'>{row.canceled_net_sales}</GridCustomCell> 
        },
        { 
            field: "canceled_pr_med", 
            headerName: "PR MED",
            headerAlign: "center", 
            align: "right", 
            flex: 1,
            minWidth: 120,
            renderCell: ({ row }) => <GridCustomCell type='canceled' align='flex-end'>{row.canceled_pr_med}</GridCustomCell> 
        }
    ]

    return (
        <Dialog { ...rest } onClose={ onClose }>
            <DialogTitleCustom>
                Detalhes { clientName }
                { onClose && (
                    <button onClick={event => onClose(event, "escapeKeyDown") }>
                        <Close/>
                    </button>
                )}
            </DialogTitleCustom>
            <DialogContainer>
                <SearchField>
                    <TextField
                        label="Pesquisar"
                        size="small"
                        type="text"
                        value={ filterContent }
                        fullWidth
                        onChange={ event => setFilterContent(event.target.value) }
                    />
                    <button type="button" onClick={() => {}}>
                        <Search/>
                    </button>
                </SearchField>
                <Grid container spacing={ 2 } mb={ 2 }>
                    <Grid item xs={ 12 } md={ 3 }>
                        <Infos type='invoiced'>
                            <legend>Faturado</legend>
                            <p><b>Total QTD:</b> { totalInvoiced.qtd }</p>
                            <p><b>Total NetSales:</b> { formatValue(totalInvoiced.net_sales) }</p>
                            <p><b>Total PR MED:</b> { formatValue(totalInvoiced.pr_med) }</p>
                        </Infos>
                    </Grid>
                    <Grid item xs={ 12 } md={ 3 }>
                        <Infos type="in_progress">
                            <legend>Processando</legend>
                            <p><b>Total QTD:</b> { totalInProgress.qtd }</p>
                            <p><b>Total NetSales:</b> { formatValue(totalInProgress.net_sales) }</p>
                            <p><b>Total PR MED:</b> { formatValue(totalInProgress.pr_med) }</p>
                        </Infos>
                    </Grid>
                    <Grid item xs={ 12 } md={ 3 }>
                        <Infos type="pendent">
                            <legend>Pendente</legend>
                            <p><b>Total QTD:</b> { totalPendent.qtd }</p>
                            <p><b>Total NetSales:</b> { formatValue(totalPendent.net_sales) }</p>
                            <p><b>Total PR MED:</b> { formatValue(totalPendent.pr_med) }</p>
                        </Infos>
                    </Grid>
                    <Grid item xs={ 12 } md={ 3 }>
                        <Infos type="canceled">
                            <legend>Cancelado</legend>
                            <p><b>Total QTD:</b> { totalCanceled.qtd }</p>
                            <p><b>Total NetSales:</b> { formatValue(totalCanceled.net_sales) }</p>
                            <p><b>Total PR MED:</b> { formatValue(totalCanceled.pr_med) }</p>
                        </Infos>
                    </Grid>
                </Grid>
                <GridContainer>
                    <DataGrid
                        columns={ columns }
                        localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
                        rows={ filteredDetails }
                        pageSize={30}
                        rowsPerPageOptions={[30]}
                        disableColumnMenu
                        disableSelectionOnClick
                        components={{
                            NoRowsOverlay: () => (
                                <Stack height="100%" alignItems="center" justifyContent="center">
                                    Nenhum resultado foi encontrado
                                </Stack>
                            )
                        }}
                    />
                </GridContainer>
            </DialogContainer>
        </Dialog>
    )
}
import React from 'react'
import { useState, useCallback, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import filesize from 'filesize'
import { toast } from 'react-toastify'

import { Preferences } from '../../../@types/theme'

import { useTheme } from '../../../hooks/theme'
import { useAuth } from '../../../hooks/auth'

import { convertFileToBase64 } from '../../../utils/format/convertFiles'
import { getErrorMessage } from '../../../utils/validations/getErrorMessage'

import Upload from '../../../components/DropZone'
import { Button } from '../../../components/Buttons/Button'

import {
    Content,
    FileList,
    ButtonActions
} from "./style"

interface UploadedFile{
    file: File
    name: string,
    readableSize: string
    url: string
}

const ChangeImage: React.FC = () => {

    const { changeImageApp, savePreferences } = useTheme()
    const { userData } = useAuth()

    const navigate = useNavigate()
    const { option } = useParams()

    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [uploadedFiles, setUploadedFiles] = useState<Array<UploadedFile>>([])
    const [animationType, setAnimationType] = useState<"open" | "close" >("open")

    const uploadFile = useCallback(async (): Promise<void> => {
        if(uploadedFiles.length <= 0){
            toast.info("Por favor selecione um arquivo antes de salvar")
            return
        }

        setIsLoading(true)

        try{
            const convertedImage = await convertFileToBase64(uploadedFiles[0].file)
            let fileType: string
            let desc: string

            if(option === "login"){
                fileType = "logo_home"
                desc = "Imagem da tela de login"
            } else if(option === "home"){
                fileType = "logo_dash"
                desc = "Imagem da tela inicial do portal"
            } else if(option === "icon"){
                fileType = "icon_logo"
                desc = "Icone da empresa no menu"
            } else{
                toast.error("Opção inválida selecionada")
                navigate("/preferences")
                return
            }
            
            const data: Preferences = {
                type: fileType,
                content: convertedImage as string,
                desc: desc
            }
            
            await savePreferences(data)

            changeImageApp({
                image: data.content,
                type: data.type
            })

            toast.success("Imagem selecionada foi salva com sucesso")

            navigate("/preferences")

        } catch(error: any){
            console.log(error)
            toast.error(getErrorMessage(error))
        } finally{
            setIsLoading(false)
        }
    },[uploadedFiles, option, changeImageApp, savePreferences, navigate])

    const selectFile = useCallback((files: Array<File>): void => {
        const submitedFiles = files.map(file => ({
            file: file,
            name: file.name,
            readableSize: filesize(file.size),
            url: URL.createObjectURL(file)
        }))

        setUploadedFiles([submitedFiles[0]])
    },[])

    useEffect(() => {
        setAnimationType("close")
        setUploadedFiles([])
        setTimeout(() => setAnimationType("open"), 10)
    },[option])

    useEffect(() => {
        if(!userData.permissions?.includes('PREFER')){
            toast.info("Você não tem permissão para acessar essa pagina")
            navigate("/")
        }
    },[userData, navigate])

    return (
        <Content startAnimation={ animationType }>
            <Upload onUpload={ selectFile } accept="image/jpeg, image/png"/>
            { uploadedFiles.length > 0 &&
                uploadedFiles.map((file, index) => (
                    <FileList key={index}>
                        <strong>{file.name}</strong>
                        <span>{file.readableSize}</span>
                    </FileList>
                ))
            }
            <ButtonActions containFiles={ uploadedFiles.length > 0 }>
                { uploadedFiles.length > 0 ?
                    <>
                    <Button 
                        type="button"
                        size='large'
                        variant="contained"
                        color="error"
                        disabled={isLoading} 
                        onClick={() => setUploadedFiles([])}
                    >
                        Cancelar
                    </Button>
                    <Button 
                        type="button"
                        size='large'
                        variant="contained"
                        color="success"
                        disabled={isLoading} 
                        onClick={ uploadFile }
                    >
                        { isLoading ? "Aguarde..." : "Alterar"}
                    </Button>
                    </>
                :
                    <Button 
                        type="submit" 
                        variant="text"
                        color="primary"
                        size='large'
                        disabled={ isLoading }
                        style={{ backgroundColor: ""}}
                        onClick={() => navigate("/preferences")}
                    >
                        Fechar
                    </Button>
                }
            </ButtonActions>
            { uploadedFiles.length > 0 &&
                <img src={ uploadedFiles[0].url } alt="teste"/>
            }
        </Content>
    )
}

export default ChangeImage
import { AprovedIcon } from "../components/Misc/Icons/AprovedIcon";
import { AprovedNFIcon } from "../components/Misc/Icons/AprovedNFIcon";
import { AwaitAproveIcon } from "../components/Misc/Icons/AwaitAproveIcon";
import { AwaitAproveNFIcon } from "../components/Misc/Icons/AwaitAproveNFIcon";
import { AwaitNFIcon } from "../components/Misc/Icons/AwaitNFIcon";
import { CanceledIcon } from "../components/Misc/Icons/CanceledIcon";
import { DivergNFIcon } from "../components/Misc/Icons/DivergNFIcon";
import { PartialBillingIcon } from "../components/Misc/Icons/PartialBillingIcon";
import { PriorityPaymentIcon } from "../components/Misc/Icons/PriorityPaymentIcon";
import { ReprovedIcon } from "../components/Misc/Icons/ReprovedIcon";
import { BuyOrderIcon } from "../components/Misc/Icons/BuyOrderIcon";
import { TotalBillingIcon } from "../components/Misc/Icons/TotalBillingIcon";
import { CostCenterIcon } from "../components/Misc/Icons/CostCenterIcon";

import { Settings, HourglassBottom /* , Close */, ReportGmailerrorred } from "@mui/icons-material";
import { ReprovedNFIcon } from "../components/Misc/Icons/ReprovedNFIcon";

export const OCFormDefaultValue = {
  typeRequest: [
    { value: "1", label: "Ordem de compra", Icon: BuyOrderIcon },
    { value: "2", label: "Pagamento Urgente", Icon: PriorityPaymentIcon },
    /* { value: "3", label: "Solicitação Adiantamento", Icon: Close } */
  ],
  status: [
    { value: "1", label: "Em manutenção", Icon: Settings },
    { value: "2", label: "Aguardando aprovação", Icon: AwaitAproveIcon },
    { value: "3", label: "Aprovado", Icon: AprovedIcon },
    { value: "4", label: "Reprovado", Icon: ReprovedIcon },
    { value: "5", label: "Fluxo financeiro total", Icon: TotalBillingIcon },
    { value: "6", label: "Cancelado", Icon: CanceledIcon },
    { value: "7", label: "Fluxo financeiro parcial", Icon: PartialBillingIcon },
    { value: "8", label: "Aguardando aprovação do centro de custo", Icon: CostCenterIcon },
  ],
  statusNF: [
    { value: "1", label: "Aguardando Aprovação da Solicitação", Icon: HourglassBottom },
    { value: "2", label: "NFs Aprovadas", Icon: AprovedNFIcon },
    { value: "3", label: "NFs Aprovadas com Divergencia", Icon: DivergNFIcon },
    { value: "4", label: "Aguardando vínculo com NF", Icon: AwaitNFIcon },
    { value: "5", label: "Reprovado", Icon: ReprovedNFIcon },
    { value: "6", label: "Aguardando aprovação", Icon: AwaitAproveNFIcon },
  ],
  statusDocument: [
    { value: "1", label: "Aguardando Vinculo", Icon: HourglassBottom },
    { value: "2", label: "OK", Icon: AprovedNFIcon },
    { value: "3", label: "Divergente", Icon: ReportGmailerrorred },
    { value: "4", label: "Aprovado com divergência", Icon: DivergNFIcon },
    { value: "5", label: "Reprovado", Icon: ReprovedNFIcon },
  ],
  brands: [
    { value: "1", label: "BIODERMA" },
    { value: "2", label: "ESTHEDERM" },
    { value: "4", label: "ETAT PUR" },
  ],
  localeDelivery: [
    {
      branch: "01",
      itens: [
        { value: "1", label: "LUFT - 14.590.963/0003-69" },
        { value: "2", label: "HERDAL - 14.590.963/0003-69" },
      ],
    },
    {
      branch: "03",
      itens: [{ value: "3", label: "MATRIZ - 14.590.963/0001-05" }],
    },
  ],
  corporativeCard: [
    { value: "S", label: "Sim" },
    { value: "N", label: "Não" },
  ],
  advance: [
    { value: "S", label: "Sim" },
    { value: "N", label: "Não" },
  ],
};

export const SupplierFormDefaultValue = {
  integra: [
    { value: "S", label: "Sim" },
    { value: "N", label: "Não" },
  ],
  status: [
    { value: "1", label: "Manutenção" },
    { value: "2", label: "Análise financeiro" },
    { value: "3", label: "Confirmado" },
  ],
  states: [
    { value: "AC", label: "Alagoas" },
    { label: "Alagoas", value: "AL" },
    { label: "Amapá", value: "AP" },
    { label: "Amazonas", value: "AM" },
    { label: "Bahia", value: "BA" },
    { label: "Ceará", value: "CE" },
    { label: "Distrito Federal", value: "DF" },
    { label: "Espírito Santo", value: "ES" },
    { label: "Goiás", value: "GO" },
    { label: "Maranhão", value: "MA" },
    { label: "Mato Grosso", value: "MT" },
    { label: "Mato Grosso do Sul", value: "MS" },
    { label: "Minas Gerais", value: "MG" },
    { label: "Pará", value: "PA" },
    { label: "Paraíba", value: "PB" },
    { label: "Paraná", value: "PR" },
    { label: "Pernambuco", value: "PE" },
    { label: "Piauí", value: "PI" },
    { label: "Rio de Janeiro", value: "RJ" },
    { label: "Rio Grande do Norte", value: "RN" },
    { label: "Rio Grande do Sul", value: "RS" },
    { label: "Rondônia", value: "RO" },
    { label: "Roraima", value: "RR" },
    { label: "Santa Catarina", value: "SC" },
    { label: "São Paulo", value: "SP" },
    { label: "Sergipe", value: "SE" },
    { label: "Tocantins", value: "TO" },
  ],
};

export const getOCStatusMask = (status: string): string => {
  switch (Number(status)) {
    case 1:
      return "Em manutenção";
    case 2:
      return "Aguardando aprovação";
    case 3:
      return "Aprovado";
    case 4:
      return "Reprovado";
    case 5:
      return "Fluxo Financeiro Total";
    case 6:
      return "Cancelado";
    case 7:
      return "Fluxo Financeiro Parcial";
    case 8:
      return "Aguardando aprovação do centro de custo";
    default:
      return "----";
  }
};

export const getNFStatusMask = (status: string): string => {
  switch (Number(status)) {
    case 1:
      return "Aguardando Aprovação da Solicitação";
    case 2:
      return "NFs Aprovadas";
    case 3:
      return "NFs Aprovadas com Divergencia";
    case 4:
      return "Aguardando vínculo com NF";
    case 5:
      return "Reprovado";
    case 6:
      return "Aguardando aprovação";
    default:
      return "----";
  }
};

export const getBrandsMask = (value: string): string => {
  switch (Number(value)) {
    case 1:
      return "BIODERMA";
    case 2:
      return "ESTHEDERM";
    case 4:
      return "ETAT PUR";
    default:
      return "----";
  }
};

export const getDeliveryLocationMask = (value: string): string => {
  switch (Number(value)) {
    case 1:
      return "LUFT - 14.590.963/0003-69";
    case 2:
      return "HERDAL - 14.590.963/0003-69";
    case 3:
      return "MATRIZ - 14.590.963/0001-05";
    default:
      return "----";
  }
};

export const getTypeMask = (value: string): string => {
  switch (Number(value)) {
    case 1:
      return "Ordem de compra";
    case 2:
      return "Pagamento Urgente";
    case 3:
      return "Solicitação Adiantamento";
    default:
      return "----";
  }
};

export const cnpjMask = (value: string): string => (
    value.replace(/\D+/g, '')
         .replace(/(\d{2})(\d)/, '$1.$2')
         .replace(/(\d{3})(\d)/, '$1.$2')
         .replace(/(\d{3})(\d)/, '$1/$2')
         .replace(/(\d{4})(\d)/, '$1-$2')
         .replace(/(-\d{2})\d+?$/, '$1')
)

export const cpfMask = (value: string): string => (
    value.replace(/\D+/g, '')
         .replace(/(\d{3})(\d)/, '$1.$2')
         .replace(/(\d{3})(\d)/, '$1.$2')
         .replace(/(\d{3})(\d)/, '$1-$2')
         .replace(/(-\d{2})\d+?$/, '$1')
)

export const cepMask = (value: string): string => (
    value.replace(/\D+/g, '').replace(/(\d{5})(\d)/, '$1-$2')
)

export const celphoneMask = (value: string, withDDD: boolean = true): string => {
    if(withDDD){
        return value.replace(/\D+/g, '')
             .replace(/(\d{0})(\d)/, '($1$2')
             .replace(/(\d{2})(\d)/, '$1) $2')
             .replace(/(\d{5})(\d)/, '$1-$2')
    } else{
        return value.replace(/\D+/g, '').replace(/(\d{5})(\d)/, '$1-$2')
    }
}

export const telphoneMask = (value: string, withDDD: boolean = true): string => {
    if(withDDD){
        return value.replace(/\D+/g, '')
             .replace(/(\d{0})(\d)/, '($1$2')
             .replace(/(\d{2})(\d)/, '$1) $2')
             .replace(/(\d{4})(\d)/, '$1-$2')
    } else{
        return value.replace(/\D+/g, '').replace(/(\d{4})(\d)/, '$1-$2')
    }
}
import React, { useMemo }  from 'react'
import { useState, useRef, useCallback} from 'react'

import { Document } from '../../../../../@types/documents'
import { AttachRequestData } from '../../../../../@types/attachFiles'

import { useDocuments } from '../../../../../hooks/documents'

import { convertFileToBase64 } from '../../../../../utils/format/convertFiles'

import DocumentFormDialog, { DocumentFormDialogRef, FormAction } from '../DocumentFormDialog'
import OptionsButtons from '../../../../../components/Buttons/OptionsButtons'
import DefaultDialog, { DefaultDialogRef } from '../../../../../components/Modal/DefaultDialog'
import AttachDialog, { UploadedFile } from '../../../../../components/Modal/AttachDialog'
import { Button } from '../../../../../components/Buttons/Button'

import { DataGrid, GridColumns, ptBR } from "@mui/x-data-grid"
import { Stack, Tooltip } from '@mui/material'
import { Add, AttachFile, Delete, Receipt, Visibility } from '@mui/icons-material'

import { Container, Actions, InitialAddIconContainer } from './styles'
import { useBuyOrder } from '../../../../../hooks/buyOrders'
import { formatValue } from '../../../../../utils/format/formatValue'
import { OCFormDefaultValue } from '../../../../../data/formValues'
import { toast } from 'react-toastify'

export interface DocumentAssignmentDialogRef {
    isOpen: boolean
    openDialog: () => void
    closeDialog: () => void
}

interface DocumentAssignmentDialogProps{
    hasAlteration: boolean
    onlyView?: boolean
    onFinishProcess: () => void
}

const DocumentAssignmentDialog: React.FC<DocumentAssignmentDialogProps> = ({ 
    onFinishProcess, 
    hasAlteration, 
    onlyView 
}) => {
    const { selectedOrder } = useBuyOrder()
    const { 
        currentDocuments,
        currentOCItens,
        removeDocument,
        changeDocumentFileStatus
    } = useDocuments()

    console.log((currentDocuments.length > 0 && !onlyView))
    console.log(onlyView)
    console.log(currentDocuments)

    const documentFormRef = useRef<DocumentFormDialogRef>(null)
    const deleteAlertDialog = useRef<DefaultDialogRef>(null)

    const [isSavingLocalData, setIsSavingLocalData] = useState<boolean>(false)
    const [selectedDocument, setSelectedDocument] = useState<Document | null>(null)
    const [formAction, setFormAction] = useState<FormAction>("add")

    const [openUploadFileModal, setOpenUploadFileModal] = useState<boolean>(false)

    const parsedDocs = useMemo(() => selectedOrder.itens?.map(item => {
        const currentDocument = selectedOrder.documentos.find((document) => document.itens?.includes(item.item as any))

        return {
            ...item,
            document: currentDocument 
                ? `${currentDocument.numero_nf}${currentDocument.serie_nf ? `-${currentDocument.serie_nf}` : ""}`
                : null
        }
    }),[selectedOrder])

    const isSyncedAllDocumentInItens = useMemo(() => {
        return parsedDocs?.every(item => item.document !== null) || false
    }, [parsedDocs])

    const renderStatusIcon = (props: Document) => {
        const currentStatus = OCFormDefaultValue.statusDocument.find(item => item.value === props.status)
        
        if(!currentStatus){
            return ""
        }

        const { Icon, label } = currentStatus

        return (
            <Tooltip title={ label }>
                <div><Icon/></div>
            </Tooltip>
        )
    }

    const columns: GridColumns = [
        { field: "status", headerName: "Status", headerAlign: "center", align: "center", renderCell: ({ row }) => renderStatusIcon(row)},
        { field: 'number', headerName: 'Número NF', headerAlign: "center", align: "center", width: 120 },
        { field: 'serie', headerName: 'Série'},
        { field: 'type', headerName: 'Tipo', width: 220},
        { field: 'value', headerName: 'Valor', flex: 1, renderCell: ({ row }) => formatValue(row.value) },
        { field: 'actions', headerName: 'Opções', headerAlign: "center", align: "center", width: 140, renderCell: ({ row }) => renderActionButton(row) }
    ]

    const renderActionButton = (row: Document) => (
        <>
        { row.status === "1" && !onlyView ?
            <>
            <OptionsButtons
                title="Anexar NF"
                type="button"
                action="attach"
                icon={ AttachFile }
                loading={ row.isLoadingUpload }
                onClick={() => { 
                    setSelectedDocument(row)
                    setOpenUploadFileModal(true)
                }}
            />
            {/* <OptionsButtons
                title="Editar"
                type="button"
                action="edit"
                icon={ Edit }
                onClick={() => { 
                    console.log(row)
                    setSelectedDocument(row)
                    setFormAction("edit")
                    documentFormRef.current?.openDialog()
                }}
            /> */}
            <OptionsButtons
                title="Remover"
                type="button"
                action="remove"
                icon={ Delete }
                onClick={() => {
                    setSelectedDocument(row)
                    deleteAlertDialog.current?.openDialog()
                }}
            />
            </>
        : 
            <OptionsButtons
                title="Visualizar"
                type="button"
                action="view"
                icon={ Visibility }
                onClick={() => {
                    setSelectedDocument(row)
                    setFormAction("view")
                    documentFormRef.current?.openDialog()
                }}
            />
        }
        </>
    )

    const handleRemove = useCallback(async() => {
        await removeDocument(selectedDocument?.id as string)
    },[selectedDocument, removeDocument])

    const saveDocumentsOnSessionStorage = useCallback(async(files: UploadedFile[]) => {
        setIsSavingLocalData(true)
        try{
            console.log("data")
            console.log(selectedDocument)
            let data: Array<Omit<AttachRequestData, "id">> = []
            let updatedList: any[] = []
    
            for(let file of files){
                data.push({
                    fileName: file.filename,
                    fileContent: await convertFileToBase64(file.file) as string
                })
            }
    
            const currentItens = JSON.parse(sessionStorage.getItem(`DocumentsForRequest${selectedOrder.id}`) || "null") as any[]

            console.log(currentItens)
    
            if(!!currentItens){
                const currentDocItens = currentItens.find(item => item.idNF === selectedDocument?.id)
    
                if(currentDocItens){
                    updatedList = currentItens.map(item => item.idNF === selectedDocument?.id ? ({
                        ...item,
                        assignedDocs: data
                    }): (item))
                } else{
                    updatedList = [
                        ...currentItens,
                        {
                            idNF: selectedDocument?.id,
                            assignedDocs: data
                        }
                    ]
                }
            } else{
                updatedList = [{
                    idNF: selectedDocument?.id,
                    assignedDocs: data
                }]
            }

            console.log(updatedList)
    
            sessionStorage.setItem(`DocumentsForRequest${selectedOrder.id}`, JSON.stringify(updatedList))

            changeDocumentFileStatus(selectedDocument!.id)
        }catch(error){
            console.log(error)
            toast.error("Falha ao realizar upload dos arquivos")
        }finally{
            setIsSavingLocalData(false)
            setOpenUploadFileModal(false)
        }

    },[selectedOrder, selectedDocument, changeDocumentFileStatus])

    return (
        <>
        <Container>
            { currentDocuments.length <= 0 ? (
                <InitialAddIconContainer 
                    onClick={() => {
                        setSelectedDocument(null)
                        documentFormRef.current?.openDialog()
                    }}
                >
                    <div>
                        <Receipt />
                        <Add />
                    </div>
                    <p>Clique aqui para adicionar uma NF</p>
                </InitialAddIconContainer>
            ):(
                <DataGrid
                    style={{ height: "45vh" }}
                    columns={ columns }
                    localeText={ ptBR.components.MuiDataGrid.defaultProps.localeText }
                    rows={ currentDocuments }
                    getRowId={ row => row.idNF }
                    pageSize={30}
                    rowsPerPageOptions={[30]}
                    disableColumnMenu
                    hideFooter
                    components={{
                        NoRowsOverlay: () => (
                            <Stack height="100%" alignItems="center" justifyContent="center">
                                Nenhum resultado foi encontrado
                            </Stack>
                        )
                    }}
                />
            )}
        </Container>
        { (currentDocuments.length > 0 && !onlyView) && (
            <Actions>
                { (
                    (currentOCItens.filter(item => item ? (
                        item.quantidade > 0 &&
                        !item.wasSynced
                    ) : false).length > 0) && 
                    (!isSyncedAllDocumentInItens)
                ) &&
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => {
                            setSelectedDocument(null)
                            setFormAction("add")
                            documentFormRef.current?.openDialog()
                        }}
                    >
                        Adicionar
                    </Button>
                }
                { hasAlteration &&
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={ onFinishProcess }
                    >
                        Validar Documentos
                    </Button>
                }
            </Actions>
        )}
        <DocumentFormDialog 
            ref={ documentFormRef }
            action={ formAction }
            selectedDocument={ selectedDocument }
        />
        <DefaultDialog 
            ref={ deleteAlertDialog }
            contentText={ `Tem certeza que deseja remover essa NF?` }
            open={ false }
            titleText="Remover NF"
            action={ handleRemove }
        />
        <AttachDialog 
            open={ openUploadFileModal }
            onUpload={ saveDocumentsOnSessionStorage }
            fullWidth
            isLoading={ isSavingLocalData }
            maxWidth="sm"
            disableEscapeKeyDown
            enableMultiSelection
            onClose={( _, reason) => {
                if(reason !== "backdropClick"){
                    setOpenUploadFileModal(false)
                }
            }}
        />
        </>
    )
}

export default DocumentAssignmentDialog
import React from 'react'
import { createContext, useContext, useCallback, useState } from 'react'

import { useAuth } from './auth'

import api from '../services/api.services'

import { getErrorMessage } from '../utils/validations/getErrorMessage'

import { Supplier, SupplierContextProps } from '../@types/supplier'
import { toast } from 'react-toastify'

const SupplierContext = createContext({} as SupplierContextProps)

export const SupplierProvider: React.FC = ({ children }) => {
    const { userData } = useAuth()

    const [suppliers, setSuppliers] = useState<Array<Supplier>>([])
    
    const [selectedSupplier, setSelectedSupplier] = useState<string>("")

    const getSuppliers = useCallback(async (params?: any | undefined): Promise<void> => {
        let requestParams = {} as any

        if(params){
            requestParams = params
        }

        if(!userData.permissions?.includes("FINANC")){
            requestParams.usuario = userData.id
        }

        setSuppliers([])
        try{
            const response = await api.get("/prefornecedor", {
                params: {
                    ...requestParams,
                    branch: userData.selectedBranch?.id as string
                }
            })

            if(response.data.data){ setSuppliers(response.data.data.prefornecedor) }
        } catch(error: any){
            console.log(error)
            toast.error(getErrorMessage(error))
        }
    },[userData])

    const removeSupplier = useCallback(async (id: string): Promise<void> => {
        try{
            await api.post("/prefornecedor", { fornecedor: {
                id: id,
                operacao: "E"
            }}, {
                params: {
                    branch: userData.selectedBranch?.id as string
                }
            })

            setSuppliers(prev => prev.filter(supplier => supplier.id !== id))

            toast.success("Pré cadastro de Fornecedor foi excluido com sucesso")
        } catch(error: any){
            console.log(error)
            throw new Error(error.message)
        }
    }, [userData])

    const selectASupplier = useCallback((id: string): void => {
        setSelectedSupplier(id)
    }, [])

    // 16
    const addNewSupplier = useCallback(async(data: Omit<Supplier, "id">): Promise<void> => {
        const requestData = {
            ...data,
            id: "",
            operacao: "I"
        }

        try{
            const response = await api.post("/prefornecedor", { fornecedor: requestData }, {
                params:{
                    branch: userData.selectedBranch?.id as string
                }
            })

            if(response.data.status.status !== 200){
                throw new Error(response.data.status.descricao)
            }

            toast.success("Fornecedor cadastrado com sucesso")
        } catch(error: any){
            console.log(error)
            throw error
        }
    },[userData])

    const searchSupplier = useCallback(async(id: string): Promise<Supplier>  => {
        let requestParams = {
            id: id,
            branch: userData.selectedBranch?.id as string
        } as any

        if(!userData.permissions?.includes("FINANC")){
            requestParams.usuario = userData.id
        }

        try{
            const response = await api.get("/prefornecedor",{ 
                params: requestParams
            })

            if(!response.data.data){ 
                throw new Error("Não foi encontrar os dados do fornecedor")
            }
            
            return response.data.data.prefornecedor[0]
        } catch(error: any){
            console.log(error)
            throw error
        }
    },[userData])

    const updateSupplier = useCallback(async(data: Supplier): Promise<void> => {
        const requestData = {
            ...data,
            operacao: "A"
        }

        try{
            await api.post("/prefornecedor", { fornecedor: requestData }, {
                params: {
                    branch: userData.selectedBranch?.id as string
                }
            })

            toast.success("Fornecedor cadastrado com sucesso")
        } catch(error: any){
            console.log(error)
            throw new Error(error.message)
        }
    },[userData])

    const implementsSupplier = useCallback(async(id: string) => {
        try{
            await api.post("/efetivarfornecedor", {
                fornecedor: { id }
            },{
                params: {
                    branch: userData.selectedBranch?.id
                }
            })

            setSuppliers(prev => prev.map(supplier => supplier.id === id ? ({
                ...supplier,
                status: "2"
            }): supplier))
            
        } catch(error: any){
            throw error
        }
    },[userData.selectedBranch])

    return(
        <SupplierContext.Provider 
            value={{ 
                suppliers, 
                selectedSupplier, 
                addNewSupplier,
                getSuppliers, 
                removeSupplier, 
                selectASupplier,
                searchSupplier,
                updateSupplier,
                implementsSupplier
            }}
        >
            { children }
        </SupplierContext.Provider>
    )
}

export const useSupplier = () => {
    const context = useContext(SupplierContext)

    if(!context){ throw new Error("Hook useSupplier must be used inside of Supplier Provider") }

    return context
}
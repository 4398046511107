import React from "react"

import { AuthProvider } from "./auth"
import { ThemeProvider } from "./theme"
import { InitialDataProvider } from "./initialData"
import { BillingProvider } from "./billing"
import { SellDetailsProvider } from "./useSellDetails"
import { AppParamsProvider } from "./appParams"

const AppProvider: React.FC = ({ children }) => (
    <ThemeProvider>
        <AuthProvider>
            <AppParamsProvider>
                <InitialDataProvider>
                    <BillingProvider>
                        <SellDetailsProvider>
                            { children }
                        </SellDetailsProvider>
                    </BillingProvider>
                </InitialDataProvider>
            </AppParamsProvider>
        </AuthProvider>
    </ThemeProvider>
)

export default AppProvider

import React from 'react'
import { useEffect } from 'react'
import { Link } from 'react-router-dom'

import { CircularProgress, SvgIconTypeMap, Tooltip } from '@mui/material'
import { Container } from "./style"
import { OverridableComponent } from '@mui/material/OverridableComponent'

export type ActionsButtonType = "view" | "edit" | "remove" | "aprove" | "attach" | "default" | "inherit" | "print" | "cancel"

interface OptionsButtonsProps{
    title?: string,
    icon: OverridableComponent<SvgIconTypeMap<{}, "svg">> & { muiName: string; }
    type: "button" | "link"
    to?: string,
    loading?: boolean
    style?: React.CSSProperties | undefined
    onClick: (event: any) => void
    action: ActionsButtonType
}

const OptionsButtons: React.FC<OptionsButtonsProps> = ({ 
    icon: 
    Icon, 
    to, 
    loading, 
    action, 
    title, 
    type, 
    onClick, 
    style
}) =>{

    useEffect(() => {
        if(type === "link"){
            if(!to){
                throw new Error("Option with type link must be have to prop")
            }
        }
    },[type, to])

    return (
        <Tooltip title={ title || "" }>
            <Container action={ action }>
                { type === "link" && !!to ?
                    <Link style={style} to={to} onClick={ onClick }>
                        { loading ? <CircularProgress size={14} /> : <Icon /> }
                    </Link>
                :
                    <button type="button" style={style} onClick={ onClick }>
                        { loading ? <CircularProgress size={14} /> : <Icon /> }
                    </button>
                }
            </Container>
        </Tooltip>
    )
}

export default OptionsButtons
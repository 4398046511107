import styled from 'styled-components'

export const Container = styled.div`
    height: calc(100vh - 214px);
    overflow-y: auto;
`

export const LoadingContainer = styled.div`
    width: 100%;
    min-height: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 4px;
`
import React, { ReactNode } from 'react'

import {
    Dialog,
    DialogContent,
    DialogProps,
    IconProps,
} from '@mui/material'

import { CloseOutlined } from '@mui/icons-material'

import { DialogTitleCustom } from '../../../styles/globalStyles'
import { CloseDialogButton, Hint } from './style'

interface DialogSubtitleProps extends DialogProps{
    title?: string
    onClose: () => void
    options: Array<{
        icon?: React.FC<IconProps> | React.FC
        legend?: ReactNode
        label: string
    }>
}

const DialogSubtitles: React.FC<DialogSubtitleProps> = ({ options, title, open, onClose, ...rest }) => {    
    return (
        <Dialog
            { ...rest }
            open={ open }
            onClose={ onClose }
        >
            <DialogTitleCustom>
                { !!title && `${title} - `}Legenda
                <CloseDialogButton onClick={ onClose }>
                    <CloseOutlined />
                </CloseDialogButton>
            </DialogTitleCustom>
            <DialogContent>
                <Hint>
                    { options.map((option, index) => {
                        const Legend = ( option.icon ? <option.icon/> : option.legend) || ""
                        return (
                            <div key={index}>
                                { Legend }
                                <p>{ option.label }</p>
                            </div>
                        )
                    })}
                </Hint>
            </DialogContent>
        </Dialog>
    )
}

export default DialogSubtitles
import styled from "styled-components"

import { ButtonBase } from "@mui/material"

export const Container = styled.div`
    width: 100%;

    &+&{
        margin-top: 10px;
    }
`

export const NavMenuItem = styled(ButtonBase)`
    width: 23% !important;
    display: flex;
    justify-content: flex-start !important;
    align-items: center;
    position: relative;
    border-radius: 5px !important;
    transition: 500ms !important;
    font-size: 18px;
    font-weight: bold;
    font-family: sans-serif;
    
    svg{
        width: 32px;
        height: 32px;
        margin: 9px;
        width: 32px;
        height: 32px;
    }

    p{
        min-width: 150px;
        text-align: left;
    }

    &:hover{
        cursor: pointer !important;
    }

    @media(max-width: 750px){
        width: 100% !important;
    }

`

export const SelectionIcon = styled.span`
    width: 10px;
    border-radius: 10px;
    height: 45px;
    background-color: #FFF;
    position: absolute;
    left: -5px;
    background-color: ${props => props.theme["yellow-500"] };
`
import styled from "styled-components"

export const NavMenuTitle = styled.div`
    width: 100%;
    margin-bottom: 12px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    position: relative;

    div.info{
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        position: relative;

        h2{
            display: flex;
            justify-content: center;
            align-items: center;
            color: #1f1f1f;
            width: 50px;
            height: 50px;
            border-radius: 50%;
        }
        
        img{
            width: 50px;
            height: 50px;
            object-fit: fill;
            border-radius: 5px;
            padding: 3px;
        }
        
        h2, img{
            background-color: #e4eaf7;
            margin-right: 15px;

            &:hover{
                cursor: pointer;
            }
        }

        p{
            color: #fff;
            font-family: sans-serif;
            font-weight: bold;

            &:hover{
                cursor: default;
            }
        }
    }
`
import styled from "styled-components"

export const Container = styled.div`
    width: calc(100% + 64px);
    background-color: white;
    margin-left: -64px;
    padding: 15px;

    p{
        font-size: 12px;
    }

    legend{
        font-size: 16px;
        font-weight: bold;
    }
    
    fieldset{
        border: 2px solid black;
    }

    @media (max-width: 750px){
        width: 100%;
        margin-left: 0px;
    }
`

export const Header = styled.div`
    width: 100%;
    padding: 5px 0px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    h1{
        font-size: 24px;
        font-weight: bold;
    }

    img{
        width: 200px;
    }
`

export const BoxContainer = styled.fieldset`
    width: 100%;
    border-radius: 5px;
    display: block;
    margin-top: 25px;

    legend{
        background-color: #FFFFFF;
        width: auto;
        padding: 0px 5px;
    }
`

export const BoxWithBreakContent = styled.fieldset`
    width: 100%;
    border-radius: 5px;
    display: block;
    break-inside: avoid;
    margin-top: 25px;

    legend{
        background-color: #FFFFFF;
        width: auto;
        padding: 0px 5px;
    }

    @media print {
        div {
            break-inside: avoid;
        }   
    }
`

export const ReportTable = styled.table`
    width: 100%;
    border-collapse: separate; 
    border-spacing: 0 10px;
    page-break-inside: auto;

    tr{
        page-break-inside: avoid;
        page-break-after: auto;
    }

    th, td{
        font-size: 12px;
    }

    thead{
        display: table-header-group;

        th{
            padding: 0px 15px;
        }
    }

    tbody{
        tr{
            height: 45px;
            
            td{
                border-top: 1px solid black;
                border-bottom: 1px solid black;
                padding: 0px 15px;
            }
            
            td:first-child{
                border-top-left-radius: 10px;
                border-bottom-left-radius: 10px;
                border-left: 1px solid black;
            }

            td:last-child{
                border-top-right-radius: 10px;
                border-bottom-right-radius: 10px;
                border-right: 1px solid black;
            }

            &:hover{
                cursor: default !important;
            }
        }
    }
`

export const Footer = styled.footer`
`
import { useMemo, useState } from 'react'

import { Column } from '@devexpress/dx-react-grid'
import { Document, Pendent } from '../..';
import { formatValue } from '../../../../utils/format/formatValue';
import { GridColumn } from '../../../../@types/datagrid';

import { DocumentItens, DocumentTable, PendentsTable } from './style';
import { DataTable } from '../../../../components/Tables/Datagrid';

export interface Luft {
    status: string
    exp_status: string
    delivery_status: string
    delivery_forecast: string
    delivery_date: string
}

interface DocumentsGridProps{
    documents: Document[],
    pendents: Pendent | null | undefined
}

export const DocumentsGrid: React.FC<DocumentsGridProps> = ({ documents, pendents }) => {
    const [pendentCategorieColumns] = useState<Column[]>([
        { name: 'name', title: 'Pendentes' },
        { name: 'total_products', title: 'Total Produtos' },
        { name: 'total_qtd', title: 'QTD' },
        { name: 'total_value', title: 'Valor Total' }
    ])

    const [pendentColumns] = useState<GridColumn[]>([
        { field: 'id', headerName: 'Pedido' },
        { field: 'product_cod', headerName: 'Código Produto' },
        { field: 'status_id', headerName: 'Status' },
        { field: 'QTD', headerName: 'Qtd' },
        { field: 'total_value', headerName: 'Valor Total' }
    ])

    const [documentColumns] = useState<GridColumn[]>([
        { 
            field: 'number', 
            headerName: 'Documento' 
        },
        { 
            field: 'serie', 
            headerName: 'Série' },
        { 
            field: 'status', 
            headerName: 'Status Luft',
            renderCell: (row: Document) => row.luft.status 
        },
        { 
            field: 'exp_status', 
            headerName: 'Status Expedição',
            renderCell: (row: Document) => row.luft.exp_status 
        },
        { 
            field: 'delivery_status', 
            headerName: 'Status de Entrega',
            renderCell: (row: Document) => row.luft.delivery_status
        },
        { 
            field: 'delivery_forecast', 
            headerName: 'Previsão de entrega',
            renderCell: (row: Document) => row.luft.delivery_forecast.replaceAll("-", "/") 
        },
        { 
            field: 'delivery_date', 
            headerName: 'Previsão de entrega',
            renderCell: (row: Document) => row.luft.delivery_date?.replaceAll("-", "/") 
        },
        { 
            field: 'created_at', 
            headerName: 'Data de Emissão' 
        },
        { 
            field: 'total_qtd', 
            headerName: 'QTD Total' 
        },
        { 
            field: 'total_value', 
            headerName: 'Valor Total' 
        }
    ])

    const [documentItensColumns] = useState<Column[]>([
        { name: 'id', title: 'Item' },
        { name: 'product_cod', title: 'Código Produto' },
        { name: 'description', title: 'Descrição' },
        { name: 'QTD', title: 'Qtd' },
        { name: 'total_value', title: 'Valor Total' }
    ])

    const pendentRows = useMemo(() => {
        return [{
            name: "Pendentes",
            total_products: `Total Produtos: ${pendents?.total_products}`,
            total_qtd: `QTD Total: ${pendents?.total_qtd}`,
            total_value: `Valor Total: ${pendents?.total_value}`
        }]
    },[pendents])

    const formatedDocuments = useMemo(() => (
        documents?.map(document => ({
            ...document,
            created_at: document.created_at.replaceAll("-", "/")
        })) || []
    ),[documents])

    const formatedPendents = useMemo(() => (
        pendents?.itens.map(item => ({
            ...item,
            total_value: formatValue(item.total_value) 
        })) || []
    ),[pendents])

    const formatedDocumentsItens = (row: Document) =>{

        const currentItens = documents.find(document => document.id === row.id)?.itens || []
        
        return currentItens.map(item => ({
            ...item,
            total_value: formatValue(item.total_value) 
        })) || []
    }

    return (
        <>
        { documents.length > 0 && (
            <DocumentTable 
                rows={[{ name: "Faturados" }]}
                columns={[{ name: 'name', title: 'Name' }]}
                disableHeader
                childToExpand={() => (
                    <DataTable
                        columns={ documentColumns }
                        rows={ formatedDocuments }
                        disableCheckboxSelection
                        paginationType='disabled'
                        setRowId={(row) => row.number}
                        childToExpand={(row) => (
                            <DocumentItens
                                rows={ formatedDocumentsItens(row as any) }
                                columns={ documentItensColumns }
                            />
                        )}
                    />
                )}
            />
        )}
        { !!pendents && (
            <PendentsTable
                rows={ pendentRows }
                columns={ pendentCategorieColumns }
                disableHeader
                childToExpand={() => (
                    <DataTable
                        rows={ formatedPendents }
                        columns={ pendentColumns }
                        disableCheckboxSelection
                        paginationType='disabled'
                    />
                )}
            />
        )}
        </>
    );
}
import { useCallback, useMemo, useState } from "react"
import { useQuery } from "react-query"
import { toast } from "react-toastify"

import apiService from "../../../services/api.services"

import { InventoryLimit, SupplyFilter } from "./components/Filters"
import { SkusTable } from "./components/SkusTable"
import { PercentChart, PercentChartOptions } from "../components/PercentChart"
import { ColumnChart } from "../components/ColumnChart"

import { CircularProgress, Grid, Skeleton, Tooltip } from "@mui/material"

import { DefaultTheme } from "../../../themes/defaultTheme"

import { Container, SkusCountContainer, ActionButton } from "./style"
import { Download } from "@mui/icons-material"
import { getErrorMessage } from "../../../utils/validations/getErrorMessage"
import { exportExcel } from "../../../utils/misc/exportExcel"

export enum BrandsTypes {
    BIODERMA = "01",
    ESTHEDERM = "02",
    NAOS = "03",
} 

interface Filters{
    limit: InventoryLimit
}

interface LimitParam{
    diasini: number
    diasfim: number
}

interface AxiosSKUListResponse{
    data: {
        sku_code: string
        sku_name: string
        stock_days: number
        per_represent: number
        inativo: "N" | "S"
    }[]
}

interface AxiosSKUResponse{
    skus_ruptura: number
    per_faturado: number
    per_ruptura: number
}

interface AxiosSkuGamaResponse{
    data: {
        gama: string
        per_gama: number
    }[]
}

type GamaInfoType = "rupture" | "attendance" | "general"

enum ConsultGamaType {
    rupture = "R",
    attendance = "A",
    general = "T"
}

const currentDate = new Date()
const currentPeriod = `${currentDate.getFullYear()}${String(currentDate.getMonth() + 1).padStart(2, "0")}`

export const SupplyDashboard: React.FC = () => {

    const [filters, setFilters] = useState<Filters>({
        limit: "5days"
    })

    const [selectedType, setSelectedType] = useState<GamaInfoType>("general")

    const [isLoadingReport, setIsLoadingReport] = useState<boolean>(false)

    const { 
        data: skuListData, 
        isLoading: skuListLoading, 
        error: skuListError, 
        isFetching: skuListFetching 
    } = useQuery([
        "skuList", 
        filters.limit
    ], 
    () =>  getDashboardSkuListValues(filters), 
    {
        staleTime: 1000 * 60 * 10, // 10min
        refetchInterval: 1000 * 60 * 10, // 10min
        refetchOnWindowFocus: true
    })

    const { 
        data: gamaData, 
        isLoading: gamaLoading,
        error: gamaError,
    } = useQuery(
        [
            "gamaInfo",
            selectedType
        ], 
        () => getGamaInfo(selectedType), 
        {
            staleTime: 1000 * 60 * 10, // 10min
            refetchInterval: 1000 * 60 * 10, // 10min
            refetchOnWindowFocus: true
        }
    )

    const { 
        data, 
        isLoading,
        error,
    } = useQuery(
        "skudashinfo", 
        () =>  getDashboardValues(), 
        {
            staleTime: 1000 * 60 * 10, // 10min
            refetchInterval: 1000 * 60 * 10, // 10min
            refetchOnWindowFocus: true
        }
    )

    const getLimitParams = (param: InventoryLimit): LimitParam | null => {
        switch(param){
            case "5days":
                return {
                    diasini: 0,
                    diasfim: 0
                }
            case "15days":
                return {
                    diasini: 6,
                    diasfim: 15
                }
            case "30days":
                return {
                    diasini: 16,
                    diasfim: 30
                }
            case "45days":
                return {
                    diasini: 31,
                    diasfim: 45
                }
            case "45plus":
                return {
                    diasini: 46,
                    diasfim: 999999
                }
            case "all": 
                return {
                    diasini: 0,
                    diasfim: 999999
                }
            default: 
                return null
        }
    }

    const getDashboardSkuListValues = async(params: Filters) => {

        const limitParams = getLimitParams(params.limit)

        const [skusListResponse] = await Promise.all([
            apiService.get<AxiosSKUListResponse>("/dashbo_rup_repres", {
                params: {
                    anomes: currentPeriod,
                    diasini: limitParams?.diasini || "0",
                    diasfim: limitParams?.diasfim || "5"
                }
            })
        ])

        if(!skusListResponse.data.data){
            toast.error("Não foi encontrado nenhum dado das skus")
        }

        return {
            skusList: skusListResponse.data.data || []
        }
    }

    const getDashboardValues = async() => {
        const skuInfoResponse = await apiService.get<AxiosSKUResponse>("/dashbo_rup_fatur", {
            params: {
                anomes: currentPeriod
            }
        })

        if(!skuInfoResponse.data.skus_ruptura){
            toast.error("Nenhum dado encontrado")
        }

        return skuInfoResponse.data
    }

    const getGamaInfo = async(type: GamaInfoType) => {

        const skuGamaResponse = await apiService.get<AxiosSkuGamaResponse>("/dashbo_rup_gama", {
            params: {
                anomes: currentPeriod,
                tipocons: ConsultGamaType[type]
            }
        })

        if(!skuGamaResponse.data.data){
            toast.error("Nenhum dado encontrado de sku por gama")
        }

        return skuGamaResponse.data.data || []
    }

    const exportListToExcel = async() => {
        toast.info("O Relatório está sendo gerado, por favor aguarde")
        setIsLoadingReport(true)

        try{
            const response = await apiService.get<AxiosSKUListResponse>("/dashbo_rup_repres", {
                params: {
                    anomes: currentPeriod,
                    diasini: 0,
                    diasfim: 0
                }
            })

            if(!response.data.data){
                toast.info("Não foram encontrados dados para emitir o relatório")
                return
            }

            exportExcel(response.data.data.map(item => ({
                "Código": item.sku_code,
                "Descrição": item.sku_name,
                "Dias de Estoque": item.stock_days,
                "Representatividade (%)": item.per_represent
            })))

            toast.success("Relatório gerado com sucesso")

        } catch(error: any){
            console.log(error)
            toast.error(getErrorMessage(error))
        } finally{
            setIsLoadingReport(false)
        }
    }

    const handleSkuTypeSelection = useCallback(({ label }: PercentChartOptions) => {
        let selection: GamaInfoType

        switch(label){
            case "Atendimento": 
                selection = "attendance"
                break
            case "Em Ruptura":
                selection = "rupture"
                break
            default:
                selection = "general"
                break
        }

        setSelectedType(prev => prev === selection ? "general" : selection)
    },[])

    const gamaChartValues = useMemo(() => ({
        name: "Representatividade",
        itens: gamaData?.map(item => ({
            category: item.gama,
            value: item.per_gama
        })) || []
    }),[gamaData])

    if(skuListError || error || gamaError){
        toast.error("Falha ao carregar dados da SKU")
    }

    return (
        <Container>
            <Grid container mb={2}>
                <Grid 
                    item 
                    xs={12} 
                    md={12}
                    display="flex"
                    alignItems="center"
                    gap="12px"
                >
                    <SupplyFilter 
                        isLoading={ skuListLoading || skuListFetching }
                        onChangeSelection={item => setFilters(prev => ({...prev, limit: item}))}
                    />
                    { isLoadingReport ? (
                        <ActionButton>
                            <CircularProgress size={18}/>
                        </ActionButton>
                    ): (
                        <Tooltip title="Exportar Relatório">
                            <ActionButton onClick={ exportListToExcel }>
                                <Download />
                            </ActionButton>
                        </Tooltip>
                    ) }
                </Grid>
            </Grid>
            <Grid 
                container
                overflow={["auto","auto","auto", "hidden"]}
                spacing={2}
            >
                <Grid item xs={12} md={12} lg={5}>
                    <SkusTable 
                        isLoading={ skuListLoading } 
                        skus={ skuListData?.skusList || []} 
                    />
                </Grid>
                <Grid item xs={12} md={12} lg={7}>
                    <Grid container spacing={2}>
                        <Grid 
                            item 
                            xs={12}
                            md={12} 
                            lg={6}
                        >
                            { isLoading ? (
                                <SkusCountContainer>
                                    <Skeleton 
                                        variant="rectangular" 
                                        height={"100%"}
                                        width={"100%"}
                                        animation="wave"
                                        style={{  borderRadius: "10px" }}
                                    />
                                </SkusCountContainer>
                            ): (
                                <SkusCountContainer>
                                    <h1>{ data?.skus_ruptura || 0 }</h1>
                                    <p>Skus em ruptura</p>
                                </SkusCountContainer>
                            ) }
                        </Grid>
                        <Grid 
                            item 
                            xs={12} 
                            md={12} 
                            lg={6}
                        >
                            <PercentChart 
                                isLoading={ isLoading }
                                height={300}
                                onSelectDataPoint={ handleSkuTypeSelection }
                                labels={[
                                    {
                                        name: "Em Ruptura",
                                        color: DefaultTheme["yellow-500"],
                                        value: data?.per_ruptura || 0
                                    },
                                    {
                                        name: "Atendimento",
                                        color: DefaultTheme["cyan-200"],
                                        value: data?.per_faturado || 0
                                    }
                                ]}
                            />
                        </Grid>
                        <Grid 
                            item 
                            xs={12} 
                            md={12} 
                            lg={12}
                        >
                            <ColumnChart
                                title="Representatividade por Gama"
                                value={ gamaChartValues  }
                                isLoading={ isLoading || gamaLoading }
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Container>
    )
}
import { useState, useEffect, useCallback } from 'react'
import { toast } from 'react-toastify'

import api from '../../../../services/api.services'

import { useAuth } from '../../../../hooks/auth'

import { removeSymbolsOnString } from '../../../../utils/format/removeSymbolsOnString'
import { cnpjMask, cpfMask } from '../../../../utils/format/masks'
import { getErrorMessage } from '../../../../utils/validations/getErrorMessage'

import { Button } from '../../../../components/Buttons/Button'
import { SelectionItemModal } from '../../../../components/Modal/SelectionItem'

import { GridColumns } from '@mui/x-data-grid'
import { Search } from '@mui/icons-material'
import { TextField } from '@mui/material'

import { SearchSupplier } from './style'

interface RequestSuppliersParams{
    tabela: string
    campos: string
    ordem: string
    pesquisa?: string
}

export interface Supplier{
    id: number
    cod: string
    loja: string
    cnpj: string
    reason: string
    name: string
    ie: string
    email: string
    isNew?: boolean
}

interface SelectSupplierProps{
    open: boolean
    initialSearch?: string
    onSelect: (products: Supplier) => void
    onClose: () => void
}

export const SelectSupplierModal: React.FC<SelectSupplierProps> = ({
    initialSearch,
    open,
    onSelect, 
    onClose 
}) => {
    const { userData } = useAuth()

    const [filterContent, setFilterContent] = useState<string>(initialSearch || "")
    const [isLoading, setIsLoading]         = useState<boolean>(true)
    const [selectedItem, setSelectedItem]   = useState<Supplier>({} as Supplier)
    const [suppliersList, setSuppliersList]   = useState<Array<Supplier>>([])

    const [tableColumns] = useState<GridColumns>([
        { field: 'cod', headerName: 'Código', align: "center", headerAlign: "center", width: 80 },
        { field: 'loja', headerName: 'Loja', align: "center", headerAlign: "center", width: 80 },
        { field: 'cnpj', headerName: 'CNPJ', width: 150, renderCell: props => props.row.cnpj.length === 14 ? cnpjMask(props.row.cnpj) : cpfMask(props.row.cnpj) },
        { field: 'reason', headerName: 'Razão Social', width: 500 },
        { field: 'name', headerName: 'Nome Fantasia', flex: 1, minWidth: 300 },
        { field: 'ie', headerName: 'Inscrição Estadual', headerAlign: "center", align: "center", flex: 1, minWidth: 150 },
        { field: 'email', headerName: "Email", flex: 1, minWidth: 300 }
    ])

    const getSuppliers = useCallback(async(): Promise<void> => {
        setSuppliersList([])

        const requestParams: RequestSuppliersParams = {
            tabela: "SA2",
            campos: "A2_NOME,A2_CGC,A2_INSCR,A2_EMAIL,A2_NREDUZ,A2_COD,A2_LOJA,A2_PRICOM",
            ordem: "A2_NOME"
        }

        if(!!filterContent){
            requestParams.pesquisa = removeSymbolsOnString(filterContent.toUpperCase())
        }

        try{
            const response = await api.get("/consultapadrao", {
                params: {
                    ...requestParams,
                    branch: userData.selectedBranch?.id as string
                }
            })

            if(response.data.data){
                setSuppliersList(response.data.data.consultapadrao.map((info: any, index: number) => ({
                    id: index,
                    cod: info.A2_COD,
                    loja: info.A2_LOJA,
                    cnpj: info.A2_CGC,
                    reason: info.A2_NOME,
                    name: info.A2_NREDUZ,
                    ie: info.A2_INSCR,
                    email: info.A2_EMAIL,
                    isNew: !info.A2_PRICOM.trim()
                })))
            }
        }catch(error: any){
            console.log(error)
            toast.error(getErrorMessage(error))
        } finally{
            setIsLoading(false)
        }

    }, [filterContent, userData])

    const handleSubmit = () => {
        onSelect(selectedItem)
        onClose()

        setTimeout(() => {
            setFilterContent("")
            setSuppliersList([])
        }, 200)
    }

    useEffect(() => {
        if(open){
            getSuppliers()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[open])

    return(
        <SelectionItemModal.Root
            open={ open }
            onClose={ onClose }
            fullWidth
            maxWidth="md"
        >
            <SelectionItemModal.Header>
                <TextField
                    label="Pesquisar Fornecedor"
                    size="small"
                    type="text"
                    value={ filterContent }
                    fullWidth
                    onChange={event => setFilterContent(event.target.value)}
                        onKeyPress={(event => {
                            if(event.key === "Enter"){
                                getSuppliers()
                            }
                        })} 
                />
                <SearchSupplier>
                    <button onClick={ getSuppliers }>
                        <Search/>
                    </button>
                </SearchSupplier>
            </SelectionItemModal.Header>
            <SelectionItemModal.Content>
                <SelectionItemModal.GridTable
                    rows={ suppliersList }
                    columns={ tableColumns }
                    notFoundMessage="Nenhum fornecedor encontrado"
                    onRowSelect={ row => setSelectedItem(row as Supplier) }
                    loading={ isLoading }
                    setClassname={ row => selectedItem.cod === row.cod ? `selected-row` : ""}
                    onRowDoubleClick={ row => {
                        setSelectedItem(row.row as Supplier)
                        onSelect(row.row as Supplier)
                        onClose()
                    }}
                />
            </SelectionItemModal.Content>
            <SelectionItemModal.Actions>
                <Button 
                    type="button"
                    size='large'
                    variant="text"
                    color="primary"
                    onClick={ onClose }
                >
                    Fechar
                </Button>
                <Button 
                    type="submit" 
                    variant="contained"
                    color="primary"
                    size='large'
                    onClick={ handleSubmit }
                >
                    Selecionar
                </Button>
            </SelectionItemModal.Actions>
        </SelectionItemModal.Root>
    )
}
import styled from "styled-components"
import { css } from "styled-components"
import { shade } from "polished"

interface FilterFormProps{
    align?: "top" | "bottom"
}

export const FilterForm = styled.div<FilterFormProps>`
    margin: 0px;
    width: 100%;
    padding: 20px;
    z-index: 1;
    background-color: ${ props => `${props.theme["blue-500"]}cc` };
    border-radius: 10px;
    backdrop-filter: blur(2px);
    box-shadow: 2px 5px 5px ${ props => shade(0.1, props.theme["blue-500"]) };

    ${ props => props.align === "top" && css`
        top: 0px;
    `}

    ${ props => props.align === "bottom" && css`
        bottom: 0px;
    `}

    ${ props => !props.align && css`
        bottom: 0px;
    `}

    .MuiTextField-root, .MuiFormControl-root{
        fieldset{
            transition: 200ms;
            border-color: #ffffff !important;
        }

        svg, input, label, select, fieldset, div{
            color: #ffffff !important;
        }

        &, fieldset{
            border-radius: 5px !important;
        }

        span, label, svg{
            color: #fff;
        }
    }

    .Mui-focused{
        color: ${props => props.theme["yellow-500"] } !important;
        
        input, div{ color: ${props => props.theme["yellow-500"] } !important; }

        fieldset{ border-color: ${props => props.theme["yellow-500"] } !important; }
    }
`

export const FilterContent = styled.div`
    width: 100%;
    
    section{
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
    }
`

export const FilterActions = styled.div`
    width: 100%;
    margin-top: 20px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 14px;

    button{
        border-color: #FFF;
        color: #FFF;

        svg{
            color: #FFF !important;
        }

        .MuiTouchRipple-root{
            span{
                background-color: #FFF;
            }
        } 

        &:hover{
            border-color: #FFF !important;
        }

        &:last-child{
            background-color: #FFF !important;
            color: ${props => props.theme["blue-500"] } !important;
        
            svg{
                color: ${props => props.theme["blue-500"] } !important;
            }
        
            &:disabled{
                background-color: ${ shade(0.4, "#FFF")} !important;
            }
        
            &:hover{
                background-color: ${ shade(0.2, "#FFF")} !important;
            }
        }
    }
`
import styled from "styled-components"
import { css } from "styled-components"
 
interface TabItemProps {
    selected?: boolean
}

export const TabNavigation = styled.div`
    width: min-content;
    display: flex;
    flex-direction: row;
    position: relative;
`

export const TabItem = styled.button<TabItemProps>`
    background-color: #ffffff00;
    width: 250px;
    border: none;
    height: auto;
    padding: 8px;
    border-radius: 10px 10px 0px 0px;
    position: relative;

    ${ props => props.selected ? css`
        background-color: ${ props.theme["blue-800"] };
        color: ${ props.theme["white-100"] };

        &::after{
            content: "";
            position: absolute;
            width: 20px;
            height: 20px;
            background-color: ${ props.theme["blue-800"] };
            right: -20px;
            bottom: 0;
            z-index: 1;
        }

        &:not(:first-child){
            &::before{
                content: "";
                position: absolute;
                width: 20px;
                height: 20px;
                background-color: ${ props.theme["blue-800"] };
                left: -20px;
                bottom: 0;
                z-index: 1;
            }

            div.last-detail{
                position: absolute;
                width: 20px;
                height: 20px;
                background-color: ${ props.theme["blue-50"] };
                border-bottom-left-radius: 9999px;
                right: -20px;
                bottom: 0;
                z-index: 2;
            }
        }
    `: css`
        background-color: ${ props => props.theme["blue-50"] };
        color: ${ props.theme["blue-800"] };
        border-radius: 20px;
        z-index: 2;
    ` }

    @media(max-width: 1210px){
        width: 200px;
        font-size: 12px !important;
        padding: 2px;
    }
`
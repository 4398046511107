import { Dialog, DialogProps, Stack } from "@mui/material"
import { DataGrid, ptBR, GridColumns } from '@mui/x-data-grid'
import { PropsWithChildren } from "react"
import { Container, ContentModal, HeaderModal, ActionButtons } from "./style"

interface Row{
    [key: string]: any
}

interface GridProps{
    columns: GridColumns
    rows: Row[]
    loading?: boolean
    notFoundMessage?: string
    setRowID?: (item: Row) => string
    setClassname?: (item: Row) => string
    onRowSelect?: (item: Row) => void
    onRowDoubleClick?: (item: Row) => void
}

const Root: React.FC<PropsWithChildren<DialogProps>> = ({ 
    children, 
    ...rest 
}) => {
    return (
        <Dialog
            fullWidth
            maxWidth="md"
            { ...rest }
        >
            <Container>
                { children }
            </Container>
        </Dialog>
    )
}

const Header: React.FC = ({ children }) => {
    return (
        <HeaderModal>
            { children }
        </HeaderModal>
    )
}

const Content: React.FC = ({ children }) => {
    return (
        <ContentModal>
            { children }
        </ContentModal>
    )
}

const Actions: React.FC = ({ children }) => {
    return (
        <ActionButtons>
            { children }
        </ActionButtons>
    )
}

const GridTable: React.FC<GridProps> = ({
    columns,
    rows,
    loading,
    notFoundMessage,
    setClassname,
    setRowID,
    onRowSelect,
    onRowDoubleClick
}) => {
    return (
        <DataGrid
            localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
            rows={ rows }
            columns={ columns }
            pageSize={30}
            rowsPerPageOptions={[30]}
            getRowId={ setRowID }
            getRowClassName={({ row }) => setClassname ? setClassname(row) : ""}
            disableColumnFilter
            loading={ loading }
            onRowClick={({ row }) => onRowSelect ? onRowSelect(row) : undefined}
            onRowDoubleClick={ onRowDoubleClick }
            components={{
                NoRowsOverlay: () => (
                    <Stack height="100%" alignItems="center" justifyContent="center">
                        { notFoundMessage || "Registros não encontrados" }
                    </Stack>
                )
            }}
        />
    )
}

Root.displayName = "SelectionItemModal.Root"
Header.displayName = "SelectionItemModal.Header"
Content.displayName = "SelectionItemModal.Content"
GridTable.displayName = "SelectionItemModal.GridTable"
Actions.displayName = "SelectionItemModal.Actions"

export const SelectionItemModal = {
    Root,
    Header,
    Content,
    GridTable,
    Actions
}
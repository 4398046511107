import { useMemo, useCallback, useState, useEffect } from "react"
import { useQuery } from "react-query"
import { toast } from "react-toastify"

import { BrandsResponseData, ClientResponseData, GamaResponseData, PercentInvoicedResponseData, Quotes } from "../../../@types/dashboards"

import apiService from "../../../services/api.services"

import { formatValue } from "../../../utils/format/formatValue"

import { InvoicedPercentChart } from "../components/InvoicedPercentChart"
import { ResumeBrandChart } from "../components/ResumeBrandChart"

import { Grid } from "@mui/material"
import { Container } from "./style"
import { DashboardFilter } from "../components/Filters"
import { BarTargetChart } from "../components/BarTargetChart"
import { ClientsTable } from "../components/ClientsTable"
import { DefaultTheme } from "../../../themes/defaultTheme"

export enum BrandsTypes {
    BIODERMA = "01",
    ESTHEDERM = "02",
    NAOS = "03"
} 

interface Filters{
    period: string
    quote: Quotes
}

const gamaChartLegends = [
    {
        name: "Realizado",
        color: DefaultTheme["cyan-200"]
    },
    {
        name: "Target",
        color: DefaultTheme["yellow-500"]
    }
]


export const MainDashboard: React.FC = () => {

    const [filters, setFilters] = useState<Filters>(() => {
        const currentDate = new Date()
        const savedPeriod = sessionStorage.getItem("@portalnaos:dashboard:period")
        const currentPeriod = savedPeriod || `${currentDate.getFullYear()}-${String(currentDate.getMonth() + 1).padStart(2, "0")}`

        return {
            period: currentPeriod,
            quote: "BU"
        }
    })

    const [selectedBrand, setSelectedBrand] = useState<BrandsTypes | null>(null)

    const { data, isLoading, error, isFetching } = useQuery([
        "dashboard", 
        filters.quote, 
        filters.period,
        selectedBrand
    ], 
    () =>  getDashboardValues(filters), 
    {
        staleTime: 1000 * 60 * 10, // 10min
        refetchInterval: 1000 * 60 * 10, // 10min
        refetchOnWindowFocus: true
    })

    const getDashboardValues = async(params: Filters) => {
        const requestParams: any = {
            anomes: params.period.replaceAll("-", ""),
            tipocota: params.quote,
        }

        if(selectedBrand){
            requestParams.marca = selectedBrand
        }

        const [
            brandsResponse, 
            invoicedResponse,
            gamaResponse,
            clientResponse
        ] = await Promise.all([
            apiService.get<BrandsResponseData>("/dashbo_venmet", {
                params: requestParams
            }),
            apiService.get<PercentInvoicedResponseData>("/dashbo_pedvennf", {
                params: requestParams
            }),
            apiService.get<GamaResponseData>("/dashbo_targama", {
                params: requestParams
            }),
            apiService.get<ClientResponseData>("/dashbo_targcli", {
                params: requestParams
            })
        ])

        if(!brandsResponse.data.summary){
            toast.error("Dados das marcas não encontrados")
        }

        if(!invoicedResponse.data){
            toast.error("Falha ao carregar dados do faturamento")
        }

        if(!gamaResponse.data){
            toast.error("Falha ao carregar dados das gamas")
        }

        if(!clientResponse.data){
            toast.error("Falha ao carregar dados dos clientes")
        }

        const brands = {
            bioderma: brandsResponse.data.summary?.find(brand => brand.brand_id === BrandsTypes.BIODERMA),
            esthederm: brandsResponse.data.summary?.find(brand => brand.brand_id === BrandsTypes.ESTHEDERM),
            naos: brandsResponse.data.summary?.find(brand => brand.brand_id === BrandsTypes.NAOS)
        }

        return {
            brands,
            invoiced: invoicedResponse.data,
            gamas: gamaResponse.data?.data || [],
            clients: clientResponse.data?.data || []
        }
    }

    const hanldeQuoteSelection = useCallback(async(quote: Quotes) => {
        setFilters(prev => ({
            ...prev,
            quote: quote
        }))
    },[])

    const handleFilter = useCallback(async(filters: Filters) => {
        setFilters(prev => ({
            ...prev,
            ...filters
        }))
    },[])

    const biodermaChartValues = useMemo(() => {
        if(!data?.brands){
            return null
        }

        const { bioderma } = data.brands

        if(!bioderma){
            return null
        }

        return {
            title: `${bioderma.brand} - R$${formatValue(bioderma.value)} %${Math.floor(bioderma.current_percent)}`,
            subtitle: `Target: R$${formatValue(bioderma.target)}`,
            labels: bioderma.data.map(item => item.date),
            values: bioderma.data.map(item => item.current_percent)
        }
    },[data?.brands])

    const esthedermChartValues = useMemo(() => {
        if(!data?.brands){
            return null
        }

        const { esthederm } = data.brands

        if(!esthederm){
            return null
        }

        return {
            title: `${esthederm.brand} - R$${formatValue(esthederm.value)} %${Math.floor(esthederm.current_percent)}`,
            subtitle: `Target: R$${formatValue(esthederm.target)}`,
            labels: esthederm.data.map(item => item.date),
            values: esthederm.data.map(item => item.current_percent)
        }
    },[data?.brands])

    const naosChartValues = useMemo(() => {
        if(!data?.brands){
            return null
        }

        const { naos } = data.brands

        if(!naos){
            return null
        }

        return {
            title: `${naos.brand} - R$${formatValue(naos.value)} %${Math.floor(naos.current_percent)}`,
            subtitle: `Target: R$${formatValue(naos.target)}`,
            labels: naos.data.map(item => item.date),
            values: naos.data.map(item => item.current_percent)
        }
    },[data?.brands])

    const gamasBiodermaChartValues = useMemo(() => {
        if(!data?.gamas){
            return []
        }

        return data.gamas
            .filter(gama => gama.brand_code === BrandsTypes.BIODERMA)
            .map(gama => ({
                label: gama.gama_description,
                value: gama.sold_amount,
                label_info_id: gama.gama_code,
                goals: [{
                    title: "Target",
                    value: gama.target_quantity
                }]
            }))
    },[data?.gamas])

    const gamasEsthedermChartValues = useMemo(() => {
        if(!data?.gamas){
            return []
        }

        return data.gamas
            .filter(gama => gama.brand_code === BrandsTypes.ESTHEDERM)
            .map(gama => ({
                label: gama.gama_description,
                value: gama.sold_amount,
                label_info_id: gama.gama_code,
                goals: [{
                    title: "Target",
                    value: gama.target_quantity
                }]
            }))
    },[data?.gamas])

    useEffect(() => {
        sessionStorage.setItem("@portalnaos:dashboard:period", filters.period)
    },[filters.period])

    if(error){
        toast.error("Falha ao carregar dados")
    }

    return (
        <Container>
            <Grid container my={2}>
                <Grid item xs={12} md={12}>
                    <DashboardFilter 
                        isLoading={ isLoading || isFetching }
                        onChangeQuote={ hanldeQuoteSelection }
                        onStartFilter={ handleFilter }
                    />
                </Grid>
            </Grid>
            <Grid 
                container
                maxHeight={"calc(100vh - 178px)"}
                overflow="auto"
                spacing={2}
            >
                <Grid item xs={12} md={12} lg={3}>
                    <ResumeBrandChart 
                        isLoading={ isLoading }
                        title={ biodermaChartValues?.title || "" }
                        subtitle={ biodermaChartValues?.subtitle || "" }
                        height={200}
                        labels={biodermaChartValues?.labels || []}
                        values={biodermaChartValues?.values || []}
                        onSelectCard={() => setSelectedBrand(BrandsTypes.BIODERMA)}
                        isSelected={ selectedBrand === BrandsTypes.BIODERMA }
                    />
                </Grid>
                <Grid item  xs={12} md={12} lg={3}>
                    <ResumeBrandChart 
                        isLoading={ isLoading }
                        title={ esthedermChartValues?.title || "" }
                        subtitle={ esthedermChartValues?.subtitle || "" }
                        height={200}
                        labels={esthedermChartValues?.labels || []}
                        values={esthedermChartValues?.values || []}
                        onSelectCard={() => setSelectedBrand(BrandsTypes.ESTHEDERM)}
                        isSelected={ selectedBrand === BrandsTypes.ESTHEDERM }
                    />
                </Grid>
                <Grid item xs={12} md={12} lg={3}>
                    <ResumeBrandChart 
                        isLoading={ isLoading }
                        title={ naosChartValues?.title || "" }
                        subtitle={ naosChartValues?.subtitle || "" }
                        height={200}
                        labels={naosChartValues?.labels || []}
                        values={naosChartValues?.values || []}
                        onSelectCard={() => setSelectedBrand(null)}
                        isSelected={ !selectedBrand }
                    />
                </Grid>
                <Grid item xs={12} md={12} lg={3}>
                    <InvoicedPercentChart 
                        isLoading={ isLoading }
                        label="% SLA Supply"
                        value={data?.invoiced?.total_percent || 0}
                        height={220}
                    />
                </Grid>
                <Grid item xs={12} md={12} lg={6}>
                    <ClientsTable 
                        isLoading={ isLoading }
                        clients={ data?.clients || [] } 
                    />
                </Grid>
                <Grid item xs={12} md={12} lg={6}>
                    <Grid container>
                        <Grid item xs={12} mb={2}>
                            <BarTargetChart
                                title="Target por gama BIODERMA (unidades)"
                                height={ 350 }
                                isLoading={ isLoading }
                                values={ (selectedBrand === BrandsTypes.BIODERMA || !selectedBrand) ? gamasBiodermaChartValues : [] }
                                legends={ gamaChartLegends }
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <BarTargetChart 
                                title="Target por gama ESTHEDERM (unidades)"
                                isLoading={ isLoading }
                                height={ 350 }
                                values={ (selectedBrand === BrandsTypes.ESTHEDERM || !selectedBrand) ? gamasEsthedermChartValues : [] }
                                legends={ gamaChartLegends }
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Container>
    )
}
import { useState, useEffect } from 'react'
import { ApexOptions } from 'apexcharts'
import Chart from 'react-apexcharts'

import { ChartLocales } from "../../../../data/chartLocales"
import { Container, NotFoundContainer, SkeletonChart } from "./style"
import { DefaultTheme } from '../../../../themes/defaultTheme'
import { formatValue } from '../../../../utils/format/formatValue'

export interface ChartSelection{
    label: string,
    value: number
}

interface ColumnChartProps{
    title: string
    value: {
        name: string
        itens: {
            category: string
            value: number
        }[]
    }
    width?: number
    height?: number
    isLoading?: boolean
    onSelectDataPoint?: (selectedPoint: ChartSelection) => void
}

const defaultOptions: ApexOptions = {
    colors: [DefaultTheme['cyan-200']],
    theme: {
        mode: "dark"
    },
    chart: {
        defaultLocale: 'pt-br',
        locales: ChartLocales
    },
    plotOptions: {
        bar: {
            borderRadius: 10,
            dataLabels: {
                position: 'top', // top, center, bottom
            },
        }
    },
    dataLabels: {
        enabled: true,
        formatter: (value) => isNaN(Number(value)) 
            ? value.toString() + "%"
            : `${formatValue(Number(value))}%`
        ,
        offsetY: -20,
        style: {
            fontSize: '12px',
            colors: [DefaultTheme['yellow-500']]
        }
    },
    xaxis: {
        categories: [],
        position: 'top',
        axisBorder: {
          show: false
        },
        axisTicks: {
          show: false
        },
        crosshairs: {
            fill: {
                type: 'gradient',
                gradient: {
                    colorFrom: '#D8E3F0',
                    colorTo: '#BED1E6',
                    stops: [0, 100],
                    opacityFrom: 0.4,
                    opacityTo: 0.5,
                }
            }
        },
        tooltip: {
            enabled: true,
        },
        labels: {
            formatter: (value) => isNaN(Number(value)) 
                ? value
                : formatValue(Number(value))
        },
    },
    yaxis: {
        axisBorder: {
            show: false
        },
        axisTicks: {
            show: false,
        },
        labels: {
            show: false,
                formatter: (value) => isNaN(value) 
                    ? value + "%"
                    : `${formatValue(value)}%`
        }
    }
}

export const ColumnChart: React.FC<ColumnChartProps> = ({ 
    title, 
    value, 
    height,
    width, 
    isLoading,
    onSelectDataPoint
}) => {

    const [options, setOptions] = useState<ApexOptions>(defaultOptions)
    const [series, setSeries] = useState<any[]>([])

    useEffect(() => {
        setOptions(prev => ({
            ...prev,
            chart: {
                ...prev.chart,
                events: {
                    dataPointSelection: onSelectDataPoint ? (
                        function (_, __, options){
                            const selectedInfo = options.w.config.series[0].data[options.dataPointIndex]
                            onSelectDataPoint(selectedInfo)
                        }
                    ): undefined
                },
            },
            title: {
                text: title,
                floating: false,
                align: 'center',
                style: {
                    color: '#444'
                }
            },
            xaxis: {
                ...prev.xaxis,
                categories: value.itens.map(item => item.category)
            }
        }))

        setSeries([{
            name: value.name,
            data: value.itens.map(item => item.value)
        }])
    },[title, value, onSelectDataPoint])

    if(isLoading){
        return (
            <Container>
                <SkeletonChart 
                    variant="rectangular" 
                    height={"100%"}
                    animation="wave"
                />
            </Container>

        )
    }

    if(value.itens.length <= 0){
        return (
            <Container>
                <NotFoundContainer>
                    <h1>Nenhum Resultado Encontrado</h1>
                </NotFoundContainer>
            </Container>
        )
    }

    return (
        <Container>
            <Chart 
                type="bar"
                options={options}
                series={series}
                height={height || "100%"}
                width={width}
            />
        </Container>
    )
}
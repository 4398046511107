import styled from "styled-components"

export const PaginationButton = styled.button`
    width: auto;
    min-width: 22px;
    height: 22px;

    background-color: #c2c2c2;
    border: none;
    border-radius: 4px;

    &[disabled]{
        background-color: ${ props => props.theme["blue-800"] };
        color: #fff;
    }

    &:hover{
        transition: 200ms;
        opacity: 0.8;
    }
`
import styled from "styled-components"

interface HeaderModalProps{
    disabled?: boolean
}

export const Container = styled.div`
    width: 100%;
    padding: 15px;

    .selected-row{
        background-color: #bbfcd0 !important;
    }
`

export const HeaderModal = styled.div<HeaderModalProps>`
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-bottom: 15px;
    position: relative;

    .Mui-focused{
        color: ${props => props.theme["yellow-500"] } !important;
        
        input{ color: ${props => props.theme["yellow-500"] } !important; }

        fieldset{ border-color: ${props => props.theme["yellow-500"] } !important; }
    }
`

export const ContentModal = styled.div`
    width: 100%;
    height: 50vh;
    position: relative;

    > p{
        position: absolute;
        bottom: 15px;
        left: 15px;
        font-size: 15px;
        background-color: #e6e6e6;
        padding: 0px 5px;
        border-radius: 10px;
        transition: 200ms;
        z-index: 1;

        &:hover{
            cursor: pointer;
            background-color: #3a6df0;
        }
    }

    > button{
        position: absolute;
        top: 0;
        right: 0;
        background-color: #ffffff00;
        border: none;
    }

    > div{
        z-index: 0;
    }

    .MuiDataGrid-row:hover{
        cursor: pointer !important;
    }

    .MuiDataGrid-selectedRowCount{
        visibility: hidden !important;
    }
`

export const ActionButtons = styled.div`
    margin-top: 25px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 8px;
`
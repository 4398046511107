import { IconProps } from "../../../@types/icons";

export const FilterIcon: React.FC<IconProps> = ({ color, height, size }) => (
    <svg 
        width={ size || "24"} 
        height={ height || "24"}  
        color={ color }
        viewBox="0 0 24 24" 
        fill="none" 
        xmlns="http://www.w3.org/2000/svg"
    >
        <path d="M21.4219 4.64063C21.3052 4.3751 21.1135 4.14941 20.8704 3.99121C20.6273 3.83301 20.3433 3.74919 20.0532 3.75001H3.94694C3.65696 3.75234 3.37379 3.83816 3.1313 3.99719C2.88881 4.15623 2.69727 4.38176 2.57959 4.64679C2.4619 4.91183 2.42306 5.20516 2.4677 5.49169C2.51234 5.77823 2.63858 6.04583 2.83132 6.26251L9.00007 13.0406V20.3531C8.99961 20.6252 9.07362 20.8922 9.21409 21.1252C9.35456 21.3583 9.55613 21.5484 9.79694 21.675C10.0138 21.7896 10.2548 21.8507 10.5001 21.8531C10.7971 21.8532 11.0875 21.7651 11.3344 21.6L14.3344 19.5938C14.5399 19.4572 14.7083 19.2718 14.8244 19.0542C14.9406 18.8366 15.001 18.5936 15.0001 18.3469V13.0406L21.1688 6.26251C21.3651 6.04661 21.4937 5.77785 21.5387 5.48955C21.5837 5.20124 21.5431 4.90607 21.4219 4.64063Z" fill="#415D60"/>
    </svg>
)